import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Layout,
  Table,
  Button,
  // Tooltip,
  // Input,
  // Icon,
  Tabs,
  Modal,
} from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { withRouter, useParams } from 'react-router-dom';
import moment from 'moment';
import PatientApi from 'api/patient';
import TransactionApi from 'api/transaction';
import AppointmentApi from 'api/appointment';
// import { PageSpinner } from 'components/page-spinner';
import { setToArray } from 'utils';

import {
  patientBillColumns,
  patientBillDetailColumns,
  patientBillDetailSummaryColumns,
  allTreatmentColumns,
  allTreatmentSummaryColumns,
} from '../../helper/';

import '../../styles/index.scss';

const { Content } = Layout;
const { TabPane } = Tabs;

export function PembayaranPasienDetail({ history }) {
  const objectTreatmentPayment = [
    {
      key: 1,
      column8: 'Total Tagihan Lunas',
      column9: 0,
    },
    {
      key: 2,
      column8: 'Total Tagihan Tak Lunas',
      column9: 0,
    },
  ];

  const objectBillSummary = [
    {
      key: 1,
      column6: 'Grand Total',
      column7: 0,
    },
  ];

  const [patient, setPatient] = useState();

  const [billStatus, setBillStatus] = useState();

  const [listUnpaidBill, setListUnpaidBill] = useState([]);
  const [listPaidBill, setListPaidBill] = useState([]);
  const [listBillDetail, setListBillDetail] = useState([]);
  const [listBillSummary, setListBillSummary] = useState(objectBillSummary);
  const [listTreatment, setListTreatment] = useState([]);
  const [listTreatmentPayment, setListTreatmentPayment] = useState(
    objectTreatmentPayment
  );

  const [isBillDetailPopupOpen, setIsBillDetailPopupOpen] = useState(false);

  // const [loadingPage, setLoadingPage] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    showSizeChanger: true,
  });

  const { patientId } = useParams();

  async function getPatientDetail() {
    const response = await PatientApi.getPatientDetail(patientId);

    let patientDetail = response.data.data;

    setPatient(patientDetail);
  }

  async function getBillList(billStatus) {
    const response = await TransactionApi.getBillList(billStatus, patientId);

    let billList = setToArray(response.data);

    const modifiedBillList = billList.map((item, index) => ({
      ...item,
      key: index + 1,
    }));

    if (billStatus === 'CONFIRMED') setListUnpaidBill(modifiedBillList);
    else if (billStatus === 'PAID') setListPaidBill(modifiedBillList);
  }

  const getUnpaidTreatmentList = async (appointmentId) => {
    const response = await AppointmentApi.getTreatmentList(
      appointmentId,
      patientId,
      billStatus
    );

    let treatment = setToArray(response.data);

    const modifiedTreatment = treatment.map((item, index) => ({
      ...item,
      key: index + 1,
    }));

    setListBillDetail(modifiedTreatment);

    const newBillSummary = [...listBillSummary];

    newBillSummary[0].column7 = response.data.data.notPaid;

    setListBillSummary(newBillSummary);
  };

  const getPaidTreatmentList = async (receiptId) => {
    const response = await TransactionApi.getPaidBillDetail(receiptId);

    let treatment = setToArray(response.data);

    const modifiedTreatment = treatment.map((item, index) => ({
      ...item,
      key: index + 1,
    }));

    setListBillDetail(modifiedTreatment);

    const newBillSummary = [...listBillSummary];

    newBillSummary[0].column7 = response.data.data.paidOff;

    setListBillSummary(newBillSummary);
  };

  const getAllTreatmentList = async () => {
    const response = await AppointmentApi.getAllTreatmentList(patientId);

    let treatment = setToArray(response.data);

    const modifiedTreatment = treatment.map((item, index) => ({
      ...item,
      key: index + 1,
    }));

    setListTreatment(modifiedTreatment);

    const newTreatmentPayment = [...listTreatmentPayment];

    newTreatmentPayment[0].column9 = response.data.data.paidOff;

    newTreatmentPayment[1].column9 = response.data.data.notPaid;

    setListTreatmentPayment(newTreatmentPayment);
  };

  const onTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleTabChange = async (key) => {
    switch (key) {
      case '1':
        await getPatientDetail();
        break;
      case '2':
        await getBillList('CONFIRMED');
        setBillStatus('CONFIRMED');
        break;
      case '3':
        await getBillList('PAID');
        setBillStatus('PAID');
        break;
      case '4':
        await getAllTreatmentList();
        break;
      case '5':
        redirectToPembayaranPasien();
        break;
      default:
        break;
    }

    setPagination({ ...pagination, current: 1, pageSize: 10 });
  };

  const handleBillDetailOpen = (appointmentId, receiptId) => {
    if (!isBillDetailPopupOpen) {
      if (billStatus === 'CONFIRMED') getUnpaidTreatmentList(appointmentId);
      else if (billStatus === 'PAID') getPaidTreatmentList(receiptId);
    }

    setIsBillDetailPopupOpen(!isBillDetailPopupOpen);
    setPagination({ ...pagination, current: 1, pageSize: 10 });
  };

  const redirectToPembayaranPasien = () => {
    history.push(`/kasir/pembayaran-pasien`);
  };

  const useInitData = () => {
    getPatientDetail();
  };

  useEffect(useInitData, []);

  // if (loadingPage) {
  //   return <PageSpinner />;
  // }

  return (
    <Layout className="order-list-page">
      <Content>
        <div className="mb-15">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Pembayaran Pasien
              </strong>
            </Col>
          </Row>
        </div>
        <div className="panel">
          <Row>
            <Col span={24}>
              <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                <TabPane tab="Data Pasien" key="1">
                  {!!patient && (
                    <>
                      <Row>
                        <Col span={24}>
                          <div className="mb-15" style={{ fontSize: 30 }}>
                            Lihat Info Pasien -{' '}
                            <strong>{patient.fullname}</strong>{' '}
                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <div className="mb-15">
                            Tanggal Daftar :{' '}
                            {moment(patient.createdAt).format('DD-MM-YYYY')}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <div className="mb-15" style={{ fontSize: 25 }}>
                            Data Pribadi
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>No.RM:</strong>
                          </div>
                        </Col>
                        <Col span={6} style={{ fontSize: 20 }}>
                          <div>
                            <strong>{patient.id}</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Foto</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>Tidak Ada Foto</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Nama Lengkap:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.fullname}</div>
                        </Col>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Status:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.maritalStatus}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Nama Panggilan:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.nickname}</div>
                        </Col>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Jenis Kelamin:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.gender}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Identitas:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.identityType}</div>
                        </Col>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>No Identitas:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.identityNumber}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Tempat, Tanggal Lahir:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{`${
                            patient.placeOfBirth ? patient.placeOfBirth : '-'
                          }, ${
                            patient.dateOfBirth
                              ? moment(patient.dateOfBirth).format('DD-MM-YYYY')
                              : '-'
                          }`}</div>
                        </Col>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Jenis / Umur:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.patientType}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Agama:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.religion}</div>
                        </Col>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Suku:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.race}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Pekerjaan:</strong>
                          </div>
                        </Col>
                        <Col span={18}>
                          <div>{patient.profession}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Telepon 1:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.phoneNumber1}</div>
                        </Col>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Telepon 2:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.phoneNumber2}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Nama Orang Tua:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.parentName}</div>
                        </Col>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Golongan Darah:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.bloodGroup}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Negara:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.nationality}</div>
                        </Col>
                        <Col span={6}>
                          <div className="mb-15">
                            <strong>Email:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.email}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <div className="mb-15" style={{ fontSize: 25 }}>
                            Alamat Sekarang
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Alamat</strong>
                          </div>
                        </Col>
                        <Col span={18}>
                          <div>{patient.patientAddress}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Provinsi:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>-</div>
                        </Col>
                        <Col span={6}>
                          <div>
                            <strong>Kota / Kabupaten:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>-</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Kecamatan:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>-</div>
                        </Col>
                        <Col span={6}>
                          <div>
                            <strong>Kelurahan:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.identitySubDistrict}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-15">
                            <strong>Kode Pos</strong>
                          </div>
                        </Col>
                        <Col span={18}>
                          <div>{patient.patientPostalCode}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <div className="mb-15" style={{ fontSize: 25 }}>
                            Data Penanggung Jawab
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Nama:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.picName}</div>
                        </Col>
                        <Col span={6}>
                          <div>
                            <strong>Hubungan:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.picPatientRelationship}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Pekerjaan Penanggung:</strong>
                          </div>
                        </Col>
                        <Col span={18}>
                          <div className="mb-5">-</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Telepon Penanggung Jawab:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.picPhoneNumber1}</div>
                        </Col>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Telepon Penanggung Jawab 2</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.picPhoneNumber2}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Alamat:</strong>
                          </div>
                        </Col>
                        <Col span={18}>
                          <div>{patient.picAddress}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Provinsi:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>-</div>
                        </Col>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Kota / Kabupaten:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>-</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Kecamatan:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>-</div>
                        </Col>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Kelurahan:</strong>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div>{patient.picSubDistrict}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <div className="mb-5">
                            <strong>Kode Pos:</strong>
                          </div>
                        </Col>
                        <Col span={18}>
                          <div>{patient.picPostalCode}</div>
                        </Col>
                      </Row>
                    </>
                  )}
                </TabPane>
                <TabPane tab="Tagihan Pasien" key="2">
                  <Table
                    dataSource={listUnpaidBill}
                    rowKey="key"
                    scroll={{ x: 1100, y: 500 }}
                    onChange={onTableChange}
                    pagination={pagination}
                    columns={patientBillColumns(handleBillDetailOpen)}
                    className="skybase-dashboard__active-order-table"
                  />
                </TabPane>
                <TabPane tab="Pembayaran Pasien" key="3">
                  <Table
                    dataSource={listPaidBill}
                    rowKey="key"
                    scroll={{ x: 1100, y: 500 }}
                    onChange={onTableChange}
                    pagination={pagination}
                    columns={patientBillColumns(handleBillDetailOpen)}
                    className="skybase-dashboard__active-order-table"
                  />
                </TabPane>
                <TabPane tab="Detail Tagihan Pasien" key="4">
                  <Table
                    dataSource={listTreatment}
                    rowKey="key"
                    scroll={{ x: 1100, y: 100 }}
                    onChange={onTableChange}
                    pagination={pagination}
                    columns={allTreatmentColumns}
                    className="skybase-dashboard__active-order-table"
                    footer={() => (
                      <Table
                        dataSource={listTreatmentPayment}
                        rowKey="key"
                        columns={allTreatmentSummaryColumns}
                        className="skybase-dashboard__active-order-table"
                        showHeader={false}
                        pagination={false}
                        size="small"
                      />
                    )}
                    size="small"
                  />
                </TabPane>
                <TabPane tab="Kembali" key="5"></TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </Content>

      <Modal
        visible={isBillDetailPopupOpen}
        title="Detail Tagihan Pasien"
        centered
        width={'80%'}
        onCancel={() => handleBillDetailOpen('', '')}
        footer={[
          <Button type="danger" onClick={() => handleBillDetailOpen('', '')}>
            TUTUP
          </Button>,
        ]}>
        <Row>
          <Col span={6}>No. Rekam Medis</Col>
          <Col span={18}>: {patientId}</Col>
        </Row>
        <Row>
          <Col span={6}>Nama</Col>
          <Col span={18}>
            <div className="mb-15">: {!!patient && patient.fullname}</div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              dataSource={listBillDetail}
              rowKey="key"
              columns={patientBillDetailColumns}
              className="skybase-dashboard__active-order-table"
              footer={() => (
                <Table
                  dataSource={listBillSummary}
                  rowKey="key"
                  columns={patientBillDetailSummaryColumns}
                  className="skybase-dashboard__active-order-table"
                  showHeader={false}
                  pagination={false}
                  size="small"
                />
              )}
              pagination={false}
              size="small"
              scroll={{ y: 200 }}
            />
          </Col>
        </Row>
      </Modal>
    </Layout>
  );
}

export default withRouter(PembayaranPasienDetail);
