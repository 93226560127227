import { message } from 'antd';
import _ from 'lodash';

/**
 * @param  {object} antFormError
 * @return  {boolean}
 */

export function hasFormErrors(antFormError) {
  return Object.keys(antFormError).some((field) => antFormError[field]);
}

/**
 * @param  {object} error
 * @param  {string} defaultMessage - fallback message when API doesn't give an error message
 */
export function alertErr(
  err,
  defaultMessage = 'connection error, check your connection!'
) {
  const errMessage = _.get(err, 'response.data.message');
  message.error(errMessage || defaultMessage);
}

// Handle object to array
export const setToArray = (resp) => {
  let theData;
  const obj = Object.entries(resp);
  obj.forEach(([key, value]) => {
    if (key === `data`) {
      const obj2 = Object.entries(value);
      obj2.forEach(([key, value]) => {
        if (key === `list`) {
          theData = value;
        }
      });
    }
  });
  return theData;
};

// Handle nested filter
export const nestedFilter = (targetArray, filters) => {
  var filterKeys = Object.keys(filters);
  return targetArray.filter(function (eachObj) {
    return filterKeys.every(function (eachKey) {
      if (!filters[eachKey].length) {
        return true;
      }
      return filters[eachKey].includes(eachObj[eachKey]);
    });
  });
};

// Locale
const locale = 'id-ID';

// Handle number formatter
export const currencyFormatter = (value) => {
  return new Intl.NumberFormat(locale).format(value);
};

// Handle number parser
export const currencyParser = (val) => {
  try {
    // for when the input gets clears
    if (typeof val === 'string' && !val.length) {
      val = '0.0';
    }

    // detecting and parsing between comma and dot
    var group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
    var decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
    var reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
    reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
    //  => 1232.21 €

    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.]/g, '');
    //  => 1232.21

    // appending digits properly
    const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;
    const needsDigitsAppended = digitsAfterDecimalCount > 2;

    if (needsDigitsAppended) {
      reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
    }

    return Number.isNaN(reversedVal) ? 0 : reversedVal;
  } catch (error) {
    console.error(error);
  }
};
