import React, { createContext, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';

import AuthenticationApi from 'api/authentication';

export const AuthenticationContext = createContext();

function AuthenticationContextProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(false);
  const [permissionDataList, setPermissionDataList] = useState([]);

  useEffect(() => {
    localStorage.getItem('cms_token') && setAuthenticated(true);

    return () => {
      setAuthenticated(false);
    };
  }, []);

  const listenToMessage = useCallback((event) => {
    const beUrl = process.env.REACT_APP_BE_URL;

    if (
      (event.origin === 'http://127.0.0.1:8000' ||
        event.origin === 'http://127.0.0.1:8099' ||
        event.origin === beUrl) &&
      event.data
    ) {
      const jsonMessage = JSON.parse(event.data);
      
      localStorage.setItem('cms_token', jsonMessage['cms_token']);
      localStorage.setItem('isEmbed', jsonMessage['isEmbed']);
      localStorage.setItem('id', jsonMessage['id']);
      localStorage.setItem('name', jsonMessage['name']);
      localStorage.setItem('role', jsonMessage['role']);
      localStorage.setItem('clinicId', jsonMessage['clinicId']);
      localStorage.setItem('clinicCode', jsonMessage['clinicCode']);
      localStorage.setItem('clinicName', jsonMessage['clinicName']);
      localStorage.setItem('clinicGroup', jsonMessage['clinicGroup']);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', (e) => listenToMessage(e));

    return () => {
      window.removeEventListener('message', (e) => listenToMessage(e));
    };
  }, [listenToMessage]);

  async function login(payload) {
    const { data } = await AuthenticationApi.login(payload);
    const accessToken = get(data, 'access_token', null);

    if (accessToken) {
      localStorage.setItem('cms_token', accessToken);

      setAuthenticated(true);
    }

    window.location.reload();
  }

  async function permissionList() {
    const { data } = await AuthenticationApi.permissions();
    setPermissionDataList(data.result);
  }

  useEffect(() => {
    permissionList();
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        authenticated,
        login,
        permissionDataList,
      }}>
      {children}
    </AuthenticationContext.Provider>
  );
}

AuthenticationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object,
};

export default AuthenticationContextProvider;
