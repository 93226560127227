import api from './index';

export default {
  getPatientVisitList(
    clinicId = '',
    from = '',
    to = '',
    limit = '',
    page = '',
    keyword = ''
  ) {
    return api.get(
      `/report/patient-visit?${clinicId ? `clinicId=${clinicId}&` : ''}${
        from ? `from=${from}` : ''
      }${to ? `&to=${to}` : ''}${limit ? `&limit=${limit}` : ''}${
        page ? `&page=${page}` : ''
      }${
          keyword ? `&keyword=${keyword}` : ''
      }`
    );
  },

  getPatientNextVisitList(
    clinicId = '',
    from = '',
    to = '',
    limit = '',
    page = ''
  ) {
    return api.get(
      `/report/patient-visit/next-visit?${
        clinicId ? `clinicId=${clinicId}&` : ''
      }${from ? `from=${from}` : ''}${to ? `&to=${to}` : ''}${
        limit ? `&limit=${limit}` : ''
      }${page ? `&page=${page}` : ''}`
    );
  },

  getCashierDetailList(
    categoryId = '',
    cashierId = '',
    from = '',
    to = '',
    limit = '',
    page = ''
  ) {
    return api.get(
      `/report/cashier-detail?${categoryId ? `categoryId=${categoryId}&` : ''}${
        cashierId ? `cashierId=${cashierId}&` : ''
      }${from ? `from=${from}` : ''}${to ? `&to=${to}` : ''}${
        limit ? `&limit=${limit}` : ''
      }${page ? `&page=${page}` : ''}`
    );
  },

  getCashierReceiptVoidList(cashierId = '', from = '', to='', fromVoid = '', toVoid = '', limit = '', page = '') {
    return api.get(
      `/report/receipt/void?${
        cashierId ? `voidBy=${cashierId}&` : ''}${
        from ? `from=${from}` : ''}${
        to ? `&to=${to}` : ''}${
        fromVoid ? `&voidFrom=${fromVoid}` : ''}${
        toVoid ? `&voidTo=${toVoid}` : ''}${
        limit ? `&limit=${limit}` : ''}${
        page ? `&page=${page}` : ''}`
    );
  },

  getDiscountList(
    clinicId = '',
    status = '',
    isPaymentDiscount = false,
    from = '',
    to = '',
    limit = '',
    page = ''
  ) {
    return api.get(
      `/report/discount?${clinicId ? `clinicId=${clinicId}&` : ''}${
        status ? `status=${status}&` : ''
      }${`isPaymentDiscount=${isPaymentDiscount}&`}${
        from ? `from=${from}` : ''
      }${to ? `&to=${to}` : ''}${limit ? `&limit=${limit}` : ''}${
        page ? `&page=${page}` : ''
      }`
    );
  },

  getCashierDetailListV2(
    categoryId = '',
    cashierId = '',
    clinicIds = '',
    clinicGroupIds = '',
    report = '',
    from = '',
    to = '',
    entryPoint = '',
    limit = '',
    page = ''
  ) {
    return api.get(
      `/report/cashier-detail/v2?${
        categoryId ? `categoryId=${categoryId}&` : ''
      }${cashierId ? `cashierId=${cashierId}&` : ''}${
        clinicIds ? `clinicIds=${clinicIds}&` : ''
      }${clinicGroupIds ? `clinicGroupIds=${clinicGroupIds}&` : ''}${
        entryPoint ? `entryPoint=${entryPoint}&` : ''
      }${
        report ? `report=${report}&` : ''
      }${from ? `from=${from}` : ''}${to ? `&to=${to}` : ''}${
        limit ? `&limit=${limit}` : ''
      }${page ? `&page=${page}` : ''}`
    );
  },

  getDoctorTreatmentReport(
    doctorIds = '', 
    serviceIds = '', 
    serviceCategoryIds = '', 
    clinicIds = '',
    paymentStatus = '',
    from = '', 
    to = '', 
    limit = '', 
    page = ''
  ) {
    return api.get(`/report/doctor-treatment?${
      doctorIds ? `doctorIds=${doctorIds}&` : ''
    }${serviceIds ? `serviceIds=${serviceIds}&` : ''}${
      serviceCategoryIds ? `serviceCategoryIds=${serviceCategoryIds}&` : ''
    }${clinicIds ? `clinicIds=${clinicIds}&` : ''}${
      paymentStatus ? `paymentStatus=${paymentStatus}&` : ''
    }${from ? `from=${from}` : ''}${
      to ? `&to=${to}` : ''
    }${limit ? `&limit=${limit}` : ''}${
      page ? `&page=${page}` : ''}`
    );
  },
};
