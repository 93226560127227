import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import './styles/index.scss';

export const SelectDropdown = ({
  placeHolder,
  options,
  onChange,
  onSearch,
  value,
  showArrow,
  showSearch,
  defaultActiveFirstOption,
  filterOption,
  notFoundContent,
  optionsLabel,
  optionsValue,
  dropdownMatchSelectWidth,
  allowClear,
  disabled,
  style,
  defaultValue
}) => {
  const { Option } = Select;

  return (
    <div className="multiselect-dropdown">
      <Select
        onChange={onChange}
        onSearch={onSearch}
        value={value}
        placeholder={placeHolder}
        showArrow={showArrow}
        showSearch={showSearch}
        defaultActiveFirstOption={defaultActiveFirstOption}
        filterOption={filterOption}
        notFoundContent={notFoundContent}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        allowClear={allowClear}
        disabled={disabled}
        style={style}
        defaultValue={defaultValue}
        >
        {options.map((value, index) => (
          <Option
            value={optionsValue ? value[optionsValue] : value.value}
            key={index}>
            {optionsLabel ? value[optionsLabel] : value.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

SelectDropdown.propTypes = {
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  placeHolder: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
  showArrow: PropTypes.bool,
  showSearch: PropTypes.bool,
  defaultActiveFirstOption: PropTypes.bool,
  filterOption: PropTypes.bool,
  notFoundContent: PropTypes.node,
  optionsLabel: PropTypes.string,
  optionsValue: PropTypes.string,
};

SelectDropdown.defaultProps = {
  options: [],
};

export default SelectDropdown;
