import React, { useState } from 'react';
import {
  Col,
  Row,
  Layout,
  Table,
  Button,
  // Tooltip,
  Input,
  // Icon,
} from 'antd';
import { withRouter } from 'react-router-dom';

import PatientApi from 'api/patient';
import { PageSpinner } from 'components/page-spinner';
import SelectDropdown from 'components/select-dropdown';
import { setToArray } from 'utils';

import {
  // headerActiveExportCsv,
  patientPaymentColumns,
} from './helper';

import './styles/index.scss';

const { Content } = Layout;

export function InteraksiPasien({ history }) {
  const searchCategoryOptions = [
    { label: 'No MR', value: 'MR' },
    { label: 'Nama', value: 'NAME' },
    { label: 'Tanggal Lahir', value: 'DOB' },
    { label: 'Nama Orang Tua', value: 'PARENT' },
    { label: 'Alamat', value: 'ADDRESS' },
  ];

  const [searchCategory, setSearchCategory] = useState('MR');
  const [searchKeyword, setSearchKeyword] = useState();

  const [listPatient, setListPatient] = useState([]);

  const [loadingPage, setLoadingPage] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    showSizeChanger: true,
  });

  async function getPatientList() {
    try {
      setLoadingPage(true);

      const response = await PatientApi.getPatientList(
        searchCategory,
        searchKeyword
      );

      let patientList = setToArray(response.data);

      setListPatient(patientList);
    } finally {
      setLoadingPage(false);
    }
  }

  const handleSearchCategoryChange = (value) => {
    setSearchCategory(value);
  };

  const handleSearchKeywordChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  const handleSearchKeywordKeyPress = (event) => {
    if (event.key === 'Enter' && !!searchKeyword) {
      getPatientList();
    }
  };

  const onTableChange = (pagination) => {
    setPagination(pagination);
  };

  const redirectToPointOfSales = (appointmentId) => {
    history.push(`/kasir/interaksi-pasien/${appointmentId}`);
  };

  // const useInitData = () => {

  // };

  // useEffect(useInitData, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <div className="mb-15">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Interaksi Pasien
              </strong>
            </Col>
          </Row>
        </div>
        <div className="panel panel__search">
          <Row>
            <Col span={10}>
              <div className="skybase-dashboard__search">
                <SelectDropdown
                  options={searchCategoryOptions}
                  placeHolder="Pilih Kategori"
                  onChange={handleSearchCategoryChange}
                  value={searchCategory}
                />
              </div>
            </Col>
            <Col span={9} offset={1}>
              <Input
                className="skybase-dashboard__search-input"
                placeholder="Cari"
                onChange={handleSearchKeywordChange}
                onKeyPress={handleSearchKeywordKeyPress}
                value={searchKeyword}
              />
            </Col>
            <Col span={3} offset={1}>
              <div className="skybase-dashboard__search">
                <Button
                  type="primary"
                  onClick={getPatientList}
                  // style={{
                  //   color: '#fff',
                  //   backgroundColor: '#36c6d3',
                  //   borderColor: '#2bb8c4',
                  // }}
                  disabled={!searchKeyword}>
                  <strong>CARI</strong>
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="panel">
          <Row>
            <Col span={24}>
              <div className="mb-15">
                <strong className="text-md skybase-dashboard__title">
                  DAFTAR PASIEN
                </strong>
              </div>
            </Col>
          </Row>
          <Table
            dataSource={listPatient}
            // rowKey="medicalRecordNumber"
            scroll={{ x: 1100, y: 500 }}
            onChange={onTableChange}
            pagination={pagination}
            columns={patientPaymentColumns(redirectToPointOfSales)}
            className="skybase-dashboard__active-order-table"
          />
        </div>
      </Content>
    </Layout>
  );
}

export default withRouter(InteraksiPasien);
