import React from 'react';
import { Button, Icon, Input, InputNumber, Tag } from 'antd';
import {
  HistoryOutlined,
  DeleteOutlined,
  CreditCardOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import SelectDropdown from 'components/select-dropdown';
import { currencyFormatter, currencyParser } from 'utils';
import moment from 'moment';

export const customerOutpatientQueueColumns = (redirectToPointOfSales) => [
  {
    title: 'Action',
    key: 'index',
    render: (row) => (
      <Button
        type="primary"
        onClick={() => redirectToPointOfSales(`${row['appointmentId']}`)}>
        BAYAR
      </Button>
    ),
    width: 100,
  },
  {
    title: 'Medical Record - Patient',
    key: 'medicalRecordNumber',
    sorter: (a, b) => a.medicalRecordNumber - b.medicalRecordNumber,
    render: (row) => (
      <>
        {`${row['medicalRecordNumber']} - `}
        <Button
          style={{
            color: '#337ab7',
            textTransform: 'uppercase',
          }}
          type="link"
          onClick={() => redirectToPointOfSales(`${row['appointmentId']}`)}>
          {row['patientName']}
        </Button>
      </>
    ),
  },
  {
    title: 'Insurance Status',
    key: 'insuranceStatusLabel',
    sorter: (a, b) =>
      a.insuranceStatusLabel.localeCompare(b.insuranceStatusLabel),
    render: (row) =>
      row['insuranceStatusLabel'] ? row['insuranceStatusLabel'] : '-',
  },
  {
    title: 'Visit Date',
    key: 'visitDate',
    sorter: (a, b) =>
      `${a.visitDate} ${a.visitStart}`.localeCompare(
        `${b.visitDate} ${b.visitStart}`
      ),
    render: (row) =>
      `${moment(row['visitDate']).format('DD-MM-YYYY')} ${moment(
        row['visitStart'],
        'hh:mm:ss'
      ).format('HH:mm:ss')}`,
  },
  {
    title: 'Clinic',
    key: 'clinicName',
    sorter: (a, b) => a.clinicName.localeCompare(b.clinicName),
    render: (row) => (row['clinicName'] ? row['clinicName'] : '-'),
  },
  {
    title: 'Unpaid (Rp.)',
    key: 'totalUnpaid',
    sorter: (a, b) => a.totalUnpaid - b.totalUnpaid,
    render: (row) =>
      row['totalUnpaid'] ? currencyFormatter(row['totalUnpaid']) : '0',
  },
];

export const treatmentColumns = (
  handleTreatmentChange,
  handleTreatmentDelete,
  insuranceStatus,
  paymentLinkStatus,
) => [
  {
    title: '',
    key: 'type',
    render: () => 'Tindakan',
  },
  {
    title: 'Items',
    key: 'description',
    render: (row) => (row['description'] ? row['description'] : '-')
  },
  {
    title: 'Created By',
    key: 'createdBy',
    render: (row) => (row['createdBy'] ? row['createdBy'] : '-'),
  },
  {
    title: 'Harga Satuan',
    key: 'unitPrice',
    render: (row) =>
      row['unitPrice']
        ? currencyFormatter(row['unitPrice'])
        : currencyFormatter(0),
  },
  {
    title: 'Jumlah',
    key: 'quantity',
    render: (row, record) => (
      <InputNumber
        className="skybase-dashboard__search-input"
        onChange={(value) =>
          handleTreatmentChange('quantity', record.key, value)
        }
        value={row['quantity']}
        disabled={insuranceStatus !== '' || paymentLinkStatus}
      />
    ),
    width: 80,
  },
  {
    title: 'Diskon',
    key: 'discountTotal',
    render: (row) =>
      row['discountTotal']
        ? currencyFormatter(row['discountTotal'])
        : currencyFormatter(0),
  },
  {
    title: 'Total',
    key: 'totalPrice',
    render: (row) =>
      row['totalPrice']
        ? currencyFormatter(row['totalPrice'])
        : currencyFormatter(0),
  },
  {
    key: 'key',
    render: (record) => (
      <Button
        type="danger"
        onClick={() => {
          handleTreatmentDelete(record.key);
        }}
        disabled={insuranceStatus !== '' || paymentLinkStatus}>
        <DeleteOutlined />
      </Button>
    ),
  },
];

export const treatmentPaymentColumns = [
  {
    title: '',
    key: 'column1',
    render: (row) => (row['column1'] ? row['column1'] : ''),
  },
  {
    title: '',
    key: 'column2',
    render: () => '',
  },
  {
    title: '',
    key: 'column3',
    render: () => '',
  },
  {
    title: '',
    key: 'column4',
    render: () => '',
    width: 90,
  },
  {
    title: '',
    key: 'column5',
    render: () => '',
  },
  {
    title: '',
    key: 'column6',
    render: (row) => (row['column6'] ? row['column6'] : '-'),
    align: 'right',
  },
  {
    title: '',
    key: 'column7',
    render: (row) =>
      !isNaN(row['column7']) ?
      `Rp ${
        row['column7']
          ? currencyFormatter(row['column7'])
          : currencyFormatter(0)
      },00` : row['column7'],
    align: 'left',
  },
];

export const discountCouponColumns = (handleUseVoucher, insuranceStatus, paymentLinkStatus) => [
  {
    title: 'Deskripsi',
    key: 'name',
    render: (row) => (row['name'] ? row['name'] : '-'),
  },
  {
    title: 'Amount',
    key: 'amountLabel',
    render: (row) => (row['amountLabel'] ? row['amountLabel'] : 0),
  },
  {
    key: 'id',
    render: (row) => (
      <Button
        type="primary"
        onClick={() => {
          handleUseVoucher(row['name']);
        }}
        disabled={insuranceStatus !== '' || paymentLinkStatus}>
        APPLY
      </Button>
    ),
    width: 100,
  },
];

export const appliedCouponColumns = [
  {
    title: 'Deskripsi',
    key: 'name',
    render: (row) => (row['name'] ? row['name'] : '-'),
  },
  {
    title: 'Amount',
    key: 'amount',
    render: (row) => (row['amount'] ? row['amount'] : 0),
  },
];

export const creditCardPaymentColumns = (
  edcOptions,
  bankOptions,
  handleCreditDebitChange,
  handleCreditDebitDelete
) => [
  {
    title: '',
    key: 'index',
  },
  {
    title: 'Mesin EDC',
    key: 'edcType',
    render: (row, record) => (
      <SelectDropdown
        placeHolder="Metode Pembayaran"
        options={edcOptions}
        onChange={(value) =>
          handleCreditDebitChange('edcType', record.key, value)
        }
        value={row['edcType']}
        dropdownMatchSelectWidth={false}
      />
    ),
    width: 180,
  },
  {
    title: 'Bank Kartu',
    key: 'cardBank',
    render: (row, record) => (
      <SelectDropdown
        placeHolder="Bank"
        options={bankOptions}
        onChange={(value) =>
          handleCreditDebitChange('cardBank', record.key, value)
        }
        value={row['cardBank']}
      />
    ),
    width: 180,
  },
  {
    title: 'Nomor Kartu',
    key: 'cardNumber',
    render: (row, record) => (
      <InputNumber
        className="skybase-dashboard__search-input"
        placeholder="Nomor Kartu"
        onChange={(value) =>
          handleCreditDebitChange('cardNumber', record.key, value)
        }
        value={row['cardNumber']}
        addonBefore={<CreditCardOutlined />}
      />
    ),
  },
  {
    title: 'Nomor Approval',
    key: 'approvalNumber',
    render: (row, record) => (
      <Input
        className="skybase-dashboard__search-input"
        placeholder="Nomor Approval"
        onChange={(event) =>
          handleCreditDebitChange(
            'approvalNumber',
            record.key,
            event.target.value
          )
        }
        value={row['approvalNumber']}
      />
    ),
  },
  {
    title: 'Trace Number',
    key: 'traceNumber',
    render: (row, record) => (
      <Input
        className="skybase-dashboard__search-input"
        placeholder="Trace Number"
        onChange={(event) =>
          handleCreditDebitChange('traceNumber', record.key, event.target.value)
        }
        value={row['traceNumber']}
      />
    ),
  },
  {
    title: 'Jumlah',
    key: 'amount',
    render: (row, record) => (
      <InputNumber
        className="skybase-dashboard__search-input"
        onChange={(value) =>
          handleCreditDebitChange('amount', record.key, value)
        }
        value={row['amount']}
        formatter={currencyFormatter}
        parser={currencyParser}
      />
    ),
  },
  {
    title: 'Action',
    key: 'key',
    render: (record) =>
      record.key > 1 ? (
        <Button
          type="danger"
          onClick={() => {
            handleCreditDebitDelete(record.key);
          }}>
          <DeleteOutlined />
        </Button>
      ) : (
        <></>
      ),
    width: 100,
  },
];

export const marketplacePaymentColumns = (
  marketplaceOptions,
  handleMarketplaceChange,
  handleMarketplaceDelete
) => [
  {
    title: '',
    key: 'marketplace',
  },
  {
    title: 'Market Place',
    key: 'marketplace',
    render: (row, record) => (
      <SelectDropdown
        options={marketplaceOptions}
        placeHolder="MarketPlace"
        onChange={(value) =>
          handleMarketplaceChange('marketplace', record.key, value)
        }
        value={row['marketplace']}
      />
    ),
  },
  {
    title: 'Nomor Voucher',
    key: 'voucherNumber',
    render: (row, record) => (
      <Input
        className="skybase-dashboard__search-input"
        placeholder="Nomor Voucher"
        onChange={(event) =>
          handleMarketplaceChange(
            'voucherNumber',
            record.key,
            event.target.value
          )
        }
        value={row['voucherNumber']}
      />
    ),
  },
  {
    title: 'Jumlah',
    key: 'amount',
    render: (row, record) => (
      <InputNumber
        className="skybase-dashboard__search-input"
        onChange={(value) =>
          handleMarketplaceChange('amount', record.key, value)
        }
        value={row['amount']}
        formatter={currencyFormatter}
        parser={currencyParser}
      />
    ),
  },
  {
    title: 'Action',
    key: 'key',
    render: (record) =>
      record.key > 1 ? (
        <Button
          type="danger"
          onClick={() => {
            handleMarketplaceDelete(record.key);
          }}>
          <DeleteOutlined />
        </Button>
      ) : (
        <></>
      ),
    width: 100,
  },
];

export const transferPaymentColumns = (
  bankOptions,
  handleTransferChange,
  handleTransferDelete
) => [
  {
    title: '',
    key: 'marketplace',
  },
  {
    title: 'Bank Pengirim',
    key: 'cardBank',
    render: (row, record) => (
      <SelectDropdown
        placeHolder="Bank"
        options={bankOptions}
        onChange={(value) =>
          handleTransferChange('cardBank', record.key, value)
        }
        value={row['cardBank']}
      />
    ),
    width: 180,
  },
  {
    title: 'Nama Rekening Pengirim',
    key: 'senderName',
    render: (row, record) => (
      <Input
        className="skybase-dashboard__search-input"
        placeholder="Nama Rekening Pengirim"
        onChange={(event) =>
          handleTransferChange('senderName', record.key, event.target.value)
        }
        value={row['senderName']}
      />
    ),
  },
  {
    title: 'Jumlah',
    key: 'amount',
    render: (row, record) => (
      <InputNumber
        className="skybase-dashboard__search-input"
        onChange={(value) => handleTransferChange('amount', record.key, value)}
        value={row['amount']}
        formatter={currencyFormatter}
        parser={currencyParser}
      />
    ),
  },
  {
    title: 'Action',
    key: 'key',
    render: (record) =>
      record.key > 1 ? (
        <Button
          type="danger"
          onClick={() => {
            handleTransferDelete(record.key);
          }}>
          <DeleteOutlined />
        </Button>
      ) : (
        <></>
      ),
    width: 100,
  },
];

export const paymentLinkColumns = (handleResetPaymentLink) => [
  {
    title: '',
    key: 'pad'
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (row) => (
      <Button
        type="primary"
        disabled={['PENDING', 'CONFIRMED', 'SETTLED'].includes(row.status)}
        onClick={() => {
          handleResetPaymentLink(row);
        }}>
        <HistoryOutlined />
      </Button>
    )
  },
  {
    title: 'Link',
    key: 'link',
    render: (row) => (
      <>
        <Button
          type="link"
          onClick={() => {
            window.open(row['paymentUrl']);
          }}>
          {row['paymentUrl']}
        </Button>
        <Button
          type="link"
          onClick={() => {
            if (
              navigator &&
              navigator.clipboard &&
              navigator.clipboard.writeText
            )
              return navigator.clipboard.writeText(row['paymentUrl']);
            return Promise.reject('The Clipboard API is not available.');
          }}>
          <CopyOutlined />
        </Button>
      </>
    ),
  },
  {
    title: 'Created At',
    key: 'createdAt',
    render: (row) => {
      const dateTime = row['createdAt'] ? new Date(row['createdAt']) : new Date();
      return dateTime.toLocaleString();
    },
  },
  {
    title: 'Status',
    key: 'status',
    render: (row) =>
      row['status'] ? (
        <Tag
          style={
            row['status'].toLowerCase() === 'pending'
              ? {
                  color: '#ffffff',
                  backgroundColor: '#feb822',
                  borderColor: '#feb822',
                  borderRadius: '20px',
                }
              : ['confirmed', 'settled'].includes(row['status'].toLowerCase())
              ? {
                  color: '#ffffff',
                  backgroundColor: '#0faf78',
                  borderColor: '#0faf78',
                  borderRadius: '20px',
                }
              : {
                  color: '#ffffff',
                  backgroundColor: '#c01720',
                  borderColor: '#c01720',
                  borderRadius: '20px',
                }
          }>
          {row['status']}
        </Tag>
      ) : (
        '-'
      ),
  },
];

export const insurancePaymentColumns = [
  {
    title: '',
    key: 'insuranceName',
  },
  {
    title: 'Nama Asuransi',
    key: 'insuranceName',
    render: (row) => (row['type'] ? row['type'] : '-'),
  },
  {
    title: 'Nomor Kartu',
    key: 'cardNumber',
    render: (row) => (row['card'] ? row['card'] : '-'),
  },
  {
    title: 'Jumlah',
    key: 'amount',
    render: (row) => (row['discount'] ? row['discount'] : '-'),
  },
  {
    title: 'Action',
    key: 'id',
    render: (row, index) =>
      index > 0 ? (
        <Button type="primary" onClick={() => {}}>
          <DeleteOutlined />
        </Button>
      ) : (
        <></>
      ),
    width: 100,
  },
];

export const patientPaymentColumns = (redirectToPatientPaymentDetail) => [
  {
    title: 'Aksi',
    key: 'index',
    render: (row) => (
      <Button
        type="primary"
        onClick={() => redirectToPatientPaymentDetail(`${row['id']}`)}>
        INFO PASIEN
      </Button>
    ),
  },
  {
    title: 'Nomer MR',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
    render: (row) => (row['id'] ? row['id'] : '-'),
  },
  {
    title: 'Nama',
    key: 'fullname',
    sorter: (a, b) => a.fullname.localeCompare(b.fullname),
    render: (row) => (row['fullname'] ? row['fullname'] : '-'),
  },
  {
    title: 'Tanggal Lahir',
    key: 'dateOfBirth',
    sorter: (a, b) => a.dateOfBirth.localeCompare(b.dateOfBirth),
    render: (row) => (row['dateOfBirth'] ? row['dateOfBirth'] : '-'),
  },
  {
    title: 'Nama Orang Tua',
    key: 'parentName',
    sorter: (a, b) => a.parentName.localeCompare(b.parentName),
    render: (row) => (row['parentName'] ? row['parentName'] : '-'),
  },
  {
    title: 'Alamat',
    key: 'patientAddress',
    sorter: (a, b) => a.patientAddress.localeCompare(b.patientAddress),
    render: (row) => (row['patientAddress'] ? row['patientAddress'] : '-'),
  },
];

export const patientBillColumns = (openBillDetailPopup) => [
  {
    title: '',
    width: 30,
  },
  {
    title: 'No',
    sorter: (a, b) => a.medicalRecordNumber - b.medicalRecordNumber,
    render: (record) => record.key,
  },
  {
    title: 'No Registrasi',
    key: 'registrationNumber',
    sorter: (a, b) => a.registrationNumber.localeCompare(b.registrationNumber),
    render: (row) =>
      row['registrationNumber'] ? row['registrationNumber'] : '-',
  },
  {
    title: 'Tanggal',
    key: 'createdAt',
    sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    render: (row) =>
      `${moment(row['createdAt']).format('DD-MM-YYYY HH:mm:ss')}`,
  },
  {
    title: 'Aksi',
    key: 'index',
    render: (row) => (
      <Button
        type="primary"
        onClick={() =>
          openBillDetailPopup(row['appointmentId'], row['receiptId'])
        }>
        DETAIL
      </Button>
    ),
  },
];

export const patientBillDetailColumns = [
  {
    title: '',
    width: 30,
  },
  {
    title: 'Tanggal Input',
    key: 'createdAt',
    sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    render: (row) =>
      `${moment(row['createdAt']).format('DD-MM-YYYY HH:mm:ss')}`,
  },
  {
    title: 'Tindakan & Resep',
    key: 'description',
    render: (row) => (row['description'] ? row['description'] : '-'),
  },
  {
    title: 'Qty',
    key: 'quantity',
    render: (row) => (row['quantity'] ? row['quantity'] : '-'),
  },
  {
    title: 'Harga',
    key: 'unitPrice',
    render: (row) =>
      row['unitPrice']
        ? currencyFormatter(row['unitPrice'])
        : currencyFormatter(0),
  },
  {
    title: 'Status',
    key: 'status',
    render: (row) => (row['status'] === 'PAID' ? 'Lunas' : 'Tidak Lunas'),
  },
  {
    title: 'Sub Total',
    key: 'totalPrice',
    render: (row) =>
      row['totalPrice']
        ? currencyFormatter(row['totalPrice'])
        : currencyFormatter(0),
  },
];

export const patientBillDetailSummaryColumns = [
  {
    title: '',
    key: 'column1',
    render: () => '',
    width: 30,
  },
  {
    title: '',
    key: 'column2',
    render: () => '',
  },
  {
    title: '',
    key: 'column3',
    render: () => '',
  },
  {
    title: '',
    key: 'column4',
    render: () => '',
  },
  {
    title: '',
    key: 'column5',
    render: () => '',
  },
  {
    title: '',
    key: 'column6',
    render: (row) => (row['column6'] ? row['column6'] : '-'),
  },
  {
    title: '',
    key: 'column7',
    render: (row) =>
      `${
        row['column7']
          ? currencyFormatter(row['column7'])
          : currencyFormatter(0)
      }`,
  },
];

export const allTreatmentColumns = [
  {
    title: '',
    width: 30,
  },
  {
    title: 'No',
    render: (record) => record.key,
  },
  {
    title: 'No Registrasi',
    key: 'registrationNumber',
    sorter: (a, b) => a.registrationNumber.localeCompare(b.registrationNumber),
    render: (row) =>
      row['registrationNumber'] ? row['registrationNumber'] : '-',
  },
  {
    title: 'Tanggal',
    key: 'date',
    sorter: (a, b) => a.date.localeCompare(b.date),
    render: (row) => `${moment(row['date']).format('DD-MM-YYYY HH:mm:ss')}`,
  },
  {
    title: 'Klinik',
    key: 'clinicName',
    sorter: (a, b) => a.clinicName.localeCompare(b.clinicName),
    render: (row) => (row['clinicName'] ? row['clinicName'] : '-'),
  },
  {
    title: 'Tindakan & Resep',
    key: 'description',
    render: (row) => (row['description'] ? row['description'] : '-'),
  },
  {
    title: 'QTY',
    key: 'quantity',
    render: (row) => (row['quantity'] ? row['quantity'] : '-'),
  },
  {
    title: 'Harga Satuan',
    key: 'unitPrice',
    render: (row) =>
      row['unitPrice']
        ? currencyFormatter(row['unitPrice'])
        : currencyFormatter(0),
  },
  {
    title: 'Sub Total',
    key: 'totalPrice',
    render: (row) =>
      row['totalPrice']
        ? currencyFormatter(row['totalPrice'])
        : currencyFormatter(0),
  },
  {
    title: 'Status',
    key: 'status',
    render: (row) => (row['status'] === 'PAID' ? 'Lunas' : 'Tidak Lunas'),
  },
];

export const allTreatmentSummaryColumns = [
  {
    title: '',
    key: 'column1',
    render: () => '',
    width: 30,
  },
  {
    title: '',
    key: 'column2',
    render: () => '',
  },
  {
    title: '',
    key: 'column3',
    render: () => '',
  },
  {
    title: '',
    key: 'column4',
    render: () => '',
  },
  {
    title: '',
    key: 'column5',
    render: () => '',
  },
  {
    title: '',
    key: 'column6',
    render: () => '',
  },
  {
    title: '',
    key: 'column7',
    render: () => '',
  },
  {
    title: '',
    key: 'column8',
    render: (row) => (row['column8'] ? row['column8'] : '-'),
  },
  {
    title: '',
    key: 'column9',
    render: (row) =>
      `${
        row['column9']
          ? currencyFormatter(row['column9'])
          : currencyFormatter(0)
      }`,
    align: 'right',
  },
  {
    title: '',
    key: 'column10',
    render: () => '',
  },
];

export const discountColumns = [
  {
    title: '',
    width: 30,
  },
  {
    title: 'No',
    render: (record) => record.key,
  },
  {
    title: 'Tanggal',
    key: 'modifiedAt',
    sorter: (a, b) => a.modifiedAt.localeCompare(b.modifiedAt),
    render: (row) => {
      return `${moment(row['modifiedAt']).format('DD-MM-YYYY HH:mm:ss')}`
    }
  },
  {
    title: 'No Kwitansi',
    key: 'receiptNumber',
    sorter: (a, b) => a.receiptNumber.localeCompare(b.receiptNumber),
    render: (row) => (row['receiptNumber'] ? row['receiptNumber'] : '-'),
  },
  {
    title: 'Nama Diskon',
    key: 'discountName',
    sorter: (a, b) => a.discountName.localeCompare(b.discountName),
    render: (row) =>
      row['discountName'] && row['discountCode']
        ? `${row['discountName']} - ${row['discountCode']}`
        : row['discountName'],
  },
  {
    title: 'Pasien',
    key: 'patientName',
    sorter: (a, b) => a.patientName.localeCompare(b.patientName),
    render: (row) => (row['patientName'] ? row['patientName'] : '-'),
  },
  {
    title: 'Kasir',
    key: 'cashierName',
    sorter: (a, b) => a.cashierName.localeCompare(b.cashierName),
    render: (row) => (row['cashierName'] ? row['cashierName'] : '-'),
  },
  {
    title: 'Jumlah',
    key: 'totalDiscount',
    render: (row) =>
      row['totalDiscount']
        ? currencyFormatter(row['totalDiscount'])
        : currencyFormatter(0),
  },
];

export const excessInsuranceColumns = (
  handleTreatmentChange,
  insuranceStatus
) => [
  {
    title: 'Items',
    key: 'description',
    render: (row) => (row['description'] ? row['description'] : '-'),
  },
  {
    title: 'Total',
    key: 'totalPrice',
    render: (row) =>
      row['totalPrice']
        ? currencyFormatter(row['totalPrice'])
        : currencyFormatter(0),
  },
  {
    title: () => (
      <>
        <span style={{ color: 'red' }}>*</span> Insurance Limit
      </>
    ),
    key: 'insuranceExcessLimit',
    render: (row, record) => (
      <InputNumber
        className="skybase-dashboard__search-input"
        onChange={(value) =>
          handleTreatmentChange('insuranceExcessLimit', record.key, value)
        }
        value={row['insuranceExcessLimit']}
        formatter={currencyFormatter}
        parser={currencyParser}
        style={{ textAlign: 'right' }}
        disabled={insuranceStatus !== ''}
      />
    ),
  },
  {
    title: 'Insurance Excess',
    key: 'insuranceExcessTotal',
    render: (row) =>
      row['insuranceExcessTotal']
        ? currencyFormatter(row['insuranceExcessTotal'])
        : currencyFormatter(0),
  },
];

export const voidDiscountColumns = [
  {
    title: '',
    width: 30,
  },
  {
    title: 'No',
    render: (record) => record.key,
  },
  {
    title: 'Tanggal',
    key: 'modifiedAt',
    sorter: (a, b) => a.modifiedAt.localeCompare(b.modifiedAt),
    render: (row) => {
      return `${moment(row['modifiedAt']).format('DD-MM-YYYY HH:mm:ss')}`
    }
  },
  {
    title: 'Nama Diskon',
    key: 'discountName',
    sorter: (a, b) => a.discountName.localeCompare(b.discountName),
    render: (row) =>
      row['discountName'] && row['discountCode']
        ? `${row['discountName']} - ${row['discountCode']}`
        : row['discountName'],
  },
  {
    title: 'Pasien',
    key: 'patientName',
    sorter: (a, b) => a.patientName.localeCompare(b.patientName),
    render: (row) => (row['patientName'] ? row['patientName'] : '-'),
  },
  {
    title: 'Kasir',
    key: 'cashierName',
    sorter: (a, b) => a.cashierName.localeCompare(b.cashierName),
    render: (row) => (row['cashierName'] ? row['cashierName'] : '-'),
  },
  {
    title: 'Jumlah',
    key: 'totalDiscount',
    render: (row) =>
      row['totalDiscount']
        ? currencyFormatter(row['totalDiscount'])
        : currencyFormatter(0),
  },
];

export const discountSummaryColumns = [
  {
    title: '',
    key: 'column1',
    render: (row) => (row['column1'] ? row['column1'] : '-'),
    width: '90%',
    align: 'right',
  },
  {
    title: '',
    key: 'column2',
    render: (row) => (row['column2'] ? row['column2'] : '0'),
    width: '10%',
  },
];

export const cashierReportDetailColumns = [
  // {
  //   title: 'No',
  //   render: (record) => record.key,
  // },
  {
    title: 'Date',
    key: 'paymentTimestamp',
    sorter: (a, b) => a.paymentTimestamp.localeCompare(b.paymentTimestamp),
    render: (row) =>
      `${moment(row['paymentTimestamp']).format('DD MMM YYYY HH:mm:ss')}`,
  },
  {
    title: 'Patient',
    key: 'medicalRecordNumber',
    sorter: (a, b) => a.medicalRecordNumber - b.medicalRecordNumber,
    render: (row) => `${row['medicalRecordNumber']} - ${row['patientName']}`,
  },
  {
    title: 'Service Category',
    key: 'treatmentCategoryName',
    sorter: (a, b) => a.treatmentCategoryName - b.treatmentCategoryName,
    render: (row) =>
      row['treatmentCategoryName'] ? row['treatmentCategoryName'] : '-',
  },
  {
    title: 'Service',
    key: 'treatmentCode',
    sorter: (a, b) => a.treatmentCode.localeCompare(b.treatmentCode),
    render: (row) => `${row['treatmentCode']} - ${row['treatmentName']}`,
  },
  {
    title: 'Jenis Kunjungan',
    key: 'jenisKunjungan',
    sorter: (a, b) => a.jenisKunjungan.localeCompare(b.jenisKunjungan),
    render: (row) => (row['jenisKunjungan'] ? row['jenisKunjungan'] : '-'),
  },
  {
    title: 'Metode Pembayaran',
    key: 'paymentMethod',
    sorter: (a, b) => a.paymentMethod.localeCompare(b.paymentMethod),
    render: (row) => (row['paymentMethod'] ? row['paymentMethod'] : '-'),
  },
  {
    title: 'Trace Number',
    key: 'traceNumber',
    sorter: (a, b) => a.traceNumber.localeCompare(b.traceNumber),
    render: (row) => (row['traceNumber'] ? row['traceNumber'] : '-'),
  },
  {
    title: 'Nomor Approval',
    key: 'approvalNumber',
    sorter: (a, b) => a.approvalNumber.localeCompare(b.approvalNumber),
    render: (row) => (row['approvalNumber'] ? row['approvalNumber'] : '-'),
  },
  {
    title: 'Sub Total',
    key: 'price',
    sorter: (a, b) => a.price.localeCompare(b.price),
    render: (row) =>
      row['price'] ? currencyFormatter(row['price']) : currencyFormatter(0),
  },
  {
    title: 'Quantity',
    key: 'quantity',
    sorter: (a, b) => a.quantity.localeCompare(b.quantity),
    render: (row) => (row['quantity'] ? row['quantity'] : '0'),
  },
  {
    title: 'Discount',
    render: (row) =>
      row['paymentDiscount']
        ? currencyFormatter(0)
        : currencyFormatter(row['discountTotal']),
  },
  {
    title: 'Invoice',
    render: (row) =>
      row['paymentDiscount']
        ? currencyFormatter(row['discountTotal'])
        : currencyFormatter(0),
  },
  {
    title: 'Total',
    key: 'totalPrice',
    render: (row) =>
      row['totalPrice']
        ? currencyFormatter(row['totalPrice'])
        : currencyFormatter(0),
  },
  {
    title: 'Doctor',
    key: 'doctorName',
    sorter: (a, b) => a.doctorName.localeCompare(b.doctorName),
    render: (row) => (row['doctorName'] ? row['doctorName'] : '-'),
  },
  {
    title: 'Assistant Doctor',
    key: 'asstDoctorName',
    sorter: (a, b) => a.asstDoctorName.localeCompare(b.asstDoctorName),
    render: (row) => (row['asstDoctorName'] ? row['asstDoctorName'] : '-'),
  },
  {
    title: 'Cashier',
    key: 'cashierName',
    sorter: (a, b) => a.cashierName.localeCompare(b.cashierName),
    render: (row) => (row['cashierName'] ? row['cashierName'] : '-'),
  },
  {
    title: 'Policlinic',
    key: 'clinicName',
    sorter: (a, b) => a.clinicName.localeCompare(b.clinicName),
    render: (row) => (row['clinicName'] ? row['clinicName'] : '-'),
  },
  {
    title: 'Clinic',
    key: 'clinicCode',
    sorter: (a, b) => a.clinicCode.localeCompare(b.clinicCode),
    render: (row) => (row['clinicCode'] ? row['clinicCode'] : '-'),
  },
  {
    title: 'Customer Entry Point',
    key: 'entryPoint',
    sorter: (a, b) => a.entryPoint.localeCompare(b.entryPoint),
    render: (row) => (row['entryPoint'] === 'SMILE_DENTAL' ? 'SMILE DENTAL' : 'RATA'),
  }
];

export const cashierReceiptColumns = (
  printReceipt,
  viewReceipt,
  managePrint,
  voidReturn,
  uploadReceipt,
  permissionDataList
) => [
  {
    title: 'No',
    render: (record) => record.key,
    width: 50,
  },
  {
    title: '',
    key: 'index',
    render: (row) => (
      <>
        <Button
          type="primary"
          onClick={() =>
            printReceipt(row['appointmentId'], row['receiptNumber'])
          }
          style={{ width: '100%' }}>
          PRINT KWITANSI
        </Button>
        <Button
          type="primary"
          onClick={() =>
            viewReceipt(row['appointmentId'], row['receiptNumber'], row['id'])
          }
          style={{
            width: '100%',
            color: '#fff',
            backgroundColor: '#F1C40F',
            borderColor: '#dab10d',
          }}>
          VIEW KWITANSI
        </Button>
        <Button
          type="primary"
          onClick={() =>
            managePrint(row['appointmentId'], row['receiptNumber'], row['id'])
          }
          style={{
            width: '100%',
            color: '#fff',
            backgroundColor: '#1BBC9B',
            borderColor: '#1BBC9B',
          }}>
          MANAGE CETAK
        </Button>
        {(moment(row['paymentDate']).isSameOrAfter(
          moment().subtract(1, 'd').format('YYYY-MM-DD')
        ) &&
          permissionDataList.find((item) => item === 183)) ||
        permissionDataList.find((item) => item === 216) ? (
          <Button
            type="danger"
            onClick={() =>
              voidReturn(row['appointmentId'], row['receiptNumber'])
            }
            style={{ width: '100%' }}>
            {'VOID & RETUR'}
          </Button>
        ) : (
          <></>
        )}
        {/* <Button
          type="danger"
          onClick={() => voidReturn(row['appointmentId'], row['receiptNumber'])}
          style={{ width: '100%' }}>
          {'VOID & RETUR'}
        </Button> */}
        <Button
          type="danger"
          onClick={() => uploadReceipt(row['id'], row['receiptPhoto'])}
          style={{
            width: '100%',
            color: '#fff',
            backgroundColor: '#c5b96b',
            borderColor: '#c5b96b',
          }}>
          UPLOAD BUKTI FOTO
        </Button>
      </>
    ),
  },
  {
    title: 'No RM - NAMA PASIEN',
    key: 'medicalRecordNumber',
    sorter: (a, b) => a.medicalRecordNumber - b.medicalRecordNumber,
    render: (row) =>
      `${row['medicalRecordNumber']} - ${
        row['patient'] ? row['patient'].fullname : '-'
      }`,
  },
  {
    title: 'NO KWITANSI',
    key: 'receiptNumber',
    sorter: (a, b) => a.receiptNumber.localeCompare(b.receiptNumber),
    render: (row) => (row['receiptNumber'] ? row['receiptNumber'] : '-'),
  },
  {
    title: 'NILAI YANG DI TAGIHKAN',
    key: 'subTotal',
    sorter: (a, b) => a.subTotal.localeCompare(b.subTotal),
    render: (row) =>
      `Rp ${
        row['subTotal']
          ? currencyFormatter(row['subTotal'])
          : currencyFormatter(0)
      }`,
  },
  {
    title: 'DISKON',
    key: 'paymentDiscount',
    sorter: (a, b) => a.paymentDiscount.localeCompare(b.paymentDiscount),
    render: (row) =>
      `Rp ${
        row['paymentDiscount']
          ? currencyFormatter(row['paymentDiscount'])
          : currencyFormatter(0)
      }`,
  },
  {
    title: 'INSURANCE',
    key: 'insuranceLimit',
    sorter: (a, b) => a.insuranceLimit.localeCompare(b.insuranceLimit),
    render: (row) =>
      `Rp ${
        row['insuranceLimit']
          ? currencyFormatter(row['insuranceLimit'])
          : currencyFormatter(0)
      }`,
  },
  {
    title: 'PPN',
    key: 'tax',
    sorter: (a, b) => a.tax.localeCompare(b.tax),
    render: (row) =>
      `Rp ${row['tax'] ? currencyFormatter(row['tax']) : currencyFormatter(0)}`,
  },
  {
    title: 'NILAI YANG DI BAYARKAN',
    key: 'grandTotal',
    sorter: (a, b) => a.grandTotal.localeCompare(b.grandTotal),
    render: (row) =>
      `Rp ${
        row['grandTotal']
          ? currencyFormatter(row['grandTotal'])
          : currencyFormatter(0)
      }`,
  },
  {
    title: 'KEMBALIAN',
    key: 'changeReturn',
    sorter: (a, b) => a.changeReturn.localeCompare(b.changeReturn),
    render: (row) =>
      `Rp ${
        row['changeReturn']
          ? currencyFormatter(row['changeReturn'])
          : currencyFormatter(0)
      }`,
  },
  {
    title: 'TANGGAL BAYAR',
    key: 'paymentDate',
    sorter: (a, b) => a.paymentDate.localeCompare(b.paymentDate),
    render: (row) => `${moment(row['paymentDate']).format('DD-MM-YYYY')}`,
  },
  {
    title: 'METODE PEMBAYARAN',
    key: 'paymentMethod',
    sorter: (a, b) => a.paymentMethod.localeCompare(b.paymentMethod),
    render: (row) => (row['paymentMethod'] ? row['paymentMethod'] : '-'),
  },
  {
    title: 'NAMA KASIR',
    key: 'cashierName',
    sorter: (a, b) => a.treatmentDiscount.localeCompare(b.treatmentDiscount),
    render: (row) => (row['cashierName'] ? row['cashierName'] : '-'),
  },
];

export const cashierReceiptVoidColumns = (printReceipt, pageNumber) => [
  {
    title: 'No',
    render: (record) => record.key + ((pageNumber-1) * 10),
    width: 50,
  },
  {
    title: 'Action',
    key: 'index',
    render: (row) => (
      <Button
        type="primary"
        onClick={() => printReceipt(row['appointmentId'], row['receiptNumber'])}>
          <Icon type="printer" />
      </Button>
    ),
    width: 70,
  },
  {
    title: 'No RM - NAMA PASIEN',
    key: 'medicalRecordNumber',
    sorter: (a, b) => a.medicalRecordNumber - b.medicalRecordNumber,
    render: (row) =>
      `${row['medicalRecordNumber']} - ${
        row['patientName'] ? row['patientName'] : '-'
      }`,
  },
  {
    title: 'NO KWITANSI',
    key: 'receiptNumber',
    sorter: (a, b) => a.receiptNumber.localeCompare(b.receiptNumber),
    render: (row) => (row['receiptNumber'] ? row['receiptNumber'] : '-'),
  },
  {
    title: 'Transaction Date',
    key: 'transactionDate',
    sorter: (a,b) => a.transactionDate.localeCompare(b.transactionDate),
    render: (row) => row['paymentDate'] ? `${moment(row['paymentDate']).format('DD MMM YYYY')}` : '-',
  },
  {
    title: 'Treatment',
    key: 'treatmentName',
    sorter: (a,b) => a.treatmentName.localeCompare(b.treatmentName),
    render: (row) => row['treatmentDescription'] ? row['treatmentDescription'] : '-',
  },
  {
    title: 'Qty',
    key: 'Qty',
    sorter: (a,b) => a.Qty - b.Qty,
    render : (row) => row['quantity'] ? row['quantity'] : '-',
  },
  {
    title: 'Doctor Name',
    key: 'doctorName',
    sorter: (a,b) => a.doctorName.localeCompare(b.doctorName),
    render: (row) => row['doctorName'] ? row['doctorName'] : '-',
  },
  {
    title: 'CS Name',
    key: 'cashierName',
    sorter: (a,b) => a.cashierName.localeCompare(b.cashierName),
    render: (row) => row['createdBy'] ? row['createdBy'] : '-',
  },
  {
    title: 'Void & Retur Date',
    key: 'returnDate',
    sorter: (a, b) => a.returnDate.localeCompare(b.returnDate),
    render: (row) => row['voidAt'] ?`${moment(row['voidAt']).format('DD MMM YYYY HH:mm')}` : '-',
  },
  {
    title: 'Voided & Returned by',
    key: 'voidBy',
    sorter: (a,b) => a.voidBy.localeCompare(b.voidBy),
    render: (row) => row['voidByName'] ? row['voidByName'] : '-',
  },
  {
    title: 'Void & Retur Reason',
    key: 'voidReason',
    sorter: (a,b) => a.voidReason.localeCompare(b.voidReason),
    render: (row) => row['voidReason'] ? row['voidReason'].length > 30 ? `${row['voidReason'].slice(0,30)}...` : row['voidReason'] : '-',
  },
  {
    title: 'NILAI YANG DI TAGIHKAN',
    key: 'subTotal',
    sorter: (a, b) => a.subTotal.localeCompare(b.subTotal),
    // render: (row) =>
    //   `Rp ${
    //     row['subTotal']
    //       ? currencyFormatter(row['subTotal'])
    //       : currencyFormatter(0)
    //   }`,
    render: (row) => {
      return {
        props: {
          style: {
            background: row['subTotal'] < row['changeReturn'] ? 'red' : '',
          },
        },
        children: `Rp ${
          row['subTotal']
            ? currencyFormatter(row['subTotal'])
            : currencyFormatter(0)
        }`,
      };
    },
  },
  {
    title: 'DISKON',
    key: 'paymentDiscount',
    sorter: (a, b) => a.paymentDiscount.localeCompare(b.paymentDiscount),
    render: (row) =>
      `Rp ${
        row['discountTotal']
          ? currencyFormatter(row['discountTotal'])
          : currencyFormatter(0)
      }`,
  },
  {
    title: 'PPN',
    key: 'tax',
    sorter: (a, b) => a.tax.localeCompare(b.tax),
    render: (row) =>
      `Rp ${row['tax'] ? currencyFormatter(row['tax']) : currencyFormatter(0)}`,
  },
  {
    title: 'NILAI YANG DI BAYARKAN',
    key: 'grandTotal',
    sorter: (a, b) => a.grandTotal.localeCompare(b.grandTotal),
    // render: (row) =>
    //   `Rp ${
    //     row['grandTotal']
    //       ? currencyFormatter(row['grandTotal'])
    //       : currencyFormatter(0)
    //   }`,
    render: (row) => {
      return {
        props: {
          style: {
            background: row['grandTotal'] < row['changeReturn'] ? 'red' : '',
          },
        },
        children: `Rp ${
          row['grandTotal']
            ? currencyFormatter(row['grandTotal'])
            : currencyFormatter(0)
        }`,
      };
    },
  },
  {
    title: 'KEMBALIAN',
    key: 'changeReturn',
    sorter: (a, b) => a.changeReturn.localeCompare(b.changeReturn),
    render: (row) =>
      `Rp ${
        row['changeReturn']
          ? currencyFormatter(row['changeReturn'])
          : currencyFormatter(0)
      }`,
  },
  {
    title: 'TANGGAL BAYAR',
    key: 'paymentDate',
    sorter: (a, b) => a.paymentDate.localeCompare(b.paymentDate),
    render: (row) => `${moment(row['paymentDate']).format('DD MMM YYYY')}`,
  },
  {
    title: 'METODE PEMBAYARAN',
    key: 'paymentMethod',
    sorter: (a, b) => a.paymentMethod.localeCompare(b.paymentMethod),
    render: (row) => (row['paymentMethod'] ? row['paymentMethod'] : '-'),
  },
  {
    title: 'NAMA KASIR',
    key: 'cashierName',
    sorter: (a,b) => a.cashierName.localeCompare(b.cashierName),
    render: (row) => (row['cashierName'] ? row['cashierName'] : '-'),
  }
];
