import React, { useEffect, useState, useRef } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { Col, Row, Layout, Button } from 'antd';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { PageSpinner } from 'components/page-spinner';
import AppointmentApi from 'api/appointment';
import TranscationApi from 'api/transaction';
import { currencyFormatter } from 'utils';
import Terbilang from 'terbilang';

import '../styles/index.scss';
import HtmlRender from 'components/html-render';

const { Content } = Layout;

export function KwitansiStrukPembayaran({ history }) {
  const [patientDetail, setPatientDetail] = useState();

  const [receiptDetail, setReceiptDetail] = useState();

  const [receiptDate, setReceiptDate] = useState();

  const [loadingPage, setLoadingPage] = useState(false);

  const printComponentRef = useRef();

  const { id } = useParams();

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const getPatientDetail = async () => {
    try {
      setLoadingPage(true);

      const appointmentId = id.split('_')[0];

      const response = await AppointmentApi.getPatientDetail(appointmentId);

      let patient = response.data.data;

      const date = patient.visitDate ? 
      new Date(patient.visitDate.split('-')[0], patient.visitDate.split('-')[1]-1, patient.visitDate.split('-')[2]).toLocaleDateString('en-gb', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      })
      : '-' ;
      setReceiptDate((prev) => ({...prev, visitDate : date}));
      setPatientDetail(patient);
    } finally {
      setLoadingPage(false);
    }
  };

  const getReceiptDetail = async () => {
    try {
      setLoadingPage(true);

      const receiptId = id.split('_')[2];

      const response = await TranscationApi.getReceiptDetailById(receiptId);

      const receipt = response.data.data;

      const date = receipt.paymentDate ? 
      new Date(receipt.paymentDate.split('-')[0], receipt.paymentDate.split('-')[1]-1, receipt.paymentDate.split('-')[2]).toLocaleDateString('en-gb', {
        month: 'long', 
        day: 'numeric',
        year: 'numeric'
      })
      : '-';
      setReceiptDate((prev) => ({...prev, paymentDate: date}));
      setReceiptDetail(receipt);
    } finally {
      setLoadingPage(false);
    }
  };

  const redirectToPreviousPage = () => {
    history.goBack();
  };

  const redirectToManageCetakKwitansi = () => {
    history.push('/kasir/manage-cetak-kasir-pasien');
  };

  const redirectToPrintPembayaran = () => {
    const appointmentId = id.split('_')[0];

    const receiptNumber = id.split('_')[1];

    history.push(`/kasir/kwitansi/${appointmentId}_${receiptNumber}`);
  };

  const redirectToStrukPembayaran = () => {
    window.location.reload();
  };

  const redirectToKwitansiPembayaran = () => {
    const appointmentId = id.split('_')[0];

    const receiptNumber = id.split('_')[1];

    const receiptId = id.split('_')[2];

    history.push(
      `/kasir/kwitansi-pembayaran/${appointmentId}_${receiptNumber}_${receiptId}`
    );
  };

  const redirectToRincianPembayaran = () => {
    const appointmentId = id.split('_')[0];

    const receiptNumber = id.split('_')[1];

    const receiptId = id.split('_')[2];

    history.push(
      `/kasir/rincian-pembayaran/${appointmentId}_${receiptNumber}_${receiptId}`
    );
  };

  const countInsuranceLimit = (insuranceClaim) => {
    let limit = 0;

    insuranceClaim.forEach((item) => {
      limit += item.limit;
    });

    return limit;
  };

  const useInitData = () => {
    if (id) {
      getPatientDetail();
      getReceiptDetail();
    }
  };

  useEffect(useInitData, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <div className="mb-15">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Struk Pembayaran
              </strong>
            </Col>
          </Row>
        </div>
        {!!patientDetail && !!receiptDetail && (
          <>
            <div className="mb-50">
              <Row>
                <Col span={24} type="flex" align="middle">
                  <div className="mb-15">
                    <Button
                      type="danger"
                      style={{ marginRight: '5px' }}
                      onClick={redirectToPreviousPage}>
                      KEMBALI
                    </Button>
                    <Button
                      type="primary"
                      style={{
                        marginRight: '5px',
                        color: '#FFF',
                        backgroundColor: '#4DB3A2',
                        borderColor: '#4DB3A2',
                      }}
                      onClick={redirectToManageCetakKwitansi}>
                      KEMBALI MANAGE KWITANSI
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24} type="flex" align="middle">
                  <Button
                    type="primary"
                    style={{ marginRight: '5px' }}
                    onClick={redirectToPrintPembayaran}>
                    PRINT PEMBAYARAN
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginRight: '5px' }}
                    onClick={redirectToStrukPembayaran}>
                    STRUK PEMBAYARAN
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      marginRight: '5px',
                      color: '#FFF',
                      backgroundColor: '#32c5d2',
                      borderColor: '#32c5d2',
                    }}
                    onClick={redirectToKwitansiPembayaran}>
                    KWITANSI PEMBAYARAN
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      marginRight: '5px',
                      color: '#FFF',
                      backgroundColor: '#c49f47',
                      borderColor: '#c49f47',
                    }}
                    onClick={redirectToRincianPembayaran}>
                    RINCIAN PEMBAYARAN
                  </Button>
                  {/* <Button
                    type="primary"
                    style={{
                      marginRight: '5px',
                      color: '#FFF',
                      backgroundColor: '#4DB3A2',
                      borderColor: '#4DB3A2',
                    }}>
                    KIRIM EMAIL KWITANSI
                  </Button> */}
                  <Button
                    type="danger"
                    style={{ marginRight: '5px' }}
                    onClick={handlePrint}>
                    PRINT HALAMAN
                  </Button>
                </Col>
              </Row>
            </div>
            <div ref={printComponentRef} style={{ padding: '40px' }}>
              <div className="mb-45">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <Col>
                    <div style={{ fontSize: '30px' }}>
                      <strong>{patientDetail.clinic.isSmileClinic ? 'SMILE' : 'RATA INDONESIA'}</strong>
                    </div>
                  </Col>
                  <Col>
                    <img
                      src={`${process.env.PUBLIC_URL}/img/logo/${patientDetail.clinic.isSmileClinic ? 'Smile-Dental-Logo-07.png' : 'logo-red.png'}`}
                      alt="Rata Logo"
                      style={patientDetail.clinic.isSmileClinic ? {width : '132px'} : {}}
                    />
                  </Col>
                </div>
              </div>
              <div className="mb-15">
                <div className='separator'>
                  <Col style={{ display: 'flex', justifyContent: 'center' }}>
                    <strong>BUKTI PEMBAYARAN</strong>
                  </Col>
                </div>
              </div>
              <div className="mb-15">
                <Row>
                  <Col span={24}>PELANGGAN</Col>
                </Row>
              </div>
              <div className="mb-15">
                <Row>
                  <Col span={12}>
                    <div
                      style={{ border: '1px solid #000000', padding: '10px' }}>
                      <div className="mb-5">
                        <div
                          style={{ display: 'inline-block', width: '100px' }}>
                          NAMA
                        </div>
                        <div style={{ display: 'inline-block' }}>
                          {patientDetail.patient.fullname}
                        </div>
                      </div>
                      <div className="mb-5">
                        <div
                          style={{ display: 'inline-block', width: '100px' }}>
                          ALAMAT
                        </div>
                        <div style={{ display: 'inline-block' }}>
                          {patientDetail.patient.identityAddress}
                        </div>
                      </div>
                      <div className="mb-5">
                        <div
                          style={{ display: 'inline-block', width: '100px' }}>
                          TELP
                        </div>
                        <div style={{ display: 'inline-block' }}>
                          +{patientDetail.patient.phoneNumber1}
                        </div>
                      </div>
                      <div>
                        <div
                          style={{ display: 'inline-block', width: '100px' }}>
                          FAX
                        </div>
                        <div style={{ display: 'inline-block' }}>-</div>
                      </div>
                    </div>
                  </Col>
                  <Col span={11} offset={1}>
                    <div
                      style={{ border: '1px solid #000000', padding: '10px' }}>
                      <div className="mb-5">
                        <div
                          style={{ display: 'inline-block', width: '175px' }}>
                          PEMESANAN #
                        </div>
                        <div style={{ display: 'inline-block' }}></div>
                      </div>
                      <div className="mb-5">
                        <div
                          style={{ display: 'inline-block', width: '175px' }}>
                          JATUH TEMPO
                        </div>
                        <div style={{ display: 'inline-block' }}>-</div>
                      </div>
                      <div className="mb-5">
                        <div
                          style={{ display: 'inline-block', width: '175px' }}>
                          REFERENSI #
                        </div>
                        <div style={{ display: 'inline-block' }}>
                          {receiptDetail.receiptNumber}
                        </div>
                      </div>
                      <div className='mb-5'>
                        <div
                          style={{display: 'inline-block', width: '175px' }}>
                          VISIT DATE
                        </div>
                        <div style={{display: 'inline-block'}}>
                          {receiptDate.visitDate}
                        </div>
                      </div>
                      <div className='mb-5'>
                        <div
                          style={{display: 'inline-block', width: '175px' }}>
                          TRANSACTION DATE
                        </div>
                        <div style={{display: 'inline-block'}}>
                          {receiptDate.paymentDate}
                        </div>
                      </div>
                      <div className='mb-5'>
                        <div
                          style={{display: 'inline-block', width: '175px' }}>
                          TREATMENT DATE
                        </div>
                        <div style={{display: 'inline-block'}}>
                          {receiptDate.visitDate}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="mb-15">
                <Row>
                  <Col span={24}>
                    <table
                      style={{
                        borderCollapse: 'collapse',
                        width: '100%',
                      }}
                      border="1"
                      className="table-receipt">
                      <tbody>
                        <tr style={{ textAlign: 'center' }}>
                          <td>No</td>
                          <td>Keterangan</td>
                          <td>Qty</td>
                          <td>Harga Satuan</td>
                          <td>Diskon (Rp.)</td>
                          <td>Jumlah (Rp.)</td>
                        </tr>
                        {!!receiptDetail.slipTreatmentList &&
                          receiptDetail.slipTreatmentList.map(
                            (treatment, index) => (
                              <tr>
                                <td style={{ textAlign: 'center' }}>
                                  {index + 1}
                                </td>
                                <td>{treatment.description}</td>
                                <td style={{ textAlign: 'center' }}>
                                  {treatment.quantity}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  {currencyFormatter(treatment.unitPrice)}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  {treatment.paymentDiscount
                                    ? 0
                                    : currencyFormatter(
                                        treatment.discountTotal
                                      )}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  {currencyFormatter(treatment.totalPrice)}
                                </td>
                              </tr>
                            )
                          )}
                        {!!receiptDetail.receiptDetailList && (
                          <>
                            <tr>
                              <td
                                style={{
                                  borderBottom: 'hidden',
                                  borderLeft: 'hidden',
                                  textAlign: 'center',
                                }}
                                colspan="4"
                                rowspan={
                                  receiptDetail.receiptDetailList.length + 7
                                }></td>
                              <td style={{ textAlign: 'right' }}>
                                <strong>SUB TOTAL</strong>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <strong>
                                  {currencyFormatter(receiptDetail.subTotal)}
                                </strong>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'right' }}>
                                <strong>DISKON</strong>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <strong>
                                  {/* {!!receiptDetail.discountReceiptList &&
                                  receiptDetail.discountReceiptList.length >
                                    0 &&
                                  receiptDetail.discountReceiptList[0]
                                    .paymentDiscount
                                    ? `(${currencyFormatter(
                                        receiptDetail.discountReceiptList[0]
                                          .totalDiscount
                                      )})`
                                    : 0} */}
                                    {!!receiptDetail.paymentDiscount ? `${currencyFormatter(receiptDetail.paymentDiscount)}` : 0}
                                </strong>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'right' }}>
                                <strong>GRAND TOTAL</strong>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <strong>
                                  {currencyFormatter(receiptDetail.grandTotal)}
                                </strong>
                              </td>
                            </tr>
                            {!!receiptDetail.receiptDetailList &&
                              receiptDetail.receiptDetailList.length > 0 &&
                              receiptDetail.receiptDetailList.map((payment) => (
                                <tr>
                                  <td style={{ textAlign: 'right' }}>
                                    <strong>{payment.paymentMethod}</strong>
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    <strong>
                                      {currencyFormatter(payment.totalPayment)}
                                    </strong>
                                  </td>
                                </tr>
                              ))}
                            {!!receiptDetail.insuranceClaims &&
                              receiptDetail.insuranceClaims.length > 0 && (
                                <tr>
                                  <td style={{ textAlign: 'right' }}>
                                    <strong>INSURANCE</strong>
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    <strong>
                                      {currencyFormatter(
                                        countInsuranceLimit(
                                          receiptDetail.insuranceClaims[0]
                                            .treatments
                                        )
                                      )}
                                    </strong>
                                  </td>
                                </tr>
                              )}
                            <tr>
                              <td style={{ textAlign: 'right' }}>
                                <strong>JUMLAH PEMBAYARAN</strong>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <strong>
                                  ({currencyFormatter(receiptDetail.grandTotal)})
                                </strong>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'right' }}>
                                <strong>SISA TAGIHAN</strong>
                              </td>
                              <td style={{ textAlign : 'center' }}>
                                <strong>
                                  {receiptDetail.grandTotal-receiptDetail.grandTotal === 0 ? '-' : currencyFormatter(receiptDetail.grandTotal-receiptDetail.grandTotal)}
                                </strong>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'right' }}>
                                <strong>KEMBALI</strong>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <strong>
                                  {currencyFormatter(
                                    receiptDetail.changeReturn
                                  )}
                                </strong>
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </div>
              <div className="mb-45">
                <Row>
                  <Col span={24}>
                    <Row>
                      <strong>
                        <u>TERBILANG</u>
                      </strong>
                    </Row>
                    <Row style={{ textTransform: 'uppercase' }}>{`${Terbilang(
                      receiptDetail.grandTotal
                    )} RUPIAH`}</Row>
                  </Col>
                </Row>
              </div>
              <div className="mb-15">
                <Row>
                  <Col span={24}>
                    <Row>
                      <strong>
                        <u>Diagnosa</u>
                      </strong>
                    </Row>
                    <Row>
                      {patientDetail.resumeDiagnosisList &&
                      patientDetail.resumeDiagnosisList.length > 0
                        ? patientDetail.resumeDiagnosisList.map((item) => (
                            <HtmlRender
                              content={item.resumeDiagnosis}></HtmlRender>
                          ))
                        : '-'}
                    </Row>
                  </Col>
                </Row>
              </div>
              {!patientDetail.clinic.isSmileClinic && 
              <div className='mb-15' style={{fontSize: '13px'}}>
                For further details and your next reservation please contact our Call Center via Whatsapp at (+62)81280004367 or email to hello@rata.id
                <br/>
                Thank you for trusting Rata.id and we are looking forward to welcoming you back soon.
              </div>
              }
            </div>
          </>
        )}
      </Content>
    </Layout>
  );
}

export default withRouter(KwitansiStrukPembayaran);
