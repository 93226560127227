import apiBe from './index-be';

export default {
  getVoucherAll(payload) {
    return apiBe.post(`api/vouchers/pos/all`, payload);
  },

  getVoucher(payload, code) {
    return apiBe.post(`api/vouchers/code/${code}`, payload);
  },

  useVoucher(payload) {
    return apiBe.put(`api/vouchers/code/use`, payload);
  },
};
