import api from './index';

export default {
  getPatientList(searchCategory, searchKeyword) {
    return api.get(`/patient?parameter=${searchCategory}&q=${searchKeyword}`);
  },

  getPatientDetail(id) {
    return api.get(`/patient/${id}`);
  },

  getFotoMedisList(medicalRecordNumber) {
    return api.get(`/patient/${medicalRecordNumber}/photo?page=1&limit=1000`);
  },
};
