import React, { useEffect, useState, useRef } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { Col, Row, Layout, Button, Modal, Select, Input, Icon, Tooltip } from 'antd';
import { useReactToPrint } from 'react-to-print';
import { PageSpinner } from 'components/page-spinner';
import HtmlRender from 'components/html-render';
import AppointmentApi from 'api/appointment';
import TranscationApi from 'api/transaction';
import UserApi from 'api/user';
import ClinicApi from 'api/clinic';
import { currencyFormatter } from 'utils';
import Terbilang from 'terbilang';

import '../styles/index.scss';
import SelectDropdown from 'components/select-dropdown';

const { Content } = Layout;
const { TextArea } = Input;

export function Kwitansi({ history }) {
  const [patientDetail, setPatientDetail] = useState();

  const [receiptDetail, setReceiptDetail] = useState();

  const [receiptDate, setReceiptDate] = useState();

  const [diagnose, setDiagnose] = useState();
  const [initDiagnose, setInitDiagnose] = useState();
  const [clinicType, setClinicType] = useState();
  const [initClinicType, setInitClinicType] = useState();


  const [listDoctorOptions, setListDoctorOptions] = useState();
  const [doctorId, setDoctorId] = useState();
  const [initDoctor, setInitDoctorId] = useState();
  const [doctorDetail, setDoctorDetail] = useState();

  const [listClinicTypeOptions, setListClinicTypeOptions] = useState();

  const [loadingPage, setLoadingPage] = useState(false);

  const [editable, setEditable] = useState(false);

  const printComponentRef = useRef();

  const { id } = useParams();

  const backgroundImageStyles = clinicType ? {
    padding: '40px',
    backgroundImage: `url(${clinicType.logo})`, 
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center', 
    backgroundSize: 'contain',
    position: 'relative',
    overflow: 'hidden',
  } : {
    padding: '40px', 
    backgroundImage: `url(${process.env.PUBLIC_URL}/img/logo/logo-red.png)`, 
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center', 
    backgroundSize: 'contain',
    position: 'relative',
    overflow: 'hidden',
  };

  const overlayStyles = {
    backgroundColor: 'rgba(255, 255, 255, 0.96)',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom:0,
    left: 0,
    zIndex:1,
  };

  const contentStyles = {
    position: 'relative',
    zIndex:2,
    color: '#000',
  };

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const getPatientDetail = async () => {
    try {
      setLoadingPage(true);

      const appointmentId = id.split('_')[0];

      const response = await AppointmentApi.getPatientDetail(appointmentId);

      let patient = response.data.data;
      
      if (patient.clinicType && Object.values(patient.clinicType).length !== 0) {
        setClinicType(patient.clinicType);
        setInitClinicType(patient.clinicType);
      } else if (Object.values(patient.clinic.clinicType).length !== 0) {
        setClinicType(patient.clinic.clinicType);
        setInitClinicType(patient.clinic.clinicType.id);
      }

      if (patient.signedBy) {
        const res = await UserApi.getDoctorById(patient.signedBy);
        setDoctorDetail(res.data.result[0]);
        setInitDoctorId(patient.signedBy);
        setDoctorId(patient.signedBy);
      }

      

      const date = patient.visitDate ?
      new Date(patient.visitDate.split('-')[0], patient.visitDate.split('-')[1]-1, patient.visitDate.split('-')[2]).toLocaleDateString('en-gb', {
        month: 'long',
        day: 'numeric',
        year : 'numeric'
      })
      : '-' ;
      let diagnosa = '';
      if (patient.customDiagnosis) {
        diagnosa = patient.customDiagnosis;
      } else if (patient.resumeDiagnosisList && patient.resumeDiagnosisList.length > 0) {
        patient.resumeDiagnosisList.forEach((val, idx) => {
          diagnosa += `${val.resumeDiagnosis} \n`
        });
      } else {
        diagnosa = '-';
      }


      setInitDiagnose((prev) => prev = diagnosa);
      setReceiptDate((prev) => ({...prev, visitDate : date}));
      setPatientDetail(patient);
    } finally {
      setLoadingPage(false);
    }
  };

  const getReceiptDetail = async () => {
    try {
      setLoadingPage(true);

      const appointmentId = id.split('_')[0];

      const receiptNumber = id.split('_')[1];

      const response = await TranscationApi.getReceiptDetail(
        appointmentId,
        receiptNumber
      );

      const receipt = response.data.data;

      const date = receipt.paymentDate ? 
      new Date(receipt.paymentDate.split('-')[0], receipt.paymentDate.split('-')[1]-1, receipt.paymentDate.split('-')[2]).toLocaleDateString('en-gb', {
        month : 'long', day: 'numeric', year : 'numeric'
      })
      : '-';

      setReceiptDate((prev) => ({...prev, paymentDate: date}));
      setReceiptDetail(receipt);
    } finally {
      setLoadingPage(false);
    }
  };

  const getDoctorList = async () => {
    try {
      setLoadingPage(true);

      const response = await UserApi.getDoctorHasSipNumber();

      let doctorList = Object.values(response.data.result);
      const doctorListOpts = doctorList.map((value) => ({
        label : value.name,
        value: value.id_user,
      }));

      setListDoctorOptions(doctorListOpts);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingPage(false);
    }
  }

  const getClinicTypeList = async () => {
    try {
      setLoadingPage(true);

      const response = await ClinicApi.listClinicType();

      let clinicTypeList = Object.values(response.data.data);
      const clinicTypeListOpts = clinicTypeList.map((value) => ({
        label: value.name,
        value: value.id,
      }));
      setListClinicTypeOptions(clinicTypeListOpts);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingPage(false);
    }
  }

  const sendReceiptToEmail = async () => {
    try {
      setLoadingPage(true);

      const receiptId = receiptDetail.id;

      const response = await TranscationApi.sendReceiptToEmail(receiptId);

      if (response.data.resultCode === '00') {
        Modal.success({
          title: 'Sukses',
          content: 'Email berhasil dikirim.',
        });
      } else {
        Modal.warning({
          title: 'Perhatian',
          content: 'Terjadi kesalahan pada server.',
        });
      }
    } catch (error) {
      Modal.warning({
        title: 'Perhatian',
        content: 'Terjadi kesalahan pada server.',
      });
    } finally {
      setLoadingPage(false);
    }
  };

  const redirectToPreviousPage = () => {
    history.goBack();
  };

  const redirectToPointOfSales = () => {
    const appointmentId = id.split('_')[0];

    history.push(`/kasir/point-of-sales/${appointmentId}`);
  };

  const redirectToManageCetakKwitansi = () => {
    history.push('/kasir/manage-cetak-kasir-pasien');
  };

  const redirectToAntrianKasir = () => {
    history.push('/kasir/antrian-pasien-rawat-jalan');
  };

  const countInsuranceLimit = (insuranceClaim) => {
    let limit = 0;

    insuranceClaim.forEach((item) => {
      limit += item.limit;
    });

    return limit;
  };

  const handleEdit = async (status) => {
    try {
      setLoadingPage(true);

      if (status === 'edit') {
        setEditable((prev) => prev = !editable);
        setInitClinicType((prev) => prev = clinicType.id);
        setDiagnose((prev) => prev = initDiagnose);
      } else if (status === 'save') {
        const payload = {
          ...patientDetail,
          customDiagnosis : diagnose,
          clinicType : {
            id : initClinicType,
          },
          signedBy : doctorId,
          clinicId : patientDetail.clinic.id,
        };
        const appointmentId = id.split('_')[0];
        const response = await AppointmentApi.editInvoice(payload, appointmentId);
        if (response.data.status === 'SUCCESS') {
          setClinicType(response.data.data.clinicType);
          setInitClinicType(response.data.data.clinicType.id);
          setEditable((prev) => prev = false);
          setInitDiagnose((prev) => prev = diagnose);
          setInitDoctorId((prev) => prev = doctorId);
          await getPatientDetail();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingPage(false);
    }
  }

  const handleClinicType = (value) => {
    setInitClinicType((prev) => prev = value);
  }

  const handleChangeSign = async (value) => {
    try {
      // setLoadingPage(true);
      
      setDoctorId((prev) => prev = value);
      const response = await UserApi.getDoctorById(value);
      setDoctorDetail(response.data.result[0]);
    } catch (error) {
      console.log(error);
    } finally {
      // setLoadingPage(false);
    }
  }

  const handleDiagnose = (event) => {
    setDiagnose((prev) => prev = event.target.value); 
  }

  const useInitData = () => {
    if (id) {
      getPatientDetail();
      getReceiptDetail();
      getDoctorList();
      getClinicTypeList();
    }
  };

  useEffect(useInitData, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <div className="mb-15">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Kwitansi
              </strong>
            </Col>
          </Row>
        </div>
        {!!patientDetail && !!receiptDetail && (
          <>
            <div className="mb-15">
              <Row>
                <Col span={24}>
                  <Button
                    type="danger"
                    style={{ marginRight: '5px' }}
                    onClick={redirectToPreviousPage}>
                    KEMBALI
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginRight: '5px' }}
                    onClick={redirectToAntrianKasir}>
                    KEMBALI ANTRIAN KASIR
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginRight: '5px' }}
                    onClick={redirectToPointOfSales}>
                    KEMBALI POINT OF SALES
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginRight: '5px' }}
                    onClick={redirectToManageCetakKwitansi}>
                    KEMBALI MANAGE KWITANSI
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginRight: '5px' }}
                    onClick={handlePrint}
                    disabled={editable}
                    >
                    PRINT THIS PAGE
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginRight: '5px' }}
                    onClick={sendReceiptToEmail}>
                    KIRIM KWITANSI
                  </Button>
                </Col>
              </Row>
            </div>
            <div className='mb-50'>
              <Row>
                <Col span={24} type='flex' align='end'>
                  {!editable ?
                  <Tooltip title='Edit'>
                    <Button type='primary' shape='circle' style={{width: '45px', height: '45px', padding: '7px', fontSize: '20px', backgroundColor: '#FBAA00', border: '1px solid #FFB822', marginRight: '40px'}} onClick={() => handleEdit('edit')}>
                      <Icon type="edit" style={{color: '#FFFFFF', fontSize: '20px',}} theme='outlined'/>
                    </Button>
                  </Tooltip>
                  :
                  <div style={{height: '45px'}}>
                    <Button onClick={() => handleEdit('edit')}>Cancel</Button>
                    <Button onClick={() => handleEdit('save')}>Save</Button>
                  </div>
                  }
                </Col>
              </Row>
            </div>
            <div 
              ref={printComponentRef} 
              style={backgroundImageStyles}
            >
              <div style={overlayStyles}>
              </div>
              <div style={contentStyles}>
                <div className="mb-45 w-100">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%'
                    }}>
                    <Col style={{width: '90%'}}>
                      <div style={{ fontSize: '25px', width: '100%'}}>
                        {editable ?
                        <SelectDropdown
                          options={listClinicTypeOptions}
                          onChange={handleClinicType}
                          value={initClinicType}
                          style={{width: '50%', fontSize: '25px', fontWeight: 'bold'}}
                          size='large'
                          defaultValue={initClinicType}
                        >
                        </SelectDropdown>
                        :
                        <strong>{clinicType.name}</strong>
                        }
                      </div>
                    </Col>
                    <Col style={{width: '10%', maxWidth: '250px'}}>
                      <img
                        src={clinicType.logo ? clinicType.logo : `${process.env.PUBLIC_URL}/img/logo/logo-red.png`}
                        alt={`${clinicType.name.toLowerCase()}-logo.img`}
                        style={{width: '100%'}}
                      />
                    </Col>
                  </div> 
                </div>
                <div>
                  <div className='d-flex align-items-center'>
                    <p style={{ width: '90px' }}>Telp</p>
                    <p style={{ marginLeft: '20px', paddingLeft: '15px'}}>{clinicType.phone ? clinicType.phone : '+6281280004367'}</p>
                  </div>
                  <div className='d-flex align-items-center'>
                    <p style={{ width: '90px' }}>Email</p>
                    <p style={{ marginLeft: '20px', paddingLeft: '15px'}}>{clinicType.email ? clinicType.email : 'cs@rata.id'}</p>
                  </div>
                </div>
                <div className="mb-15">
                  <div className='separator'>
                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                      <strong>Bukti Pemesanan</strong>
                    </Col>
                  </div>
                </div>
                <div className="mb-15">
                  <Row>
                    <Col span={24}>PELANGGAN</Col>
                  </Row>
                </div>
                <div className="mb-15">
                  <Row>
                    <Col span={12}>
                      <div
                        style={{ border: '1px solid #000000', padding: '10px' }}>
                        <div className="mb-5">
                          <div
                            style={{ display: 'inline-block', width: '100px' }}>
                            NAMA
                          </div>
                          <div style={{ display: 'inline-block' }}>
                            {patientDetail.patient.fullname}
                          </div>
                        </div>
                        <div className="mb-5">
                          <div
                            style={{ display: 'inline-block', width: '100px' }}>
                            ALAMAT
                          </div>
                          <div style={{ display: 'inline-block' }}>
                            {patientDetail.patient.identityAddress}
                          </div>
                        </div>
                        <div className="mb-5">
                          <div
                            style={{ display: 'inline-block', width: '100px' }}>
                            TELP
                          </div>
                          <div style={{ display: 'inline-block' }}>
                            +{patientDetail.patient.phoneNumber1}
                          </div>
                        </div>
                        <div>
                          <div
                            style={{ display: 'inline-block', width: '100px' }}>
                            FAX
                          </div>
                          <div style={{ display: 'inline-block' }}>-</div>
                        </div>
                      </div>
                    </Col>
                    <Col span={11} offset={1}>
                      <div
                        style={{ border: '1px solid #000000', padding: '10px' }}>
                        <div className="mb-5">
                          <div
                            style={{ display: 'inline-block', width: '175px' }}>
                            PEMESANAN #
                          </div>
                          <div style={{ display: 'inline-block' }}></div>
                        </div>
                        <div className="mb-5">
                          <div
                            style={{ display: 'inline-block', width: '175px' }}>
                            JATUH TEMPO
                          </div>
                          <div style={{ display: 'inline-block' }}>-</div>
                        </div>
                        <div className="mb-5">
                          <div
                            style={{ display: 'inline-block', width: '175px' }}>
                            REFERENSI #
                          </div>
                          <div style={{ display: 'inline-block' }}>
                            {receiptDetail.receiptNumber}
                          </div>
                        </div>
                        <div className='mb-5'>
                          <div style={{display: 'inline-block', width: '175px' }}>
                          </div>
                          <div style={{display: 'inline-block'}}>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="mb-15">
                  <Row>
                    <Col span={24}>
                      <table
                        style={{
                          borderCollapse: 'collapse',
                          width: '100%',
                        }}
                        border="1"
                        className="table-receipt">
                        <tbody>
                          <tr style={{ textAlign: 'center' }}>
                            <td>No</td>
                            <td>Keterangan</td>
                            <td>Qty</td>
                            <td>Harga Satuan</td>
                            <td>Diskon (Rp.)</td>
                            <td>Jumlah (Rp.)</td>
                          </tr>
                          {!!receiptDetail.slipTreatmentList &&
                            receiptDetail.slipTreatmentList.map(
                              (treatment, index) => (
                                <tr>
                                  <td style={{ textAlign: 'center' }}>
                                    {index + 1}
                                  </td>
                                  <td>{treatment.description}</td>
                                  <td style={{ textAlign: 'center' }}>
                                    {treatment.quantity}
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {currencyFormatter(treatment.unitPrice)}
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {treatment.paymentDiscount
                                      ? 0
                                      : currencyFormatter(
                                          treatment.discountTotal
                                        )}
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {currencyFormatter(treatment.totalPrice)}
                                  </td>
                                </tr>
                              )
                            )}
                          {!!receiptDetail.receiptDetailList && (
                            <>
                              <tr>
                                <td
                                  style={{
                                    borderBottom: 'hidden',
                                    borderLeft: 'hidden',
                                    textAlign: 'center',
                                  }}
                                  colspan="4"
                                  rowspan={
                                    receiptDetail.receiptDetailList.length + 7
                                  }></td>
                                <td style={{ textAlign: 'right' }}>
                                  <strong>SUB TOTAL</strong>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <strong>
                                    {currencyFormatter(receiptDetail.subTotal)}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: 'right' }}>
                                  <strong>DISKON</strong>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <strong>
                                    {/* {!!receiptDetail.discountReceiptList &&
                                    receiptDetail.discountReceiptList.length >
                                      0 &&
                                    receiptDetail.discountReceiptList[0]
                                      .paymentDiscount
                                      ? `(${currencyFormatter(
                                          receiptDetail.discountReceiptList[0]
                                            .totalDiscount
                                        )})`
                                      : 0} */}
                                      {!!receiptDetail.paymentDiscount ? `${currencyFormatter(receiptDetail.paymentDiscount)}` : 0}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: 'right' }}>
                                  <strong>GRAND TOTAL</strong>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <strong>
                                    {currencyFormatter(receiptDetail.grandTotal)}
                                  </strong>
                                </td>
                              </tr>
                              {!!receiptDetail.receiptDetailList &&
                                receiptDetail.receiptDetailList.length > 0 &&
                                receiptDetail.receiptDetailList.map((payment) => (
                                  <tr>
                                    <td style={{ textAlign: 'right' }}>
                                      <strong>{payment.paymentMethod}</strong>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                      <strong>
                                        {currencyFormatter(payment.totalPayment)}
                                      </strong>
                                    </td>
                                  </tr>
                                ))}
                              {!!receiptDetail.insuranceClaims &&
                                receiptDetail.insuranceClaims.length > 0 && (
                                  <tr>
                                    <td style={{ textAlign: 'right' }}>
                                      <strong>INSURANCE</strong>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                      <strong>
                                        {currencyFormatter(
                                          countInsuranceLimit(
                                            receiptDetail.insuranceClaims[0]
                                              .treatments
                                          )
                                        )}
                                      </strong>
                                    </td>
                                  </tr>
                                )}
                              <tr>
                                <td style={{ textAlign: 'right' }}>
                                  <strong>JUMLAH PEMBAYARAN</strong>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <strong>
                                    ({currencyFormatter(receiptDetail.grandTotal)})
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: 'right' }}>
                                  <strong>SISA TAGIHAN</strong>
                                </td>
                                <td style={{ textAlign : 'center' }}>
                                  <strong>
                                    {receiptDetail.grandTotal-receiptDetail.grandTotal === 0 ? '-' : currencyFormatter(receiptDetail.grandTotal-receiptDetail.grandTotal)}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: 'right' }}>
                                  <strong>KEMBALI</strong>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <strong>
                                    {currencyFormatter(
                                      receiptDetail.changeReturn
                                    )}
                                  </strong>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col span={12}>
                    <div className="mb-45">
                      <Row>
                        <Col span={24}>
                          <Row>
                            <strong>
                              <u>TERBILANG</u>
                            </strong>
                          </Row>
                          <Row style={{ textTransform: 'uppercase' }}>{`${Terbilang(
                            receiptDetail.grandTotal
                          )} RUPIAH`}</Row>
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-10">
                      <Row>
                        <Col span={24} style={{minHeight: '100px'}}>
                          <Row>
                            <strong>
                              <u>Diagnosa</u>
                            </strong>
                          </Row>
                          <Row>
                            {!editable 
                              ?
                                initDiagnose 
                              : 
                              <TextArea autoSize={{minRows: 3, maxRows: 6}} placeholder='Diagnose description' onChange={handleDiagnose} defaultValue={initDiagnose} value={diagnose}/>
                            }
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <div className='mb-10' style={{fontSize: '13px'}}>
                      <i>Kuitansi ini merupakan bukti pembayaran yang SAH.</i>
                      <br/>
                      <i>Pengembalian dana tidak dapat dilakukan dengan alasan apapun apabila kuitansi treatment/produk telah diterbitkan.</i>
                    </div>
                  </Col>
                  <Col span={12}>
                      <div style={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', alignItems: 'flex-end', paddingRight: '20px'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '250px', minHeight: '400px'}}>
                          <p style={{marginBottom: '5px', textAlign: 'center'}}>Doctor</p>
                          {!editable ? <></> :
                          <div className='skybase-dashboard__search' style={{width: '100%'}}>
                            <SelectDropdown
                              options={listDoctorOptions}
                              placeHolder='Select Doctor'
                              onChange={handleChangeSign}
                              value={doctorId}
                              allowClear
                              showSearch
                              defaultValue={initDoctor}
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            />
                          </div>
                          }
                          {initDoctor ?
                            <>
                              { doctorDetail.signature && <img src={process.env.REACT_APP_AWS_URL + doctorDetail.signature} alt='doctor-signature' style={{width: '150px', height: '150px'}}/> }
                              <p style={{marginBottom: '0px', marginTop: '15px', fontWeight: 'normal'}}>{doctorDetail.name}</p> 
                              <p style={{marginBottom: '0px', fontWeight: 'normal', fontSize: '12px'}}>SIP: {doctorDetail.sip_number}</p>
                            </>
                            :
                            <></>
                          }
                        </div>
                      </div>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        )}
      </Content>
    </Layout>
  );
}

export default withRouter(Kwitansi);
