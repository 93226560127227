import React from 'react';
import { Button } from 'antd';
import { currencyFormatter } from 'utils';

export const paymentMethodColumns = (viewRow, editRow, deleteRow) => [
  {
    title: '#',
    key: 'index',
    render: (row) => (
      <>
        <Button
          type="primary"
          onClick={() =>
            viewRow(row['id'], {
              bank: row['name'],
              chartOfAccount: row['chartOfAccount'],
              name: row['name'],
              feeCharge: row['feeCharge'],
              feeChargeInterbank: row['feeChargeInterbank'],
              paymentMethodStatus:
                row['paymentMethodStatus'] === 1
                  ? 'Uang Masuk'
                  : row['paymentMethodStatus'] === 2
                  ? 'Uang Keluar'
                  : '-',
              paymentMethod: row['paymentMethod'].name,
            })
          }
          style={{ width: '100%' }}>
          LIHAT
        </Button>
        <Button
          type="primary"
          onClick={() =>
            editRow(row['id'], {
              bank: row['bank'].id,
              chartOfAccount: row['chartOfAccount'],
              name: row['name'],
              feeCharge: row['feeCharge'],
              feeChargeInterbank: row['feeChargeInterbank'],
              paymentMethodStatus: row['paymentMethodStatus'],
              paymentMethod: row['paymentMethod'].id,
            })
          }
          style={{
            width: '100%',
            color: '#fff',
            backgroundColor: '#F1C40F',
            borderColor: '#dab10d',
          }}>
          UBAH
        </Button>
        <Button
          type="danger"
          onClick={() => deleteRow(row['id'])}
          style={{ width: '100%' }}>
          HAPUS
        </Button>
      </>
    ),
    width: '15%',
  },
  {
    title: 'BANK',
    key: 'bank',
    render: (row) => (row['name'] ? row['name'] : '-'),
  },
  {
    title: 'CHART OF ACCOUNT',
    key: 'chartOfAccount',
    render: (row) => (row['chartOfAccount'] ? row['chartOfAccount'] : '-'),
  },
  {
    title: 'NAMA',
    key: 'name',
    render: (row) => (row['name'] ? row['name'] : '-'),
  },
  {
    title: 'FEE CHARGE',
    key: 'feeCharge',
    render: (row) => currencyFormatter(row['feeCharge']),
  },
  {
    title: 'STATUS METODE',
    key: 'paymentMethodStatus',
    render: (row) =>
      row['paymentMethodStatus'] === 1
        ? 'Uang Masuk'
        : row['paymentMethodStatus'] === 2
        ? 'Uang Keluar'
        : '-',
  },
  {
    title: 'FEE CHARGE BEDA BANK',
    key: 'feeChargeInterbank',
    render: (row) => currencyFormatter(row['feeChargeInterbank']),
  },
  {
    title: 'Jenis Master Pembayaran',
    key: 'paymentMethod',
    render: (row) => (row['paymentMethod'] ? row['paymentMethod'].name : '-'),
  },
];
