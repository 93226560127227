import React from 'react';
import { Button } from 'antd';

export const categoryAfterSalesColumns = (editRow, deleteRow) => [
  {
    title: '#',
    key: 'index',
    render: (row) => (
      <>
        <Button
          type="primary"
          onClick={() =>
            editRow(row['id'], {
              code: row['code'],
              name: row['name'],
            })
          }
          style={{
            width: '100%',
            color: '#fff',
            backgroundColor: '#F1C40F',
            borderColor: '#dab10d',
          }}>
          UBAH
        </Button>
        <Button
          type="danger"
          onClick={() => deleteRow(row['id'])}
          style={{ width: '100%' }}>
          HAPUS
        </Button>
      </>
    ),
    width: '15%',
  },
  {
    title: 'Kode',
    key: 'code',
    render: (row) => (row['code'] ? row['code'] : '-'),
  },
  {
    title: 'Nama',
    key: 'name',
    render: (row) => (row['name'] ? row['name'] : '-'),
  },
];

export const complainAfterSalesColumns = (editRow, deleteRow) => [
  {
    title: '#',
    key: 'index',
    render: (row) => (
      <>
        <Button
          type="primary"
          onClick={() =>
            editRow(row['id'], {
              code: row['code'],
              name: row['name'],
              category: row['afterSalesCategory'].id,
            })
          }
          style={{
            width: '100%',
            color: '#fff',
            backgroundColor: '#F1C40F',
            borderColor: '#dab10d',
          }}>
          UBAH
        </Button>
        <Button
          type="danger"
          onClick={() => deleteRow(row['id'])}
          style={{ width: '100%' }}>
          HAPUS
        </Button>
      </>
    ),
    width: '15%',
  },
  {
    title: 'Kode',
    key: 'code',
    render: (row) => (row['code'] ? row['code'] : '-'),
  },
  {
    title: 'Nama',
    key: 'name',
    render: (row) => (row['name'] ? row['name'] : '-'),
  },
  {
    title: 'Kategori',
    key: 'afterSalesCategory',
    render: (row) =>
      row['afterSalesCategory'] ? row['afterSalesCategory'].name : '-',
  },
];

export const categoryProductionColumns = (editRow, deleteRow) => [
  {
    title: '#',
    key: 'index',
    render: (row) => (
      <>
        <Button
          type="primary"
          onClick={() =>
            editRow(row['id'], {
              name: row['name'],
            })
          }
          style={{
            width: '100%',
            color: '#fff',
            backgroundColor: '#F1C40F',
            borderColor: '#dab10d',
          }}>
          UBAH
        </Button>
        <Button
          type="danger"
          onClick={() => deleteRow(row['id'])}
          style={{ width: '100%' }}>
          HAPUS
        </Button>
      </>
    ),
    width: '15%',
  },
  {
    title: 'Nama',
    key: 'name',
    render: (row) => (row['name'] ? row['name'] : '-'),
  },
];

export const reasonLabOrderColumns = (viewRow, editRow, deleteRow) => [
  {
    title: '#',
    key: 'index',
    render: (row) => (
      <>
        <Button
          type="primary"
          onClick={() =>
            viewRow(row['id'], {
              reason: row['reason'],
              type: row['reasonType'].name,
            })
          }
          style={{ width: '100%' }}>
          LIHAT
        </Button>
        <Button
          type="primary"
          onClick={() =>
            editRow(row['id'], {
              reason: row['reason'],
              type: row['reasonType'].id,
            })
          }
          style={{
            width: '100%',
            color: '#fff',
            backgroundColor: '#F1C40F',
            borderColor: '#dab10d',
          }}>
          UBAH
        </Button>
        <Button
          type="danger"
          onClick={() => deleteRow(row['id'])}
          style={{ width: '100%' }}>
          HAPUS
        </Button>
      </>
    ),
    width: '15%',
  },
  {
    title: 'Reason',
    key: 'reason',
    render: (row) => (row['reason'] ? row['reason'] : '-'),
  },
  {
    title: 'Type',
    key: 'reasonType',
    render: (row) => (row['reasonType'] ? row['reasonType'].name : '-'),
  },
];
