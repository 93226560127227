import React, { useEffect, useState } from 'react';
import { Col, Row, Layout, Button, Input, Card, Modal } from 'antd';
import {
  ExclamationCircleTwoTone,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import moment from 'moment';
import { withRouter, useParams } from 'react-router-dom';
import { PageSpinner } from 'components/page-spinner';
import AppointmentApi from 'api/appointment';
import TranscationApi from 'api/transaction';
import { currencyFormatter } from 'utils';

import '../styles/index.scss';

const { Content } = Layout;
const { TextArea } = Input;

export function DetailBatalKasir({ history }) {
  const [patientDetail, setPatientDetail] = useState();
  const [receiptDetail, setReceiptDetail] = useState();
  const [voidReason, setVoidReason] = useState();

  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);

  const [loadingPage, setLoadingPage] = useState(false);

  const { id } = useParams();

  const getPatientDetail = async () => {
    try {
      setLoadingPage(true);

      const appointmentId = id.split('_')[0];

      const response = await AppointmentApi.getPatientDetail(appointmentId);

      let patient = response.data.data;

      setPatientDetail(patient);
    } finally {
      setLoadingPage(false);
    }
  };

  const getReceiptDetail = async () => {
    try {
      setLoadingPage(true);

      const appointmentId = id.split('_')[0];

      const receiptNumber = id.split('_')[1];

      const response = await TranscationApi.getReceiptDetail(
        appointmentId,
        receiptNumber
      );

      const receipt = response.data.data;

      setReceiptDetail(receipt);
    } finally {
      setLoadingPage(false);
    }
  };

  const submitVoidReturn = async () => {
    const voidId = localStorage.getItem('id');
    const voidName = localStorage.getItem('name');

    const payload = {
      voidBy: voidId ? voidId : 0,
      voidByName: voidName ? voidName : '',
      voidReason : voidReason,
    };

    const response = await TranscationApi.submitVoidReturn(
      payload,
      receiptDetail.id
    );

    if (response.data.resultCode === '00') {
      handleFlow('openSuccess');
    } else {
      handleFlow('closeConfirm');
      return Modal.warning({
        title: 'Perhatian',
        content: 'Terjadi kesalahan pada server.',
      });
    }
  };

  const handleFlow = (flow) => {
    switch (flow) {
      case 'openConfirm':
        setIsConfirmPopupOpen(true);
        break;
      case 'closeConfirm':
        setIsConfirmPopupOpen(false);
        break;
      case 'submit':
        if (!voidReason) {
          document.getElementById('voidReasonWarning').innerHTML = 'Mohon mengisi alasan retur';
          return;
        }
        document.getElementById('voidReasonWarning').innerHTML = '';
        submitVoidReturn();
        break;
      case 'openSuccess':
        setIsConfirmPopupOpen(false);
        setIsSuccessPopupOpen(true);
        break;
      case 'closeSuccess':
        setIsSuccessPopupOpen(false);
        redirectToManageBatalKwitansi();
        break;
      default:
        break;
    }
  };

  const redirectToPreviousPage = () => {
    history.goBack();
  };

  const redirectToManageBatalKwitansi = () => {
    history.push('/kasir/manage-batal-kasir-pasien');
  };

  const countInsuranceLimit = (insuranceClaim) => {
    let limit = 0;

    insuranceClaim.forEach((item) => {
      limit += item.limit;
    });

    return limit;
  };

  const useInitData = () => {
    if (id) {
      getPatientDetail();
      getReceiptDetail();
    }
  };

  useEffect(useInitData, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <Card
          title="Detail Batal Kasir"
          headStyle={{
            fontSize: '20px'
          }}
          style={{ border: '0px' }}
        >
          {!!patientDetail && !!receiptDetail && (
            <>
              <div className="mb-15">
                <Row type="flex" align="middle">
                  <Col span={5} offset={1}>
                    Pasien
                  </Col>
                  <Col span={5}>
                    <Input readOnly value={patientDetail.patientName} />
                  </Col>
                  <Col span={5} offset={1}>
                    Tanggal Bayar
                  </Col>
                  <Col span={5}>
                    <Input
                      readOnly
                      value={moment(receiptDetail.paymentDate).format(
                        'DD/MM/YYYY'
                      )}
                    />
                  </Col>
                </Row>
              </div>
              <div className="mb-15">
                <Row type="flex" align="middle">
                  <Col span={5} offset={1}>
                    Nomer Kwitansi
                  </Col>
                  <Col span={5}>
                    <Input readOnly value={receiptDetail.receiptNumber} />
                  </Col>
                  <Col span={5} offset={1}></Col>
                  <Col span={5}></Col>
                </Row>
              </div>
              <div className="mb-15">
                <Row>
                  <Col span={24}>
                    <table
                      style={{
                        borderCollapse: 'collapse',
                        width: '100%',
                      }}
                      border="1"
                      className="table-receipt">
                      <tbody>
                        <tr style={{ textAlign: 'center' }}>
                          <td>
                            <strong>No</strong>
                          </td>
                          <td>
                            <strong>Item Information</strong>
                          </td>
                          <td>
                            <strong>JUMLAH</strong>
                          </td>
                          <td>
                            <strong>HARGA SATUAN</strong>
                          </td>
                          <td>
                            <strong>DISKON</strong>
                          </td>
                          <td>
                            <strong>TOTAL</strong>
                          </td>
                        </tr>
                        {!!receiptDetail.slipTreatmentList &&
                          receiptDetail.slipTreatmentList.map(
                            (treatment, index) => (
                              <tr>
                                <td style={{ textAlign: 'center' }}>
                                  {index + 1}
                                </td>
                                <td>{treatment.description}</td>
                                <td style={{ textAlign: 'center' }}>
                                  {treatment.quantity}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  {currencyFormatter(treatment.unitPrice)}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  {currencyFormatter(treatment.discountTotal)}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  {currencyFormatter(treatment.totalPrice)}
                                </td>
                              </tr>
                            )
                          )}
                        {!!receiptDetail.receiptDetailList && (
                          <>
                            <tr>
                              <td
                                style={{
                                  borderBottom: 'hidden',
                                  borderLeft: 'hidden',
                                  textAlign: 'center',
                                }}
                                colspan="4"
                                rowspan={
                                  receiptDetail.receiptDetailList.length + 5
                                }></td>
                              <td style={{ textAlign: 'right' }}>
                                <strong>SUB TOTAL</strong>
                              </td>
                              <td style={{ textAlign: 'right' }}>
                                <strong>
                                  Rp {currencyFormatter(receiptDetail.subTotal)}
                                </strong>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'right' }}>
                                <strong>GRAND TOTAL</strong>
                              </td>
                              <td style={{ textAlign: 'right' }}>
                                <strong>
                                  Rp{' '}
                                  {currencyFormatter(receiptDetail.grandTotal)}
                                </strong>
                              </td>
                            </tr>
                            {!!receiptDetail.receiptDetailList &&
                              receiptDetail.receiptDetailList.length > 0 &&
                              receiptDetail.receiptDetailList.map((payment) => (
                                <tr>
                                  <td style={{ textAlign: 'right' }}>
                                    <strong>{payment.paymentMethod}</strong>
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <strong>
                                      Rp{' '}
                                      {currencyFormatter(payment.totalPayment)}
                                    </strong>
                                  </td>
                                </tr>
                              ))}
                            {!!receiptDetail.insuranceClaims &&
                              receiptDetail.insuranceClaims.length > 0 && (
                                <tr>
                                  <td style={{ textAlign: 'right' }}>
                                    <strong>INSURANCE</strong>
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <strong>
                                      Rp{' '}
                                      {currencyFormatter(
                                        countInsuranceLimit(
                                          receiptDetail.insuranceClaims[0]
                                            .treatments
                                        )
                                      )}
                                    </strong>
                                  </td>
                                </tr>
                              )}
                            <tr>
                              <td style={{ textAlign: 'right' }}>
                                <strong>JUMLAH PEMBAYARAN</strong>
                              </td>
                              <td style={{ textAlign: 'right' }}>
                                <strong>
                                  Rp{' '}
                                  {currencyFormatter(receiptDetail.grandTotal)}
                                </strong>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'right' }}>
                                <strong>KEMBALI</strong>
                              </td>
                              <td style={{ textAlign: 'right' }}>
                                <strong>
                                  Rp{' '}
                                  {currencyFormatter(
                                    receiptDetail.changeReturn
                                  )}
                                </strong>
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </div>
              <div>
                <Row type="flex" align="middle" justify="end">
                  <div className="mr-10">
                    <Button type="default" onClick={redirectToPreviousPage}>
                      Cancel
                    </Button>
                  </div>
                  <div>
                    <Button
                      type="danger"
                      onClick={() => handleFlow('openConfirm')}>
                      Void & Retur
                    </Button>
                  </div>
                </Row>
              </div>
            </>
          )}
        </Card>

        <Modal
          visible={isConfirmPopupOpen}
          width={'35%'}
          title={<span style={{fontSize: '16px'}}>Void & Retur Transaction</span>}
          closable={false}
          footer={[
            <Button type="default" onClick={() => handleFlow('closeConfirm')}>
              Cancel
            </Button>,
            <Button type="danger" onClick={() => handleFlow('submit')}>
              Retur
            </Button>,
          ]}>
            <div className='mb-15'>
              <label for='voidReason'>Void & Retur Reason *</label>
              <TextArea
                autoSize={{minRows:3, maxRows: 5}}
                placeholder='Insert text'
                id='voidReason'
                required
                onChange={(e) => setVoidReason(e.target.value)}
                value={voidReason}
                maxLength={255}
              />
              <small style={{color: 'red'}} id='voidReasonWarning'></small>
            </div>
        </Modal>

        <Modal
          visible={isSuccessPopupOpen}
          width={'35%'}
          closable={false}
          footer={[
            <Button type="primary" onClick={() => handleFlow('closeSuccess')}>
              OK
            </Button>,
          ]}>
          <Row>
            <div className="mt-10 mb-10">
              <CheckCircleTwoTone
                twoToneColor="#a5dc86"
                style={{ fontSize: 50 }}
              />
            </div>
          </Row>
          <Row>
            <div className="mb-10">
              <strong>Berhasil</strong>
            </div>
          </Row>
          <Row>
            <div>Data Berhasil Diperbarui</div>
          </Row>
        </Modal>
      </Content>
    </Layout>
  );
}

export default withRouter(DetailBatalKasir);
