import React, { useEffect, useState } from 'react';
import { Col, Row, Layout, Button, Input, Card, Checkbox } from 'antd';
import { withRouter, useParams } from 'react-router-dom';
import { PageSpinner } from 'components/page-spinner';
import AppointmentApi from 'api/appointment';
import TranscationApi from 'api/transaction';
import { currencyFormatter } from 'utils';

import '../styles/index.scss';

const { Content } = Layout;

export function ManagePrintKwitansi({ history }) {
  const [patientDetail, setPatientDetail] = useState();
  const [receiptDetail, setReceiptDetail] = useState();

  const [listReceipt, setListReceipt] = useState([]);
  const [listReceiptSelection, setListReceiptSelection] = useState([]);

  const [loadingPage, setLoadingPage] = useState(false);

  const { id } = useParams();

  const getPatientDetail = async () => {
    try {
      setLoadingPage(true);

      const appointmentId = id.split('_')[0];

      const response = await AppointmentApi.getPatientDetail(appointmentId);

      let patient = response.data.data;

      setPatientDetail(patient);
    } finally {
      setLoadingPage(false);
    }
  };

  const getReceiptDetail = async () => {
    try {
      setLoadingPage(true);

      const receiptId = id.split('_')[2];

      const response = await TranscationApi.getReceiptDetailById(receiptId);

      const receipt = response.data.data;

      setReceiptDetail(receipt);

      const { slipTreatmentList } = receipt;

      const listReceiptId = slipTreatmentList.map((item) => item.id);

      setListReceipt(listReceiptId);
      setListReceiptSelection(listReceiptId);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleReceiptCheck = (id) => {
    const listReceiptId = [...listReceiptSelection];

    if (listReceiptId.includes(id)) {
      const index = listReceiptId.indexOf(id);

      listReceiptId.splice(index, 1);
    } else {
      listReceiptId.push(id);
    }

    setListReceiptSelection(listReceiptId);
  };

  const redirectToPrintKwitansi = () => {
    const appointmentId = id.split('_')[0];

    const receiptNumber = id.split('_')[1];

    const receiptId = id.split('_')[2];

    let slipTreatmentId = '';

    for (let index = 0; index < listReceipt.length; index++) {
      slipTreatmentId += `${listReceipt[index]}-`;
    }

    history.push(
      `/kasir/kwitansi-selection/${appointmentId}_${receiptNumber}_${receiptId}_${slipTreatmentId.slice(
        0,
        -1
      )}_all`
    );
  };

  const redirectToPrintKwitansiSelection = () => {
    const appointmentId = id.split('_')[0];

    const receiptNumber = id.split('_')[1];

    const receiptId = id.split('_')[2];

    let slipTreatmentId = '';

    for (let index = 0; index < listReceiptSelection.length; index++) {
      slipTreatmentId += `${listReceiptSelection[index]}-`;
    }

    history.push(
      `/kasir/kwitansi-selection/${appointmentId}_${receiptNumber}_${receiptId}_${slipTreatmentId.slice(
        0,
        -1
      )}_selection`
    );
  };

  const useInitData = () => {
    if (id) {
      getPatientDetail();
      getReceiptDetail();
    }
  };

  useEffect(useInitData, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <Card
          title="Manage Print Kwitansi"
          headStyle={{
            backgroundColor: '#3598dc',
            color: '#fff',
          }}
          style={{ border: '1px solid #3598dc' }}>
          {!!patientDetail && !!receiptDetail && (
            <>
              <div className="mb-15">
                <Row type="flex" align="middle">
                  <Col span={5} offset={1}>
                    Pasien
                  </Col>
                  <Col span={5}>
                    <Input readOnly value={patientDetail.patientName} />
                  </Col>
                  <Col span={5} offset={1}>
                    Nomor Rekam Medis
                  </Col>
                  <Col span={5}>
                    <Input readOnly value={patientDetail.medicalRecordNumber} />
                  </Col>
                </Row>
              </div>
              <div className="mb-15">
                <Row>
                  <Col span={24}>
                    <table
                      style={{
                        borderCollapse: 'collapse',
                        width: '100%',
                      }}
                      border="1"
                      className="table-receipt">
                      <tbody>
                        <tr style={{ textAlign: 'center' }}>
                          <td>
                            <strong>Deskripsi</strong>
                          </td>
                          <td>
                            <strong>JML</strong>
                          </td>
                          <td>
                            <strong>HARGA</strong>
                          </td>
                          <td>
                            <strong>DISKON</strong>
                          </td>
                          <td>
                            <strong>TOTAL</strong>
                          </td>
                          <td>
                            <strong>Aksi</strong>
                          </td>
                        </tr>
                        {!!receiptDetail.slipTreatmentList &&
                          receiptDetail.slipTreatmentList.map(
                            (treatment, index) => (
                              <tr>
                                <td>{treatment.description}</td>
                                <td style={{ textAlign: 'center' }}>
                                  {treatment.quantity}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  {currencyFormatter(treatment.unitPrice)}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  {currencyFormatter(treatment.discountTotal)}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  {currencyFormatter(treatment.totalPrice)}
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <Checkbox
                                    checked={listReceiptSelection.includes(
                                      treatment.id
                                    )}
                                    onChange={() =>
                                      handleReceiptCheck(treatment.id)
                                    }></Checkbox>
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </div>
              <div>
                <Row type="flex" align="middle" justify="end">
                  <div className="mr-10">
                    <Button type="primary" onClick={redirectToPrintKwitansi}>
                      PRINT ALL
                    </Button>
                  </div>
                  <div>
                    <Button
                      type="primary"
                      style={{
                        width: '100%',
                        color: '#fff',
                        backgroundColor: '#36c6d3',
                        borderColor: '#2bb8c4',
                      }}
                      onClick={redirectToPrintKwitansiSelection}
                      disabled={listReceiptSelection.length < 1}>
                      PRINT
                    </Button>
                  </div>
                </Row>
              </div>
            </>
          )}
        </Card>
      </Content>
    </Layout>
  );
}

export default withRouter(ManagePrintKwitansi);
