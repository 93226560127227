import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Layout,
  Table,
  Button,
  Input,
  DatePicker,
  Modal,
} from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import ReportApi from 'api/report';
import ClinicApi from 'api/clinic';
import { PageSpinner } from 'components/page-spinner';
import SelectDropdown from 'components/select-dropdown';
import { setToArray } from 'utils';
import XLSX from 'xlsx';

import {
  visitationPatientCheckColumns,
  visitationPatientSummaryColumns,
} from '../helper/';
import '../styles/index.scss';

const { Content } = Layout;

export function CekLaporanKunjunganPasien({ history }) {
  const objectVisitationSummary = [
    {
      key: 1,
      column1: 'Total Pasien Baru',
      column2: 0,
    },
    {
      key: 2,
      column1: 'Total Pasien Lama',
      column2: 0,
    },
  ];

  const [clinic, setClinic] = useState();
  const [clinicOptions, setClinicOptions] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [queue, setQueue] = useState([]);
  const [filteredQueue, setFilteredQueue] = useState([]);
  const [summaryQueue, setSummaryQueue] = useState([]);

  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);

  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    showSizeChanger: true,
    total: 0,
  });

  async function getQueueList(limit, page) {
    try {
      setLoadingTable(true);

      const response = await ReportApi.getPatientVisitList(
        clinic,
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
        limit,
        page
      );

      let queueList = setToArray(response.data);

      const modifiedQueueList = queueList.map((item, index) => ({
        ...item,
        key: index + 1,
      }));

      setQueue(modifiedQueueList);
      setFilteredQueue(modifiedQueueList);

      const newVisitationSummary = [...objectVisitationSummary];

      newVisitationSummary[0].column2 = response.data.data.newPatientTotal;
      newVisitationSummary[1].column2 = response.data.data.oldPatientTotal;

      setSummaryQueue(objectVisitationSummary);

      const paginationConfig = {
        ...pagination,
        current: response.data.data.page,
        pageSize: response.data.data.limit,
        total: response.data.data.totalData,
      };

      setPagination(paginationConfig);
    } finally {
      setLoadingTable(false);
    }
  }

  async function getClinicList() {
    try {
      setLoadingPage(true);

      const response = await ClinicApi.listClinic();

      let clinicList = setToArray(response.data);

      const clinicListOpts = clinicList.map((value) => ({
        label: value.clinicName,
        value: value.id,
      }));

      clinicListOpts.unshift({ label: 'Semua Klinik', value: '' });

      setClinicOptions(clinicListOpts);
    } finally {
      setLoadingPage(false);
    }
  }

  async function generateReport(format) {
    try {
      const response = await ReportApi.getPatientVisitList(
        clinic,
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD')
      );

      let queueList = setToArray(response.data);

      let arrayExcel = [
        [
          'No Registrasi',
          'Tanggal dan Jam Datang',
          'Tanggal dan Jam Pulang',
          'Nomor Rekam Medis',
          'Nama',
          'Klinik',
          'Dokter',
          'Diagnosa',
          'Asuhan Keperawatan',
          'CPPT Dokter',
          'CPPT Perawat',
          'Status Pasien',
        ],
      ];

      queueList.forEach((item, index) => {
        arrayExcel.push([
          index + 1, //No
          `${moment(item.visitDate).format('DD-MM-YYYY')} ${moment(
            item.visitStart,
            'hh:mm:ss'
          ).format('HH:mm:ss')}`, // Tanggal dan Jam Datang
          `${moment(item.visitDate).format('DD-MM-YYYY')} ${moment(
            item.visitEnd,
            'hh:mm:ss'
          ).format('HH:mm:ss')}`, // Tanggal dan Jam Pulang
          item.medicalRecordNumber ? item.medicalRecordNumber : '-', // Nomor Rekam Medis
          item.patientName ? item.patientName : '-', // Nama
          item.clinicName ? item.clinicName : '-', // Klinik
          item.doctorName ? item.doctorName : '-', // Dokter
          item.diagnose ? item.diagnose : '-', // Diagnosa
          item.nurseInstruction ? item.nurseInstruction : '-', // Asuhan Keperawatan
          item.doctorCppt ? item.doctorCppt : '-', // CPPT Dokter
          item.nurseCppt ? item.nurseCppt : '-', // CPPT Perawat
          item.statusPatient ? item.statusPatient : '-', // Status Pasien
        ]);
      });

      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.aoa_to_sheet(arrayExcel);
      XLSX.utils.book_append_sheet(wb, ws, 'Cek Laporan Kunjungan Pasien');

      const fileName = `Cek_Laporan_Kunjungan_Pasien_${moment().format(
        'YYYY-MM-DD'
      )}_${moment().format('hhmmss')}.${format}`;

      /* Trigger Download with `writeFile` */
      XLSX.writeFile(wb, fileName, { compression: true });
    } catch (error) {
      Modal.warning({
        title: 'PERHATIAN',
        content: 'Terjadi error pada server. Silahkan coba lagi!',
      });
    }
  }

  const handleClinicChange = (value) => {
    setClinic(value);
  };

  const handleStartDateChange = (date, dateString) => {
    if (!date) setStartDate(moment());
    else setStartDate(date);
  };

  const handleEndDateChange = (date, dateString) => {
    if (!date) setEndDate(moment());
    else setEndDate(date);
  };

  const onTableChange = (pagination) => {
    getQueueList(pagination.pageSize, pagination.current);
    setPagination(pagination);
  };

  //search
  const onSearchChange = (e) => {
    const convertValue = e.target.value.toLowerCase();
    const labelValue = e.target.value;
    setSearch(labelValue);

    const filteredData = queue.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          !isEmpty(item[key]) &&
          item[key].toString().toLowerCase().search(convertValue) !== -1
      );
    });

    setFilteredQueue(filteredData);
  };

  const useInitData = () => {
    getQueueList(pagination.pageSize, pagination.current);
    getClinicList();

    const today = moment();
    setStartDate(today);
    setEndDate(today);

    setSummaryQueue(objectVisitationSummary);
  };

  useEffect(useInitData, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <div className="mb-15">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Cek Laporan Kunjungan Pasien
              </strong>
            </Col>
          </Row>
        </div>
        <div className="panel panel__search">
          <Row>
            <Col span={2}>
              <div className="mb-15">
                <div className="skybase-dashboard__search">
                  <Input
                    disabled
                    value="Mulai"
                    style={{
                      color: 'rgba(0, 0, 0, 0.65)',
                      cursor: 'auto',
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className="mb-15">
                <div className="skybase-dashboard__search">
                  <DatePicker
                    showToday
                    allowClear={false}
                    format="DD/MM/YYYY"
                    onChange={handleStartDateChange}
                    value={startDate}
                  />
                </div>
              </div>
            </Col>
            <Col span={2}>
              <div className="mb-15">
                <div className="skybase-dashboard__search">
                  <Input
                    disabled
                    value="Sampai"
                    style={{
                      color: 'rgba(0, 0, 0, 0.65)',
                      cursor: 'auto',
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className="mb-15">
                <div className="skybase-dashboard__search">
                  <DatePicker
                    showToday
                    allowClear={false}
                    format="DD/MM/YYYY"
                    onChange={handleEndDateChange}
                    value={endDate}
                  />
                </div>
              </div>
            </Col>
            <Col span={4}>
              <div className="mb-15">
                <div className="skybase-dashboard__search">
                  <Button
                    onClick={() => getQueueList(10, 1)}
                    style={{
                      color: '#fff',
                      backgroundColor: '#36c6d3',
                      borderColor: '#2bb8c4',
                    }}>
                    <strong>TAMPILKAN</strong>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={2}>
              <div className="skybase-dashboard__search">
                <Input
                  disabled
                  value="Klinik"
                  style={{
                    color: 'rgba(0, 0, 0, 0.65)',
                    cursor: 'auto',
                  }}
                />
              </div>
            </Col>
            <Col span={22}>
              <div className="skybase-dashboard__search">
                <SelectDropdown
                  options={clinicOptions}
                  placeHolder="Pilih Klinik"
                  onChange={handleClinicChange}
                  value={clinic}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="panel">
          <Row>
            <Col span={24}>
              <div className="mb-15">
                <strong className="text-md skybase-dashboard__title">
                  Kunjungan Pasien
                </strong>
              </div>
            </Col>
          </Row>
          {loadingTable ? (
            <PageSpinner />
          ) : (
            <>
              <Row>
                <Col span={24} type="flex" align="end">
                  <div className="mb-15">
                    <Button onClick={() => {}}>PRINT</Button>
                    <Button onClick={() => {}}>COPY</Button>
                    <Button onClick={() => {}}>PDF</Button>
                    <Button
                      onClick={() => {
                        generateReport('xlsx');
                      }}>
                      EXCEL
                    </Button>
                    <Button
                      onClick={() => {
                        generateReport('csv');
                      }}>
                      CSV
                    </Button>
                    <Button
                      onClick={() => getQueueList(pagination.pageSize, 1)}>
                      RELOAD
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={2} type="flex" align="start"></Col>
                <Col span={5} offset={17} type="flex" align="end">
                  <div className="mb-15">
                    <Input
                      className="skybase-dashboard__search-input"
                      placeholder="Search"
                      onChange={onSearchChange}
                      value={search}
                    />
                  </div>
                </Col>
              </Row>
              <Table
                dataSource={filteredQueue}
                rowKey="key"
                columns={visitationPatientCheckColumns}
                onChange={onTableChange}
                className="skybase-dashboard__active-order-table"
                footer={() => (
                  <Table
                    dataSource={summaryQueue}
                    rowKey="key"
                    columns={visitationPatientSummaryColumns}
                    className="skybase-dashboard__active-order-table"
                    showHeader={false}
                    pagination={false}
                    size="small"
                  />
                )}
                pagination={pagination}
                size="small"
                scroll={{ x: 1500, y: 500 }}
              />
            </>
          )}
        </div>
      </Content>
    </Layout>
  );
}

export default withRouter(CekLaporanKunjunganPasien);
