import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Layout,
  Table,
  Button,
  // Tooltip,
  Input,
  DatePicker,
} from 'antd';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import PatientInteractionApi from 'api/patient-interaction';
import MarketingApi from 'api/marketing';
import { PageSpinner } from 'components/page-spinner';
import SelectDropdown from 'components/select-dropdown';
import { setToArray } from 'utils';
import XLSX from 'xlsx';

import { interactionPatientReportColumns } from './helper';

import './styles/index.scss';

const { Content } = Layout;

export function LaporanInteraksiPasien({ history }) {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [category, setCategory] = useState();

  const [listPatient, setListPatient] = useState([]);
  const [listFilteredPatient, setListFilteredPatient] = useState([]);
  const [listCategoryOptions, setListCategoryOptions] = useState([]);

  // const [loadingPage, setLoadingPage] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);

  const [search, setSearch] = useState('');

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    showSizeChanger: true,
    total: 0,
  });

  async function getPatientList(limit, page) {
    try {
      setLoadingTable(true);

      const response = await PatientInteractionApi.getAllAfterSalesList(
        category,
        '',
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
        limit,
        page
      );

      let patientList = setToArray(response.data);

      let newPatientList = [];

      patientList.forEach((item) => {
        newPatientList.push({
          ...item,
          fullname: item.patient ? item.patient.fullname : null,
        });
      });

      setListPatient(newPatientList);
      setListFilteredPatient(newPatientList);

      const paginationConfig = {
        ...pagination,
        current: response.data.data.page,
        pageSize: response.data.data.limit,
        total: response.data.data.totalData,
      };

      setPagination(paginationConfig);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTable(false);
    }
  }

  const getCategoryOptions = async () => {
    const response = await MarketingApi.getCategoryList();

    let categoryList = setToArray(response.data);

    const categoryListOpts = categoryList.map((value) => ({
      label: value.name,
      value: value.name,
    }));

    setListCategoryOptions(categoryListOpts);
  };

  const handleCategoryChange = (value) => {
    setCategory(value);
  };

  const handleStartDateChange = (date, dateString) => {
    if (!date) setStartDate(moment());
    else setStartDate(date);
  };

  const handleEndDateChange = (date, dateString) => {
    if (!date) setEndDate(moment());
    else setEndDate(date);
  };

  const generateReport = async (format) => {
    try {
      const response = await PatientInteractionApi.getAllAfterSalesList(
        category,
        true,
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD')
      );

      let patientList = setToArray(response.data);

      let newPatientList = [];

      patientList.forEach((item) => {
        newPatientList.push({
          ...item,
          fullname: item.patient ? item.patient.fullname : null,
        });
      });

      let arrayExcel = [
        [
          'NO',
          'TANGGAL (INPUT)',
          'NAMA PASIEN',
          'GROUP',
          'KATEGORI',
          'KELUHAN',
          'DETAIL KELUHAN',
          'KELUHAN ALIGNER RA',
          'KELUHAN ALIGNER RB',
          'SOLUSI',
          'STATUS',
          'STAFF NAME',
          'TANGGAL (PENDING CASE)',
          'TANGGAL (CLOSED CASE)',
        ],
      ];

      newPatientList.forEach((item, index) => {
        arrayExcel.push([
          index + 1, //No
          moment(item.createdAt).format('DD-MM-YYYY'), //Tanggal (Input)
          item.patientName, //Nama Pasien
          item.group, // Group
          item.category, // Kategori
          item.complain, // Keluhan
          item.complainDetail, // Detail Keluhan
          item.alignerIssueRa, // Keluhan Aligner RA
          item.alignerIssueRb, // Keluhan Aligner RB
          item.solution, // Solusi
          item.status ? item.status.toUpperCase() : '', // Status
          item.staffName, // Staff Name
          item.pendingTimestamp
            ? moment(item.pendingTimestamp).format('DD-MM-YYYY HH:mm:ss')
            : '', // Tanggal (Pending Case)
          item.closedTimestamp
            ? moment(item.closedTimestamp).format('DD-MM-YYYY HH:mm:ss')
            : '', // Tanggal (Closed Case)
        ]);
      });

      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.aoa_to_sheet(arrayExcel);
      XLSX.utils.book_append_sheet(wb, ws, 'Laporan Interaksi Pasien');

      const fileName = `Laporan_Interaksi_Pasien_${moment().format(
        'YYYY-MM-DD'
      )}_${moment().format('hhmmss')}.${format}`;

      /* Trigger Download with `writeFile` */
      XLSX.writeFile(wb, fileName, { compression: true });
    } catch (error) {
      console.log(error);
    }
  };

  const onTableChange = (pagination) => {
    getPatientList(pagination.pageSize, pagination.current);
    setPagination(pagination);
  };

  //search
  const onSearchChange = (e) => {
    const convertValue = e.target.value.toLowerCase();
    const labelValue = e.target.value;
    setSearch(labelValue);

    const filteredData = listPatient.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          !isEmpty(item[key]) &&
          item[key].toString().toLowerCase().search(convertValue) !== -1
      );
    });

    setListFilteredPatient(filteredData);
  };

  const useInitData = () => {
    getCategoryOptions();
    getPatientList(pagination.pageSize, pagination.current);

    const today = moment();
    setStartDate(today);
    setEndDate(today);
  };

  useEffect(useInitData, []);


  return (
    <Layout className="order-list-page">
      <Content>
        <div className="mb-15">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Laporan Interaksi Pasien
              </strong>
            </Col>
          </Row>
        </div>
        <div className="panel panel__search">
          <Row>
            <Col span={2}>
              <div className="mb-15">
                <div className="skybase-dashboard__search">
                  <Input
                    disabled
                    value="Mulai"
                    style={{
                      color: 'rgba(0, 0, 0, 0.65)',
                      cursor: 'auto',
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className="mb-15">
                <div className="skybase-dashboard__search">
                  <DatePicker
                    showToday
                    allowClear={false}
                    format="DD/MM/YYYY"
                    onChange={handleStartDateChange}
                    value={startDate}
                  />
                </div>
              </div>
            </Col>
            <Col span={2}>
              <div className="mb-15">
                <div className="skybase-dashboard__search">
                  <Input
                    disabled
                    value="Sampai"
                    style={{
                      color: 'rgba(0, 0, 0, 0.65)',
                      cursor: 'auto',
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className="mb-15">
                <div className="skybase-dashboard__search">
                  <DatePicker
                    showToday
                    allowClear={false}
                    format="DD/MM/YYYY"
                    onChange={handleEndDateChange}
                    value={endDate}
                  />
                </div>
              </div>
            </Col>
            <Col span={4}>
              <div className="mb-15">
                <div className="skybase-dashboard__search">
                  <Button
                    onClick={() => getPatientList(10, 1)}
                    style={{
                      color: '#fff',
                      backgroundColor: '#36c6d3',
                      borderColor: '#2bb8c4',
                    }}>
                    <strong>TAMPILKAN</strong>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="skybase-dashboard__search">
                <SelectDropdown
                  options={listCategoryOptions}
                  placeHolder="Pilih Kategori"
                  onChange={handleCategoryChange}
                  value={category}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="panel">
          <Row>
            <Col span={24}>
              <div className="mb-15">
                <strong className="text-md skybase-dashboard__title">
                  LAPORAN INTERAKSI PASIEN
                </strong>
              </div>
            </Col>
          </Row>
          {loadingTable ? (
            <PageSpinner />
          ) : (
            <>
              <Row>
                <Col span={24} type="flex" align="end">
                  <div className="mb-15">
                    <Button onClick={() => {}}>PRINT</Button>
                    <Button onClick={() => {}}>COPY</Button>
                    <Button onClick={() => {}}>PDF</Button>
                    <Button onClick={() => generateReport('xlsx')}>
                      EXCEL
                    </Button>
                    <Button onClick={() => generateReport('csv')}>CSV</Button>
                    <Button
                      onClick={() => getPatientList(pagination.pageSize, 1)}>
                      RELOAD
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={2} type="flex" align="start"></Col>
                <Col span={5} offset={17} type="flex" align="end">
                  <div className="mb-15">
                    <Input
                      className="skybase-dashboard__search-input"
                      placeholder="Search"
                      onChange={onSearchChange}
                      value={search}
                    />
                  </div>
                </Col>
              </Row>
              <Table
                dataSource={listFilteredPatient}
                // rowKey="medicalRecordNumber"
                scroll={{ x: 1100, y: 500 }}
                onChange={onTableChange}
                pagination={pagination}
                columns={interactionPatientReportColumns}
                className="skybase-dashboard__active-order-table"
              />
            </>
          )}
        </div>
      </Content>
    </Layout>
  );
}

export default withRouter(LaporanInteraksiPasien);
