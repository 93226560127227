import api from './index';

export default {
  getFotoBeforeAfterList(medicalRecordNumber) {
    return api.get(
      `/medical-record/lab-order/patient/${medicalRecordNumber}/before-after?page=1&limit=1000`
    );
  },

  getTreatmentPlanList(medicalRecordNumber) {
    return api.get(
      `/medical-record/patient/${medicalRecordNumber}/file?page=1&limit=1000`
    );
  },

  getAligner(medicalRecordNumber) {
    return api.get(
      `/medical-record/lab-order/patient/${medicalRecordNumber}/aligner`
    );
  },

  getFirstSetVisitList(medicalRecordNumber) {
    return api.get(
      `/medical-record/lab-order/patient/${medicalRecordNumber}/first-set-visit?page=1&limit=1000`
    );
  },

  getRefinementList(medicalRecordNumber) {
    return api.get(
      `/medical-record/lab-order/patient/${medicalRecordNumber}/refinement?page=1&limit=1000`
    );
  },

  getCetakUlangList(medicalRecordNumber) {
    return api.get(
      `/medical-record/lab-order/patient/${medicalRecordNumber}/cetak-ulang?page=1&limit=1000`
    );
  },

  getExtraAlignerList(medicalRecordNumber) {
    return api.get(
      `/medical-record/lab-order/patient/${medicalRecordNumber}/extra-aligner?page=1&limit=1000`
    );
  },

  getRetainerRataList(medicalRecordNumber) {
    return api.get(
      `/medical-record/lab-order/patient/${medicalRecordNumber}/retainer?page=1&limit=1000`
    );
  },

  getReQcList(medicalRecordNumber) {
    return api.get(
      `/medical-record/lab-order/patient/${medicalRecordNumber}/re-qc?page=1&limit=1000`
    );
  },

  getNoteList(medicalRecordNumber) {
    return api.get(
      `/medical-record/lab-order/patient/${medicalRecordNumber}/note?page=1&limit=1000`
    );
  },
};
