import {Select} from 'antd';
import debounce from 'lodash/debounce';
import React, {useMemo, useRef, useState} from 'react';
import PropTypes from "prop-types";

export const DebounceSelect = ({
                                   placeHolder,
                                   extOptions,
                                   onChange,
                                   onSearch,
                                   value,
                                   showArrow,
                                   showSearch,
                                   defaultActiveFirstOption,
                                   filterOption,
                                   notFoundContent,
                                   optionsLabel,
                                   optionsValue,
                                   dropdownMatchSelectWidth,
                                   allowClear,
                                   disabled,
                                   debounceTimeout
                               }) => {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState(extOptions);
    const fetchRef = useRef(0);
    const {Option} = Select;

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            onSearch(value).then(newOptions => {
                if (fetchId !== fetchRef.current) {
                    return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [onSearch, debounceTimeout]);

    return (
        <div className="multiselect-dropdown">
            <Select
                onChange={onChange}
                onSearch={debounceFetcher}
                value={value}
                placeholder={placeHolder}
                showArrow={showArrow}
                showSearch={showSearch}
                defaultActiveFirstOption={defaultActiveFirstOption}
                filterOption={filterOption}
                notFoundContent={fetching? notFoundContent : null}
                dropdownMatchSelectWidth={dropdownMatchSelectWidth}
                allowClear={allowClear}
                disabled={disabled}>
                {options ? options.map((value, index) => (
                    <Option
                        value={optionsValue ? value[optionsValue] : value.value}
                        key={index}>
                        {optionsLabel ? value[optionsLabel] : value.label}
                    </Option>
                )): null}
            </Select>
        </div>
    );
};

DebounceSelect.propTypes = {
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    placeHolder: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.string,
    showArrow: PropTypes.bool,
    showSearch: PropTypes.bool,
    defaultActiveFirstOption: PropTypes.bool,
    filterOption: PropTypes.bool,
    notFoundContent: PropTypes.node,
    optionsLabel: PropTypes.string,
    optionsValue: PropTypes.string,
    debounceTimeout: PropTypes.number
};

DebounceSelect.defaultProps = {
    options: [],
    debounceTimeout: 500
};

export default DebounceSelect;
