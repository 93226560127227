import api from './index';
import apiUpload from './index-upload';

export default {
  getAfterSalesList(medicalRecordNumber) {
    return api.get(`/patient-interaction/${medicalRecordNumber}/after-sales`);
  },

  submitAfterSales(payload, medicalRecordNumber) {
    return api.post(
      `/patient-interaction/${medicalRecordNumber}/after-sales`,
      payload
    );
  },

  editAfterSales(payload, medicalRecordNumber, id) {
    return api.put(
      `/patient-interaction/${medicalRecordNumber}/after-sales/${id}`,
      payload
    );
  },

  getInformedConcern(medicalRecordNumber) {
    return api.get(
      `/patient-interaction/${medicalRecordNumber}/informed-concern`
    );
  },

  submitInformedConcern(payload, medicalRecordNumber) {
    return apiUpload.post(
      `/patient-interaction/${medicalRecordNumber}/informed-concern`,
      payload
    );
  },

  getAllAfterSalesList(
    category = '',
    report = '',
    from = '',
    to = '',
    limit = '',
    page = ''
  ) {
    return api.get(
      `/patient-interaction/after-sales?${
        category ? `category=${category}&` : ''
      }${report ? `report=${report}&` : ''}${from ? `from=${from}` : ''}${
        to ? `&to=${to}` : ''
      }${limit ? `&limit=${limit}` : ''}${page ? `&page=${page}` : ''}`
    );
  },
};
