import React, { useEffect, useState, useContext } from 'react';
import {
  Col,
  Row,
  Layout,
  Table,
  Button,
  Input,
  DatePicker,
  Modal,
  Upload,
} from 'antd';
import {
  ExclamationCircleTwoTone,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import moment from 'moment';
import ImageViewer from 'react-simple-image-viewer';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import TransactionApi from 'api/transaction';
import UserApi from 'api/user';
import { PageSpinner } from 'components/page-spinner';
import SelectDropdown from 'components/select-dropdown';
import { setToArray, currencyFormatter } from 'utils';

import { AuthenticationContext } from 'contexts/authentication';

import { cashierReceiptColumns, discountSummaryColumns } from '../helper/';
import '../styles/index.scss';

const { Content } = Layout;

export function ManageCetakKasirPasien({ history }) {
  const objectReceiptDetail = [
    {
      key: 1,
      column1: 'Total Nilai Yang Di Tagihkan',
      column2: 0,
    },
    {
      key: 2,
      column1: 'Total Diskon',
      column2: 0,
    },
    {
      key: 3,
      column1: 'Total PPN',
      column2: 0,
    },
    {
      key: 4,
      column1: 'Total Nilai Yang Di Bayarkan',
      column2: 0,
    },
  ];

  const [cashier, setCashier] = useState();
  const [medicalRecordNumber, setMedicalRecordNumber] = useState();
  const [startDate, setStartDate] = useState();

  const [listCashierOptions, setListCashierOptions] = useState([]);

  const [listReceipt, setListReceipt] = useState([]);
  const [listFilteredReceipt, setListFilteredReceipt] = useState([]);
  const [listSummaryReceipt, setListSummaryReceipt] = useState([]);

  const [listFileReceipt, setListFileReceipt] = useState([]);

  const [idModal, setIdModal] = useState('');
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [listImages, setListImages] = useState([]);

  const [loadingPage, setLoadingPage] = useState(false);

  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    showSizeChanger: true,
  });

  const { permissionDataList } = useContext(AuthenticationContext);

  async function getReceiptList() {
    try {
      setLoadingPage(true);

      const response = await TransactionApi.getCashierReceiptList(
        cashier,
        medicalRecordNumber,
        moment(startDate).format('YYYY-MM-DD')
      );

      let receiptList = setToArray(response.data);

      const modifiedReceiptList = receiptList.map((item, index) => ({
        ...item,
        key: index + 1,
      }));

      setListReceipt(modifiedReceiptList);
      setListFilteredReceipt(modifiedReceiptList);

      const newReceiptSummary = [...objectReceiptDetail];

      let grandTotalSummary = 0;
      let paymentDiscountSummary = 0;
      let taxSummary = 0;
      // let

      modifiedReceiptList.forEach((item) => {
        grandTotalSummary += item.grandTotal;
        paymentDiscountSummary += item.paymentDiscount;
        taxSummary += item.tax;
      });

      newReceiptSummary[0].column2 = currencyFormatter(grandTotalSummary);
      newReceiptSummary[1].column2 = currencyFormatter(paymentDiscountSummary);
      newReceiptSummary[2].column2 = currencyFormatter(taxSummary);
      newReceiptSummary[3].column2 = currencyFormatter(grandTotalSummary);

      setListSummaryReceipt(newReceiptSummary);
    } catch (error) {
      Modal.warning({
        title: 'PERHATIAN',
        content: 'Terjadi error pada server. Silahkan coba lagi!',
      });
    } finally {
      setLoadingPage(false);
    }
  }

  async function getCashierList() {
    try {
      setLoadingPage(true);

      const response = await UserApi.getCashierList();

      let cashierList = Object.values(response.data.result);

      const cashierListOpts = cashierList.map((value) => ({
        label: value.name,
        value: value.id_user,
      }));

      setListCashierOptions(cashierListOpts);
    } finally {
      setLoadingPage(false);
    }
  }

  async function submitReceipt(listFile) {
    try {
      setLoadingPage(true);

      const payload = new FormData();

      payload.append('file', listFileReceipt[0]);

      const response = await TransactionApi.submitReceipt(payload, idModal);

      if (response.data.resultCode === '00') {
        handleFlow('openSuccess');
      } else {
        handleFlow('closeConfirm');
        Modal.warning({
          title: 'Perhatian',
          content: 'Terjadi kesalahan pada server.',
        });
      }
    } finally {
      setLoadingPage(false);
    }
  }

  const handleCashierChange = (value) => {
    setCashier(value);
  };

  const handleMedicalRecordNumberChange = (event) => {
    setMedicalRecordNumber(event.target.value);
  };

  const handleStartDateChange = (date, dateString) => {
    if (!date) setStartDate(moment());
    else setStartDate(date);
  };

  const handlePrintReceiptClick = (appointmentId, receiptNumber) => {
    history.push(`/kasir/kwitansi/${appointmentId}_${receiptNumber}`);
  };

  const handleViewReceiptClick = (appointmentId, receiptNumber, receiptId) => {
    history.push(
      `/kasir/struk-pembayaran/${appointmentId}_${receiptNumber}_${receiptId}`
    );
  };

  const handleManagePrintClick = (appointmentId, receiptNumber, receiptId) => {
    history.push(
      `/kasir/manage-print-kwitansi/${appointmentId}_${receiptNumber}_${receiptId}`
    );
  };

  const handleVoidReturnClick = (appointmentId, receiptNumber) => {
    history.push(`/kasir/detail-batal-kasir/${appointmentId}_${receiptNumber}`);
  };

  const handleUploadReceiptClick = (receiptId, receiptPhoto) => {
    setIdModal(receiptId);
    setListImages([receiptPhoto]);

    if (receiptPhoto && receiptPhoto.split('?')[0].split('.').pop() === 'pdf')
      window.open(receiptPhoto);
    else if (
      receiptPhoto &&
      receiptPhoto.split('?')[0].split('.').pop() !== 'pdf'
    )
      handleImageViewerOpen(0);
    else setIsEditPopupOpen(true);
  };

  const handleReceiptBeforeUpload = (file) => {
    const isExtensionCorrect =
      file.type === 'application/pdf' ||
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg';

    if (isExtensionCorrect) {
      setListFileReceipt([file]);
    } else {
      Modal.warning({
        title: 'Perhatian',
        content:
          'Extension file tidak sesuai. Silahkan upload file dengan extension pdf, jpeg, jpg.',
      });
    }

    return false;
  };

  const handleReceiptRemove = () => {
    setListFileReceipt([]);
  };

  const handleReceiptUpload = () => {
    const formData = new FormData();

    listFileReceipt.forEach((file) => {
      formData.append('file', file);
    });

    submitReceipt(formData);
  };

  const handleFlow = (flow) => {
    switch (flow) {
      case 'closePopup':
        setIdModal();

        setIsEditPopupOpen(false);
        break;
      case 'openConfirm':
        setIsEditPopupOpen(false);
        setIsConfirmPopupOpen(true);
        break;
      case 'closeConfirm':
        setIdModal();
        setListFileReceipt([]);

        setIsConfirmPopupOpen(false);
        break;
      case 'submit':
        handleReceiptUpload();
        break;
      case 'openSuccess':
        getReceiptList();
        setListFileReceipt([]);
        setIdModal();

        setIsConfirmPopupOpen(false);
        setIsSuccessPopupOpen(true);
        break;
      case 'closeSuccess':
        setIsSuccessPopupOpen(false);
        break;
      default:
        break;
    }
  };

  const handleImageViewerOpen = (index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  };

  const handleImageViewerClose = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const onTableChange = (pagination) => {
    setPagination(pagination);
  };

  //search
  const onSearchChange = (e) => {
    const convertValue = e.target.value.toLowerCase();
    const labelValue = e.target.value;
    setSearch(labelValue);

    const filteredData = listReceipt.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          !isEmpty(item[key]) &&
          item[key].toString().toLowerCase().search(convertValue) !== -1
      );
    });

    setListFilteredReceipt(filteredData);
  };

  const useInitData = () => {
    getReceiptList();
    getCashierList();

    const today = moment();
    setStartDate(today);
  };

  useEffect(useInitData, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <div className="mb-15">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Manage Cetak Kasir Pasien
              </strong>
            </Col>
          </Row>
        </div>
        <div className="panel panel__search">
          <Row>
            <Col span={2}>
              <div className="mb-15">
                <div className="skybase-dashboard__search">
                  <Input
                    disabled
                    value="Tanggal"
                    style={{
                      color: 'rgba(0, 0, 0, 0.65)',
                      cursor: 'auto',
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col span={18}>
              <div className="mb-15">
                <div className="skybase-dashboard__search">
                  <DatePicker
                    showToday
                    allowClear={false}
                    format="DD/MM/YYYY"
                    onChange={handleStartDateChange}
                    value={startDate}
                  />
                </div>
              </div>
            </Col>
            <Col span={4}>
              <div className="mb-15">
                <div className="skybase-dashboard__search">
                  <Button
                    onClick={getReceiptList}
                    style={{
                      color: '#fff',
                      backgroundColor: '#36c6d3',
                      borderColor: '#2bb8c4',
                    }}>
                    <strong>TAMPILKAN</strong>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="mb-15">
                <div className="skybase-dashboard__search">
                  <SelectDropdown
                    options={listCashierOptions}
                    placeHolder="Pilih Nama Kasir"
                    onChange={handleCashierChange}
                    value={cashier}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <div className="skybase-dashboard__search">
                <Input
                  disabled
                  value="No Rekam Medis"
                  style={{
                    color: 'rgba(0, 0, 0, 0.65)',
                    cursor: 'auto',
                  }}
                />
              </div>
            </Col>
            <Col span={20}>
              <div className="skybase-dashboard__search">
                <Input
                  placeHolder="No Rekam Medis"
                  onChange={handleMedicalRecordNumberChange}
                  value={medicalRecordNumber}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="panel">
          <Row>
            <Col span={24}>
              <div className="mb-15">
                <strong className="text-md skybase-dashboard__title">
                  MANAGE CETAK KASIR PASIEN
                </strong>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24} type="flex" align="end">
              <div className="mb-15">
                <Button onClick={() => {}}>PRINT</Button>
                <Button onClick={() => {}}>COPY</Button>
                <Button onClick={() => {}}>PDF</Button>
                <Button onClick={() => {}}>EXCEL</Button>
                <Button onClick={() => {}}>CSV</Button>
                <Button onClick={getReceiptList}>RELOAD</Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={2} type="flex" align="start"></Col>
            <Col span={5} offset={17} type="flex" align="end">
              <div className="mb-15">
                <Input
                  className="skybase-dashboard__search-input"
                  placeholder="Search No Kwitansi"
                  onChange={onSearchChange}
                  value={search}
                />
              </div>
            </Col>
          </Row>
          <Table
            dataSource={listFilteredReceipt}
            rowKey="key"
            columns={cashierReceiptColumns(
              handlePrintReceiptClick,
              handleViewReceiptClick,
              handleManagePrintClick,
              handleVoidReturnClick,
              handleUploadReceiptClick,
              permissionDataList
            )}
            onChange={onTableChange}
            className="skybase-dashboard__active-order-table"
            footer={() => (
              <Table
                dataSource={listSummaryReceipt}
                rowKey="key"
                columns={discountSummaryColumns}
                className="skybase-dashboard__active-order-table"
                showHeader={false}
                pagination={false}
                size="small"
              />
            )}
            pagination={pagination}
            size="small"
            scroll={{ x: 2000, y: 500 }}
          />
        </div>
      </Content>

      <Modal
        visible={isEditPopupOpen}
        title="Upload bukti foto kwitansi"
        width={'35%'}
        closable={false}
        footer={[
          <Button type="default" onClick={() => handleFlow('closePopup')}>
            TUTUP
          </Button>,
          <Button
            type="danger"
            onClick={() => handleFlow('openConfirm')}
            disabled={listFileReceipt.length === 0}>
            UPLOAD FOTO
          </Button>,
        ]}>
        <Row>
          <Col span={24}>
            <Row type="flex" align="top">
              <Col span={6} className="mb-5">
                <div>File Foto</div>
              </Col>
              <Col span={18}>
                <Upload
                  beforeUpload={handleReceiptBeforeUpload}
                  onRemove={handleReceiptRemove}
                  fileList={listFileReceipt}>
                  <Button>Pilih File</Button>
                </Upload>
                <div
                  style={{
                    width: 'fit-content',
                    marginTop: '10px',
                    padding: '15px',
                    backgroundColor: '#f9e491',
                    borderColor: '#f9e491',
                    borderRadius: '2px',
                    color: '#c29d0b',
                  }}>
                  File format : *pdf, *jpeg, *jpg
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={isConfirmPopupOpen}
        width={'35%'}
        closable={false}
        footer={[
          <Button type="default" onClick={() => handleFlow('closeConfirm')}>
            Tidak
          </Button>,
          <Button type="danger" onClick={() => handleFlow('submit')}>
            Ya
          </Button>,
        ]}>
        <Row>
          <div className="mt-10 mb-10">
            <ExclamationCircleTwoTone
              twoToneColor="#f8bb86"
              style={{ fontSize: 50 }}
            />
          </div>
        </Row>
        <Row>
          <div>
            <strong>Apakah data sudah sesuai?</strong>
          </div>
        </Row>
      </Modal>

      <Modal
        visible={isSuccessPopupOpen}
        width={'35%'}
        closable={false}
        footer={[
          <Button type="primary" onClick={() => handleFlow('closeSuccess')}>
            OK
          </Button>,
        ]}>
        <Row>
          <div className="mt-10 mb-10">
            <CheckCircleTwoTone
              twoToneColor="#a5dc86"
              style={{ fontSize: 50 }}
            />
          </div>
        </Row>
        <Row>
          <div className="mb-10">
            <strong>Success</strong>
          </div>
        </Row>
        <Row>
          <div>Data berhasil disimpan</div>
        </Row>
      </Modal>

      {isViewerOpen && (
        <ImageViewer
          src={listImages}
          currentIndex={currentImage}
          onClose={handleImageViewerClose}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
        />
      )}
    </Layout>
  );
}

export default withRouter(ManageCetakKasirPasien);
