import api from './index';
import apiBe from './index-be';

export default {
  getListBank() {
    return api.get(`/bank`);
  },

  getListBankFromBE() {
    return apiBe.get(`/bank`);
  },
};
