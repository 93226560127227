import axios from 'axios';

const baseURL = process.env.REACT_APP_RATA_API_URL;

const createAPILogin = () => {
  const axiosInstance = axios.create({
    baseURL,
    headers: {
      Accept: 'application/x-www-form-urlencoded',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return axiosInstance;
};

const createAPIPermission = () => {
  const axiosInstance = axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('cms_token')}`,
    },
  });

  return axiosInstance;
};

const apiLogin = createAPILogin();
const apiPermission = createAPIPermission();

export default {
  login(payload) {
    return apiLogin.post(`/oauth/token`, payload);
  },
  permissions() {
    return apiPermission.get(`api/granted-feature`);
  },
};
