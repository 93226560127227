import api from './index';
import apiBe from './index-be';

export default {
  listClinic() {
    return api.get(`/clinic`);
  },

  listClinicGroup() {
    return api.get(`/clinic/group`);
  },

  listClinicType() {
    return api.get('/clinic/types');
  },
  getClinicType(id) {
    return api.get(`/clinic/types/${id}`);
  }
};
