import api from './index';

export default {
    getPaymentLinks(receiptId) {
        return api.get(
            `/transaction/receipt/${receiptId}/payment-links`
        );
    },
    generatePaymentLink(receiptId, slipTreatmentList, discountReceiptList, receipt) {
        return api.post(
            `/transaction/receipt/${receiptId}/payment-links`,
            {
                slipTreatmentList: slipTreatmentList,
                discountReceiptList: discountReceiptList,
                receipt: receipt
            }
        );
    },
    updatePaymentLink(receiptNumber, paymentLink) {
        return api.post(
            `/transaction/receipt/number/${receiptNumber}/payment-links/status`,
            paymentLink
        );
    }
};
