import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Layout,
  Table,
  Button,
  Input,
  DatePicker,
  Collapse,
} from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import AppointmentApi from 'api/appointment';
// import ClinicApi from 'api/clinic';
import { PageSpinner } from 'components/page-spinner';
import SelectDropdown from 'components/select-dropdown';
import { setToArray } from 'utils';

import { customerOutpatientQueueColumns } from '../helper/';
import '../styles/index.scss';

const { Content } = Layout;
const { Panel } = Collapse;
const { Search } = Input;

export function AntrianPasienRawatJalan({ history }) {
  const [clinic, setClinic] = useState();
  // const [clinicLabel, setClinicLabel] = useState();
  // const [clinicOptions, setClinicOptions] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [rangeDate, setRangeDate] = useState();
  const [paymentStatus, setPaymentStatus] = useState();
  const [insuranceStatus, setInsuranceStatus] = useState();

  const [queue, setQueue] = useState([]);
  const [filteredQueue, setFilteredQueue] = useState([]);

  // const [loadingPage, setLoadingPage] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);

  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    showSizeChanger: true,
    total: 0,
  });

  const listDateRange = [
    {
      label: 'Last 7 Days',
      value: 'SEVEN',
    },
    {
      label: 'Last 30 Days',
      value: 'THIRTY',
    },
    {
      label: 'Custom Range',
      value: 'CUSTOM',
    },
  ];

  const paymentStatusOptions = [
    { label: 'Belum Bayar', value: false },
    { label: 'Sudah Bayar', value: true },
  ];

  const insuranceStatusOptions = [
    { label: 'Waiting for Documents', value: 'WAITING_FOR_DOCUMENTS' },
    { label: 'Documents in Progress', value: 'DOCUMENTS_IN_PROGRESS' },
    { label: 'Approved by Insurance', value: 'APPROVED_BY_INSURANCE' },
  ];

  const generateInsuranceStatus = (lastStatus) => {
    switch (lastStatus) {
      case 'WAITING_FOR_DOCUMENTS':
      case 'waiting-for-documents':
        return 'Waiting for Documents';
      case 'DOCUMENTS_IN_PROGRESS':
      case 'documents-in-progress':
        return 'Documents in Progress';
      case 'APPROVED_BY_FINANCE':
      case 'approved-by-finance':
        return 'Approved by Finance';
      case 'APPROVED_BY_INSURANCE':
      case 'approved-by-insurance':
        return 'Approved by Insurance';
      default:
        break;
    }
  };

  async function getQueueList(limit, page) {
    try {
      setLoadingTable(true);

      const response = await AppointmentApi.listQueue(
        localStorage.getItem('clinicId')
          ? localStorage.getItem('clinicId')
          : clinic
          ? clinic
          : 0,
        paymentStatus,
        insuranceStatus,
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
        limit,
        page
      );

      let queueList = setToArray(response.data);

      const modifiedQueueList = queueList.map((item, index) => ({
        ...item,
        key: index + 1,
        insuranceStatusLabel: generateInsuranceStatus(item.insuranceStatus),
      }));

      setQueue(modifiedQueueList);
      setFilteredQueue(modifiedQueueList);

      const paginationConfig = {
        ...pagination,
        current: response.data.data.page,
        pageSize: response.data.data.limit,
        total: response.data.data.totalData,
      };

      setPagination(paginationConfig);
    } finally {
      setLoadingTable(false);
    }
  }

  // async function getClinicList() {
  //   try {
  //     setLoadingPage(true);
  //
  //     const response = await ClinicApi.listClinic();
  //
  //     let clinicList = setToArray(response.data);
  //
  //     const clinicListOpts = clinicList.map((value) => ({
  //       label: value.clinicName,
  //       value: value.clinicCode,
  //     }));
  //
  //     clinicListOpts.unshift({ label: 'Semua Klinik', value: '' });
  //
  //     setClinicOptions(clinicListOpts);
  //   } finally {
  //     setLoadingPage(false);
  //   }
  // }

  const handleRangeDateChange = (value) => {
    const today = moment();

    if (value === 'SEVEN') {
      const sevenDaysAgo = moment().subtract(7, 'd');

      setStartDate(sevenDaysAgo);
      setEndDate(today);
    } else if (value === 'THIRTY') {
      const thirtyDaysAgo = moment().subtract(30, 'd');

      setStartDate(thirtyDaysAgo);
      setEndDate(today);
    } else {
      setStartDate();
      setEndDate();
    }

    setRangeDate(value);
  };

  // const handleClinicChange = (value) => {
  //   setClinic(value);
  // };

  const handlePaymentStatusChange = (value) => {
    setPaymentStatus(value);
  };

  const handleInsuranceStatusChange = (value) => {
    setInsuranceStatus(value);
  };

  const handleStartDateChange = (date, dateString) => {
    if (!date) setStartDate(moment());
    else setStartDate(date);
  };

  const handleEndDateChange = (date, dateString) => {
    if (!date) setEndDate(moment());
    else setEndDate(date);
  };

  const onTableChange = (pagination) => {
    getQueueList(pagination.pageSize, pagination.current);
    setPagination(pagination);
  };

  //search
  const onSearchChange = (e) => {
    const convertValue = e.target.value.toLowerCase();
    const labelValue = e.target.value;
    setSearch(labelValue);

    const filteredData = queue.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          !isEmpty(item[key]) &&
          item[key].toString().toLowerCase().search(convertValue) !== -1
      );
    });

    setFilteredQueue(filteredData);
  };

  const redirectToPointOfSales = (appointmentId) => {
    history.push(`/kasir/point-of-sales/${appointmentId}`);
  };

  const resetAllFields = () => {
    setRangeDate();
    setStartDate();
    setEndDate();
    setPaymentStatus();
    setInsuranceStatus();
  };

  const useInitData = () => {
    getQueueList(pagination.pageSize, pagination.current);
    // getClinicList();

    const today = moment();
    setStartDate(today);
    setEndDate(today);

    setClinic(
      localStorage.getItem('clinicId') ? localStorage.getItem('clinicId') : 0
    );
    // setClinicLabel(
    //   localStorage.getItem('clinicName')
    //     ? localStorage.getItem('clinicName')
    //     : null
    // );
  };

  useEffect(useInitData, []);

  // if (loadingPage) {
  //   return <PageSpinner />;
  // }

  return (
    <Layout className="order-list-page">
      <Content>
        <div className="mb-15">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Antrian Pasien Rawat Jalan
              </strong>
            </Col>
          </Row>
        </div>
        <Collapse
          defaultActiveKey={['1']}
          expandIconPosition="right"
          className="mb-15">
          <Panel
            header={
              <strong className="text-md skybase-dashboard__title">
                Filter
              </strong>
            }
            key="1">
            <div>
              <Row gutter={[16, 8]}>
                <Col span={8}>
                  Date
                  <SelectDropdown
                    options={listDateRange}
                    placeHolder="Select Range Date"
                    onChange={handleRangeDateChange}
                    value={rangeDate}
                    allowClear
                  />
                </Col>
                <Col span={8}>
                  Start Date
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <DatePicker
                        showToday
                        allowClear={false}
                        format="DD/MM/YYYY"
                        onChange={handleStartDateChange}
                        value={startDate}
                        placeholder="Start Date"
                        disabled={rangeDate !== 'CUSTOM'}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  End Date
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <DatePicker
                        showToday
                        allowClear={false}
                        format="DD/MM/YYYY"
                        onChange={handleEndDateChange}
                        value={endDate}
                        placeholder="End Date"
                        disabled={rangeDate !== 'CUSTOM'}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col span={8}>
                  Status Pembayaran
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <SelectDropdown
                        options={paymentStatusOptions}
                        placeHolder="Select Status Pembayaran"
                        showArrow
                        onChange={handlePaymentStatusChange}
                        value={paymentStatus}
                        allowClear
                      />
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  Insurance Status
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <SelectDropdown
                        options={insuranceStatusOptions}
                        placeHolder="Select Insurance Status"
                        showArrow
                        onChange={handleInsuranceStatusChange}
                        value={insuranceStatus}
                        allowClear
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <hr></hr>
              <Row className="mt-20 mb-10">
                <Col span={24} type="flex" align="end">
                  <Row gutter={[16, 8]}>
                    <Button onClick={resetAllFields}>Reset</Button>
                    <Button
                      type="primary"
                      style={{ marginLeft: '10px', marginRight: '10px' }}
                      onClick={() => getQueueList(10, 1)}>
                      Filter
                    </Button>
                  </Row>
                </Col>
              </Row>
            </div>
          </Panel>
        </Collapse>
        <div className="panel">
          <Row>
            <Col span={24}>
              <div className="mb-15">
                <strong className="text-md skybase-dashboard__title">
                  List of Antrian Pasien Rawat Jalan
                </strong>
              </div>
            </Col>
          </Row>
          {loadingTable ? (
            <PageSpinner />
          ) : (
            <>
              <Row>
                <Col span={2} type="flex" align="start"></Col>
                <Col span={5} offset={17} type="flex" align="end">
                  <div className="mb-15">
                    <Search
                      placeholder="Search"
                      onChange={onSearchChange}
                      value={search}
                    />
                  </div>
                </Col>
              </Row>
              <Table
                dataSource={filteredQueue}
                rowKey="key"
                scroll={{ x: 1100, y: 500 }}
                onChange={onTableChange}
                pagination={pagination}
                columns={customerOutpatientQueueColumns(redirectToPointOfSales)}
                className="skybase-dashboard__active-order-table"
              />
            </>
          )}
        </div>
      </Content>
    </Layout>
  );
}

export default withRouter(AntrianPasienRawatJalan);
