import React from 'react';
import { Button } from 'antd';
import { EyeOutlined, CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { currencyFormatter } from 'utils';
import moment from 'moment';

export const insuranceReportWaitingForDocumentsColumns = (
  handleInsuranceModal
) => [
  {
    title: 'No',
    render: (record) => record.key,
    width: 50,
  },
  {
    title: 'Action',
    render: (row) => (
      <>
        <Button
          type="primary"
          onClick={() => {
            handleInsuranceModal(true, row['receiptId'], '1');
          }}
          style={{
            backgroundColor: '#6067E6',
            color: 'white',
            borderColor: '#6067E6',
          }}>
          <EyeOutlined />
        </Button>
      </>
    ),
    width: 80,
  },
  {
    title: 'Patient',
    key: 'medicalRecordNumber',
    sorter: (a, b) => a.medicalRecordNumber - b.medicalRecordNumber,
    render: (row) => (
      <>
        <>
          {row['patientMedicalRecordNumber']} - {row['patientName']}
        </>
        <br></br>
        <>{row['patientEmail']}</>
        <br></br>
        <>{row['patientPhone']}</>
      </>
    ),
    width: 250,
  },
  {
    title: 'Insurance Status',
    key: 'lastStatusLabel',
    sorter: (a, b) => a.lastStatusLabel.localeCompare(b.lastStatusLabel),
    render: (row) => (row['lastStatusLabel'] ? row['lastStatusLabel'] : '-'),
  },
  {
    title: 'Insurance Company',
    key: 'insuranceCompanyName',
    sorter: (a, b) =>
      a.insuranceCompanyName.localeCompare(b.insuranceCompanyName),
    render: (row) =>
      row['insuranceCompanyName'] ? row['insuranceCompanyName'] : '-',
  },
  {
    title: 'Insurance Provider',
    key: 'insuranceProviderName',
    sorter: (a, b) =>
      a.insuranceProviderName.localeCompare(b.insuranceProviderName),
    render: (row) =>
      row['insuranceProviderName'] ? row['insuranceProviderName'] : '-',
  },
  {
    title: 'Patient Insurance ID Number',
    key: 'patientInsuranceNumber',
    sorter: (a, b) =>
      a.patientInsuranceNumber.localeCompare(b.patientInsuranceNumber),
    render: (row) =>
      row['patientInsuranceNumber'] ? row['patientInsuranceNumber'] : '-',
  },
  {
    title: 'Last Updated',
    key: 'lastUpdatedBy',
    sorter: (a, b) => a.lastUpdatedBy.localeCompare(b.lastUpdatedBy),
    // render: (row) => `${row['lastUpdatedBy']} - ${row['lastUpdatedBy']}`,
    render: (row) => (
      <>
        <>{row['lastUpdatedBy']}</>
        <br></br>
        <>{moment(row['updatedAt']).format('DD MMM YYYY HH:mm')}</>
      </>
    ),
  },
  {
    title: 'Cashier Name',
    key: 'cashierName',
    sorter: (a, b) => a.cashierName.localeCompare(b.cashierName),
    render: (row) => (row['cashierName'] ? row['cashierName'] : '-'),
  },
  {
    title: 'Doctor Name',
    key: 'doctorName',
    sorter: (a, b) => a.doctorName.localeCompare(b.doctorName),
    render: (row) => (row['doctorName'] ? row['doctorName'] : '-'),
  },
  {
    title: 'Clinic',
    key: 'clinicName',
    sorter: (a, b) => a.clinicName.localeCompare(b.clinicName),
    render: (row) => (row['clinicName'] ? row['clinicName'] : '-'),
  },
  {
    title: 'Revision Note',
    key: 'revisionNote',
    sorter: (a,b) => a.revisionNote.localeCompare(b.revisionNote),
    render: (row) => (row['revisionNote'] ? row['revisionNote'] : '-'),
  }
];

export const insuranceReportColumns = (handleInsuranceModal) => [
  {
    title: 'No',
    render: (record) => record.key,
    width: 50,
  },
  {
    title: 'Action',
    render: (row) => (
      <>
        <Button
          type="primary"
          onClick={() => {
            handleInsuranceModal(true, row['receiptId'], '1');
          }}
          style={{
            backgroundColor: '#6067E6',
            color: 'white',
            borderColor: '#6067E6',
          }}>
          <EyeOutlined />
        </Button>
      </>
    ),
    width: 80,
  },
  {
    title: 'Patient',
    key: 'medicalRecordNumber',
    sorter: (a, b) => a.medicalRecordNumber - b.medicalRecordNumber,
    render: (row) => (
      <>
        <>
          {row['patientMedicalRecordNumber']} - {row['patientName']}
        </>
        <br></br>
        <>{row['patientEmail']}</>
        <br></br>
        <>{row['patientPhone']}</>
      </>
    ),
    width: 250,
  },
  {
    title: 'Insurance Status',
    key: 'lastStatusLabel',
    sorter: (a, b) => a.lastStatusLabel.localeCompare(b.lastStatusLabel),
    render: (row) => (row['lastStatusLabel'] ? row['lastStatusLabel'] : '-'),
  },
  {
    title: 'Insurance Company',
    key: 'insuranceCompanyName',
    sorter: (a, b) =>
      a.insuranceCompanyName.localeCompare(b.insuranceCompanyName),
    render: (row) =>
      row['insuranceCompanyName'] ? row['insuranceCompanyName'] : '-',
  },
  {
    title: 'Insurance Provider',
    key: 'insuranceProviderName',
    sorter: (a, b) =>
      a.insuranceProviderName.localeCompare(b.insuranceProviderName),
    render: (row) =>
      row['insuranceProviderName'] ? row['insuranceProviderName'] : '-',
  },
  {
    title: 'Patient Insurance ID Number',
    key: 'patientInsuranceNumber',
    sorter: (a, b) =>
      a.patientInsuranceNumber.localeCompare(b.patientInsuranceNumber),
    render: (row) =>
      row['patientInsuranceNumber'] ? row['patientInsuranceNumber'] : '-',
  },
  {
    title: 'Disbursement Date',
    key: 'disbursementDate',
    sorter: (a, b) => a.disbursementDate.localeCompare(b.disbursementDate),
    render: (row) => (row['disbursementDate'] ? row['disbursementDate'] : '-'),
  },
  {
    title: 'Letter of Authorization (LoA)',
    key: 'loaFilename',
    sorter: (a, b) => a.loaFilename - b.loaFilename,
    render: (row) => (
      <button
        onClick={() => {
          handleInsuranceModal(true, row['receiptId'], '3');
        }}
        style={{
          backgroundColor: 'transparent',
          color: 'blue',
          border: 0,
          textAlign: 'left',
          cursor: 'pointer',
          overflowWrap: 'anywhere',
        }}>
        <u>{row['loaFilename']}</u>
      </button>
    ),
  },
  {
    title: 'Letter of Confirmation (LoC)',
    key: 'locFilename',
    sorter: (a, b) => a.locFilename - b.locFilename,
    render: (row) => (
      <button
        onClick={() => {
          handleInsuranceModal(true, row['receiptId'], '4');
        }}
        style={{
          backgroundColor: 'transparent',
          color: 'blue',
          border: 0,
          textAlign: 'left',
          cursor: 'pointer',
          overflowWrap: 'anywhere',
        }}>
        <u>{row['locFilename']}</u>
      </button>
    ),
  },
  {
    title: 'Supporting Documents',
    key: 'documentCount',
    sorter: (a, b) => a.documentCount - b.documentCount,
    render: (row) => (
      <button
        onClick={() => {
          handleInsuranceModal(true, row['receiptId'], '5');
        }}
        style={{
          backgroundColor: 'transparent',
          color: 'blue',
          border: 0,
          textAlign: 'left',
          cursor: 'pointer',
          overflowWrap: 'anywhere',
        }}>
        <u>{row['documentCount']} documents</u>
      </button>
    ),
  },
  {
    title: 'Last Updated',
    key: 'lastUpdatedBy',
    sorter: (a, b) => a.lastUpdatedBy.localeCompare(b.lastUpdatedBy),
    // render: (row) => `${row['lastUpdatedBy']} - ${row['lastUpdatedBy']}`,
    render: (row) => (
      <>
        <>{row['lastUpdatedBy']}</>
        <br></br>
        <>{moment(row['updatedAt']).format('DD MMM YYYY HH:mm')}</>
      </>
    ),
  },
  {
    title: 'Cashier Name',
    key: 'cashierName',
    sorter: (a, b) => a.cashierName.localeCompare(b.cashierName),
    render: (row) => (row['cashierName'] ? row['cashierName'] : '-'),
  },
  {
    title: 'Doctor Name',
    key: 'doctorName',
    sorter: (a, b) => a.doctorName.localeCompare(b.doctorName),
    render: (row) => (row['doctorName'] ? row['doctorName'] : '-'),
  },
  {
    title: 'Clinic',
    key: 'clinicName',
    sorter: (a, b) => a.clinicName.localeCompare(b.clinicName),
    render: (row) => (row['clinicName'] ? row['clinicName'] : '-'),
  },
];

export const insuranceReportDocumentsInProgressColumns = (handleInsuranceModal, handleApproveInsuranceModal, handleReviseInsuranceModal) => [
  {
    title: 'No',
    render: (record) => record.key,
    width: 50,
  },
  {
    title: 'Action',
    render: (row) => (
      <>
        <Button
          type="primary"
          onClick={() => {
            handleInsuranceModal(true, row['receiptId'], '1');
          }}
          style={{
            backgroundColor: '#6067E6',
            color: 'white',
            borderColor: '#6067E6',
          }}
          size='small'
        >
          <EyeOutlined />
        </Button>
        <Button
          type='primary'
          onClick={() => {
            handleApproveInsuranceModal(row['receiptId'], 'APPROVED_BY_FINANCE');
          }}
          style={{
            backgroundColor: '#50D050',
            color : 'white',
            borderColor: '#50D050',
          }}
          size='small'
        >
          <CheckOutlined />
        </Button>
        <Button
          type='primary'
          onClick={() => {
            handleReviseInsuranceModal(true, row['receiptId']);
          }}
          style={{
            backgroundColor: '#FF3131',
            color: 'white',
            borderColor: '#FF3131',
          }}
          size='small'
        >
          <CloseOutlined />
        </Button>
      </>
    ),
    width: 110,
  },
  {
    title: 'Patient',
    key: 'medicalRecordNumber',
    sorter: (a, b) => a.medicalRecordNumber - b.medicalRecordNumber,
    render: (row) => (
      <>
        <>
          {row['patientMedicalRecordNumber']} - {row['patientName']}
        </>
        <br></br>
        <>{row['patientEmail']}</>
        <br></br>
        <>{row['patientPhone']}</>
      </>
    ),
    width: 250,
  },
  {
    title: 'Insurance Status',
    key: 'lastStatusLabel',
    sorter: (a, b) => a.lastStatusLabel.localeCompare(b.lastStatusLabel),
    render: (row) => (row['lastStatusLabel'] ? row['lastStatusLabel'] : '-'),
  },
  {
    title: 'Insurance Company',
    key: 'insuranceCompanyName',
    sorter: (a, b) =>
      a.insuranceCompanyName.localeCompare(b.insuranceCompanyName),
    render: (row) =>
      row['insuranceCompanyName'] ? row['insuranceCompanyName'] : '-',
  },
  {
    title: 'Insurance Provider',
    key: 'insuranceProviderName',
    sorter: (a, b) =>
      a.insuranceProviderName.localeCompare(b.insuranceProviderName),
    render: (row) =>
      row['insuranceProviderName'] ? row['insuranceProviderName'] : '-',
  },
  {
    title: 'Patient Insurance ID Number',
    key: 'patientInsuranceNumber',
    sorter: (a, b) =>
      a.patientInsuranceNumber.localeCompare(b.patientInsuranceNumber),
    render: (row) =>
      row['patientInsuranceNumber'] ? row['patientInsuranceNumber'] : '-',
  },
  {
    title: 'Letter of Authorization (LoA)',
    key: 'loaFilename',
    sorter: (a, b) => a.loaFilename - b.loaFilename,
    render: (row) => (
      <button
        onClick={() => {
          handleInsuranceModal(true, row['receiptId'], '3');
        }}
        style={{
          backgroundColor: 'transparent',
          color: 'blue',
          border: 0,
          textAlign: 'left',
          cursor: 'pointer',
          overflowWrap: 'anywhere',
        }}>
        <u>{row['loaFilename']}</u>
      </button>
    ),
  },
  {
    title: 'Letter of Confirmation (LoC)',
    key: 'locFilename',
    sorter: (a, b) => a.locFilename - b.locFilename,
    render: (row) => (
      <button
        onClick={() => {
          handleInsuranceModal(true, row['receiptId'], '4');
        }}
        style={{
          backgroundColor: 'transparent',
          color: 'blue',
          border: 0,
          textAlign: 'left',
          cursor: 'pointer',
          overflowWrap: 'anywhere',
        }}>
        <u>{row['locFilename']}</u>
      </button>
    ),
  },
  {
    title: 'Supporting Documents',
    key: 'documentCount',
    sorter: (a, b) => a.documentCount - b.documentCount,
    render: (row) => (
      <button
        onClick={() => {
          handleInsuranceModal(true, row['receiptId'], '5');
        }}
        style={{
          backgroundColor: 'transparent',
          color: 'blue',
          border: 0,
          textAlign: 'left',
          cursor: 'pointer',
          overflowWrap: 'anywhere',
        }}>
        <u>{row['documentCount']} documents</u>
      </button>
    ),
  },
  {
    title: 'Last Updated',
    key: 'lastUpdatedBy',
    sorter: (a, b) => a.lastUpdatedBy.localeCompare(b.lastUpdatedBy),
    // render: (row) => `${row['lastUpdatedBy']} - ${row['lastUpdatedBy']}`,
    render: (row) => (
      <>
        <>{row['lastUpdatedBy']}</>
        <br></br>
        <>{moment(row['updatedAt']).format('DD MMM YYYY HH:mm')}</>
      </>
    ),
  },
  {
    title: 'Cashier Name',
    key: 'cashierName',
    sorter: (a, b) => a.cashierName.localeCompare(b.cashierName),
    render: (row) => (row['cashierName'] ? row['cashierName'] : '-'),
  },
  {
    title: 'Doctor Name',
    key: 'doctorName',
    sorter: (a, b) => a.doctorName.localeCompare(b.doctorName),
    render: (row) => (row['doctorName'] ? row['doctorName'] : '-'),
  },
  {
    title: 'Clinic',
    key: 'clinicName',
    sorter: (a, b) => a.clinicName.localeCompare(b.clinicName),
    render: (row) => (row['clinicName'] ? row['clinicName'] : '-'),
  },
];

export const insuranceReportApprovedByFinanceColumns = (handleInsuranceModal, handleDisbursementDateModal) => [
  {
    title: 'No',
    render: (record) => record.key,
    width: 50,
  },
  {
    title: 'Action',
    render: (row) => (
      <>
        <Button
          type="primary"
          onClick={() => {
            handleInsuranceModal(true, row['receiptId'], '1');
          }}
          style={{
            backgroundColor: '#6067E6',
            color: 'white',
            borderColor: '#6067E6',
          }}
          >
          <EyeOutlined />
        </Button>
        <Button
          onClick={() => {
            handleDisbursementDateModal(true, row['receiptId']);
          }}
        >
          <EditOutlined />
        </Button>
      </>
    ),
    width: 110,
  },
  {
    title: 'Patient',
    key: 'medicalRecordNumber',
    sorter: (a, b) => a.medicalRecordNumber - b.medicalRecordNumber,
    render: (row) => (
      <>
        <>
          {row['patientMedicalRecordNumber']} - {row['patientName']}
        </>
        <br></br>
        <>{row['patientEmail']}</>
        <br></br>
        <>{row['patientPhone']}</>
      </>
    ),
    width: 250,
  },
  {
    title: 'Insurance Status',
    key: 'lastStatusLabel',
    sorter: (a, b) => a.lastStatusLabel.localeCompare(b.lastStatusLabel),
    render: (row) => (row['lastStatusLabel'] ? row['lastStatusLabel'] : '-'),
  },
  {
    title: 'Insurance Company',
    key: 'insuranceCompanyName',
    sorter: (a, b) =>
      a.insuranceCompanyName.localeCompare(b.insuranceCompanyName),
    render: (row) =>
      row['insuranceCompanyName'] ? row['insuranceCompanyName'] : '-',
  },
  {
    title: 'Insurance Provider',
    key: 'insuranceProviderName',
    sorter: (a, b) =>
      a.insuranceProviderName.localeCompare(b.insuranceProviderName),
    render: (row) =>
      row['insuranceProviderName'] ? row['insuranceProviderName'] : '-',
  },
  {
    title: 'Patient Insurance ID Number',
    key: 'patientInsuranceNumber',
    sorter: (a, b) =>
      a.patientInsuranceNumber.localeCompare(b.patientInsuranceNumber),
    render: (row) =>
      row['patientInsuranceNumber'] ? row['patientInsuranceNumber'] : '-',
  },
  {
    title: 'Letter of Authorization (LoA)',
    key: 'loaFilename',
    sorter: (a, b) => a.loaFilename - b.loaFilename,
    render: (row) => (
      <button
        onClick={() => {
          handleInsuranceModal(true, row['receiptId'], '3');
        }}
        style={{
          backgroundColor: 'transparent',
          color: 'blue',
          border: 0,
          textAlign: 'left',
          cursor: 'pointer',
          overflowWrap: 'anywhere',
        }}>
        <u>{row['loaFilename']}</u>
      </button>
    ),
  },
  {
    title: 'Letter of Confirmation (LoC)',
    key: 'locFilename',
    sorter: (a, b) => a.locFilename - b.locFilename,
    render: (row) => (
      <button
        onClick={() => {
          handleInsuranceModal(true, row['receiptId'], '4');
        }}
        style={{
          backgroundColor: 'transparent',
          color: 'blue',
          border: 0,
          textAlign: 'left',
          cursor: 'pointer',
          overflowWrap: 'anywhere',
        }}>
        <u>{row['locFilename']}</u>
      </button>
    ),
  },
  {
    title: 'Supporting Documents',
    key: 'documentCount',
    sorter: (a, b) => a.documentCount - b.documentCount,
    render: (row) => (
      <button
        onClick={() => {
          handleInsuranceModal(true, row['receiptId'], '5');
        }}
        style={{
          backgroundColor: 'transparent',
          color: 'blue',
          border: 0,
          textAlign: 'left',
          cursor: 'pointer',
          overflowWrap: 'anywhere',
        }}>
        <u>{row['documentCount']} documents</u>
      </button>
    ),
  },
  {
    title: 'Last Updated',
    key: 'lastUpdatedBy',
    sorter: (a, b) => a.lastUpdatedBy.localeCompare(b.lastUpdatedBy),
    // render: (row) => `${row['lastUpdatedBy']} - ${row['lastUpdatedBy']}`,
    render: (row) => (
      <>
        <>{row['lastUpdatedBy']}</>
        <br></br>
        <>{moment(row['updatedAt']).format('DD MMM YYYY HH:mm')}</>
      </>
    ),
  },
  {
    title: 'Cashier Name',
    key: 'cashierName',
    sorter: (a, b) => a.cashierName.localeCompare(b.cashierName),
    render: (row) => (row['cashierName'] ? row['cashierName'] : '-'),
  },
  {
    title: 'Doctor Name',
    key: 'doctorName',
    sorter: (a, b) => a.doctorName.localeCompare(b.doctorName),
    render: (row) => (row['doctorName'] ? row['doctorName'] : '-'),
  },
  {
    title: 'Clinic',
    key: 'clinicName',
    sorter: (a, b) => a.clinicName.localeCompare(b.clinicName),
    render: (row) => (row['clinicName'] ? row['clinicName'] : '-'),
  },
];

export const excessInsuranceColumns = [
  // {
  //   title: 'No',
  //   render: (index) => index,
  //   width: 50,
  // },
  {
    title: 'Service',
    key: 'name',
    render: (row) => (row['name'] ? row['name'] : '-'),
  },
  {
    title: 'Total',
    key: 'total',
    render: (row) =>
      row['total'] ? currencyFormatter(row['total']) : currencyFormatter(0),
  },
  {
    title: 'Insurance Limit',
    key: 'limit',
    render: (row) =>
      row['limit'] ? currencyFormatter(row['limit']) : currencyFormatter(0),
  },
  {
    title: 'Insurance Excess',
    key: 'excess',
    render: (row) =>
      row['excess'] ? currencyFormatter(row['excess']) : currencyFormatter(0),
  },
];
