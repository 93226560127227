import React, {useEffect, useState} from 'react';
import {Button, Col, Input, InputNumber, Layout, message, Modal, Row, Table, Tabs, Upload} from 'antd';
import SelectDropdown from 'components/select-dropdown';
import DebounceSelect from "../../../components/debounce-select";
import {PageSpinner} from 'components/page-spinner';
import {useParams, withRouter} from 'react-router-dom';
import TreatmentApi from 'api/treatment';
import AppointmentApi from 'api/appointment';
import BankApi from 'api/bank';
import ParameterApi from 'api/parameter';
import TransactionApi from 'api/transaction';
import PaymentLinkApi from 'api/payment-link';
import FinanceApi from 'api/finance';
import VouchersApi from 'api/vouchers';
import InsuranceApi from 'api/insurance';
import {currencyFormatter, currencyParser, setToArray} from 'utils';
import {
  appliedCouponColumns,
  creditCardPaymentColumns,
  discountCouponColumns,
  excessInsuranceColumns,
  marketplacePaymentColumns,
  paymentLinkColumns,
  transferPaymentColumns,
  treatmentColumns,
  treatmentPaymentColumns
} from '../helper/';

import '../styles/index.scss';
import moment from 'moment';
import {head, includes, intersection} from 'lodash';

const { Content } = Layout;
const { TabPane } = Tabs;

export function PointOfSales({ history }) {
  const objectTreatmentPayment = [
    {
      key: 1,
      column1: 'Jumlah Item (0)',
      column6: 'Sub Total',
      column7: 0,
    },
    {
      key: 2,
      column1: '',
      column6: 'Diskon Pembayaran',
      column7: 0,
    },
    {
      key: 3,
      column1: '',
      column6: 'Pajak 0%',
      column7: 0,
    },
    {
      key: 4,
      column1: '',
      column6: 'Covered by Insurance',
      column7: 0,
    },
    {
      key: 5,
      column1: '',
      column6: 'Total Tagihan',
      column7: 0,
    },
    {
      key: 6,
      column1: '',
      column6: 'Sisa Tagihan',
      column7: 0,
    },
    {
      key: 7,
      column1: '',
      column6: 'Total Kembali',
      column7: 0,
    },
  ];

  const objectExcessInsuranceTotal = [
    {
      key: 1,
      column1: '',
      column6: 'Total Insurance Excess',
      column7: 0,
    },
  ];

  const objectCreditDebit = {
    key: 1,
    edcType: '',
    cardBank: '',
    cardNumber: '',
    approvalNumber: '',
    traceNumber: '',
    amount: 0,
  };

  const objectMarketplace = {
    key: 1,
    marketplace: '',
    voucherNumber: '',
    amount: 0,
  };

  const objectInsurance = {
    key: 1,
    insuranceName: '',
    cardNumber: '',
    amount: 0,
  };

  const objectTransfer = {
    key: 1,
    cardBank: '',
    senderName: '',
    amount: 0,
  };

  const [activeTabKey, setActiveTabKey] = useState('1')
  const [receiptId, setReceiptId] = useState();
  const [receiptNumber, setReceiptNumber] = useState();
  const [patientDetail, setPatientDetail] = useState();
  const [treatmentCategoryGroup, setTreatmentCategoryGroup] = useState();

  const [listTreatment, setListTreatment] = useState([]);
  const [listTreatmentPayment, setListTreatmentPayment] = useState(
    objectTreatmentPayment
  );
  // const [listDiscount, setListDiscount] = useState([]);
  const [listAppliedCoupon, setListAppliedCoupon] = useState([]);
  const [listCreditDebit, setListCreditDebit] = useState([objectCreditDebit]);
  const [listMarketplace, setListMarketplace] = useState([objectMarketplace]);
  const [listMidtrans, setListMidtrans] = useState([])
  const [listInsurance, setListInsurance] = useState([objectInsurance]);
  const [listTransfer, setListTransfer] = useState([objectTransfer]);
  const [listVoucher, setListVoucher] = useState([]);
  const [listVoucherAll, setListVoucherAll] = useState([]);
  const [listFilteredVoucherAll, setListFilteredVoucherAll] = useState([]);

  const [
    listTreatmentCategoryGroupOptions,
    setListTreatmentCategoryGroupOptions,
  ] = useState();
  const [listTreatmentOptions, setListTreatmentOptions] = useState();
  const [listEdcOptions, setListEdcOptions] = useState();
  const [listBankOptions, setListBankOptions] = useState();
  const [listMarketplaceOptions, setListMarketplaceOptions] = useState();

  const [payment, setPayment] = useState();
  const [discountCoupon, setDiscountCoupon] = useState();
  const [amountPaid, setAmountPaid] = useState(0);
  const [additionalDiscount, setAdditionalDiscount] = useState(0);
  const [maxAdditionalDiscount, setMaxAdditionalDiscount] = useState(0);
  // const [totalTreatment, setTotalTreatment] = useState(0);
  const [totalFinal, setTotalFinal] = useState(0);
  const [totalCash, setTotalCash] = useState(0);
  const [totalPaymentReceived, setTotalPaymentReceived] = useState(0);
  const [totalVoucher, setTotalVoucher] = useState(0);

  const [isVoucherUsingUniqueCode, setIsVoucherUsingUniqueCode] =
    useState(false);

  const [insuranceDetail, setInsuranceDetail] = useState(null);
  const [listInsuranceCompany, setListInsuranceCompany] = useState([]);
  const [listInsuranceCompanyOptions, setListInsuranceCompanyOptions] =
    useState([]);
  const [listInsuranceProviderOptions, setListInsuranceProviderOptions] =
    useState([]);
  const [insuranceCompany, setInsuranceCompany] = useState(1);
  const [insuranceProvider, setInsuranceProvider] = useState();
  const [insurancePatientID, setInsurancePatientID] = useState();
  const [listFileInsuranceLOA, setListFileInsuranceLOA] = useState([]);
  const [listFileInsuranceLOC, setListFileInsuranceLOC] = useState([]);
  const [listFileInsuranceSupportingDocs, setListFileInsuranceSupportingDocs] =
    useState([]);
  const [excessInsuranceTotal, setExcessInsuranceTotal] = useState(
    objectExcessInsuranceTotal
  );
  const [insuranceStatus, setInsuranceStatus] = useState('');
  const [insuranceStatusLabel, setInsuranceStatusLabel] = useState('');
  const [insuranceUpdatedBy, setInsuranceUpdatedBy] = useState('');
  const [insuranceUpdatedAt, setInsuranceUpdatedAt] = useState('');
  const [insuranceContact, setInsuranceContact] = useState('');
  const [isInsuranceSubmitClaimLoading, setIsInsuranceSubmitClaimLoading] =
    useState(false);
  const [
    isInsuranceSubmitLoaDocumentsLoading,
    setIsInsuranceSubmitLoaDocumentsLoading,
  ] = useState(false);
  const [
    isInsuranceSubmitLocDocumentsLoading,
    setIsInsuranceSubmitLocDocumentsLoading,
  ] = useState(false);
  const [
    isPaymentLinkBtnLoading,
    setIsPaymentLinkBtnLoading,
  ] = useState(false);
  const [
    isRefreshPaylinkBtnLoading,
    setIsRefreshPaylinkBtnLoading,
  ] = useState(false);
  const [
    isInsuranceSubmitSupportingDocumentsLoading,
    setIsInsuranceSubmitSupportingDocumentsLoading,
  ] = useState(false);
  const [
    isInsuranceSubmitDocumentsLoading,
    setIsInsuranceSubmitDocumentsLoading,
  ] = useState(false);
  const [
    isInsuranceApproveDocumentsLoading,
    setIsInsuranceApproveDocumentsLoading,
  ] = useState(false);
  const [
    isInsuranceReviseDocumentsLoading,
    setIsInsuranceReviseDocumentsLoading,
  ] = useState(false);
  useState(false);
  const [isInsuranceApproveModalShown, setIsInsuranceApproveModalShown] =
    useState(false);
  const [isInsuranceReviseModalShown, setIsInsuranceReviseModalShown] =
    useState(false);

  const [isJenisKunjunganDisabled, setIsJenisKunjunganDisabled] =
    useState(false);

  const [paymentLinkStatus, setPaymentLinkStatus] = useState(null);

  const [listPaymentLink, setListPaymentLink] = useState([]);

  const [search, setSearch] = useState('');

  const [loadingPage, setLoadingPage] = useState(false);

  const { appointmentId } = useParams();

  const handleTreatmentCategoryGroupChange = (value) => {
    setTreatmentCategoryGroup(value);
  };

  const handleAdditionalDiscountChange = (value) => {
    setAdditionalDiscount((prev) => prev = value);
  };

  const handleTreatmentChange = async (type, key, value) => {
    try {
      const newTreatmentList = [...listTreatment];

      const indexTreatment = newTreatmentList.findIndex(
        (item) => key === item.key
      );
      const modifiedTreatment = newTreatmentList[indexTreatment];

      const payload = {
        quantity: !!value ? value : 0,
        unitPrice: modifiedTreatment['unitPrice'],
        date: moment().format('YYYY-MM-DD HH:mm:ss'),
        receiptId: receiptId,
        doctorId: modifiedTreatment['doctorId'],
        doctorName: modifiedTreatment['doctorName'],
        asstDoctorId: modifiedTreatment['asstDoctorId'],
        asstDoctorName: modifiedTreatment['asstDoctorName'],
      };

      let response;

      if (type !== 'insuranceExcessLimit') {
        response = await AppointmentApi.editTreatment(
          payload,
          appointmentId,
          patientDetail.patient.id,
          modifiedTreatment.id
        );
        if (modifiedTreatment['insuranceExcessLimit'] > modifiedTreatment['totalPrice']) {
          modifiedTreatment['insuranceExcessLimit'] = modifiedTreatment['totalPrice'];
        }
        modifiedTreatment['insuranceExcessTotal'] = modifiedTreatment['totalPrice'] - modifiedTreatment['insuranceExcessLimit'];
      }

      if (
        type === 'insuranceExcessLimit' ||
        (!!response && response.data.status === 'SUCCESS')
      ) {
        const newTreatmentPayment = [...listTreatmentPayment];
        const indexTreatmentPayment = newTreatmentPayment.findIndex((item) => item.column6 === 'Diskon Pembayaran');
        if (newTreatmentPayment[indexTreatmentPayment].column7 > 0) {
          setListVoucher([]);
          setListAppliedCoupon([]);
          handleDiscountReset();
        }
        modifiedTreatment[type] = !!value ? value : 0;

        modifiedTreatment['totalPrice'] =
          modifiedTreatment['unitPrice'] * modifiedTreatment['quantity'] -
          modifiedTreatment['discountTotal'];

        modifiedTreatment['insuranceExcessTotal'] =
          modifiedTreatment['totalPrice'] -
          modifiedTreatment['insuranceExcessLimit'];

        newTreatmentList.splice(indexTreatment, 1, modifiedTreatment);

        setListTreatment(newTreatmentList);

        if (listVoucher.length && listVoucher[0].multiple_treatment === 0) {
          handleDiscountPerItemApply(listVoucher);
        } else {
          handleDiscountMultipleTreatment(listVoucher);
        }
      }
    } catch (error) {
      Modal.warning({
        title: 'Perhatian',
        content: 'Terjadi kesalahan pada server.',
      });
      console.log(error);
    }
  };

  const handleTreatmentDelete = async (key) => {
    try {
      setLoadingPage(true);

      const newTreatmentList = [...listTreatment];

      const deletedTreatment = newTreatmentList.find(
        (item) => key === item.key
      );

      const response = await AppointmentApi.deleteTreatment(
        appointmentId,
        patientDetail.patient.id,
        deletedTreatment.id
      );

      if (response.data.status === 'SUCCESS') {
        const indexTreatment = newTreatmentList.findIndex(
          (item) => key === item.key
        );

        newTreatmentList.splice(indexTreatment, 1);

        const newTreatmentPayment = [...listTreatmentPayment];
        let indexAddDisc = newTreatmentPayment.findIndex((x) => x.column6 === 'Diskon Tambahan');
        if (newTreatmentList.filter((treatment) => treatment.treatmentType === 'IMPLANT').length) {
          // add column
          let indexDisc = newTreatmentPayment.findIndex((x) => x.column6 === 'Diskon Pembayaran');
          // diskon tambahan
          if (indexAddDisc === -1) {
            // PAJAK 0%
            let indexTax = newTreatmentPayment.findIndex((item) => item.column6 === 'Pajak 0%');
            newTreatmentPayment[indexTax].key++;
            // Covered by Insurance
            let indexInsurance = newTreatmentPayment.findIndex((item) => item.column6 === 'Covered by Insurance');
            newTreatmentPayment[indexInsurance].key++;
            // Total Tagihan
            let indexTotalTagihan = newTreatmentPayment.findIndex((item) => item.column6 === 'Total Tagihan');
            newTreatmentPayment[indexTotalTagihan].key++;
            // Sisa Tagihan
            let indexSisaTagihan = newTreatmentPayment.findIndex((item) => item.column6 === 'Sisa Tagihan');
            newTreatmentPayment[indexSisaTagihan].key++;
            // Total Kembali
            let indexTotalKembali = newTreatmentPayment.findIndex((item) => item.column6 === 'Total Kembali');
            newTreatmentPayment[indexTotalKembali].key++;
            // add column diskon tambahan
            let discountTambahan = {
              key : 3,
              column1 : '',
              column6 : 'Diskon Tambahan',
              column7 : <InputNumber 
                          className='skybase-dashboard__search-input' 
                          onChange={handleAdditionalDiscountChange} 
                          formatter={value => `Rp ${currencyFormatter(value)}`}
                          defaultValue={additionalDiscount}
                          parser={currencyParser} 
                          style={{textAlign: 'right'}}
                          max={maxAdditionalDiscount}
                          value={additionalDiscount}
                          disabled={insuranceStatus !== '' || paymentLinkStatus}
                        />
            };
            newTreatmentPayment.splice(indexDisc+1,0, discountTambahan);
          }
        } else if (indexAddDisc !== -1) {
          let indexTax = newTreatmentPayment.findIndex((item) => item.column6 === 'Pajak 0%');
          newTreatmentPayment[indexTax].key--;
          // Covered by Insurance
          let indexInsurance = newTreatmentPayment.findIndex((item) => item.column6 === 'Covered by Insurance');
          newTreatmentPayment[indexInsurance].key--;
          // Total Tagihan
          let indexTotalTagihan = newTreatmentPayment.findIndex((item) => item.column6 === 'Total Tagihan');
          newTreatmentPayment[indexTotalTagihan].key--;
          // Sisa Tagihan
          let indexSisaTagihan = newTreatmentPayment.findIndex((item) => item.column6 === 'Sisa Tagihan');
          newTreatmentPayment[indexSisaTagihan].key--;
          // Total Kembali
          let indexTotalKembali = newTreatmentPayment.findIndex((item) => item.column6 === 'Total Kembali');
          newTreatmentPayment[indexTotalKembali].key--;
          newTreatmentPayment.splice(indexAddDisc,1);
        }
        setAdditionalDiscount(0);
        setListTreatmentPayment(newTreatmentPayment);
        // Voucher
        const voucher = !!listVoucher && listVoucher[0];

        if (!!voucher) {
          const { voucher_terms } = voucher;
          const minAmount = voucher.voucher.min_transaction;
          const maxAmount = voucher.voucher.max_applied_amount;

          if (
            voucher.voucher.is_voucher_payment === 0 &&
            voucher.voucher.discount_type === 1 // diskon per item nominal
          ) {
            // logic diskon per item nominal
            let amountVoucher = 0;
            const voucherTermsTreatment = voucher_terms.filter(
              (item) => item.id_master_voucher_term === 3
            );

            if (voucherTermsTreatment.length > 0) {
              const availableTreatment = voucherTermsTreatment.map(
                (item) => item.value
              );
              const existingTreatment = newTreatmentList.map(
                (item) => item.treatmentId
              );

              const idTreatment = intersection(
                availableTreatment,
                existingTreatment
              );

              if (idTreatment.length > 0) {
                const appliedIdTreatment = head(idTreatment);
                const appliedTreatment = newTreatmentList.find(
                  (item) => item.treatmentId === appliedIdTreatment
                );

                amountVoucher = handleAmountVoucher(
                  voucher.voucher.amount,
                  minAmount,
                  maxAmount,
                  appliedTreatment.unitPrice * appliedTreatment.quantity,
                  'nominal'
                );

                // const newTreatmentList = [...listTreatment];

                const indexTreatment = newTreatmentList.findIndex(
                  (item) => appliedTreatment.key === item.key
                );
                const modifiedTreatment = newTreatmentList[indexTreatment];

                modifiedTreatment['discountTotal'] = amountVoucher;

                modifiedTreatment['totalPrice'] =
                  modifiedTreatment['unitPrice'] *
                    modifiedTreatment['quantity'] -
                  modifiedTreatment['discountTotal'];

                newTreatmentList.splice(indexTreatment, 1, modifiedTreatment);

                setListTreatment(newTreatmentList);
              } else {
                setListTreatment(newTreatmentList);
              }
            }
          } else if (
            voucher.voucher.is_voucher_payment === 0 &&
            voucher.voucher.discount_type === 2 // diskon per item persentase
          ) {
            // logic diskon per item persentase
            let amountVoucher = 0;
            const voucherTermsTreatment = voucher_terms.filter(
              (item) => item.id_master_voucher_term === 3
            );

            if (voucherTermsTreatment.length > 0) {
              const availableTreatment = voucherTermsTreatment.map(
                (item) => item.value
              );
              const existingTreatment = newTreatmentList.map(
                (item) => item.treatmentId
              );

              const idTreatment = intersection(
                availableTreatment,
                existingTreatment
              );

              if (idTreatment.length > 0) {
                const appliedIdTreatment = head(idTreatment);
                const appliedTreatment = newTreatmentList.find(
                  (item) => item.treatmentId === appliedIdTreatment
                );

                amountVoucher = handleAmountVoucher(
                  voucher.voucher.amount,
                  minAmount,
                  maxAmount,
                  appliedTreatment.unitPrice * appliedTreatment.quantity,
                  'percentage'
                );

                // const newTreatmentList = [...listTreatment];

                const indexTreatment = newTreatmentList.findIndex(
                  (item) => appliedTreatment.key === item.key
                );
                const modifiedTreatment = newTreatmentList[indexTreatment];

                modifiedTreatment['discountTotal'] = amountVoucher;

                modifiedTreatment['totalPrice'] =
                  modifiedTreatment['unitPrice'] *
                    modifiedTreatment['quantity'] -
                  modifiedTreatment['discountTotal'];

                newTreatmentList.splice(indexTreatment, 1, modifiedTreatment);

                setListTreatment(newTreatmentList);
              } else {
                setListTreatment(newTreatmentList);
              }
            }
          } else {
            setListTreatment(newTreatmentList);
          }
        } else {
          setListTreatment(newTreatmentList);
        }
      }
    } catch (error) {
      Modal.warning({
        title: 'Perhatian',
        content: 'Terjadi kesalahan pada server.',
      });
    } finally {
      setLoadingPage(false);
    }
  };

  const handleTreatmentOptionsSearch = async (value) => {
    if (value) {
      return await getTreatmentOptionsList(value);
    } else {
      return [];
    }
  };

  const handleTreatmentOptionsChange = async (value) => {
    try {
      setLoadingPage(true);

      const objectTreatmentChosen = listTreatmentOptions.find(
        (treatment) => treatment.code === value
      );

      const existingTreatment = listTreatment.find(
        (treatment) => treatment.treatmentCode === value
      );

      const voucher = !!listVoucher && listVoucher[0];

      if (!!existingTreatment) {
        const existingTreatment = listTreatment.find(
          (treatment) => treatment.treatmentCode === value
        );

        handleTreatmentChange(
          'quantity',
          existingTreatment.key,
          existingTreatment.quantity + 1
        );
      } else {
        // hit api bang aji
        const payload = {
          doctorId: patientDetail.doctorId,
          doctorName: null,
          unitPrice: objectTreatmentChosen.price,
          quantity: 1,
          description: objectTreatmentChosen.name,
          treatmentCode: objectTreatmentChosen.code,
          treatmentId: objectTreatmentChosen.id,
          date: moment().format('YYYY-MM-DD HH:mm:ss'),
          createdBy: localStorage.getItem('name')
            ? localStorage.getItem('name')
            : '',
          receiptId: receiptId,
        };

        const response = await AppointmentApi.addTreatment(
          payload,
          appointmentId,
          patientDetail.patient.id
        );

        if (response.data.status === 'SUCCESS') {
          // masukin id nya di bawah
          const objectConvertedTreatmentChosen = {
            id: response.data.data.id,
            medicalRecordNumber: patientDetail.medicalRecordNumber,
            appointmentId: parseInt(appointmentId, 10),
            treatmentCode: objectTreatmentChosen.code,
            quantity: 1,
            currency: objectTreatmentChosen.currency,
            totalPrice: objectTreatmentChosen.price,
            unitPrice: objectTreatmentChosen.price,
            description: objectTreatmentChosen.name,
            doctorId: objectTreatmentChosen.doctorId,
            doctorName: objectTreatmentChosen.doctorName,
            asstDoctorId: objectTreatmentChosen.asstDoctorId,
            asstDoctorName: objectTreatmentChosen.asstDoctorName,
            discountCode: null,
            discountTotal: null,
            status: null,
            registrationNumber: null,
            createdAt: null,
            modifiedAt: null,
            key:
              listTreatment.length > 0
                ? listTreatment[listTreatment.length - 1].key + 1
                : 1,
            treatmentId: objectTreatmentChosen.id,
            treatmentCategoryId: objectTreatmentChosen.treatmentCategory.id,
            treatmentType : objectTreatmentChosen.treatmentType,
            withTax: objectTreatmentChosen.withTax,
            createdBy: localStorage.getItem('name')
              ? localStorage.getItem('name')
              : '',
            insuranceExcessLimit: 0,
            insuranceExcessTotal: objectTreatmentChosen.price,
          };
          const newTreatmentPayment = [...listTreatmentPayment];
          let indexAddDisc = newTreatmentPayment.findIndex((x) => x.column6 === 'Diskon Tambahan');
          if (response.data.data.treatmentType === 'IMPLANT' && indexAddDisc === -1) {
            let indexDisc = newTreatmentPayment.findIndex((x) => x.column6 === 'Diskon Pembayaran');
            // PAJAK 0%
            let indexTax = newTreatmentPayment.findIndex((item) => item.column6 === 'Pajak 0%');
            newTreatmentPayment[indexTax].key++;
            // Covered by Insurance
            let indexInsurance = newTreatmentPayment.findIndex((item) => item.column6 === 'Covered by Insurance');
            newTreatmentPayment[indexInsurance].key++;
            // Total Tagihan
            let indexTotalTagihan = newTreatmentPayment.findIndex((item) => item.column6 === 'Total Tagihan');
            newTreatmentPayment[indexTotalTagihan].key++;
            // Sisa Tagihan
            let indexSisaTagihan = newTreatmentPayment.findIndex((item) => item.column6 === 'Sisa Tagihan');
            newTreatmentPayment[indexSisaTagihan].key++;
            // Total Kembali
            let indexTotalKembali = newTreatmentPayment.findIndex((item) => item.column6 === 'Total Kembali');
            newTreatmentPayment[indexTotalKembali].key++;
            // add column diskon tambahan
            let discountTambahan = {
              key : 3,
              column1 : '',
              column6 : 'Diskon Tambahan',
              column7 : <InputNumber 
                          className='skybase-dashboard__search-input' 
                          onChange={handleAdditionalDiscountChange} 
                          formatter={value => `Rp ${currencyFormatter(value)}`}
                          parser={currencyParser} 
                          style={{textAlign: 'right'}}
                          defaultValue={additionalDiscount}
                          max={maxAdditionalDiscount}
                          value={additionalDiscount}
                          disabled={insuranceStatus !== '' || paymentLinkStatus}
                        />
            };
            newTreatmentPayment.splice(indexDisc+1,0, discountTambahan);
            setListTreatmentPayment(newTreatmentPayment);
          }
          setAdditionalDiscount(0);
          const newObjectConvertedTreatmentChosen =
            !!voucher &&
            (voucher.voucher.is_voucher_payment === 0 ||
              voucher.voucher.type === 2)
              ? handleDiscountPerItemNew(objectConvertedTreatmentChosen)
              : objectConvertedTreatmentChosen;

          if (!!voucher && voucher.multiple_treatment === 0) {
            const discountTreatment = listTreatment.find((item) => item.discountTotal > 0);
            if (Object.keys(discountTreatment).length > 0) {
              const totalPrice = listTreatment.map((obj) => {return obj.totalPrice});
              const price = Math.max(...totalPrice, newObjectConvertedTreatmentChosen.totalPrice);
              let appliedTreatment = [];
              appliedTreatment = listTreatment.find((item) => item.totalPrice === price);
              if (appliedTreatment) {
                appliedTreatment = appliedTreatment[0];
                newObjectConvertedTreatmentChosen.discountTotal = 0;
                newObjectConvertedTreatmentChosen.totalPrice = newObjectConvertedTreatmentChosen.unitPrice * newObjectConvertedTreatmentChosen.quantity;
                newObjectConvertedTreatmentChosen.insuranceExcessTotal = newObjectConvertedTreatmentChosen.totalPrice - newObjectConvertedTreatmentChosen.insuranceExcessLimit;
              } else {
                listTreatment.forEach((item) => {
                  item.discountTotal = 0;
                  item.totalPrice = item.unitPrice * item.quantity;
                  item.insuranceExcessTotal = item.totalPrice - item.insuranceExcessLimit;
                });
              }
            }
          }
          setListTreatment([
            ...listTreatment,
            newObjectConvertedTreatmentChosen,
          ]);
        } else {
          Modal.warning({
            title: 'Perhatian',
            content: 'Terjadi kesalahan pada server.',
          });
        }
      }
    } catch (error) {
      console.error(error);
      Modal.warning({
        title: 'Perhatian',
        content: 'Terjadi kesalahan pada server.',
      });
    } finally {
      setLoadingPage(false);
    }
  };

  const handleTotalCashChange = (value) => {
    setTotalCash(value);
  };

  const handleTotalCashClick = (value) => {
    if (value === 'FULL') {
      setTotalCash(totalFinal - amountPaid);
    } else {
      setTotalCash(totalCash + value);
    }
  };

  const handleCreditDebitChange = (type, key, value) => {
    const newCreditDebitList = [...listCreditDebit];

    const indexCreditDebit = newCreditDebitList.findIndex(
      (item) => key === item.key
    );

    const modifiedCreditDebit = newCreditDebitList[indexCreditDebit];

    modifiedCreditDebit[type] = value;

    newCreditDebitList.splice(indexCreditDebit, 1, modifiedCreditDebit);

    setListCreditDebit(newCreditDebitList);
  };

  const handleCreditDebitDelete = (key) => {
    const newCreditDebitList = [...listCreditDebit];

    const indexCreditDebit = newCreditDebitList.findIndex(
      (item) => key === item.key
    );

    newCreditDebitList.splice(indexCreditDebit, 1);

    setListCreditDebit(newCreditDebitList);
  };

  const handleMarketplaceChange = (type, key, value = 0) => {
    const newMarketplaceList = [...listMarketplace];

    const indexMarketplace = newMarketplaceList.findIndex(
      (item) => key === item.key
    );

    const modifiedMarketplace = newMarketplaceList[indexMarketplace];

    modifiedMarketplace[type] = value;

    newMarketplaceList.splice(indexMarketplace, 1, modifiedMarketplace);

    setListMarketplace(newMarketplaceList);
  };

  const handleMarketplaceDelete = (key) => {
    const newMarketplaceList = [...listMarketplace];

    const indexMarketplace = newMarketplaceList.findIndex(
      (item) => key === item.key
    );

    newMarketplaceList.splice(indexMarketplace, 1);

    setListMarketplace(newMarketplaceList);
  };

  const handleTransferChange = (type, key, value) => {
    const newTransferList = [...listTransfer];

    const indexTransfer = newTransferList.findIndex((item) => key === item.key);

    const modifiedTransfer = newTransferList[indexTransfer];

    modifiedTransfer[type] = value;

    newTransferList.splice(indexTransfer, 1, modifiedTransfer);

    setListTransfer(newTransferList);
  };

  const handleTransferDelete = (key) => {
    const newTransferList = [...listTransfer];

    const indexTransfer = newTransferList.findIndex((item) => key === item.key);

    newTransferList.splice(indexTransfer, 1);

    setListTransfer(newTransferList);
  };

  const addNewPayment = (type) => {
    switch (type) {
      case 'creditDebit':
        setListCreditDebit([
          ...listCreditDebit,
          {
            ...objectCreditDebit,
            key: listCreditDebit[listCreditDebit.length - 1].key + 1,
          },
        ]);
        break;
      case 'marketplace':
        setListMarketplace([
          ...listMarketplace,
          {
            ...objectMarketplace,
            key: listMarketplace[listMarketplace.length - 1].key + 1,
          },
        ]);
        break;
      case 'insurance':
        setListInsurance([
          ...listInsurance,
          {
            ...objectInsurance,
            key: listInsurance[listInsurance.length - 1].key + 1,
          },
        ]);
        break;
      case 'transfer':
        setListTransfer([
          ...listTransfer,
          {
            ...objectTransfer,
            key: listTransfer[listTransfer.length - 1].key + 1,
          },
        ]);
        break;
      default:
        break;
    }
  };

  const handleDiscountCouponChange = (event) => {
    var delayTimer;

    const word = event.target.value;
    setDiscountCoupon(word);

    if (delayTimer) clearTimeout(delayTimer);
    delayTimer = setTimeout(function () {
      // Do the ajax stuff
      getVoucher(word);
    }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
  };

  const handleResetPaymentLink = async (paymentLink) => {
    try {
      paymentLink.status = 'PENDING';
      const res = await PaymentLinkApi.updatePaymentLink(receiptNumber, paymentLink);
      if (res.status === 200 && res.data.status === 'SUCCESS') {
        loadPaymentLinks();
      }
    } catch (err) {
      console.error(err);
    }
  }

  const getPatientDetail = async () => {
    try {
      setLoadingPage(true);

      const response = await AppointmentApi.getPatientDetail(appointmentId);
      // const responsev2 = await AppointmentApi.getPatientDetailv2(appointmentId);
      const receiptLatest = await AppointmentApi.getLatestReceiptv2(appointmentId);
      let patient = response.data.data;
      const chosenReceiptId = receiptLatest.data.data.id;
      const res = await TransactionApi.getReceiptDetailById(chosenReceiptId);

      const paidAmount = receiptLatest.data.data.amountPaid;


      const chosenReceiptNumber = receiptLatest.data.data.receiptNumber;

      const treatment = patient.receiptList[0].slipTreatmentList;

      const modifiedTreatment = treatment.map((item, index) => ({
        ...item,
        key: index + 1,
        insuranceExcessLimit: 0,
        insuranceExcessTotal: item.totalPrice - item.discountTotal,
        totalPrice : item.totalPrice - item.discountTotal,
      }));

      const newTreatmentPayment = [...listTreatmentPayment];
      if (modifiedTreatment.filter((treatment) => treatment.treatmentType === 'IMPLANT').length) {
        let indexAddDisc = newTreatmentPayment.findIndex((x) => x.column6 === 'Diskon Tambahan');
        // add column
        let indexDisc = newTreatmentPayment.findIndex((x) => x.column6 === 'Diskon Pembayaran');
        // diskon tambahan
        if (indexAddDisc === -1) {
          // PAJAK 0%
          let indexTax = newTreatmentPayment.findIndex((item) => item.column6 === 'Pajak 0%');
          newTreatmentPayment[indexTax].key++;
          // Covered by Insurance
          let indexInsurance = newTreatmentPayment.findIndex((item) => item.column6 === 'Covered by Insurance');
          newTreatmentPayment[indexInsurance].key++;
          // Total Tagihan
          let indexTotalTagihan = newTreatmentPayment.findIndex((item) => item.column6 === 'Total Tagihan');
          newTreatmentPayment[indexTotalTagihan].key++;
          // Sisa Tagihan
          let indexSisaTagihan = newTreatmentPayment.findIndex((item) => item.column6 === 'Sisa Tagihan');
          newTreatmentPayment[indexSisaTagihan].key++;
          // Total Kembali
          let indexTotalKembali = newTreatmentPayment.findIndex((item) => item.column6 === 'Total Kembali');
          newTreatmentPayment[indexTotalKembali].key++;
          // add column diskon tambahan
          let discountTambahan = {
            key : 3,
            column1 : '',
            column6 : 'Diskon Tambahan',
            column7 : <InputNumber 
                        className='skybase-dashboard__search-input' 
                        onChange={handleAdditionalDiscountChange} 
                        formatter={value => `Rp ${currencyFormatter(value)}`}
                        parser={currencyParser} 
                        style={{textAlign: 'right'}}
                        defaultValue={additionalDiscount}
                        value={additionalDiscount}
                        disabled={insuranceStatus !== '' || paymentLinkStatus}
                      />
          };
          newTreatmentPayment.splice(indexDisc+1,0, discountTambahan);
          setListTreatmentPayment(newTreatmentPayment);
        }
      }
      setListTreatment(modifiedTreatment);

      if (!!patient.receiptList[0].jenisKunjungan) {
        const chosenJenisKunjungan = patient.receiptList[0].jenisKunjungan.id;

        setTreatmentCategoryGroup(chosenJenisKunjungan);
        setIsJenisKunjunganDisabled(true);
      }
      setPatientDetail(patient); 
      setAmountPaid(paidAmount);
      setReceiptId(chosenReceiptId);
      setReceiptNumber(chosenReceiptNumber);

      getInsuranceDetail(chosenReceiptId);

      getVoucherAll(response.data.data.patient.beId);
      // getTreatmentList(appointmentId, patient.patient.id);
    } finally {
      setLoadingPage(false);
    }
  };

  // const getTreatmentList = async (appointmentId, medicalRecordNumber) => {
  //   try {
  //     setLoadingPage(true);
  //
  //     // const clinicId = localStorage.getItem('clinicId');
  //
  //     // const response = await AppointmentApi.getTreatmentListWithDiscount(
  //     //   appointmentId,
  //     //   medicalRecordNumber,
  //     //   'CONFIRMED',
  //     //   clinicId ? clinicId : 0
  //     // );
  //
  //     const response = await AppointmentApi.getTreatmentList(
  //       appointmentId,
  //       medicalRecordNumber,
  //       'CONFIRMED'
  //     );
  //
  //     let treatment = setToArray(response.data);
  //
  //     const modifiedTreatment = treatment.map((item, index) => ({
  //       ...item,
  //       key: index + 1,
  //       insuranceExcessLimit: 0,
  //       insuranceExcessTotal: item.totalPrice,
  //     }));
  //
  //     setListTreatment(modifiedTreatment);
  //   } finally {
  //     setLoadingPage(false);
  //   }
  // };

  const getTreatmentCategoryGroupOptionsList = async (keyword) => {
    const response = await TreatmentApi.listTreatmentCategoryGroupOptions();

    let treatmentCategoryGroupList = setToArray(response.data);

    const treatmentCategoryGroupListOpts = treatmentCategoryGroupList.map(
      (value) => ({
        label: value.name,
        value: value.id,
      })
    );

    setListTreatmentCategoryGroupOptions(treatmentCategoryGroupListOpts);
  };

  const getTreatmentOptionsList = async (keyword) => {
    const response = await TreatmentApi.listTreatmentOptions(keyword);

    let treatmentList = response.data.data;

    const filteredTreatmentList = [];

    treatmentList.forEach((treatment) => {
      if (
        treatment.code !== 'DP-0.5JT' &&
        treatment.code !== 'DP-5JT' &&
        treatment.code !== 'DP-3JT' &&
        treatment.code !== 'DP-2JT' &&
        treatment.code !== 'DP-1JT'
      ) {
        filteredTreatmentList.push(treatment);
      }
    });

    setListTreatmentOptions(filteredTreatmentList);
    return filteredTreatmentList;
  };

  const getEdcList = async () => {
    const response = await FinanceApi.getMethodList();

    let paymentMethodList = setToArray(response.data);

    const paymentMethodListOpts = paymentMethodList.map((value) => ({
      label: value.name,
      value: value.name,
    }));

    setListEdcOptions(paymentMethodListOpts);
  };

  const getBankList = async () => {
    try {
      setLoadingPage(true);

      const response = await BankApi.getListBankFromBE();

      let bankList = response.data;

      const bankListOpts = bankList.map((value) => ({
        label: value.nama_bank,
        value: value.nama_bank,
      }));

      setListBankOptions(bankListOpts);
    } finally {
      setLoadingPage(false);
    }
  };

  const getMarketplaceList = async () => {
    try {
      setLoadingPage(true);

      const response = await ParameterApi.getListMarketplace();

      let marketplaceList = response.data.data;

      const marketplaceListOpts = marketplaceList.map((value) => ({
        label: value.value,
        value: value.code,
      }));

      setListMarketplaceOptions(marketplaceListOpts);
    } finally {
      setLoadingPage(false);
    }
  };

  const getVoucherAll = async (beId) => {
    try {
      const payload = {
        id_clinic: localStorage.getItem('clinicId')
          ? parseInt(localStorage.getItem('clinicId'), 10)
          : 0,
        id_customer: beId,
        id_clinic_group: localStorage.getItem('clinicGroup')
          ? localStorage.getItem('clinicGroup').split(',')
          : 0,
        // id_clinic: 48,
        // id_customer: 16929,
        // id_clinic_group: [2, 3],
      };

      const response = await VouchersApi.getVoucherAll(payload);

      let voucherList = response.data.result;

      voucherList.forEach((item) => {
        item.amountLabel =
          item.type === 1
            ? item.discount_type === 1
              ? item.amount
              : `${item.amount}%`
            : 'multiple';
      });

      setListVoucherAll(voucherList);
      setListFilteredVoucherAll(voucherList);
    } catch (error) {
      setListVoucherAll([]);
    }
  };

  const getVoucher = async (code) => {
    try {
      if (code) {
        handleDiscountReset();

        const payload = {
          id_clinic: localStorage.getItem('clinicId')
            ? parseInt(localStorage.getItem('clinicId'), 10)
            : 0,
          id_customer: patientDetail.patient.beId,
          id_clinic_group: localStorage.getItem('clinicGroup')
            ? localStorage.getItem('clinicGroup').split(',')
            : 0,
        };

        // const payload = {
        //   id_clinic: 48,
        //   id_customer: 16929,
        //   id_clinic_group: [2, 3],
        // };

        const response = await VouchersApi.getVoucher(payload, code);

        let voucherList = [];

        if (!response.data.has_unique_code) {
          const newObj = [...response.data.result];

          for (let index = 0; index < newObj.length; index++) {
            const voucher = {
              ...response.data.result[index],
            };
            newObj[index].voucher = voucher;
          }

          voucherList = newObj;
        } else {
          voucherList = response.data.result;
        }

        setListVoucher(voucherList);
        setIsVoucherUsingUniqueCode(response.data.has_unique_code);
        const treatment = [...listTreatment];
        if (voucherList.voucher_treatment_type === 'IMPLANT') {
          if (!treatment.filter((item) => item.treatmentType === 'IMPLANT').length || !treatment.filter((item) => item.treatmentType !== 'IMPLANT').length) {
            handleDiscountReset();
            return Modal.info({
              title: 'Alert',
              content: 'Voucher hanya bisa digunakan saat transaksi pembayaran implant',
            });
          }
        } else if (voucherList.voucher_treatment_type === 'GENERAL') {
          if(!treatment.filter((item) => item.treatmentType === 'GENERAL').length) {
            handleDiscountReset();
            return Modal.info({
              title: 'Alert',
              content: 'Voucher hanya bisa digunakan saat transaksi general',
            });
          }
        }
        if (voucherList.is_voucher_payment === 1) {
          const insurance = listTreatment.filter((item) => item.insuranceExcessLimit > 0);
          if (insurance.length > 0) {
            handleDiscountReset();
            return Modal.warning({
              title : 'Warning',
              content : 'Voucher tidak bisa digunakan karena sudah ada insurance',
            });
          }
          Modal.info({
            title: 'Alert',
            content: 'Voucher dengan diskon pembayaran = YA , akan dihapus apabila menggunakan insurance',
          });
        }

        if (voucherList[0].multiple_treatment) {
          handleDiscountMultipleTreatment(voucherList);
        } else {
          handleDiscountPerItemApply(voucherList);
        }

        if (voucherList.length > 0) {
          setListAppliedCoupon([
            {
              name: voucherList[0].voucher.name,
              amount:
                voucherList[0].voucher.type === 1
                  ? voucherList[0].voucher.discount_type === 1
                    ? voucherList[0].voucher.amount
                    : `${voucherList[0].voucher.amount}%`
                  : 'multiple',
            },
          ]);
        } else {
          setListAppliedCoupon([]);
        }
      } else {
        setListVoucher([]);
        setListAppliedCoupon([]);
      }
    } catch (error) {
      console.error(error);
      setListVoucher([]);
      setListAppliedCoupon([]);
    }
  };

  const handleUseVoucher = (name) => {
    getVoucher(name);
    setDiscountCoupon();
  };

  const handleDiscountPerItemApply = (voucherList) => {
    const treatment = [...listTreatment];

    // Voucher
    const voucher = !!voucherList && voucherList[0];
    const treatmentVoucher = [];
    if (voucher && !voucher.is_voucher_payment) {
      const { voucher_terms, type } = voucher;
      let minAmount = 0;
      let maxAmount = 0;

      for (let index = 0; index < voucher_terms.length; index++) {
        // if (type === 1 || voucher.voucher.type === 1) {
        //   minAmount = voucher.voucher.min_transaction
        //     ? parseInt(voucher.voucher.min_transaction)
        //     : 0;
        //   maxAmount = voucher.voucher.max_applied_amount
        //     ? parseInt(voucher.voucher.max_applied_amount)
        //     : 0;
        // } else if (type === 2 || voucher.voucher.type === 2) {
        //   minAmount = voucher_terms[index].min_transaction
        //     ? parseInt(voucher_terms[index].min_transaction)
        //     : 0;
        //   maxAmount = voucher_terms[index].max_applied_amount
        //     ? parseInt(voucher_terms[index].max_applied_amount)
        //     : 0;
        // }

        // let amountVoucher = 0;

        if (voucher_terms[index].id_master_voucher_term === 3) {
          const availableTreatment = voucher_terms[index].value; // ambil dari value untuk id tindakannya
          treatmentVoucher.push(availableTreatment);

          // const existingTreatment = treatment.map((item) => item.treatmentId);

          // const idTreatment = includes(existingTreatment, availableTreatment);

          // if (idTreatment) {
          //   const appliedIdTreatment = availableTreatment;
          //   const appliedTreatment = treatment.find(
          //     (item) => item.treatmentId === appliedIdTreatment
          //   );

          //   if (type === 1 || voucher.voucher.type === 1)
          //     amountVoucher = handleAmountVoucher(
          //       parseInt(voucher.voucher.amount),
          //       minAmount,
          //       maxAmount,
          //       appliedTreatment.totalPrice,
          //       voucher.voucher.discount_type === 1 ? 'nominal' : 'percentage'
          //     );
          //   else if (type === 2 || voucher.voucher.type === 2)
          //     amountVoucher = handleAmountVoucher(
          //       parseInt(voucher_terms[index].discount_amount),
          //       minAmount,
          //       maxAmount,
          //       appliedTreatment.totalPrice,
          //       voucher_terms[index].discount_type === 1
          //         ? 'nominal'
          //         : 'percentage'
          //     );

          //   const newTreatmentList = [...listTreatment];

          //   const indexTreatment = newTreatmentList.findIndex(
          //     (item) => appliedTreatment.key === item.key
          //   );
          //   const modifiedTreatment = newTreatmentList[indexTreatment];

          //   modifiedTreatment['discountTotal'] = amountVoucher;

          //   modifiedTreatment['totalPrice'] =
          //     Math.max(modifiedTreatment['unitPrice'] * modifiedTreatment['quantity'] -
          //     modifiedTreatment['discountTotal'], 0);

          //   if (modifiedTreatment['totalPrice'] === 0) {
          //     modifiedTreatment['discountTotal'] = modifiedTreatment['unitPrice'] * modifiedTreatment['quantity'];
          //   }

          //   newTreatmentList.splice(indexTreatment, 1, modifiedTreatment);
          //   setListTreatment(newTreatmentList);
          // }
        }
      }
      const existingTreatment = treatment.map((item) => item.treatmentId);

      const applicableTreatment = [];

      for (let i=0; i<existingTreatment.length; i++) {
        if (treatmentVoucher.includes(existingTreatment[i])) {
          applicableTreatment.push(treatment[i]);
        }
      };

      let appliedTreatment = [];

      if (applicableTreatment.length > 1) {
        for (let idx = 0; idx<applicableTreatment.length; idx++) {
          applicableTreatment[idx].discountTotal = 0;
          applicableTreatment[idx].totalPrice = applicableTreatment[idx].quantity * applicableTreatment[idx].unitPrice;
        }
        const price = applicableTreatment.map(obj => {
          return obj.totalPrice;
        });
        const maxTotalPrice = Math.max(...price);
        appliedTreatment = treatment.find((item) => item.totalPrice === maxTotalPrice);
        if (appliedTreatment.length > 1) appliedTreatment = appliedTreatment[0];
      } else {
        appliedTreatment = applicableTreatment[0];
      }

      let amountVoucher = 0;

      if (type === 1 || voucher.voucher.type === 1) {
        minAmount = voucher.voucher.min_transaction
          ? parseInt(voucher.voucher.min_transaction)
          : 0;
        maxAmount = voucher.voucher.max_applied_amount
          ? parseInt(voucher.voucher.max_applied_amount)
          : 0;
      } else if (type === 2 || voucher.voucher.type === 2) {
        const voucherIdx = voucher_terms.findIndex((item) => item.value === appliedTreatment.treatmentId);
        minAmount = voucher_terms[voucherIdx].min_transaction
          ? parseInt(voucher_terms[voucherIdx].min_transaction)
          : 0;
        maxAmount = voucher_terms[voucherIdx].max_applied_amount
          ? parseInt(voucher_terms[voucherIdx].max_applied_amount)
          : 0;
      }

      if (type === 1 || voucher.voucher.type === 1)
        amountVoucher = handleAmountVoucher(
          parseInt(voucher.voucher.amount),
          minAmount,
          maxAmount,
          appliedTreatment.totalPrice,
          voucher.voucher.discount_type === 1 ? 'nominal' : 'percentage'
        );
      else if (type === 2 || voucher.voucher.type === 2) {
        const voucherIdx = voucher_terms.findIndex((item) => item.value === appliedTreatment.treatmentId);
        amountVoucher = handleAmountVoucher(
          parseInt(voucher_terms[voucherIdx].discount_amount),
          minAmount,
          maxAmount,
          appliedTreatment.totalPrice,
          voucher_terms[voucherIdx].discount_type === 1
          ? 'nominal'
          : 'percentage'
        );
      }
      const newTreatmentList = [...listTreatment];
      const indexTreatment = newTreatmentList.findIndex((item) => appliedTreatment.key === item.key);
      const modifiedTreatment = newTreatmentList[indexTreatment];

      modifiedTreatment['totalPrice'] = Math.max(modifiedTreatment['unitPrice'] * modifiedTreatment['quantity'] - amountVoucher, 0);
      modifiedTreatment['discountTotal'] = amountVoucher;

      if (modifiedTreatment['totalPrice'] === 0) {
        modifiedTreatment['discountTotal'] = modifiedTreatment['unitPrice'] * modifiedTreatment['quantity'];
      };

      if (modifiedTreatment['insuranceExcessLimit'] > modifiedTreatment['totalPrice']) {
        modifiedTreatment['insuranceExcessLimit'] = modifiedTreatment['totalPrice'];
      }
      modifiedTreatment['insuranceExcessTotal'] = modifiedTreatment['totalPrice'] - modifiedTreatment['insuranceExcessLimit'];

      newTreatmentList.forEach((item) => {
        item['insuranceExcessTotal'] = item['totalPrice'] - item['insuranceExcessLimit'];
      });

      newTreatmentList.splice(indexTreatment, 1, modifiedTreatment);
      setListTreatment(newTreatmentList);
    }
  };

  const handleDiscountMultipleTreatment = (voucherList) => {
    const treatment = [...listTreatment];

    const voucher = !!voucherList && voucherList[0];
    if (voucher && !voucher.is_voucher_payment) {
      const { voucher_terms, type } = voucher;
      let minAmount = 0;
      let maxAmount = 0;

      for (let index = 0; index < voucher_terms.length; index++) {

        if (type === 1 || voucher.voucher.type === 1) {
          minAmount = voucher.voucher.min_transaction
            ? parseInt(voucher.voucher.min_transaction)
            : 0;
          maxAmount = voucher.voucher.max_applied_amount
            ? parseInt(voucher.voucher.max_applied_amount)
            : 0;
        } else if (type === 2 || voucher.voucher.type === 2) {
          minAmount = voucher_terms[index].min_transaction
            ? parseInt(voucher_terms[index].min_transaction)
            : 0;
          maxAmount = voucher_terms[index].max_applied_amount
            ? parseInt(voucher_terms[index].max_applied_amount)
            : 0;
        }

        let amountVoucher = 0;

        if (voucher_terms[index].id_master_voucher_term === 3) {
          const availableTreatment = voucher_terms[index].value;

          const existingTreatment = treatment.map((item) => item.treatmentId);
          const idTreatment = includes(existingTreatment, availableTreatment);

          if (idTreatment) {
            const appliedIdTreatment = availableTreatment;
            const appliedTreatment = treatment.find((item) => item.treatmentId === appliedIdTreatment);

            if (type === 1 || voucher.voucher.type === 1)
            amountVoucher = handleAmountVoucher(
              parseInt(voucher.voucher.amount),
              minAmount,
              maxAmount,
              appliedTreatment.totalPrice,
              voucher.voucher.discount_type === 1 ? 'nominal' : 'percentage'
              );
            else if (type === 2 || voucher.voucher.type === 2)
              amountVoucher = handleAmountVoucher(
              parseInt(voucher_terms[index].discount_amount),
              minAmount,
              maxAmount,
              appliedTreatment.totalPrice,
              voucher_terms[index].discount_type === 1 ? 'nominal' : 'percentage'
              );

            const newTreatmentList = [...listTreatment];
            const indexTreatment = newTreatmentList.findIndex((item) => appliedTreatment.key === item.key);
            const modifiedTreatment = newTreatmentList[indexTreatment];

            modifiedTreatment['totalPrice'] = Math.max(modifiedTreatment['unitPrice'] * modifiedTreatment['quantity'] - amountVoucher, 0);
            modifiedTreatment['discountTotal'] = amountVoucher;

            if (modifiedTreatment['totalPrice'] === 0) {
              modifiedTreatment['discountTotal'] = modifiedTreatment['unitPrice'] * modifiedTreatment['quantity'];
            }

            if (modifiedTreatment['insuranceExcessLimit'] > modifiedTreatment['totalPrice']) {
              modifiedTreatment['insuranceExcessLimit'] = modifiedTreatment['totalPrice'];
            }
            modifiedTreatment['insuranceExcessTotal'] = modifiedTreatment['totalPrice'] - modifiedTreatment['insuranceExcessLimit'];

            newTreatmentList.forEach((item) => {
              item['insuranceExcessTotal'] = item['totalPrice'] - item['insuranceExcessLimit'];
            });

            newTreatmentList.splice(indexTreatment, 1, modifiedTreatment);            
            setListTreatment(newTreatmentList);
          }
        }
      }
    }
  };

  const handleDiscountPerItemNew = (newTreatment) => {
    const voucher = !!listVoucher && listVoucher[0];
    let minAmount = 0;
    let maxAmount = 0;

    if (!!voucher) {
      const { voucher_terms, type } = voucher;
      let newTreatmentObject = {};

      for (let index = 0; index < voucher_terms.length; index++) {
        if (type === 1 || voucher.voucher.type === 1) {
          minAmount = voucher.voucher.min_transaction
            ? parseInt(voucher.voucher.min_transaction)
            : 0;
          maxAmount = voucher.voucher.max_applied_amount
            ? parseInt(voucher.voucher.max_applied_amount)
            : 0;
        } else if (type === 2 || voucher.voucher.type === 2) {
          minAmount = voucher_terms[index].min_transaction
            ? parseInt(voucher_terms[index].min_transaction)
            : 0;
          maxAmount = voucher_terms[index].max_applied_amount
            ? parseInt(voucher_terms[index].max_applied_amount)
            : 0;
        }

        let amountVoucher = 0;

        if (voucher_terms[index].id_master_voucher_term === 3) {
          const availableTreatment = voucher_terms[index].value; // ambil dari value untuk id tindakannya

          const existingTreatment = newTreatment.treatmentId;

          amountVoucher = handleAmountVoucher(
            parseInt(voucher_terms[index].discount_amount),
            minAmount,
            maxAmount,
            newTreatment.totalPrice,
            voucher_terms[index].discount_type === 1 ? 'nominal' : 'percentage'
          );

          if (type === 1 || voucher.voucher.type === 1)
            amountVoucher = handleAmountVoucher(
              parseInt(voucher.voucher.amount),
              minAmount,
              maxAmount,
              newTreatment.totalPrice,
              voucher.voucher.discount_type === 1 ? 'nominal' : 'percentage'
            );
          else if (type === 2 || voucher.voucher.type === 2)
            amountVoucher = handleAmountVoucher(
              parseInt(voucher_terms[index].discount_amount),
              minAmount,
              maxAmount,
              newTreatment.totalPrice,
              voucher_terms[index].discount_type === 1
                ? 'nominal'
                : 'percentage'
            );

          if (availableTreatment === existingTreatment) {
            newTreatmentObject = {
              ...newTreatment,
              discountTotal: amountVoucher,
              totalPrice: newTreatment.totalPrice - amountVoucher,
            };
            break;
          } else {
            newTreatmentObject = newTreatment;
          }
        }
      }

      return newTreatmentObject;
    } else {
      return newTreatment;
    }
  };

  const handleAmountVoucher = (
    amount,
    minAmount,
    maxAmount,
    totalPrice,
    type
  ) => {
    let amountVoucher = 0;
    if (type === 'nominal') {
      if (totalPrice >= minAmount) {
        amountVoucher = amount;
      }
    } else if (type === 'percentage') {
      const toNominal = parseInt((totalPrice * amount) / 100);

      if (totalPrice >= minAmount) {
        amountVoucher = toNominal <= maxAmount ? toNominal : maxAmount;
      }
    }

    return amountVoucher;
  };

  const handleDiscountReset = () => {
    setListVoucher([]);
    // setDiscountCoupon();

    const newTreatmentList = [...listTreatment];

    newTreatmentList.forEach((item) => {
      item['discountTotal'] = 0;

      item['totalPrice'] =
        item['unitPrice'] * item['quantity'] - item['discountTotal'];
      
      item['insuranceExcessTotal'] =
        item['totalPrice'] - item['insuranceExcessLimit'];
    });

    setListTreatment(newTreatmentList);
  };

  const handleVoucherMultipleTreatment = (voucher) => {
    if (
      voucher.multiple_treatment === 0 ||
      voucher.voucher.multiple_treatment === 0
    ) {
      const treatmentWithVoucher = listTreatment.filter(
        (item) => item.discountTotal > 0
      );

      const idTreatmentWithVoucher = treatmentWithVoucher.map(
        (item) => item.treatmentId
      );

      const idVoucherTerms = voucher.voucher_terms.map((item) => item.value);

      const appliedTreatmentWithVoucher = intersection(
        idTreatmentWithVoucher,
        idVoucherTerms
      );

      if (appliedTreatmentWithVoucher.length > 1) return false;
    }

    return true;
  };

  const isPaymentDataComplete = () => {
    let isCreditDebitDataComplete = listCreditDebit.some(
      (item) =>
        item.edcType.toLowerCase() !== 'transfer' &&
        item.amount > 0 &&
        (item.edcType === '' ||
          item.cardBank === '' ||
          item.cardNumber === '' ||
          item.approvalNumber === '' ||
          item.traceNumber === '')
    );

    let isMarketplaceDataComplete = listMarketplace.some(
      (item) =>
        item.amount > 0 &&
        (item.marketplace === '' || item.voucherNumber === '')
    );

    let isTransferDataComplete = listTransfer.some(
      (item) =>
        item.amount > 0 && (item.cardBank === '' || item.senderName === '')
    );

    return {
      isCreditDebitDataComplete,
      isMarketplaceDataComplete,
      isTransferDataComplete,
    };
  };

  const postSubmit = async () => {
    try {
      setLoadingPage(true);

      const {
        isCreditDebitDataComplete,
        isMarketplaceDataComplete,
        isTransferDataComplete,
      } = isPaymentDataComplete();

      if (!treatmentCategoryGroup) {
          return Modal.warning({
              title: 'Tidak Dapat Melanjutkan Pembayaran',
              content: 'Anda harus mengisi field Jenis Kunjungan',
          });
      }
      if (isCreditDebitDataComplete) {
          return Modal.warning({
              title: 'Perhatian',
              content:
                  'Data Debit/Credit kurang lengkap! Silahkan lengkapi data terlebih dahulu.',
          });
      }
      if (isMarketplaceDataComplete) {
          return Modal.warning({
              title: 'Perhatian',
              content:
                  'Data Marketplace kurang lengkap! Silahkan lengkapi data terlebih dahulu.',
          });
      }
      if (isTransferDataComplete) {
          return Modal.warning({
              title: 'Perhatian',
              content:
                  'Data Transfer kurang lengkap! Silahkan lengkapi data terlebih dahulu.',
          });
      }
      if (totalPaymentReceived < (totalFinal - amountPaid)) {
          return Modal.error({
              title: 'Gagal',
              content: 'Jumlah nominal tidak mencukupi total pembayaran',
          });
      }

      if (totalPaymentReceived >= (totalFinal - amountPaid)) {

        const modifiedTreatmentList = [];
        const modifiedPaymentList = [];
        if (
          !!listVoucher &&
          listVoucher.length > 0 &&
          listVoucher[0].voucher.is_voucher_payment === 1 &&
          listVoucher[0].voucher.type !== 2
        ) {
          let reminderAmount = totalVoucher;

          for (let index = 0; index < listTreatment.length; index++) {
            if (listTreatment[index].treatmentType !== 'IMPLANT') {
              modifiedTreatmentList.push({
                id: listTreatment[index].id,
                treatmentCode: listTreatment[index].treatmentCode,
                quantity: listTreatment[index].quantity,
                currency: 'IDR',
                totalPrice: listTreatment[index].totalPrice,
                unitPrice: listTreatment[index].unitPrice,
                // priceBeforeTax:
                //   parseInt(listTreatment[index].unitPrice) / 1.1,
                description: listTreatment[index].description,
                doctorId: listTreatment[index].doctorId,
                doctorName: listTreatment[index].doctorName,
                discountCode:
                  !!listVoucher && Object.keys(listVoucher).length > 0
                    ? listVoucher.code
                    : null, // ngambil discount code
                // discountTotal: listTreatment[index].discountTotal,
                discountTotal: listTreatment[index].withTax
                  ? 0
                  : reminderAmount > listTreatment[index].totalPrice
                  ? listTreatment[index].totalPrice
                  : reminderAmount > 0
                  ? reminderAmount
                  : 0,
                treatmentId: listTreatment[index].treatmentId,
                treatmentCategoryId:
                  listTreatment[index].treatmentCategoryId,
                paymentDiscount:
                  !!listVoucher && Object.keys(listVoucher).length > 0
                    ? listVoucher.is_voucher_payment === 0 ||
                      listVoucher.type === 2
                      ? false
                      : true
                    : null,
                createdBy: listTreatment[index].createdBy,
              });
  
              if (!listTreatment[index].withTax) {
                reminderAmount = reminderAmount - listTreatment[index].totalPrice;
              }
            } else {
              modifiedTreatmentList.push({
                id: listTreatment[index].id,
                treatmentCode: listTreatment[index].treatmentCode,
                quantity: listTreatment[index].quantity,
                currency: 'IDR',
                totalPrice: listTreatment[index].totalPrice,
                unitPrice: listTreatment[index].unitPrice,
                // priceBeforeTax:
                //   parseInt(listTreatment[index].unitPrice) / 1.1,
                description: listTreatment[index].description,
                doctorId: listTreatment[index].doctorId,
                doctorName: listTreatment[index].doctorName,
                discountCode:
                  !!listVoucher && Object.keys(listVoucher).length > 0
                    ? listVoucher.code
                    : null, // ngambil discount code
                // discountTotal: listTreatment[index].discountTotal,
                discountTotal: 0,
                treatmentId: listTreatment[index].treatmentId,
                treatmentCategoryId:
                  listTreatment[index].treatmentCategoryId,
                paymentDiscount:
                  !!listVoucher && Object.keys(listVoucher).length > 0
                    ? listVoucher.is_voucher_payment === 0 ||
                      listVoucher.type === 2
                      ? false
                      : true
                    : null,
                createdBy: listTreatment[index].createdBy,
              });
            }
          }
        } else {
          listTreatment.forEach((item) => {
            const objTreatment = {
              id: item.id,
              treatmentCode: item.treatmentCode,
              quantity: item.quantity,
              currency: 'IDR',
              totalPrice: item.totalPrice,
              unitPrice: item.unitPrice,
              // priceBeforeTax: parseInt(item.unitPrice) / 1.1,
              description: item.description,
              doctorId: item.doctorId,
              doctorName: item.doctorName,
              discountCode:
                !!listVoucher && listVoucher.length > 0
                  ? listVoucher[0].code
                  : null, // ngambil discount code
              discountTotal: item.discountTotal,
              treatmentId: item.treatmentId,
              treatmentCategoryId: item.treatmentCategoryId,
              paymentDiscount:
                !!listVoucher && listVoucher.length > 0
                  ? listVoucher[0].voucher.is_voucher_payment === 0 ||
                    listVoucher[0].voucher.type === 2
                    ? false
                    : true
                  : null,
              createdBy: item.createdBy,
              // discountTotal: diskon pembayaran, kurangin harga item dari atas / diskon per tindakan, kurangin sesuai isi diskon / kalo gak ada, 0
            };

            modifiedTreatmentList.push(objTreatment);
          });
        }

        listCreditDebit.forEach((item) => {
          if (item.amount > 0) {
            const objCreditDebit = {
              edcType: item.edcType,
              paymentMethod: item.cardBank,
              cardNumber: item.cardNumber.toString(),
              approvalNumber: item.approvalNumber,
              traceNumber: item.traceNumber,
              couponCode: '',
              currency: 'IDR',
              totalPayment: item.amount,
            };

            modifiedPaymentList.push(objCreditDebit);
          }
        });

        listMarketplace.forEach((item) => {
          if (item.amount > 0) {
            const objMarketplace = {
              edcType: '',
              paymentMethod: item.marketplace,
              cardNumber: '',
              approvalNumber: '',
              couponCode: item.voucherNumber,
              currency: 'IDR',
              totalPayment: item.amount,
            };

            modifiedPaymentList.push(objMarketplace);
          }
        });

        listTransfer.forEach((item) => {
          if (item.amount > 0) {
            const objTransfer = {
              edcType: '',
              paymentMethod: `${item.cardBank} (TRANSFER)`,
              cardNumber: item.senderName,
              approvalNumber: '',
              couponCode: '',
              currency: 'IDR',
              totalPayment: item.amount,
            };

            modifiedPaymentList.push(objTransfer);
          }
        });

        if (totalCash > 0) {
          const objCash = {
            edcType: '',
            paymentMethod: 'CASH',
            cardNumber: '',
            approvalNumber: '',
            couponCode: '',
            currency: 'IDR',
            totalPayment: totalCash,
          };

          modifiedPaymentList.push(objCash);
        }

        let totalDiscount = 0;

        if (
          !!listVoucher &&
          listVoucher.length > 0 &&
          (listVoucher[0].voucher.is_voucher_payment === 0 ||
            listVoucher[0].voucher.type === 2)
        ) {
          const treatment = listTreatment;
          treatment.forEach(({ discountTotal }) => {
            totalDiscount += discountTotal;
          });
        } else if (
          !!listVoucher &&
          listVoucher.length > 0 &&
          listVoucher[0].voucher.is_voucher_payment === 1
        ) {
          totalDiscount = totalVoucher;
        }

        const payload = {
          receipt: {
            ...payment,
            id: receiptId,
            receiptNumber: receiptNumber,
            paymentDate: moment().format('YYYY-MM-DD'),
            paymentDiscount: 0,
            tax: 0,
            status:
              (!!insuranceDetail && insuranceStatus === 'approved') ||
              !insuranceDetail
                ? 'PAID'
                : 'CONFIRMED',
            cashierName: localStorage.getItem('name')
              ? localStorage.getItem('name')
              : '',
            modifiedBy: localStorage.getItem('id')
              ? parseInt(localStorage.getItem('id'))
              : 0,
            jenisKunjungan: {
              id: treatmentCategoryGroup,
            },
          },
          slipTreatmentList: modifiedTreatmentList,
          receiptDetailList: modifiedPaymentList,
        };

        if (
          !!listVoucher &&
          listVoucher.length > 0 &&
          totalDiscount > 0
        ) {
          if (handleVoucherMultipleTreatment(listVoucher[0])) {
            try {
              payload.discountReceiptList = [
                {
                  discountName:
                    !!listVoucher && listVoucher.length > 0
                      ? listVoucher[0].voucher.name
                      : null,
                  discountCode:
                    !!listVoucher && listVoucher.length > 0
                      ? listVoucher[0].code
                      : null,
                  totalDiscount: totalDiscount,
                  patientName: patientDetail.patientName,
                  paymentDate: moment().format('YYYY-MM-DD'),
                  paymentDiscount:
                    !!listVoucher && listVoucher.length > 0
                      ? listVoucher[0].voucher.is_voucher_payment ===
                          0 || listVoucher[0].voucher.type === 2
                        ? false
                        : true
                      : null,
                },
              ];

              const payloadVoucher = {
                voucher_code: !isVoucherUsingUniqueCode
                  ? listVoucher[0].voucher.name
                  : listVoucher[0].code,
                voucher_name: listVoucher[0].voucher.name,
                id_customer: patientDetail.patient.beId,
                id_clinic: localStorage.getItem('clinicId')
                  ? parseInt(localStorage.getItem('clinicId'), 10)
                  : 0,
                id_clinic_group: localStorage.getItem('clinicGroup')
                  ? localStorage.getItem('clinicGroup').split(',')
                  : 0,
                has_unique_code: isVoucherUsingUniqueCode ? 1 : 0,
              };

              const responseVoucher = await VouchersApi.useVoucher(
                payloadVoucher
              );

              if (responseVoucher.data.status === 'success')
                try {
                  const response = await TransactionApi.submitPayment(
                    payload,
                    appointmentId,
                    patientDetail.patient.id
                  );

                  if (response.data.resultCode === '00')
                    history.push(
                      `/kasir/kwitansi/${appointmentId}_${response.data.data.receipt.receiptNumber}`
                    );
                  else
                    Modal.warning({
                      title: 'Perhatian',
                      content: 'Terjadi kesalahan pada server.',
                    });
                } catch (error) {
                  Modal.warning({
                    title: 'Perhatian',
                    content: 'Terjadi kesalahan pada server.',
                  });
                }
              else
                Modal.warning({
                  title: 'Perhatian',
                  content: 'Kode voucher tidak bisa digunakan.',
                });
            } catch (error) {
              Modal.warning({
                title: 'Perhatian',
                content: 'Kode voucher tidak bisa digunakan.',
              });
            }
          } else {
            Modal.warning({
              title: 'Perhatian',
              content: 'Voucher hanya bisa diapply untuk satu item.',
            });
          }
        } else {
          try {
            const response = await TransactionApi.submitPayment(
              payload,
              appointmentId,
              patientDetail.patient.id
            );

            if (response.data.resultCode === '00') {
              history.push(
                `/kasir/kwitansi/${appointmentId}_${response.data.data.receipt.receiptNumber}`
              );
            } else
              Modal.warning({
                title: 'Perhatian',
                content: 'Terjadi kesalahan pada server.',
              });
          } catch (error) {
            Modal.warning({
              title: 'Perhatian',
              content: 'Terjadi kesalahan pada server.',
            });
          }
        }
      }
    } finally {
      setLoadingPage(false);
    }
  };

  const handleVoucherTreatmentAvailable = () => {
    const voucher = !!listVoucher && listVoucher[0];
    const treatment = listTreatment;

    if (!!voucher) {
      const { voucher_terms } = voucher;

      // tambahin logic cek id_master_voucher_term, id 3 atau tidak ada 3
      const voucherTermsTreatment = voucher_terms.filter(
        (item) => item.id_master_voucher_term === 3
      );

      if (voucherTermsTreatment.length > 0) {
        const availableTreatment = voucherTermsTreatment.map(
          (item) => item.value
        );
        const existingTreatment = treatment.map((item) => item.treatmentId);

        const isVoucherAvailable = existingTreatment.some(
          (r) => availableTreatment.indexOf(r) >= 0
        );

        return isVoucherAvailable;
      } else {
        return true;
      }
    }
    return false;
  };

  //search
  const onSearchChange = (e) => {
    const convertValue = e.target.value.toString().toLowerCase();
    const labelValue = e.target.value;
    setSearch(labelValue);

    const filteredData = listVoucherAll.filter((item) => {
      return (
        item.amount.toString().includes(convertValue) ||
        item.name.toString().toLowerCase().includes(convertValue)
      );
    });

    setListFilteredVoucherAll(filteredData);
  };

  // handle flow insurance
  const handleInsuranceFlow = (status) => {
    switch (status) {
      case 'waiting':
        if (isInsuranceSubmitClaimValid()) handleInsuranceWaiting();
        break;
      case 'inprogress':
        if (isInsuranceSubmitDocumentsValid()) handleInsuranceInProgress();
        break;
      case 'approved':
        handleInsuranceApproved();
        break;
      case 'revised':
        handleInsuranceRevised();
        break;
      default:
        break;
    }
  };

  const handleInsuranceWaiting = async () => {
    try {
      setIsInsuranceSubmitClaimLoading(true);

      const treatment = listTreatment.map((item) => ({
        id: item.id,
        name: item.description,
        total: item.totalPrice,
        limit: item.insuranceExcessLimit,
        excess: item.insuranceExcessTotal,
        discountTotal: item.discountTotal ? item.discountTotal : 0,
      }));

      let totalDiscount = 0;

      if (!!listVoucher && listVoucher.length > 0 && (listVoucher[0].voucher.is_voucher_payment !== 1 || listVoucher[0].voucher.type === 2)) {
        const treatmentList = listTreatment;
        treatmentList.forEach(({ discountTotal }) => {
          totalDiscount += discountTotal;
        });
      }


      const payload = {
        company: {
          id: insuranceCompany,
        },
        provider: {
          id: insuranceProvider,
        },
        patientInsuranceNumber: insurancePatientID,
        treatments: treatment,
        lastUpdatedBy: localStorage.getItem('name')
        ? localStorage.getItem('name')
        : '',
      };

      if (!!listVoucher && listVoucher.length > 0 && totalDiscount > 0 && listVoucher[0].voucher.is_voucher_payment !== 1) {
        const payloadVoucher = {
          voucher_code : !isVoucherUsingUniqueCode ? listVoucher[0].voucher.name : listVoucher[0].code,
          voucher_name : listVoucher[0].voucher.name,
          id_customer : patientDetail.patient.beId,
          id_clinic : localStorage.getItem('clinic') ? parseInt(localStorage.getItem('clinic'),10) : 0,
          id_clinic_group : localStorage.getItem('clinicGroup') ? localStorage.getItem('clinicGroup').split(',') : 0,
          has_unique_code : isVoucherUsingUniqueCode ? 1 : 0
        };

        const responseVoucher = await VouchersApi.useVoucher(
          payloadVoucher
        );
        if (responseVoucher.data.status === 'success') {
          payload.discountReceiptList = [
            {
              discountName : !!listVoucher && listVoucher.length > 0 ? listVoucher[0].voucher.name : null,
              discountCode : !!listVoucher && listVoucher.length > 0 ? listVoucher[0].code : null,
              totalDiscount : totalDiscount,
              patientName : patientDetail.patientName,
              paymentDate : moment().format('YYYY-MM-DD'),
              paymentDiscount : false,
            }
          ];
        } else {
          return Modal.warning({
            title : 'Perhatian',
            content : 'Kode voucher tidak dapat digunakan',
          });
        }
      }

      const response = await InsuranceApi.submitInsuranceClaim(
        payload,
        receiptId
      );

      if (response.data.status === 'SUCCESS') {
        handleDiscountReset();
        setDiscountCoupon();
        setListAppliedCoupon([]);

        const insurance = await getInsuranceDetail(receiptId);
        getPatientDetail();

        window.open(
          `/kasir/invoice-insurance/${appointmentId}_${insurance.receiptNumber}`
        );
      } else {
        Modal.warning({
          title: 'Perhatian',
          content: 'Terjadi kesalahan pada server.',
        });
      }
    } catch (error) {
      Modal.warning({
        title: 'Perhatian',
        content: 'Terjadi kesalahan pada server.',
      });
    } finally {
      setIsInsuranceSubmitClaimLoading(false);
    }
  };

  const handleInsuranceInProgress = () => {
    updateInsuranceStatus('DOCUMENTS_IN_PROGRESS');
  };

  const handleInsuranceApproved = () => {
    updateInsuranceStatus('APPROVED_BY_INSURANCE');
    setIsInsuranceApproveModalShown(false);
  };

  const handleInsuranceRevised = () => {
    updateInsuranceStatus('WAITING_FOR_DOCUMENTS');
    setIsInsuranceReviseModalShown(false);
  };

  const updateInsuranceStatus = async (status) => {
    try {
      if (status === 'DOCUMENTS_IN_PROGRESS')
        setIsInsuranceSubmitDocumentsLoading(true);
      else if (status === 'APPROVED_BY_INSURANCE')
        setIsInsuranceApproveDocumentsLoading(true);
      else if (status === 'WAITING_FOR_DOCUMENTS')
        setIsInsuranceReviseDocumentsLoading(true);

      const payload = {
        status,
        lastUpdatedBy: localStorage.getItem('name')
          ? localStorage.getItem('name')
          : '',
      };

      if (status === 'APPROVED_BY_INSURANCE') {
        payload.jenisKunjungan = {
          id: treatmentCategoryGroup,
        };
        payload.cashierName = localStorage.getItem('name')
          ? localStorage.getItem('name')
          : '';
      }

      let canProceedToPayment = true;

      if (status === 'APPROVED_BY_INSURANCE' && !treatmentCategoryGroup) {
        canProceedToPayment = false;
      }

      if (canProceedToPayment) {
        const response = await InsuranceApi.updateInsuranceStatus(
          payload,
          receiptId
        );

        if (response.data.status === 'SUCCESS') {
          getInsuranceDetail(receiptId);

          if (status === 'APPROVED_BY_INSURANCE') {
            history.push(`/kasir/kwitansi/${appointmentId}_${receiptNumber}`);
          }
        } else {
          Modal.warning({
            title: 'Perhatian',
            content: 'Terjadi kesalahan pada server.',
          });
        }
      } else {
        Modal.warning({
          title: 'Tidak Dapat Melanjutkan Pembayaran',
          content: 'Anda harus mengisi field Jenis Kunjungan',
        });
      }
    } catch (error) {
      Modal.warning({
        title: 'Perhatian',
        content: 'Terjadi kesalahan pada server.',
      });
    } finally {
      if (status === 'DOCUMENTS_IN_PROGRESS')
        setIsInsuranceSubmitDocumentsLoading(false);
      else if (status === 'APPROVED_BY_INSURANCE')
        setIsInsuranceApproveDocumentsLoading(false);
      else if (status === 'WAITING_FOR_DOCUMENTS')
        setIsInsuranceReviseDocumentsLoading(false);
    }
  };

  // get insurance detail
  const getInsuranceDetail = async (receiptId) => {
    try {
      const response = await InsuranceApi.getInsuranceDetail(receiptId);

      let insurance = response.data.data;
      setInsuranceDetail(insurance);

      if (insurance && insurance.receiptNumber) {
        if (insurance.receiptNumber !== receiptNumber) {
          setReceiptNumber(insurance.receiptNumber);
        }
      }
      return insurance;

    } catch (error) {
      Modal.warning({
        title: 'Perhatian',
        content: 'Terjadi kesalahan pada server.',
      });
      console.log(error);
    }
  };

  // insurance claim
  const getInsuranceCompanyList = async () => {
    try {
      setLoadingPage(true);

      const response = await InsuranceApi.getListInsuranceCompany();

      let insuranceList = response.data.data;

      const insuranceListOpts = insuranceList.map((value) => ({
        label: value.name,
        value: value.id,
      }));

      setListInsuranceCompany(insuranceList);
      setListInsuranceCompanyOptions(insuranceListOpts);

      const insuranceProviderList = insuranceList[0].providers;

      const insuranceProviderListOpts = insuranceProviderList.map((value) => ({
        label: value.name,
        value: value.id,
      }));

      setListInsuranceProviderOptions(insuranceProviderListOpts);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleInsuranceCompanyChange = (value) => {
    const chosenInsuranceCompany = listInsuranceCompany.filter(
      (item) => item.name === insuranceCompany
    );

    const insuranceProviderList = chosenInsuranceCompany.provider;

    const insuranceProviderOpts = insuranceProviderList.map((value) => ({
      label: value.name,
      value: value.id,
    }));

    setInsuranceCompany(value);
    setListInsuranceProviderOptions(insuranceProviderOpts);
  };

  const handleInsuranceProviderChange = (value) => {
    setInsuranceProvider(value);
  };

  const handleInsurancePatientIDChange = (e) => {
    setInsurancePatientID(e.target.value);
  };

  // insurance waiting for documents
  const uploadInsuranceDocumentSingle = async (file, docType) => {
    try {
      if (docType === 'loa') setIsInsuranceSubmitLoaDocumentsLoading(true);
      else if (docType === 'loc') setIsInsuranceSubmitLocDocumentsLoading(true);
      else if (docType === 'support')
        setIsInsuranceSubmitSupportingDocumentsLoading(true);

      const formData = new FormData();

      switch (docType) {
        case 'loa':
          formData.append('loaDocument', file);
          break;
        case 'loc':
          formData.append('locDocument', file);
          break;
        case 'support':
          formData.append('otherDocument', file);
          break;
        default:
          break;
      }

      const lastUpdatedBy = localStorage.getItem('name')
        ? localStorage.getItem('name')
        : '';

      const response = await InsuranceApi.submitInsuranceDocuments(
        formData,
        receiptId,
        lastUpdatedBy
      );

      if (response.data.resultCode === '00') {
        getInsuranceDetail(receiptId);
      } else {
        Modal.warning({
          title: 'Perhatian',
          content: 'Terjadi kesalahan pada server.',
        });
      }
    } catch (error) {
      Modal.warning({
        title: 'Perhatian',
        content: 'Terjadi kesalahan pada server.',
      });
      console.log(error);
    } finally {
      if (docType === 'loa') setIsInsuranceSubmitLoaDocumentsLoading(false);
      else if (docType === 'loc')
        setIsInsuranceSubmitLocDocumentsLoading(false);
      else if (docType === 'support')
        setIsInsuranceSubmitSupportingDocumentsLoading(false);
    }
  };

  const handleInsuranceDocsRemove = (file, docType) => {
    switch (docType) {
      case 'loa':
      case 'loc':
        deleteInsuranceLoaLocDocument(docType);
        break;
      case 'support':
        deleteInsuranceSupportDocument(file);
        break;
      default:
        break;
    }
  };

  const deleteInsuranceLoaLocDocument = async (docType) => {
    try {
      let payload = {};

      if (docType === 'loa') {
        payload = {
          ...insuranceDetail,
          loaUrl: null,
          loaFilename: null,
        };
      } else if (docType === 'loc') {
        payload = {
          ...insuranceDetail,
          locUrl: null,
          locFilename: null,
        };
      }

      const response = await InsuranceApi.deleteInsuranceLoaLocDocuments(
        payload,
        receiptId
      );

      if (response.data.resultCode === '00') {
        getInsuranceDetail(receiptId);
      } else {
        Modal.warning({
          title: 'Perhatian',
          content: 'Terjadi kesalahan pada server.',
        });
      }
    } catch (error) {
      Modal.warning({
        title: 'Perhatian',
        content: 'Terjadi kesalahan pada server.',
      });
      console.log(error);
    }
  };

  const deleteInsuranceSupportDocument = async (file) => {
    try {
      const lastUpdatedBy = localStorage.getItem('name')
        ? localStorage.getItem('name')
        : '';

      const response = await InsuranceApi.deleteInsuranceSupportDocuments(
        receiptId,
        lastUpdatedBy,
        file.uid
      );

      if (response.data.resultCode === '00') {
        getInsuranceDetail(receiptId);
      } else {
        Modal.warning({
          title: 'Perhatian',
          content: 'Terjadi kesalahan pada server.',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isInsuranceSubmitClaimValid = () => {
    let isValid = true;

    if (!insuranceProvider) {
      isValid = false;
      message.warn('Silahkan isi Provider', 3);
    }

    if (!insurancePatientID) {
      isValid = false;
      message.warn('Silahkan isi Patient ID', 3);
    }

    return isValid;
  };

  const isInsuranceSubmitDocumentsValid = () => {
    let isValid = true;

    if (listFileInsuranceLOA.length === 0) {
      isValid = false;
      message.warn('Silahkan upload file LoA', 3);
    }

    if (listFileInsuranceLOC.length === 0) {
      isValid = false;
      message.warn('Silahkan upload file LoC', 3);
    }

    if (listFileInsuranceSupportingDocs.length === 0) {
      isValid = false;
      message.warn('Silahkan upload file Supporting Documents', 3);
    }

    return isValid;
  };

  const useInitData = () => {
    if (appointmentId) {
      getPatientDetail();
    }

    getTreatmentCategoryGroupOptionsList();

    getEdcList();
    getBankList();
    getMarketplaceList();
    // getVoucherAll(beId);
    getInsuranceCompanyList();

    // to assign value
    // setListDiscount([]);

  };

  const useCountTotal = () => {
    const newTreatmentPayment = [...listTreatmentPayment];
    let totalPayment = 0;
    let totalChange = 0;
    let totalGrandTotal = 0;
    let totalVoucher = 0;
    let totalFinal = 0;
    let totalItemWithTax = 0;

    const treatment = [...listTreatment];
    let totalTreatmentPrice = 0;
    let totalTreatmentItem = 0;

    const creditDebit = [...listCreditDebit];
    let totalCreditDebit = 0;

    const marketplace = [...listMarketplace];
    let totalMarketplace = 0;

    const transfer = [...listTransfer];
    let totalTransfer = 0;

    treatment.forEach(({ totalPrice }) => {
      totalTreatmentPrice += totalPrice;
      totalTreatmentItem += 1;
    });

    let totalImplantTreatment = 0;
    treatment.forEach((item) => {
      if (item.treatmentType === 'IMPLANT') {
        totalImplantTreatment += item.totalPrice;
      }
    });
    setMaxAdditionalDiscount(totalImplantTreatment);
    if (maxAdditionalDiscount < additionalDiscount) setAdditionalDiscount(maxAdditionalDiscount);
    // setTotalTreatment(totalTreatmentPrice);

    creditDebit.forEach(({ amount }) => {
      totalCreditDebit += amount;
    });

    marketplace.forEach(({ amount }) => {
      totalMarketplace += amount;
    });

    transfer.forEach(({ amount }) => {
      totalTransfer += amount;
    });

    let totalMidtrans = 0
    for (let midtrans of listMidtrans) {
      totalMidtrans += midtrans.totalPayment;
    }

    totalPayment =
      totalCash + totalCreditDebit + totalMarketplace + totalTransfer + totalMidtrans;

    setTotalPaymentReceived(totalPayment);

    // Voucher
    const voucher = !!listVoucher && listVoucher[0];

    const isVoucherTreatmentAvailable = handleVoucherTreatmentAvailable();

    if (!!voucher) {
      const minAmount = voucher.voucher.min_transaction;
      const maxAmount = voucher.voucher.max_applied_amount;

      if (voucher.voucher_treatment_type === 'IMPLANT' || voucher.voucher_treatment_type === 'GENERAL') {
        treatment.forEach((val) => {
          if (val.treatmentType === 'IMPLANT') {
            totalTreatmentPrice -= val.totalPrice;
          }
        }); 
      }
      if (
        voucher.is_voucher_payment === 1 &&
        voucher.discount_type === 1 &&
        isVoucherTreatmentAvailable // diskon pembayaran nominal
      ) {
        // logic diskon pembayaran nominal
        const amountVoucher = voucher.voucher.amount;

        totalVoucher =
          totalTreatmentPrice >= minAmount
            ? amountVoucher > totalTreatmentPrice
              ? totalTreatmentPrice
              : amountVoucher
            : 0;
      } else if (
        voucher.voucher.is_voucher_payment === 1 &&
        voucher.voucher.discount_type === 2 &&
        isVoucherTreatmentAvailable // diskon pembayaran persentase
      ) {
        // logic diskon pembayaran persentase
        const amountVoucher = parseInt(
          (totalTreatmentPrice * voucher.voucher.amount) / 100
        );

        totalVoucher =
          totalTreatmentPrice >= minAmount && amountVoucher <= maxAmount
            ? amountVoucher > totalTreatmentPrice
              ? totalTreatmentPrice
              : amountVoucher
            : 0;
      }

      treatment.forEach((item) => {
        if (item.withTax) totalItemWithTax += item.totalPrice;
      });

      if (voucher.voucher_treatment_type === 'IMPLANT' || voucher.voucher_treatment_type === 'GENERAL') {
        treatment.forEach((val) => {
          if (val.treatmentType === 'IMPLANT') {
            totalTreatmentPrice += val.totalPrice;
          }
        });
      }
    }

    if (totalTreatmentPrice - totalItemWithTax < totalVoucher)
      totalVoucher -= totalItemWithTax;

    totalGrandTotal = totalTreatmentPrice - totalVoucher - additionalDiscount;

    setTotalVoucher(totalVoucher);

    // Insurance
    const newInsuranceExcess = [...excessInsuranceTotal];
    let totalInsuranceExcess = 0;
    let totalInsuranceLimit = 0;

    treatment.forEach(({ insuranceExcessLimit, insuranceExcessTotal }) => {
      totalInsuranceLimit += insuranceExcessLimit;
      totalInsuranceExcess += insuranceExcessTotal;
    });

    newInsuranceExcess[0].column7 = totalInsuranceExcess;

    setExcessInsuranceTotal(newInsuranceExcess);

    totalFinal = totalGrandTotal - totalInsuranceLimit;

    setTotalFinal(totalFinal);

    if (totalPayment > (totalFinal - amountPaid)) totalChange = totalPayment - (totalFinal - amountPaid);

    // Total Item
    if (!treatment.filter((item) => item.treatmentType === 'IMPLANT').length) {
      let indexAddDisc = newTreatmentPayment.findIndex(x => x.column6 === 'Diskon Tambahan');
      if (indexAddDisc !== -1) {
        let indexTax = newTreatmentPayment.findIndex((item) => item.column6 === 'Pajak 0%');
        newTreatmentPayment[indexTax].key--;
        // Covered by Insurance
        let indexInsurance = newTreatmentPayment.findIndex((item) => item.column6 === 'Covered by Insurance');
        newTreatmentPayment[indexInsurance].key--;
        // Total Tagihan
        let indexTotalTagihan = newTreatmentPayment.findIndex((item) => item.column6 === 'Total Tagihan');
        newTreatmentPayment[indexTotalTagihan].key--;
        // Sisa Tagihan
        let indexSisaTagihan = newTreatmentPayment.findIndex((item) => item.column6 === 'Sisa Tagihan');
        newTreatmentPayment[indexSisaTagihan].key--;
        // Total Kembali
        let indexTotalKembali = newTreatmentPayment.findIndex((item) => item.column6 === 'Total Kembali');
        newTreatmentPayment[indexTotalKembali].key--;
        newTreatmentPayment.splice(indexAddDisc,1);
        setAdditionalDiscount(0);
      }
    }
    newTreatmentPayment[0].column1 = `Jumlah Item (${totalTreatmentItem})`;

    // Total Price
    newTreatmentPayment[0].column7 = totalTreatmentPrice;

    // Voucher
    newTreatmentPayment[1].column7 = totalVoucher;

    // Excess Insurance
    let indexInsurance = newTreatmentPayment.findIndex(x => x.column6 === 'Covered by Insurance');
    if (insuranceStatus === '') {
      newTreatmentPayment[indexInsurance].column7 = 0;
    } else {
      newTreatmentPayment[indexInsurance].column7 = totalInsuranceLimit;
    }

    let indexAddDisc = newTreatmentPayment.findIndex((x) => x.column6 === 'Diskon Tambahan');
    if (indexAddDisc !== -1) {
      newTreatmentPayment[indexAddDisc].column7 = <InputNumber 
                                                    className='skybase-dashboard__search-input' 
                                                    onChange={handleAdditionalDiscountChange} 
                                                    formatter={value => `Rp ${currencyFormatter(value)}`}
                                                    parser={currencyParser} 
                                                    style={{textAlign: 'right'}}
                                                    defaultValue={additionalDiscount}
                                                    value={additionalDiscount}
                                                    max={maxAdditionalDiscount}
                                                    disabled={insuranceStatus !== '' || paymentLinkStatus}
                                                  />
    }

    // Total Payment
    let index = newTreatmentPayment.findIndex(x => x.column6 === 'Total Tagihan');
    newTreatmentPayment[index].column7 = totalFinal;

    // Sisa Tagihan
    let indexSisaTagihan = newTreatmentPayment.findIndex(x => x.column6 === 'Sisa Tagihan');
    let sisaTagihan = 0;
    const paidAmount = amountPaid;
    sisaTagihan = Math.max(Math.min(newInsuranceExcess[0].column7 - paidAmount, totalFinal), 0);
    newTreatmentPayment[indexSisaTagihan].column7 = sisaTagihan;


    // Total Change
    let idx = newTreatmentPayment.findIndex(x => x.column6 === 'Total Kembali');
    newTreatmentPayment[idx].column7 = totalChange;

    let objectPayment = {};

    if (newTreatmentPayment[4].column6 === 'Balance Credit') {
      objectPayment = {
        subTotal: totalTreatmentPrice,
        balancePayment : newTreatmentPayment[4].column7,
        grandTotal : totalGrandTotal,
        changeReturn: totalChange,
        amountPaid: totalPayment,
      }
    } else if (newTreatmentPayment[4].column6 === 'Balance Deduction') {
      objectPayment = {
        subTotal : totalTreatmentPrice,
        balancePayment : -newTreatmentPayment[4].column7,
        grandTotal : totalGrandTotal,
        changeReturn: totalChange,
        amountPaid: totalPayment,
      }
    } else if (newTreatmentPayment[2].column6 === 'Diskon Tambahan') {
      objectPayment = {
        subTotal: totalTreatmentPrice,
        additionalDiscount: additionalDiscount,
        grandTotal: totalGrandTotal,
        changeReturn: totalChange,
        amountPaid: totalPayment,
      }
    } else {
      objectPayment = {
        subTotal: totalTreatmentPrice,
        grandTotal: totalGrandTotal,
        changeReturn: totalChange,
        amountPaid: totalPayment,
      };
    }
    setPayment(objectPayment);

    setListTreatmentPayment(newTreatmentPayment);
  };

  const useInitInsurance = () => {
    if (!!insuranceDetail) {
      const status = insuranceDetail.history[0].status;

      switch (status) {
        case 'WAITING_FOR_DOCUMENTS':
          setInsuranceStatus('waiting');
          setInsuranceStatusLabel('Waiting For Documents');
          break;
        case 'DOCUMENTS_IN_PROGRESS':
          setInsuranceStatus('inprogress');
          setInsuranceStatusLabel('Documents in Progress');
          break;
        case 'APPROVED_BY_INSURANCE':
          setInsuranceStatus('approve');
          setInsuranceStatusLabel('Approved By Insurance');
          break;
        case 'DOCUMENTS_IN_REVISION':
          setInsuranceStatus('revise');
          setInsuranceStatusLabel('Waiting For Documents');
          break;
        case 'APPROVED_BY_FINANCE':
          setInsuranceStatus('approved-by-finance');
          setInsuranceStatusLabel('Approved By Finance');
          break;
        default:
          break;
      }

      setInsuranceUpdatedBy(insuranceDetail.lastUpdatedBy);
      setInsuranceUpdatedAt(moment().format('DD MMMM YYYY HH:mm'));
      setInsuranceContact(insuranceDetail.company.contactUrl);
      setInsuranceCompany(insuranceDetail.company.id);
      setInsuranceProvider(insuranceDetail.provider.id);
      setInsurancePatientID(insuranceDetail.patientInsuranceNumber);

      const excessTreatment = [...listTreatment];

      excessTreatment.forEach((treatment) => {
        const insuranceTreatment = insuranceDetail.treatments.find(
          (item) => item.name === treatment.description
        );
        const insuranceTreatmentLimit = insuranceTreatment.limit;
        const insuranceTreatmentTotal = insuranceTreatment.excess;

        treatment.insuranceExcessLimit = insuranceTreatmentLimit;
        treatment.insuranceExcessTotal = insuranceTreatmentTotal;
      });

      setListTreatment(excessTreatment);

      if (insuranceDetail.loaUrl) {
        setListFileInsuranceLOA([
          {
            uid: '1',
            name: insuranceDetail.loaFilename
              ? insuranceDetail.loaFilename
              : 'LoA',
            status: 'done',
            url: insuranceDetail.loaUrl,
          },
        ]);
      } else {
        setListFileInsuranceLOA([]);
      }

      if (insuranceDetail.locUrl) {
        setListFileInsuranceLOC([
          {
            uid: '1',
            name: insuranceDetail.locFilename
              ? insuranceDetail.locFilename
              : 'LoC',
            status: 'done',
            url: insuranceDetail.locUrl,
          },
        ]);
      } else {
        setListFileInsuranceLOC([]);
      }

      if (insuranceDetail.documents && insuranceDetail.documents.length > 0) {
        const supportDocs = [];

        insuranceDetail.documents.forEach((doc) => {
          supportDocs.push({
            uid: doc.id,
            name: doc.filename,
            status: 'done',
            url: doc.url,
          });
        });

        setListFileInsuranceSupportingDocs(supportDocs);
      } else {
        setListFileInsuranceSupportingDocs([]);
      }
    }
  };

  const generatePaymentLink = async () => {
    try {
      setIsPaymentLinkBtnLoading(true);

        let modifiedTreatmentList = [];
        let discountReceiptList = [];

        if (
            !!listVoucher &&
            listVoucher.length > 0 &&
            listVoucher[0].voucher.is_voucher_payment === 1 &&
            listVoucher[0].voucher.type !== 2
        ) {
          let reminderAmount = totalVoucher;

          for (let index = 0; index < listTreatment.length; index++) {
            if (listTreatment[index].treatmentType !== 'IMPLANT') {
              modifiedTreatmentList.push({
                id: listTreatment[index].id,
                treatmentCode: listTreatment[index].treatmentCode,
                quantity: listTreatment[index].quantity,
                currency: 'IDR',
                totalPrice: listTreatment[index].totalPrice,
                unitPrice: listTreatment[index].unitPrice,
                // priceBeforeTax:
                //   parseInt(listTreatment[index].unitPrice) / 1.1,
                description: listTreatment[index].description,
                doctorId: listTreatment[index].doctorId,
                doctorName: listTreatment[index].doctorName,
                discountCode:
                    !!listVoucher && Object.keys(listVoucher).length > 0
                        ? listVoucher.code
                        : null, // ngambil discount code
                // discountTotal: listTreatment[index].discountTotal,
                discountTotal: listTreatment[index].withTax
                    ? 0
                    : reminderAmount > listTreatment[index].totalPrice
                        ? listTreatment[index].totalPrice
                        : reminderAmount > 0
                            ? reminderAmount
                            : 0,
                treatmentId: listTreatment[index].treatmentId,
                treatmentCategoryId:
                listTreatment[index].treatmentCategoryId,
                paymentDiscount:
                    !!listVoucher && Object.keys(listVoucher).length > 0
                        ? !(listVoucher.is_voucher_payment === 0 ||
                        listVoucher.type === 2)
                        : null,
                createdBy: listTreatment[index].createdBy,
              });
  
              if (!listTreatment[index].withTax) reminderAmount = reminderAmount - listTreatment[index].totalPrice;
            } else {
            modifiedTreatmentList.push({
              id: listTreatment[index].id,
              treatmentCode: listTreatment[index].treatmentCode,
              quantity: listTreatment[index].quantity,
              currency: 'IDR',
              totalPrice: listTreatment[index].totalPrice,
              unitPrice: listTreatment[index].unitPrice,
              // priceBeforeTax:
              //   parseInt(listTreatment[index].unitPrice) / 1.1,
              description: listTreatment[index].description,
              doctorId: listTreatment[index].doctorId,
              doctorName: listTreatment[index].doctorName,
              discountCode:
                  !!listVoucher && Object.keys(listVoucher).length > 0
                      ? listVoucher.code
                      : null, // ngambil discount code
              // discountTotal: listTreatment[index].discountTotal,
              discountTotal: 0,
              treatmentId: listTreatment[index].treatmentId,
              treatmentCategoryId:
              listTreatment[index].treatmentCategoryId,
              paymentDiscount:
                  !!listVoucher && Object.keys(listVoucher).length > 0
                      ? !(listVoucher.is_voucher_payment === 0 ||
                      listVoucher.type === 2)
                      : null,
              createdBy: listTreatment[index].createdBy,
            });
            }
            modifiedTreatmentList.push({
              id: listTreatment[index].id,
              treatmentCode: listTreatment[index].treatmentCode,
              quantity: listTreatment[index].quantity,
              currency: 'IDR',
              totalPrice: listTreatment[index].totalPrice,
              unitPrice: listTreatment[index].unitPrice,
              // priceBeforeTax:
              //   parseInt(listTreatment[index].unitPrice) / 1.1,
              description: listTreatment[index].description,
              doctorId: listTreatment[index].doctorId,
              doctorName: listTreatment[index].doctorName,
              discountCode:
                  !!listVoucher && listVoucher.length > 0
                      ? listVoucher[0].code
                      : null, // ngambil discount code
              // discountTotal: listTreatment[index].discountTotal,
              discountTotal: listTreatment[index].withTax
                  ? 0
                  : reminderAmount > listTreatment[index].totalPrice
                      ? listTreatment[index].totalPrice
                      : reminderAmount > 0
                          ? reminderAmount
                          : 0,
              treatmentId: listTreatment[index].treatmentId,
              treatmentCategoryId:
              listTreatment[index].treatmentCategoryId,
              paymentDiscount:
                  !!listVoucher && listVoucher.length > 0
                      ? !(listVoucher[0].voucher.is_voucher_payment === 0 ||
                      listVoucher[0].voucher.type === 2)
                      : null,
              createdBy: listTreatment[index].createdBy,
            });

            if (!listTreatment[index].withTax)
              reminderAmount =
                  reminderAmount - listTreatment[index].totalPrice;
          }
        } else {
          listTreatment.forEach((item) => {
            const objTreatment = {
              id: item.id,
              treatmentCode: item.treatmentCode,
              quantity: item.quantity,
              currency: 'IDR',
              totalPrice: item.totalPrice,
              unitPrice: item.unitPrice,
              // priceBeforeTax: parseInt(item.unitPrice) / 1.1,
              description: item.description,
              doctorId: item.doctorId,
              doctorName: item.doctorName,
              discountCode:
                  !!listVoucher && listVoucher.length > 0
                      ? listVoucher[0].code
                      : null, // ngambil discount code
              discountTotal: item.discountTotal,
              treatmentId: item.treatmentId,
              treatmentCategoryId: item.treatmentCategoryId,
              paymentDiscount:
                  !!listVoucher && listVoucher.length > 0
                      ? !(listVoucher[0].voucher.is_voucher_payment === 0 ||
                      listVoucher[0].voucher.type === 2)
                      : null,
              createdBy: item.createdBy,
              // discountTotal: diskon pembayaran, kurangin harga item dari atas / diskon per tindakan, kurangin sesuai isi diskon / kalo gak ada, 0
            };

            modifiedTreatmentList.push(objTreatment);
          });
        }

        let totalDiscount = 0;

        if (
            !!listVoucher &&
            listVoucher.length > 0 &&
            (listVoucher[0].voucher.is_voucher_payment === 0 ||
                listVoucher[0].voucher.type === 2)
        ) {
          listTreatment.forEach(({ discountTotal }) => {
            totalDiscount += discountTotal;
          });
        } else if (
            !!listVoucher &&
            listVoucher.length > 0 &&
            listVoucher[0].voucher.is_voucher_payment === 1
        ) {
          totalDiscount = totalVoucher;
        }

        let receipt = {
          ...payment,
          id: receiptId,
          receiptNumber: receiptNumber,
          paymentDate: moment().format('YYYY-MM-DD'),
          paymentDiscount: 0,
          tax: 0,
          status:
            (!!insuranceDetail && insuranceStatus === 'approved') ||
            !insuranceDetail
              ? 'PAID'
              : 'CONFIRMED',
          cashierName: localStorage.getItem('name')
            ? localStorage.getItem('name')
            : '',
          modifiedBy: localStorage.getItem('id')
            ? parseInt(localStorage.getItem('id'))
            : 0,
          jenisKunjungan: {
            id: treatmentCategoryGroup,
          }
        }
        if (
            !!listVoucher &&
            listVoucher.length > 0 &&
            totalDiscount > 0
        ) {
          if (handleVoucherMultipleTreatment(listVoucher[0])) {
            try {
              discountReceiptList = [
                {
                  discountName:
                      !!listVoucher && listVoucher.length > 0
                          ? listVoucher[0].voucher.name
                          : null,
                  discountCode:
                      !!listVoucher && listVoucher.length > 0
                          ? listVoucher[0].code
                          : null,
                  totalDiscount: totalDiscount,
                  patientName: patientDetail.patientName,
                  paymentDate: moment().format('YYYY-MM-DD'),
                  paymentDiscount:
                      !!listVoucher && listVoucher.length > 0
                          ? !(listVoucher[0].voucher.is_voucher_payment ===
                          0 || listVoucher[0].voucher.type === 2)
                          : null,
                },
              ];

              const res = await PaymentLinkApi.generatePaymentLink(receiptId, modifiedTreatmentList, discountReceiptList, receipt);
              if (res.data.status === 'SUCCESS') {
                setListPaymentLink([...listPaymentLink, res.data.data]);
              }
            } catch (err) {
              Modal.warning({
                title: 'Perhatian',
                content: 'Kode voucher tidak bisa digunakan.',
              });
            }
          } else {
            Modal.warning({
              title: 'Perhatian',
              content: 'Voucher hanya bisa diapply untuk satu item.',
            });
          }
        } else {
          try {
            const res = await PaymentLinkApi.generatePaymentLink(receiptId, modifiedTreatmentList, discountReceiptList, receipt);
            if (res.data.status === 'SUCCESS') {
              setListPaymentLink([...listPaymentLink, res.data.data]);
            }
          } catch (err) {
            console.error(err);
          }
        }
    } catch (err) {
      console.error(err);
    } finally {
      setIsPaymentLinkBtnLoading(false);
    }
  }

  const loadAppliedDiscounts = async () => {
    if (patientDetail) {
      for (let voucher of patientDetail.receiptList[0].discountReceiptList) {
        if (voucher.discountName === 'TANAM (Custom)') {
          setAdditionalDiscount(voucher.totalDiscount);
        } else {
          await getVoucher(voucher.discountName);
        }
      }
    }
  }

  const loadPaymentLinks = async () => {
    if (receiptId) {
      try {
        setIsRefreshPaylinkBtnLoading(true);
        const res1 = await PaymentLinkApi.getPaymentLinks(receiptId);
        if (res1.data.status === 'SUCCESS') {
          if (res1.data.data.list.length > 0) {
            setListPaymentLink(res1.data.data.list);
          }
        }
        const res2 = await TransactionApi.getReceiptDetailById(patientDetail.receiptList[0].id)
        if (res2.data.status === 'SUCCESS') {
          patientDetail.receiptList[0].receiptDetailList = res2.data.data.receiptDetailList;
        }
        if (patientDetail.receiptList[0].receiptDetailList) {
          const midtransPayments = [];
          for(let receiptDetail of patientDetail.receiptList[0].receiptDetailList) {
            if (receiptDetail.paymentMethod === 'MIDTRANS') {
              midtransPayments.push(receiptDetail);
            } else if (receiptDetail.paymentMethod === 'CASH') {
              if (receiptDetail.totalPayment === 1) {
                midtransPayments.push(receiptDetail);
              }
            }
          }
          setListMidtrans(midtransPayments);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsRefreshPaylinkBtnLoading(false);
      }
    }
  }

  useEffect(useInitData, []);

  useEffect(useCountTotal, [
    listTreatment,
    totalCash,
    listCreditDebit,
    listMarketplace,
    listTransfer,
    listMidtrans,
    listVoucher,
    additionalDiscount
  ]);

  useEffect(useInitInsurance, [insuranceDetail]);

  useEffect(() => {
    loadAppliedDiscounts();
  }, [patientDetail])

  useEffect(() => {
    loadPaymentLinks();
  }, [receiptId])

  useEffect(() => {
    const status = listPaymentLink.length > 0 ? listPaymentLink[0].status : null;
    setPaymentLinkStatus(status);
    setActiveTabKey(status && ['PENDING', 'CONFIRMED', 'SETTLED', 'REFUNDED'].includes(status) ? '5' : '1');
  }, [listPaymentLink])

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout>
      <Content>
        <div className="mb-15">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Point of Sales
              </strong>
            </Col>
          </Row>
        </div>

        <Row>
          <Col span={17}>
            <div className="panel">
              <div className="mb-15">
                <Row>
                  <Col span={8}>Patient</Col>
                  <Col span={8}>
                    : {!!patientDetail ? patientDetail.patient.id : '-'},{' '}
                    {!!patientDetail ? patientDetail.patientName : '-'}
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Registration Number</Col>
                  <Col span={8}>
                    : {!!patientDetail ? patientDetail.registrationNumber : '-'}
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Patient Status</Col>
                  <Col span={8}>
                    : <strong>Pasien Lama</strong>
                  </Col>
                </Row>
              </div>
              <div className="mb-15">
                <Table
                  dataSource={listTreatment}
                  rowKey="key"
                  columns={treatmentColumns(
                    handleTreatmentChange,
                    handleTreatmentDelete,
                    insuranceStatus,
                    paymentLinkStatus
                  )}
                  className="skybase-dashboard__active-order-table"
                  footer={() => (
                    <Table
                      dataSource={listTreatmentPayment}
                      rowKey="key"
                      columns={treatmentPaymentColumns}
                      className="skybase-dashboard__active-order-table"
                      rowClassName={(record) => {
                        if (record.column6 === 'Total Tagihan')
                          return 'table-footer__summary-total-payment';
                        else if (record.column6 === 'Total Kembali')
                          return 'table-footer__summary-total-change';
                        else if (record.column6 === 'Sisa Tagihan')
                          return 'table-footer__summary-sisa-tagihan';
                        else return '';
                      }}
                      showHeader={false}
                      pagination={false}
                      size="small"
                    />
                  )}
                  pagination={false}
                  size="small"
                />
              </div>
              <div className="mb-15">
                <Row>
                  <Col span={24}>Diskon Pembayaran</Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <div className="mb-15">
                      <div className="skybase-dashboard__search">
                        <Input
                          placeholder="Kode Kupon"
                          onChange={handleDiscountCouponChange}
                          value={discountCoupon}
                          disabled={insuranceStatus !== '' || paymentLinkStatus}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className="mb-15">
                      <div className="skybase-dashboard__search">
                        <Button
                          onClick={() => {
                            handleDiscountReset();
                            setDiscountCoupon();
                            setListAppliedCoupon([]);
                          }}
                          style={{
                            color: '#fff',
                            backgroundColor: '#36c6d3',
                            borderColor: '#2bb8c4',
                          }}
                          disabled={insuranceStatus !== ''}>
                          <strong>RESET</strong>
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="mb-15">
                <Tabs activeKey={activeTabKey} onChange={activeKey => {setActiveTabKey(activeKey)}}>
                  <TabPane tab="Cash" key="1" disabled={['PENDING', 'CONFIRMED', 'SETTLED', 'REFUNDED'].includes(paymentLinkStatus)}>
                    <Row type="flex" style={{ justifyContent: 'center' }}>
                      <div className="mb-15">
                        <Button
                          style={{
                            backgroundColor: '#c49f47',
                            borderColor: '#c49f47',
                            color: 'white',
                          }}
                          size="large"
                          onClick={() => handleTotalCashClick(500)}>
                          500
                        </Button>
                        <Button
                          style={{
                            backgroundColor: '#95A5A6',
                            borderColor: '#95A5A6',
                            color: 'white',
                          }}
                          size="large"
                          onClick={() => handleTotalCashClick(1000)}>
                          1.000
                        </Button>
                        <Button
                          style={{
                            backgroundColor: '#F4D03F',
                            borderColor: '#F4D03F',
                            color: 'white',
                          }}
                          size="large"
                          onClick={() => handleTotalCashClick(5000)}>
                          5.000
                        </Button>
                        <Button
                          style={{
                            backgroundColor: '#8E44AD',
                            borderColor: '#8E44AD',
                            color: 'white',
                          }}
                          size="large"
                          onClick={() => handleTotalCashClick(10000)}>
                          10.000
                        </Button>
                        <Button
                          style={{
                            backgroundColor: '#4c87b9',
                            borderColor: '#4c87b9',
                            color: 'white',
                          }}
                          size="large"
                          onClick={() => handleTotalCashClick(50000)}>
                          50.000
                        </Button>
                        <Button
                          style={{
                            backgroundColor: '#D91E18',
                            borderColor: '#D91E18',
                            color: 'white',
                          }}
                          size="large"
                          onClick={() => handleTotalCashClick(100000)}>
                          100.000
                        </Button>
                        <Button
                          style={{
                            backgroundColor: '#2f353b',
                            borderColor: '#2f353b',
                            color: 'white',
                          }}
                          size="large"
                          onClick={() => {
                            handleTotalCashClick('FULL');
                          }}>
                          FULL BAYAR
                        </Button>
                      </div>
                    </Row>
                    <Row>
                      <div className="mb-15">
                        Jumlah Uang Tunai
                        <InputNumber
                          className="skybase-dashboard__search-input"
                          onChange={handleTotalCashChange}
                          value={totalCash}
                          formatter={currencyFormatter}
                          parser={currencyParser}
                          style={{ textAlign: 'right' }}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div>
                        <Button
                          onClick={postSubmit}
                          type="primary"
                          style={{
                            width: '100%',
                          }}>
                          <strong>SIMPAN DAN BAYAR</strong>
                        </Button>
                      </div>
                    </Row>
                  </TabPane>
                  <TabPane tab="Debit/Credit" key="2" disabled={['PENDING', 'CONFIRMED', 'SETTLED', 'REFUNDED'].includes(paymentLinkStatus)}>
                    <Table
                      dataSource={listCreditDebit}
                      rowKey="key"
                      columns={creditCardPaymentColumns(
                        listEdcOptions,
                        listBankOptions,
                        handleCreditDebitChange,
                        handleCreditDebitDelete
                      )}
                      footer={() => (
                        <Button
                          onClick={() => addNewPayment('creditDebit')}
                          type="primary"
                          style={{
                            width: '100%',
                          }}>
                          <strong>+</strong>
                        </Button>
                      )}
                      pagination={false}
                      className="skybase-dashboard__active-order-table"
                      size="small"
                    />
                    <Row>
                      <div className="mt-15">
                        <Button
                          onClick={postSubmit}
                          type="primary"
                          style={{
                            width: '100%',
                          }}>
                          <strong>SIMPAN DAN BAYAR</strong>
                        </Button>
                      </div>
                    </Row>
                  </TabPane>
                  <TabPane tab="Market Place" key="3" disabled={['PENDING', 'CONFIRMED', 'SETTLED', 'REFUNDED'].includes(paymentLinkStatus)}>
                    <Table
                      dataSource={listMarketplace}
                      rowKey="key"
                      columns={marketplacePaymentColumns(
                        listMarketplaceOptions,
                        handleMarketplaceChange,
                        handleMarketplaceDelete
                      )}
                      footer={() => (
                        <Button
                          onClick={() => addNewPayment('marketplace')}
                          type="primary"
                          style={{
                            width: '100%',
                          }}>
                          <strong>+</strong>
                        </Button>
                      )}
                      pagination={false}
                      className="skybase-dashboard__active-order-table"
                      size="small"
                    />
                    <Row>
                      <div className="mt-15">
                        <Button
                          onClick={postSubmit}
                          type="primary"
                          style={{
                            width: '100%',
                          }}>
                          <strong>SIMPAN DAN BAYAR</strong>
                        </Button>
                      </div>
                    </Row>
                  </TabPane>
                  <TabPane tab="Transfer" key="4" disabled={['PENDING', 'CONFIRMED', 'SETTLED', 'REFUNDED'].includes(paymentLinkStatus)}>
                    <Table
                      dataSource={listTransfer}
                      rowKey="key"
                      columns={transferPaymentColumns(
                        listBankOptions,
                        handleTransferChange,
                        handleTransferDelete
                      )}
                      footer={() => (
                        <Button
                          onClick={() => addNewPayment('transfer')}
                          type="primary"
                          style={{
                            width: '100%',
                          }}>
                          <strong>+</strong>
                        </Button>
                      )}
                      pagination={false}
                      className="skybase-dashboard__active-order-table"
                      size="small"
                    />
                    <Row>
                      <div className="mt-15">
                        <Button
                          onClick={postSubmit}
                          type="primary"
                          style={{
                            width: '100%',
                          }}>
                          <strong>SIMPAN DAN BAYAR</strong>
                        </Button>
                      </div>
                    </Row>
                  </TabPane>
                  <TabPane tab="Payment Link" key="5">
                    <div className="mb-15">
                      {listPaymentLink.length === 0 ?
                      <Button
                        onClick={generatePaymentLink}
                        loading={isPaymentLinkBtnLoading}
                        style={{
                          backgroundColor: '#6067E6',
                          color: 'white',
                          borderColor: '#6067E6',
                        }}>
                        Generate Payment Link
                      </Button> :
                      <Button
                          onClick={loadPaymentLinks}
                          loading={isRefreshPaylinkBtnLoading}
                          style={{
                            backgroundColor: '#6067E6',
                            color: 'white',
                            borderColor: '#6067E6',
                          }}>
                        Refresh Status
                      </Button>
                      }
                    </div>
                    <Table
                      dataSource={listPaymentLink}
                      rowKey="key"
                      columns={paymentLinkColumns(handleResetPaymentLink)}
                      pagination={false}
                      className="skybase-dashboard__active-order-table"
                      size="small"
                    />
                    <Row>
                      <div className="mt-15">
                        <Button
                          onClick={postSubmit}
                          type="primary"
                          style={{
                            width: '100%',
                          }}>
                          <strong>SIMPAN DAN BAYAR</strong>
                        </Button>
                      </div>
                    </Row>
                  </TabPane>
                  <TabPane tab="Insurance" key="6" disabled={['PENDING', 'CONFIRMED', 'SETTLED', 'REFUNDED'].includes(paymentLinkStatus)}>
                    <div className="mb-15">
                      <Row>
                        <Col span={8}>Claim Status</Col>
                        <Col span={8}>
                          :{' '}
                          {!!insuranceStatusLabel ? (
                            <span
                              style={
                                insuranceStatus === 'approve'
                                  ? { color: 'green' }
                                  : {}
                              }>
                              {insuranceStatusLabel}
                            </span>
                          ) : (
                            '-'
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>Updated By</Col>
                        <Col span={8}>
                          : {!!insuranceUpdatedBy ? insuranceUpdatedBy : '-'}{' '}
                          <span style={{ color: 'grey' }}>
                            {!!insuranceUpdatedAt ? insuranceUpdatedAt : ''}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>Insurance Contact</Col>
                        <Col span={8}>
                          :{' '}
                          {insuranceContact ? (
                            <a
                              href={insuranceContact}
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: 'blue' }}>
                              <u>Insurance Contact.pdf</u>
                            </a>
                          ) : (
                            '-'
                          )}
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-15">
                      <Row gutter={[16, 8]}>
                        <Col span={8}>
                          Insurance Company
                          <div className="mb-15 skybase-dashboard__search">
                            <SelectDropdown
                              options={listInsuranceCompanyOptions}
                              placeHolder="Select Insurance Company"
                              onChange={handleInsuranceCompanyChange}
                              value={insuranceCompany}
                              allowClear
                              disabled
                            />
                          </div>
                        </Col>
                        <Col span={8}>
                          <span style={{ color: 'red' }}>*</span> Provider
                          <div className="mb-15 skybase-dashboard__search">
                            <SelectDropdown
                              options={listInsuranceProviderOptions}
                              placeHolder="Select Provider"
                              onChange={handleInsuranceProviderChange}
                              value={insuranceProvider}
                              allowClear
                              disabled={insuranceStatus !== ''}
                            />
                          </div>
                        </Col>
                        <Col span={8}>
                          <span style={{ color: 'red' }}>*</span> Patient
                          Insurance ID Number
                          <div className="mb-15 skybase-dashboard__search">
                            <Input
                              placeholder="Insert Patient Insurance ID Number"
                              onChange={handleInsurancePatientIDChange}
                              value={insurancePatientID}
                              disabled={insuranceStatus !== ''}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-15">
                      <Table
                        dataSource={listTreatment}
                        rowKey="key"
                        columns={excessInsuranceColumns(
                          handleTreatmentChange,
                          insuranceStatus
                        )}
                        footer={() => (
                          <Table
                            dataSource={excessInsuranceTotal}
                            rowKey="key"
                            columns={treatmentPaymentColumns}
                            className="skybase-dashboard__active-order-table"
                            rowClassName={(record) => {
                              if (record.key === 4)
                                return 'table-footer__summary-total-payment';
                              else if (record.key === 5)
                                return 'table-footer__summary-total-change';
                              else return '';
                            }}
                            showHeader={false}
                            pagination={false}
                            size="small"
                          />
                        )}
                        pagination={false}
                        size="small"
                      />
                    </div>
                    {insuranceStatus === '' && (
                      <>
                        <div className="mb-15">
                          <Row gutter={[16, 8]}>
                            <Col span={6}>
                              <Button
                                onClick={() => handleInsuranceFlow('waiting')}
                                loading={isInsuranceSubmitClaimLoading}
                                style={{
                                  backgroundColor: '#6067E6',
                                  color: 'white',
                                  borderColor: '#6067E6',
                                }}>
                                <b
                                  style={
                                    isInsuranceSubmitClaimLoading
                                      ? {
                                          marginLeft: '10px',
                                        }
                                      : {}
                                  }>
                                  Save and View Invoice
                                </b>
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                    {insuranceStatus !== '' && (
                      <>
                        <div className="mb-15">
                          <Row gutter={[16, 8]}>
                            <Col span={12}>
                              <span style={{ color: 'red' }}>*</span> Letter of
                              Authorization (LoA)
                              <br></br>
                              <Upload
                                customRequest={({ file }) =>
                                  uploadInsuranceDocumentSingle(file, 'loa')
                                }
                                onRemove={(file) =>
                                  handleInsuranceDocsRemove(file, 'loa')
                                }
                                fileList={listFileInsuranceLOA}
                                accept=".pdf,.jpeg,.jpg"
                                showUploadList={{
                                  showRemoveIcon:
                                    insuranceStatus === 'waiting' ||
                                    insuranceStatus === 'revise',
                                }}
                                defaultFileList={listFileInsuranceLOA}>
                                <Button
                                  disabled={
                                    insuranceStatus !== 'waiting' &&
                                    insuranceStatus !== 'revise'
                                  }
                                  loading={
                                    isInsuranceSubmitLoaDocumentsLoading
                                  }>
                                  <b
                                    style={
                                      isInsuranceSubmitLoaDocumentsLoading
                                        ? {
                                            marginLeft: '10px',
                                          }
                                        : {}
                                    }>
                                    Pilih File
                                  </b>
                                </Button>
                              </Upload>
                            </Col>
                            <Col span={12}>
                              <span style={{ color: 'red' }}>*</span> Letter of
                              Confirmation (LoC)
                              <br></br>
                              <Upload
                                customRequest={({ file }) =>
                                  uploadInsuranceDocumentSingle(file, 'loc')
                                }
                                onRemove={(file) =>
                                  handleInsuranceDocsRemove(file, 'loc')
                                }
                                fileList={listFileInsuranceLOC}
                                accept=".pdf,.jpeg,.jpg"
                                showUploadList={{
                                  showRemoveIcon:
                                    insuranceStatus === 'waiting' ||
                                    insuranceStatus === 'revise',
                                }}
                                defaultFileList={listFileInsuranceLOC}>
                                <Button
                                  disabled={
                                    insuranceStatus !== 'waiting' &&
                                    insuranceStatus !== 'revise'
                                  }
                                  loading={
                                    isInsuranceSubmitLocDocumentsLoading
                                  }>
                                  <b
                                    style={
                                      isInsuranceSubmitLocDocumentsLoading
                                        ? {
                                            marginLeft: '10px',
                                          }
                                        : {}
                                    }>
                                    Pilih File
                                  </b>
                                </Button>
                              </Upload>
                            </Col>
                          </Row>
                        </div>
                        <div className="mb-15">
                          <Row gutter={[16, 8]}>
                            <Col span={12}>
                              <span style={{ color: 'red' }}>*</span> Supporting
                              Documents{' '}
                              <span style={{ color: 'green' }}>
                                required: <b>Invoice</b> and{' '}
                                <b>Filled Insurance Claim Form</b>
                              </span>
                              <br></br>
                              <Upload
                                customRequest={({ file }) =>
                                  uploadInsuranceDocumentSingle(file, 'support')
                                }
                                onRemove={(file) =>
                                  handleInsuranceDocsRemove(file, 'support')
                                }
                                fileList={listFileInsuranceSupportingDocs}
                                accept=".pdf,.jpeg,.jpg"
                                showUploadList={{
                                  showRemoveIcon:
                                    insuranceStatus === 'waiting' ||
                                    insuranceStatus === 'revise',
                                }}
                                defaultFileList={
                                  listFileInsuranceSupportingDocs
                                }
                                // className="ant-upload-disabled"
                                disabled={
                                  insuranceStatus !== 'waiting' &&
                                  insuranceStatus !== 'revise'
                                }>
                                {/* <p className="ant-upload-drag-icon">
                                  <Icon type="cloud-upload" />
                                </p>
                                <p className="ant-upload-hint">
                                  Drop Supporting Documents
                                </p> */}
                                <Button
                                  disabled={
                                    insuranceStatus !== 'waiting' &&
                                    insuranceStatus !== 'revise'
                                  }
                                  loading={
                                    isInsuranceSubmitSupportingDocumentsLoading
                                  }>
                                  <b
                                    style={
                                      isInsuranceSubmitSupportingDocumentsLoading
                                        ? {
                                            marginLeft: '10px',
                                          }
                                        : {}
                                    }>
                                    Pilih File
                                  </b>
                                </Button>
                              </Upload>
                            </Col>
                          </Row>
                        </div>
                        {insuranceStatus === 'waiting' && (
                          <>
                            <div className="mb-15">
                              <Row gutter={[16, 8]}>
                                <Col span={6}>
                                  <Button
                                    type="text"
                                    onClick={() =>
                                      handleInsuranceFlow('inprogress')
                                    }
                                    loading={isInsuranceSubmitDocumentsLoading}
                                    style={{
                                      backgroundColor: '#6067E6',
                                      color: 'white',
                                      borderColor: '#6067E6',
                                    }}>
                                    <b
                                      style={
                                        isInsuranceSubmitDocumentsLoading
                                          ? {
                                              marginLeft: '10px',
                                            }
                                          : {}
                                      }>
                                      Submit Insurance Documents
                                    </b>
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {/* {insuranceStatus === 'inprogress' && (
                      <>
                        <div className="mb-15">
                          <Row gutter={[16, 8]}>
                            <Col span={12}>
                              <Button
                                block
                                style={{
                                  color: '#0FAF78',
                                  borderColor: '#0FAF78',
                                }}
                                onClick={() =>
                                  setIsInsuranceApproveModalShown(true)
                                }>
                                <Icon type="check" />
                                <b>Approve</b>
                              </Button>
                            </Col>
                            <Col span={12}>
                              <Button
                                block
                                style={{
                                  color: '#FEB822',
                                  borderColor: '#FEB822',
                                }}
                                onClick={() =>
                                  setIsInsuranceReviseModalShown(true)
                                }>
                                <Icon type="exclamation" />
                                <b>Revise</b>
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </>
                    )} */}
                  </TabPane>
                </Tabs>
              </div>
              {/* <div>
                <Button
                  onClick={postSubmit}
                  type="primary"
                  style={{
                    width: '100%',
                  }}>
                  <strong>SIMPAN DAN BAYAR</strong>
                </Button>
              </div> */}
            </div>
          </Col>
          <Col span={6} offset={1}>
            <Row>
              <div className="panel">
                <h4>Jenis Kunjungan *</h4>
                <SelectDropdown
                  placeHolder="Pilih Jenis Kunjungan"
                  options={listTreatmentCategoryGroupOptions || []}
                  onChange={(value) =>
                    handleTreatmentCategoryGroupChange(value)
                  }
                  disabled={isJenisKunjunganDisabled}
                  value={treatmentCategoryGroup}
                />
                <br />
                <h4>Tindakan</h4>
                <DebounceSelect
                  placeHolder="Cari Tindakan"
                  options={listTreatmentOptions}
                  onSearch={handleTreatmentOptionsSearch}
                  onChange={handleTreatmentOptionsChange}
                  showSearch
                  showArrow={false}
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  notFoundContent={null}
                  optionsLabel="name"
                  optionsValue="code"
                  disabled={insuranceStatus !== '' || paymentLinkStatus}
                />
              </div>
            </Row>
            <Row>
              <div className="panel">
                <div className="mb-15">
                  <strong className="text-md skybase-dashboard__title">
                    Diskon
                  </strong>
                </div>
                <div className="mb-15">
                  <Input
                    className="skybase-dashboard__search-input"
                    placeholder="Search"
                    onChange={onSearchChange}
                    value={search}
                    disabled={insuranceStatus !== ''}
                  />
                </div>
                <Table
                  dataSource={listFilteredVoucherAll}
                  rowKey="key"
                  columns={discountCouponColumns(
                    handleUseVoucher,
                    insuranceStatus,
                    paymentLinkStatus
                  )}
                  className="skybase-dashboard__active-order-table"
                />
              </div>
            </Row>
            <Row>
              <div className="panel">
                <div className="mb-15">
                  <strong className="text-md skybase-dashboard__title">
                    Applied Coupon
                  </strong>
                </div>

                <Table
                  dataSource={listAppliedCoupon}
                  rowKey="key"
                  columns={appliedCouponColumns}
                  className="skybase-dashboard__active-order-table"
                />
              </div>
            </Row>
          </Col>
        </Row>
      </Content>

      <Modal
        visible={isInsuranceApproveModalShown || isInsuranceReviseModalShown}
        title={`${
          isInsuranceApproveModalShown ? 'Approve' : 'Revise'
        } Insurance`}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setIsInsuranceApproveModalShown(false);
              setIsInsuranceReviseModalShown(false);
            }}>
            {isInsuranceApproveModalShown ? 'Check Again' : 'Cancel'}
          </Button>,
          <Button
            key="submit"
            type="default"
            style={
              isInsuranceApproveModalShown
                ? { backgroundColor: '#0FAF78', color: 'white' }
                : { backgroundColor: '#FEB822', color: 'white' }
            }
            onClick={
              isInsuranceApproveModalShown
                ? () => handleInsuranceFlow('approved')
                : () => handleInsuranceFlow('revised')
            }
            loading={
              isInsuranceApproveDocumentsLoading ||
              isInsuranceReviseDocumentsLoading
            }>
            <b
              style={
                isInsuranceApproveDocumentsLoading ||
                isInsuranceReviseDocumentsLoading
                  ? {
                      marginLeft: '10px',
                    }
                  : {}
              }>
              {isInsuranceApproveModalShown ? 'Approve' : 'Revise'}
            </b>
          </Button>,
        ]}
        closable={false}>
        {isInsuranceApproveModalShown ? (
          <>
            Please double check. Once you{' '}
            <b>approve {receiptNumber} insurance</b>, you can’t undo the action.
          </>
        ) : (
          <>
            Are you sure you want to <b>revise {receiptNumber} insurance</b>?
          </>
        )}
      </Modal>
    </Layout>
  );
}

export default withRouter(PointOfSales);
