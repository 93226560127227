import api from './index';
import apiUpload from './index-upload';

export default {
  submitPayment(payload, appointmentId, medicalRecordNumber) {
    return api.post(
      `/transaction/appointment/${appointmentId}/patient/${medicalRecordNumber}`,
      payload
    );
  },

  getReceiptDetail(appointmentId, receiptNumber) {
    return api.get(
      `/transaction/appointment/${appointmentId}/receipt/${receiptNumber}`
    );
  },

  getBillList(billStatus, patientId) {
    return api.get(`/transaction/${billStatus}/patient/${patientId}`);
  },

  getBillDetail(billStatus, patientId) {
    return api.get(
      `/transaction/${billStatus}/patient/${patientId}/slip-treatment`
    );
  },

  getPaidBillDetail(receiptId) {
    return api.get(`/transaction/slip-treatment/receipt/${receiptId}`);
  },

  getReceiptList() {
    return api.get(`/transaction/receipt`);
  },

  getCashierReceiptList(
    cashierId = '',
    medicalRecordNumber = '',
    date = '',
    limit = '',
    page = ''
  ) {
    return api.get(
      `/transaction/receipt?${cashierId ? `cashierId=${cashierId}&` : ''}${
        medicalRecordNumber ? `medicalRecordNumber=${medicalRecordNumber}&` : ''
      }${date ? `date=${date}` : ''}${limit ? `&limit=${limit}` : ''}${
        page ? `&page=${page}` : ''
      }`
    );
  },

  submitVoidReturn(payload, receiptId) {
    return api.post(`/transaction/receipt/${receiptId}/void`, payload);
  },

  getReceiptDetailById(id) {
    return api.get(`/transaction/receipt/${id}`);
  },

  getReceiptDetailByIdAndSlipTreatmentId(payload, id) {
    return api.post(`/transaction/receipt/${id}`, payload);
  },

  submitReceipt(payload, receiptId) {
    return apiUpload.post(`/transaction/receipt/${receiptId}/upload`, payload);
  },

  sendReceiptToEmail(receiptId) {
    return api.get(`/transaction/receipt/${receiptId}/send-email`);
  }
};
