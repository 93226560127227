import React, { useEffect, useState } from 'react';
import { Col, Row, Layout, Table, Button, Input, DatePicker } from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import ReportApi from 'api/report';
import ClinicApi from 'api/clinic';
import { PageSpinner } from 'components/page-spinner';
import SelectDropdown from 'components/select-dropdown';
import { setToArray, currencyFormatter } from 'utils';

import {
  discountColumns,
  voidDiscountColumns,
  discountSummaryColumns,
} from '../helper/';
import '../styles/index.scss';

const { Content } = Layout;

export function LaporanDiskon({ history }) {
  const objectDiscountSummary = [
    {
      key: 1,
      column1: 'Grand Total',
      column2: 0,
    },
  ];

  const objectDiscountPerItemSummary = [
    {
      key: 1,
      column1: 'Grand Total',
      column2: 0,
    },
  ];

  const objectDiscountVoidSummary = [
    {
      key: 1,
      column1: 'Grand Total',
      column2: 0,
    },
  ];

  const objectDiscountVoidPerItemSummary = [
    {
      key: 1,
      column1: 'Grand Total',
      column2: 0,
    },
  ];

  const [clinic, setClinic] = useState();
  const [clinicOptions, setClinicOptions] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [listDiscount, setListDiscount] = useState([]);
  const [listFilteredDiscount, setListFilteredDiscount] = useState([]);
  const [listSummaryDiscount, setListSummaryDiscount] = useState([]);

  const [listDiscountPerItem, setListDiscountPerItem] = useState([]);
  const [listFilteredDiscountPerItem, setListFilteredDiscountPerItem] =
    useState([]);
  const [listSummaryDiscountPerItem, setListSummaryDiscountPerItem] = useState(
    []
  );

  const [listDiscountVoid, setListDiscountVoid] = useState([]);
  const [listFilteredDiscountVoid, setListFilteredDiscountVoid] = useState([]);
  const [listSummaryDiscountVoid, setListSummaryDiscountVoid] = useState([]);

  const [listDiscountVoidPerItem, setListDiscountVoidPerItem] = useState([]);
  const [listFilteredDiscountVoidPerItem, setListFilteredDiscountVoidPerItem] =
    useState([]);
  const [listSummaryDiscountVoidPerItem, setListSummaryDiscountVoidPerItem] =
    useState([]);

  const [loadingPage, setLoadingPage] = useState(false);

  const [searchDiscount, setSearchDiscount] = useState('');
  const [paginationDiscount, setPaginationDiscount] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    showSizeChanger: true,
  });

  const [searchDiscountPerItem, setSearchDiscountPerItem] = useState('');
  const [paginationDiscountPerItem, setPaginationDiscountPerItem] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    showSizeChanger: true,
  });

  const [searchDiscountVoid, setSearchDiscountVoid] = useState('');
  const [paginationDiscountVoid, setPaginationDiscountVoid] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    showSizeChanger: true,
  });

  const [searchDiscountVoidPerItem, setSearchDiscountVoidPerItem] =
    useState('');
  const [paginationDiscountVoidPerItem, setPaginationDiscountVoidPerItem] =
    useState({
      current: 1,
      pageSize: 10,
      pageSizeOptions: [5, 10, 15, 20],
      showSizeChanger: true,
    });

  async function getClinicList() {
    try {
      setLoadingPage(true);

      const response = await ClinicApi.listClinic();

      let clinicList = setToArray(response.data);

      const clinicListOpts = clinicList.map((value) => ({
        label: value.clinicName,
        value: value.id,
      }));

      clinicListOpts.unshift({ label: 'Semua Klinik', value: '' });

      setClinicOptions(clinicListOpts);
    } finally {
      setLoadingPage(false);
    }
  }

  async function getDiscountList(status, isPaymentDiscount) {
    try {
      setLoadingPage(true);

      const response = await ReportApi.getDiscountList(
        clinic,
        status,
        isPaymentDiscount,
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD')
      );

      let queueList = setToArray(response.data);

      const modifiedQueueList = queueList.map((item, index) => ({
        ...item,
        key: index + 1,
      }));

      if (status === 'paid' && isPaymentDiscount) {
        setListDiscount(modifiedQueueList);
        setListFilteredDiscount(modifiedQueueList);

        const newDiscountSummary = [...objectDiscountSummary];

        newDiscountSummary[0].column2 = response.data.data.grandTotal
          ? currencyFormatter(response.data.data.grandTotal)
          : 0;

        setListSummaryDiscount(newDiscountSummary);
      } else if (status === 'paid' && !isPaymentDiscount) {
        setListDiscountPerItem(modifiedQueueList);
        setListFilteredDiscountPerItem(modifiedQueueList);

        const newDiscountPerItemSummary = [...objectDiscountPerItemSummary];

        newDiscountPerItemSummary[0].column2 = response.data.data.grandTotal
          ? currencyFormatter(response.data.data.grandTotal)
          : 0;

        setListSummaryDiscountPerItem(newDiscountPerItemSummary);
      } else if (status === 'void' && isPaymentDiscount) {
        setListDiscountVoid(modifiedQueueList);
        setListFilteredDiscountVoid(modifiedQueueList);

        const newDiscountVoidSummary = [...objectDiscountVoidSummary];

        newDiscountVoidSummary[0].column2 = response.data.data.grandTotal
          ? currencyFormatter(response.data.data.grandTotal)
          : 0;

        setListSummaryDiscountVoid(newDiscountVoidSummary);
      } else if (status === 'void' && !isPaymentDiscount) {
        setListDiscountVoidPerItem(modifiedQueueList);
        setListFilteredDiscountVoidPerItem(modifiedQueueList);

        const newDiscountVoidPerItemSummary = [
          ...objectDiscountVoidPerItemSummary,
        ];

        newDiscountVoidPerItemSummary[0].column2 = response.data.data.grandTotal
          ? currencyFormatter(response.data.data.grandTotal)
          : 0;

        setListSummaryDiscountVoidPerItem(newDiscountVoidPerItemSummary);
      }
    } finally {
      setLoadingPage(false);
    }
  }

  const getAllDiscountList = () => {
    getDiscountList('paid', true);
    getDiscountList('paid', false);
    getDiscountList('void', true);
    getDiscountList('void', false);
  };

  const handleClinicChange = (value) => {
    setClinic(value);
  };

  const handleStartDateChange = (date, dateString) => {
    if (!date) setStartDate(moment());
    else setStartDate(date);
  };

  const handleEndDateChange = (date, dateString) => {
    if (!date) setEndDate(moment());
    else setEndDate(date);
  };

  const onDiscountTableChange = (pagination) => {
    setPaginationDiscount(pagination);
  };

  const onDiscountPerItemTableChange = (pagination) => {
    setPaginationDiscountPerItem(pagination);
  };

  const onDiscountVoidTableChange = (pagination) => {
    setPaginationDiscountVoid(pagination);
  };

  const onDiscountVoidPerItemTableChange = (pagination) => {
    setPaginationDiscountVoidPerItem(pagination);
  };

  //search
  const onDiscountSearchChange = (e) => {
    const convertValue = e.target.value.toLowerCase();
    const labelValue = e.target.value;
    setSearchDiscount(labelValue);

    const filteredData = listDiscount.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          !isEmpty(item[key]) &&
          item[key].toString().toLowerCase().search(convertValue) !== -1
      );
    });

    setListFilteredDiscount(filteredData);
  };

  const onDiscountPerItemSearchChange = (e) => {
    const convertValue = e.target.value.toLowerCase();
    const labelValue = e.target.value;
    setSearchDiscountPerItem(labelValue);

    const filteredData = listDiscountPerItem.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          !isEmpty(item[key]) &&
          item[key].toString().toLowerCase().search(convertValue) !== -1
      );
    });

    setListFilteredDiscountPerItem(filteredData);
  };

  const onDiscountVoidSearchChange = (e) => {
    const convertValue = e.target.value.toLowerCase();
    const labelValue = e.target.value;
    setSearchDiscountVoid(labelValue);

    const filteredData = listDiscountVoid.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          !isEmpty(item[key]) &&
          item[key].toString().toLowerCase().search(convertValue) !== -1
      );
    });

    setListFilteredDiscountVoid(filteredData);
  };

  const onDiscountVoidPerItemSearchChange = (e) => {
    const convertValue = e.target.value.toLowerCase();
    const labelValue = e.target.value;
    setSearchDiscountVoidPerItem(labelValue);

    const filteredData = listDiscountVoidPerItem.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          !isEmpty(item[key]) &&
          item[key].toString().toLowerCase().search(convertValue) !== -1
      );
    });

    setListFilteredDiscountVoidPerItem(filteredData);
  };

  const useInitData = () => {
    getClinicList();
    getAllDiscountList();

    const today = moment();
    setStartDate(today);
    setEndDate(today);
  };

  useEffect(useInitData, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <div className="mb-15">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Laporan Diskon
              </strong>
            </Col>
          </Row>
        </div>
        <div className="panel">
          <Row>
            <Col span={2}>
              <div className="mb-15">
                <div className="skybase-dashboard__search">
                  <Input
                    disabled
                    value="Mulai"
                    style={{
                      color: 'rgba(0, 0, 0, 0.65)',
                      cursor: 'auto',
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className="mb-15">
                <div className="skybase-dashboard__search">
                  <DatePicker
                    showToday
                    allowClear={false}
                    format="DD/MM/YYYY"
                    onChange={handleStartDateChange}
                    value={startDate}
                  />
                </div>
              </div>
            </Col>
            <Col span={2}>
              <div className="mb-15">
                <div className="skybase-dashboard__search">
                  <Input
                    disabled
                    value="Sampai"
                    style={{
                      color: 'rgba(0, 0, 0, 0.65)',
                      cursor: 'auto',
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className="mb-15">
                <div className="skybase-dashboard__search">
                  <DatePicker
                    showToday
                    allowClear={false}
                    format="DD/MM/YYYY"
                    onChange={handleEndDateChange}
                    value={endDate}
                  />
                </div>
              </div>
            </Col>
            <Col span={4}>
              <div className="mb-15">
                <div className="skybase-dashboard__search">
                  <Button
                    onClick={getAllDiscountList}
                    style={{
                      color: '#fff',
                      backgroundColor: '#36c6d3',
                      borderColor: '#2bb8c4',
                    }}>
                    <strong>TAMPILKAN</strong>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={2}>
              <div className="skybase-dashboard__search">
                <Input
                  disabled
                  value="Klinik"
                  style={{
                    color: 'rgba(0, 0, 0, 0.65)',
                    cursor: 'auto',
                  }}
                />
              </div>
            </Col>
            <Col span={22}>
              <div className="skybase-dashboard__search">
                <SelectDropdown
                  options={clinicOptions}
                  placeHolder="Pilih Klinik"
                  onChange={handleClinicChange}
                  value={clinic}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="panel mb-15">
          <Row>
            <Col span={24}>
              <div className="mb-15">
                <strong className="text-md skybase-dashboard__title">
                  DISKON PEMBAYARAN
                </strong>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24} type="flex" align="end">
              <div className="mb-15">
                <Button onClick={() => {}}>PRINT</Button>
                <Button onClick={() => {}}>COPY</Button>
                <Button onClick={() => {}}>PDF</Button>
                <Button onClick={() => {}}>EXCEL</Button>
                <Button onClick={() => {}}>CSV</Button>
                <Button onClick={() => getDiscountList('paid', true)}>
                  RELOAD
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={2} type="flex" align="start"></Col>
            <Col span={5} offset={17} type="flex" align="end">
              <div className="mb-15">
                <Input
                  className="skybase-dashboard__search-input"
                  placeholder="Search"
                  onChange={onDiscountSearchChange}
                  value={searchDiscount}
                />
              </div>
            </Col>
          </Row>
          <Table
            dataSource={listFilteredDiscount}
            rowKey="key"
            columns={discountColumns}
            onChange={onDiscountTableChange}
            className="skybase-dashboard__active-order-table"
            footer={() => (
              <Table
                dataSource={listSummaryDiscount}
                rowKey="key"
                columns={discountSummaryColumns}
                className="skybase-dashboard__active-order-table"
                showHeader={false}
                pagination={false}
                size="small"
              />
            )}
            pagination={paginationDiscount}
            size="small"
            scroll={{ x: 1100, y: 500 }}
          />
        </div>

        <div className="panel mb-15">
          <Row>
            <Col span={24}>
              <div className="mb-15">
                <strong className="text-md skybase-dashboard__title">
                  DISKON PER ITEM
                </strong>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24} type="flex" align="end">
              <div className="mb-15">
                <Button onClick={() => {}}>PRINT</Button>
                <Button onClick={() => {}}>COPY</Button>
                <Button onClick={() => {}}>PDF</Button>
                <Button onClick={() => {}}>EXCEL</Button>
                <Button onClick={() => {}}>CSV</Button>
                <Button onClick={() => getDiscountList('paid', false)}>
                  RELOAD
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={2} type="flex" align="start"></Col>
            <Col span={5} offset={17} type="flex" align="end">
              <div className="mb-15">
                <Input
                  className="skybase-dashboard__search-input"
                  placeholder="Search"
                  onChange={onDiscountPerItemSearchChange}
                  value={searchDiscountPerItem}
                />
              </div>
            </Col>
          </Row>
          <Table
            dataSource={listFilteredDiscountPerItem}
            rowKey="key"
            columns={discountColumns}
            onChange={onDiscountPerItemTableChange}
            className="skybase-dashboard__active-order-table"
            footer={() => (
              <Table
                dataSource={listSummaryDiscountPerItem}
                rowKey="key"
                columns={discountSummaryColumns}
                className="skybase-dashboard__active-order-table"
                showHeader={false}
                pagination={false}
                size="small"
              />
            )}
            pagination={paginationDiscountPerItem}
            size="small"
            scroll={{ x: 1100, y: 500 }}
          />
        </div>

        <div className="panel mb-15">
          <Row>
            <Col span={24}>
              <div className="mb-15">
                <strong className="text-md skybase-dashboard__title">
                  DISKON PEMBAYARAN BATAL
                </strong>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24} type="flex" align="end">
              <div className="mb-15">
                <Button onClick={() => {}}>PRINT</Button>
                <Button onClick={() => {}}>COPY</Button>
                <Button onClick={() => {}}>PDF</Button>
                <Button onClick={() => {}}>EXCEL</Button>
                <Button onClick={() => {}}>CSV</Button>
                <Button onClick={() => getDiscountList('void', true)}>
                  RELOAD
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={2} type="flex" align="start"></Col>
            <Col span={5} offset={17} type="flex" align="end">
              <div className="mb-15">
                <Input
                  className="skybase-dashboard__search-input"
                  placeholder="Search"
                  onChange={onDiscountVoidSearchChange}
                  value={searchDiscountVoid}
                />
              </div>
            </Col>
          </Row>
          <Table
            dataSource={listFilteredDiscountVoid}
            rowKey="key"
            columns={discountColumns}
            onChange={onDiscountVoidTableChange}
            className="skybase-dashboard__active-order-table"
            footer={() => (
              <Table
                dataSource={listSummaryDiscountVoid}
                rowKey="key"
                columns={discountSummaryColumns}
                className="skybase-dashboard__active-order-table"
                showHeader={false}
                pagination={false}
                size="small"
              />
            )}
            pagination={paginationDiscountVoid}
            size="small"
            scroll={{ x: 1100, y: 500 }}
          />
        </div>

        <div className="panel">
          <Row>
            <Col span={24}>
              <div className="mb-15">
                <strong className="text-md skybase-dashboard__title">
                  DISKON PER ITEM BATAL
                </strong>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24} type="flex" align="end">
              <div className="mb-15">
                <Button onClick={() => {}}>PRINT</Button>
                <Button onClick={() => {}}>COPY</Button>
                <Button onClick={() => {}}>PDF</Button>
                <Button onClick={() => {}}>EXCEL</Button>
                <Button onClick={() => {}}>CSV</Button>
                <Button onClick={() => getDiscountList('void', false)}>
                  RELOAD
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={2} type="flex" align="start"></Col>
            <Col span={5} offset={17} type="flex" align="end">
              <div className="mb-15">
                <Input
                  className="skybase-dashboard__search-input"
                  placeholder="Search"
                  onChange={onDiscountVoidPerItemSearchChange}
                  value={searchDiscountVoidPerItem}
                />
              </div>
            </Col>
          </Row>
          <Table
            dataSource={listFilteredDiscountVoidPerItem}
            rowKey="key"
            columns={voidDiscountColumns}
            onChange={onDiscountVoidPerItemTableChange}
            className="skybase-dashboard__active-order-table"
            footer={() => (
              <Table
                dataSource={listSummaryDiscountVoidPerItem}
                rowKey="key"
                columns={discountSummaryColumns}
                className="skybase-dashboard__active-order-table"
                showHeader={false}
                pagination={false}
                size="small"
              />
            )}
            pagination={paginationDiscountVoidPerItem}
            size="small"
            scroll={{ x: 1100, y: 500 }}
          />
        </div>
      </Content>
    </Layout>
  );
}

export default withRouter(LaporanDiskon);
