import React, { useEffect, useState } from 'react';
import { Col, Row, Layout, Table, Button, Input, Modal } from 'antd';
import {
  ExclamationCircleTwoTone,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import { isEmpty } from 'lodash';
// import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router-dom';
import MarketingApi from 'api/marketing';
import { PageSpinner } from 'components/page-spinner';
import SelectDropdown from 'components/select-dropdown';
import { setToArray } from 'utils';

import { reasonLabOrderColumns } from '../helper/';
import '../styles/index.scss';

const { Content } = Layout;

export function ManageReasonLabOrder({ history }) {
  const [reason, setReason] = useState();
  const [type, setType] = useState();

  const [listReason, setListReason] = useState([]);
  const [listFilteredReason, setListFilteredReason] = useState([]);
  const [listTypeOptions, setListTypeOptions] = useState([]);

  const [idModal, setIdModal] = useState();
  const [typeModal, setTypeModal] = useState();

  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);

  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);

  const [loadingPage, setLoadingPage] = useState(false);

  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    showSizeChanger: true,
  });

  async function getTypeList() {
    try {
      setLoadingPage(true);

      const response = await MarketingApi.getReasonLabOrderType();

      let typeList = setToArray(response.data);

      const typeListOpts = typeList.map((value) => ({
        label: value.name,
        value: value.id,
      }));

      setListTypeOptions(typeListOpts);
    } finally {
      setLoadingPage(false);
    }
  }

  async function getReasonList() {
    try {
      setLoadingPage(true);

      const response = await MarketingApi.getReasonLabOrderList();

      let complainList = setToArray(response.data);

      setListReason(complainList);
      setListFilteredReason(complainList);
    } finally {
      setLoadingPage(false);
    }
  }

  async function submitReason() {
    const payload = {
      reason: reason,
      reasonTypeId: type,
    };

    const response = await MarketingApi.submitReasonLabOrder(payload);

    if (response.data.resultCode === '00') {
      getReasonList();

      setReason();
      setType();

      setIsConfirmPopupOpen(false);
      setIsSuccessPopupOpen(true);
    }
  }

  async function editReason() {
    const payload = {
      reason: reason,
      reasonTypeId: type,
    };

    const response = await MarketingApi.editReasonLabOrder(payload, idModal);

    if (response.data.resultCode === '00') {
      getReasonList();

      setIdModal();
      setReason();
      setType();

      setIsConfirmPopupOpen(false);
      setIsSuccessPopupOpen(true);
    }
  }

  async function deleteReason() {
    const response = await MarketingApi.deleteReasonLabOrder(idModal);

    if (response.data.resultCode === '00') {
      getReasonList();

      setIdModal();

      setIsConfirmPopupOpen(false);
      setIsSuccessPopupOpen(true);
    }
  }

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleTypeChange = (value) => {
    setType(value);
  };

  const handleViewReasonClick = (id, value) => {
    handleFlow('view', 'openPopup', id, value);
  };

  const handleEditReasonClick = (id, value) => {
    handleFlow('edit', 'openPopup', id, value);
  };

  const handleDeleteReasonClick = (id) => {
    setIdModal(id);

    handleFlow('delete', 'openConfirm', id);
  };

  const handleFlow = (type, flow, id, value) => {
    if (typeModal !== type) setTypeModal(type);

    if (type === 'new') {
      if (flow === 'openPopup') {
        setIsEditPopupOpen(true);
      } else if (flow === 'closePopup') {
        setIdModal();
        setReason();
        setType();

        setIsEditPopupOpen(false);
      } else if (flow === 'openConfirm') {
        setIsEditPopupOpen(false);
        setIsConfirmPopupOpen(true);
      } else if (flow === 'closeConfirm') {
        setIsConfirmPopupOpen(false);
      } else if (flow === 'submit') {
        submitReason();
      } else if (flow === 'closeSuccess') {
        setIdModal();
        setReason();
        setType();

        setIsSuccessPopupOpen(false);
      }
    } else if (type === 'edit') {
      if (flow === 'openPopup') {
        const { reason, type } = value;

        setIdModal(id);
        setReason(reason);
        setType(type);

        setIsEditPopupOpen(true);
      } else if (flow === 'closePopup') {
        setIdModal();
        setReason();
        setType();

        setIsEditPopupOpen(false);
      } else if (flow === 'openConfirm') {
        setIsEditPopupOpen(false);
        setIsConfirmPopupOpen(true);
      } else if (flow === 'closeConfirm') {
        setIsConfirmPopupOpen(false);
      } else if (flow === 'submit') {
        editReason();
      } else if (flow === 'closeSuccess') {
        setIdModal();
        setReason();
        setType();

        setIsSuccessPopupOpen(false);
      }
    } else if (type === 'delete') {
      if (flow === 'openConfirm') {
        setIsConfirmPopupOpen(true);
      } else if (flow === 'closeConfirm') {
        setIsConfirmPopupOpen(false);
      } else if (flow === 'submit') {
        deleteReason();
      } else if (flow === 'closeSuccess') {
        setIsSuccessPopupOpen(false);
      }
    } else if (type === 'view') {
      if (flow === 'openPopup') {
        const { reason, type } = value;

        setIdModal(id);
        setReason(reason);
        setType(type);

        setIsEditPopupOpen(true);
      } else if (flow === 'closePopup') {
        setIdModal();
        setReason();
        setType();

        setIsEditPopupOpen(false);
      }
    }
  };

  const onTableChange = (pagination) => {
    setPagination(pagination);
  };

  //search
  const onSearchChange = (e) => {
    const convertValue = e.target.value.toLowerCase();
    const labelValue = e.target.value;
    setSearch(labelValue);

    const filteredData = listReason.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          !isEmpty(item[key]) &&
          item[key].toString().toLowerCase().search(convertValue) !== -1
      );
    });

    setListFilteredReason(filteredData);
  };

  const useInitData = () => {
    getTypeList();
    getReasonList();
  };

  const useEvaluateSubmitButton = () => {
    if (!!reason && !!type) {
      setIsSubmitButtonEnabled(true);
    } else {
      setIsSubmitButtonEnabled(false);
    }
  };

  useEffect(useInitData, []);

  useEffect(useEvaluateSubmitButton, [reason, type]);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <div className="mb-15">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Manage Reason Lab Order
              </strong>
            </Col>
          </Row>
        </div>
        <div className="panel">
          <Row>
            <Col span={12}>
              <div className="mb-15">
                <Button
                  style={{
                    color: '#FFF',
                    backgroundColor: '#32c5d2',
                    borderColor: '#32c5d2',
                  }}
                  onClick={() => handleFlow('new', 'openPopup')}>
                  <strong>TAMBAH REASON LAB ORDER</strong>
                </Button>
              </div>
            </Col>
            <Col span={12} type="flex" align="end">
              <div className="mb-15">
                <Button onClick={() => {}}>PRINT</Button>
                <Button onClick={() => {}}>COPY</Button>
                <Button onClick={() => {}}>PDF</Button>
                <Button onClick={() => {}}>EXCEL</Button>
                <Button onClick={() => {}}>CSV</Button>
                <Button onClick={getReasonList}>RELOAD</Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={2} type="flex" align="start">
              <></>
            </Col>
            <Col span={5} offset={17} type="flex" align="end">
              <div className="mb-15">
                <Input
                  className="skybase-dashboard__search-input"
                  placeholder="Search"
                  onChange={onSearchChange}
                  value={search}
                />
              </div>
            </Col>
          </Row>
          <Table
            dataSource={listFilteredReason}
            rowKey="id"
            scroll={{ y: 500 }}
            onChange={onTableChange}
            pagination={pagination}
            columns={reasonLabOrderColumns(
              handleViewReasonClick,
              handleEditReasonClick,
              handleDeleteReasonClick
            )}
            className="skybase-dashboard__active-order-table"
          />
        </div>
      </Content>

      <Modal
        visible={isEditPopupOpen}
        width={'35%'}
        closable={false}
        footer={
          typeModal === 'view'
            ? [
                <Button
                  type="danger"
                  onClick={() => handleFlow(typeModal, 'closePopup')}>
                  Kembali
                </Button>,
              ]
            : [
                <Button
                  type="danger"
                  onClick={() => handleFlow(typeModal, 'closePopup')}>
                  Kembali
                </Button>,
                <Button
                  type="primary"
                  onClick={() => handleFlow(typeModal, 'openConfirm')}
                  disabled={!isSubmitButtonEnabled}>
                  Simpan
                </Button>,
              ]
        }>
        <Row type="flex" align="middle">
          <Col span={6} className="mb-5">
            <div>Reason</div>
          </Col>
          <Col span={18}>
            <div className="skybase-dashboard__search mb-5">
              <Input
                placeholder="Reason"
                onChange={handleReasonChange}
                value={reason}
                readOnly={typeModal === 'view'}
              />
            </div>
          </Col>
        </Row>
        <Row type="flex" align="middle">
          <Col span={6} className="mb-5">
            <div>Type</div>
          </Col>
          <Col span={18}>
            <div className="skybase-dashboard__search mb-5">
              {typeModal === 'view' ? (
                <Input readOnly value={type} />
              ) : (
                <SelectDropdown
                  options={listTypeOptions}
                  placeHolder="Type"
                  onChange={handleTypeChange}
                  value={type}
                />
              )}
            </div>
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={isConfirmPopupOpen}
        width={'35%'}
        closable={false}
        footer={[
          <Button
            type="default"
            onClick={() => handleFlow(typeModal, 'closeConfirm')}>
            Tidak
          </Button>,
          <Button type="danger" onClick={() => handleFlow(typeModal, 'submit')}>
            Ya
          </Button>,
        ]}>
        <Row>
          <div className="mt-10 mb-10">
            <ExclamationCircleTwoTone
              twoToneColor="#f8bb86"
              style={{ fontSize: 50 }}
            />
          </div>
        </Row>
        {typeModal === 'delete' ? (
          <>
            <Row>
              <div className="mb-10">
                <strong>Anda sudah yakin melakukan penghapusan data?</strong>
              </div>
            </Row>
            <Row>
              <div>Data yang sudah dihapus tidak dapat dikembalikan</div>
            </Row>
          </>
        ) : (
          <Row>
            <div>
              <strong>Apakah data sudah sesuai?</strong>
            </div>
          </Row>
        )}
      </Modal>

      <Modal
        visible={isSuccessPopupOpen}
        width={'35%'}
        closable={false}
        footer={[
          <Button
            type="primary"
            onClick={() => handleFlow(typeModal, 'closeSuccess')}>
            OK
          </Button>,
        ]}>
        <Row>
          <div className="mt-10 mb-10">
            <CheckCircleTwoTone
              twoToneColor="#a5dc86"
              style={{ fontSize: 50 }}
            />
          </div>
        </Row>
        <Row>
          <div className="mb-10">
            <strong>Success</strong>
          </div>
        </Row>
        <Row>
          <div>
            Data berhasil {typeModal === 'delete' ? 'dihapus' : 'disimpan'}
          </div>
        </Row>
      </Modal>
    </Layout>
  );
}

export default withRouter(ManageReasonLabOrder);
