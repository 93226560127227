import api from './index';

export default {
  listTreatmentOptions(keyword = '') {
    return api.get(`/treatment/bulk?q=${keyword}`);
  },

  listTreatmentCategoryOptions() {
    return api.get(`/treatment/category`);
  },

  listTreatmentCategoryGroupOptions() {
    return api.get(`/treatment/category/group`);
  },
};
