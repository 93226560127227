import api from './index';

export default {
  listQueue(
    clinicId = '',
    isPaid = '',
    insuranceStatus = '',
    from = '',
    to = '',
    limit = '',
    page = ''
  ) {
    return api.get(
      `/appointment/patient/attend/queue?status=FINISHED${
        clinicId ? `&clinicId=${clinicId}` : ''
      }${isPaid !== '' ? `&isPaid=${isPaid}` : ''}${
        insuranceStatus !== '' ? `&insuranceStatus=${insuranceStatus}` : ''
      }${from ? `&from=${from}` : ''}${to ? `&to=${to}` : ''}${
        limit ? `&limit=${limit}` : ''
      }${page ? `&page=${page}` : ''}`
    );
  },

  getPatientDetail(appointmentId = '') {
    return api.get(`/appointment/${appointmentId}?isAll=true`);
  },

  getPatientDetailv2(appointmentId = '') {
    return api.get(`/v2/appointments/${appointmentId}`);
  },

  getReceiptv2(appointmentId = '') {
    return api.get(`/v2/appointments/${appointmentId}/receipts`);
  },

  getLatestReceiptv2(appointmentId = '') {
    return api.get(`/v2/appointments/${appointmentId}/receipts/latest`);
  },

  getTreatmentList(appointmentId, medicalRecordNumber, status) {
    return api.get(
      `/appointment/${appointmentId}/patient/${medicalRecordNumber}/slip-treatment?status=${status}`
    );
  },

  getAllTreatmentList(medicalRecordNumber = '') {
    return api.get(
      `/appointment/patient/${medicalRecordNumber}/slip-treatment`
    );
  },

  getTreatmentListWithDiscount(
    appointmentId,
    medicalRecordNumber,
    status,
    clinicId
  ) {
    return api.get(
      // `/appointment/${appointmentId}/patient/${medicalRecordNumber}/slip-treatment?status=${status}`
      `/appointment/${appointmentId}/patient/${medicalRecordNumber}/pos/slip-treatment?status=${status}${
        clinicId ? `&clinicId=${clinicId}` : ''
      }`
    );
  },

  getAllTreatmentHistory(medicalRecordNumber = '') {
    return api.get(
      `/appointment/patient/${medicalRecordNumber}?status=FINISHED`
    );
  },

  addTreatment(payload, appointmentId, medicalRecordNumber) {
    return api.post(
      `/appointment/${appointmentId}/patient/${medicalRecordNumber}/slip-treatment`,
      payload
    );
  },

  editTreatment(payload, appointmentId, medicalRecordNumber, id) {
    return api.put(
      `/appointment/${appointmentId}/patient/${medicalRecordNumber}/slip-treatment/${id}`,
      payload
    );
  },

  deleteTreatment(appointmentId, medicalRecordNumber, id) {
    return api.delete(
      `/appointment/${appointmentId}/patient/${medicalRecordNumber}/slip-treatment/${id}`
    );
  },

  editInvoice(payload, appointmentId) {
    return api.put(`/appointment/${appointmentId}?isAll=true`, payload);
  }
};
