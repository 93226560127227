import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Layout,
  Table,
  Button,
  Input,
  InputNumber,
  Modal,
} from 'antd';
import {
  ExclamationCircleTwoTone,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import { isEmpty } from 'lodash';
// import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router-dom';
import FinanceApi from 'api/finance';
import BankApi from 'api/bank';
import { PageSpinner } from 'components/page-spinner';
import SelectDropdown from 'components/select-dropdown';
import { setToArray, currencyFormatter, currencyParser } from 'utils';

import { paymentMethodColumns } from '../helper/';
import '../styles/index.scss';

const { Content } = Layout;

export function ManageMetodePembayaran({ history }) {
  const [bank, setBank] = useState();
  const [chartOfAccount, setChartOfAccount] = useState();
  const [name, setName] = useState();
  const [feeCharge, setFeeCharge] = useState(0);
  const [feeChargeInterbank, setFeeChargeInterbank] = useState(0);
  const [paymentMethodStatus, setPaymentMethodStatus] = useState();
  const [paymentMethod, setPaymentMethod] = useState();

  const [listMethod, setListMethod] = useState([]);
  const [listFilteredMethod, setListFilteredMethod] = useState([]);
  const [listBankOptions, setListBankOptions] = useState([]);
  const [listChartOfAccountOptions, setListChartOfAccountOptions] = useState(
    []
  );
  const [listPaymentMethodStatusOptions, setListPaymentMethodStatusOptions] =
    useState([]);
  const [listPaymentMethodOptions, setListPaymentMethodOptions] = useState([]);

  const [idModal, setIdModal] = useState();
  const [typeModal, setTypeModal] = useState();

  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);

  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);

  const [loadingPage, setLoadingPage] = useState(false);

  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    showSizeChanger: true,
  });

  const getChartOfAccountList = () => {
    const chartOfAccountOpts = [
      { label: '1', value: '1' },
      { label: 'Jasa Medis', value: 'Jasa Medis' },
    ];

    setListChartOfAccountOptions(chartOfAccountOpts);
  };

  const getPaymentMethodStatusList = () => {
    const paymentMethodStatusOpts = [
      { label: 'Uang Masuk', value: 1 },
      { label: 'Uang Keluar', value: 2 },
    ];

    setListPaymentMethodStatusOptions(paymentMethodStatusOpts);
  };

  async function getBankList() {
    try {
      setLoadingPage(true);

      const response = await BankApi.getListBankFromBE();

      let bankList = response.data;

      const bankListOpts = bankList.map((value) => ({
        label: value.nama_bank,
        value: value.id_bank,
      }));

      setListBankOptions(bankListOpts);
    } finally {
      setLoadingPage(false);
    }
  }

  async function getPaymentMethodList() {
    try {
      setLoadingPage(true);

      const response = await FinanceApi.getPaymentMethodType();

      let paymentMethodList = setToArray(response.data);

      const paymentMethodListOpts = paymentMethodList.map((value) => ({
        label: value.name,
        value: value.id,
      }));

      setListPaymentMethodOptions(paymentMethodListOpts);
    } finally {
      setLoadingPage(false);
    }
  }

  async function getMethodList() {
    try {
      setLoadingPage(true);

      const response = await FinanceApi.getMethodList();

      let methodList = setToArray(response.data);

      setListMethod(methodList);
      setListFilteredMethod(methodList);
    } finally {
      setLoadingPage(false);
    }
  }

  async function submitMethod() {
    const payload = {
      bankId: bank,
      chartOfAccount: chartOfAccount,
      name: name,
      feeCharge: feeCharge,
      feeChargeInterbank: feeChargeInterbank,
      paymentMethodStatus: paymentMethodStatus,
      paymentMethodId: paymentMethod,
    };

    const response = await FinanceApi.submitMethod(payload);

    if (response.data.resultCode === '00') {
      getMethodList();

      setBank();
      setChartOfAccount();
      setName();
      setFeeCharge(0);
      setFeeChargeInterbank(0);
      setPaymentMethodStatus();
      setPaymentMethod();

      setIsConfirmPopupOpen(false);
      setIsSuccessPopupOpen(true);
    }
  }

  async function editMethod() {
    const payload = {
      bankId: bank,
      chartOfAccount: chartOfAccount,
      name: name,
      feeCharge: feeCharge,
      feeChargeInterbank: feeChargeInterbank,
      paymentMethodStatus: paymentMethodStatus,
      paymentMethodId: paymentMethod,
    };

    const response = await FinanceApi.editMethod(payload, idModal);

    if (response.data.resultCode === '00') {
      getMethodList();

      setIdModal();
      setBank();
      setChartOfAccount();
      setName();
      setFeeCharge(0);
      setFeeChargeInterbank(0);
      setPaymentMethodStatus();
      setPaymentMethod();

      setIsConfirmPopupOpen(false);
      setIsSuccessPopupOpen(true);
    }
  }

  async function deleteMethod() {
    const response = await FinanceApi.deleteMethod(idModal);

    if (response.data.resultCode === '00') {
      getMethodList();

      setIdModal();

      setIsConfirmPopupOpen(false);
      setIsSuccessPopupOpen(true);
    }
  }

  const handleBankChange = (value) => {
    setBank(value);
  };

  const handleChartOfAccountChange = (value) => {
    setChartOfAccount(value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleFeeChargeChange = (value) => {
    setFeeCharge(value);
  };

  const handleFeeChargeInterbankChange = (value) => {
    setFeeChargeInterbank(value);
  };

  const handlePaymentMethodStatusChange = (value) => {
    setPaymentMethodStatus(value);
  };

  const handlePaymentMethodChange = (value) => {
    setPaymentMethod(value);
  };

  const handleViewMethodClick = (id, value) => {
    handleFlow('view', 'openPopup', id, value);
  };

  const handleEditMethodClick = (id, value) => {
    handleFlow('edit', 'openPopup', id, value);
  };

  const handleDeleteMethodClick = (id) => {
    setIdModal(id);

    handleFlow('delete', 'openConfirm', id);
  };

  const handleFlow = (type, flow, id, value) => {
    if (typeModal !== type) setTypeModal(type);

    if (type === 'new') {
      if (flow === 'openPopup') {
        setIsEditPopupOpen(true);
      } else if (flow === 'closePopup') {
        setIdModal();
        setBank();
        setChartOfAccount();
        setName();
        setFeeCharge(0);
        setFeeChargeInterbank(0);
        setPaymentMethodStatus();
        setPaymentMethod();

        setIsEditPopupOpen(false);
      } else if (flow === 'openConfirm') {
        setIsEditPopupOpen(false);
        setIsConfirmPopupOpen(true);
      } else if (flow === 'closeConfirm') {
        setIsConfirmPopupOpen(false);
      } else if (flow === 'submit') {
        submitMethod();
      } else if (flow === 'closeSuccess') {
        setIdModal();
        setBank();
        setChartOfAccount();
        setName();
        setFeeCharge(0);
        setFeeChargeInterbank(0);
        setPaymentMethodStatus();
        setPaymentMethod();

        setIsSuccessPopupOpen(false);
      }
    } else if (type === 'edit') {
      if (flow === 'openPopup') {
        const {
          bank,
          chartOfAccount,
          name,
          feeCharge,
          feeChargeInterbank,
          paymentMethodStatus,
          paymentMethod,
        } = value;

        setIdModal(id);
        setBank(bank);
        setChartOfAccount(chartOfAccount);
        setName(name);
        setFeeCharge(feeCharge);
        setFeeChargeInterbank(feeChargeInterbank);
        setPaymentMethodStatus(paymentMethodStatus);
        setPaymentMethod(paymentMethod);

        setIsEditPopupOpen(true);
      } else if (flow === 'closePopup') {
        setIdModal();
        setBank();
        setChartOfAccount();
        setName();
        setFeeCharge(0);
        setFeeChargeInterbank(0);
        setPaymentMethodStatus();
        setPaymentMethod();

        setIsEditPopupOpen(false);
      } else if (flow === 'openConfirm') {
        setIsEditPopupOpen(false);
        setIsConfirmPopupOpen(true);
      } else if (flow === 'closeConfirm') {
        setIsConfirmPopupOpen(false);
      } else if (flow === 'submit') {
        editMethod();
      } else if (flow === 'closeSuccess') {
        setIdModal();
        setBank();
        setChartOfAccount();
        setName();
        setFeeCharge(0);
        setFeeChargeInterbank(0);
        setPaymentMethodStatus();
        setPaymentMethod();

        setIsSuccessPopupOpen(false);
      }
    } else if (type === 'delete') {
      if (flow === 'openConfirm') {
        setIsConfirmPopupOpen(true);
      } else if (flow === 'closeConfirm') {
        setIsConfirmPopupOpen(false);
      } else if (flow === 'submit') {
        deleteMethod();
      } else if (flow === 'closeSuccess') {
        setIsSuccessPopupOpen(false);
      }
    } else if (type === 'view') {
      if (flow === 'openPopup') {
        const {
          bank,
          chartOfAccount,
          name,
          feeCharge,
          feeChargeInterbank,
          paymentMethodStatus,
          paymentMethod,
        } = value;

        setIdModal(id);
        setBank(bank);
        setChartOfAccount(chartOfAccount);
        setName(name);
        setFeeCharge(feeCharge);
        setFeeChargeInterbank(feeChargeInterbank);
        setPaymentMethodStatus(paymentMethodStatus);
        setPaymentMethod(paymentMethod);

        setIsEditPopupOpen(true);
      } else if (flow === 'closePopup') {
        setIdModal();
        setBank();
        setChartOfAccount();
        setName();
        setFeeCharge(0);
        setFeeChargeInterbank(0);
        setPaymentMethodStatus();
        setPaymentMethod();

        setIsEditPopupOpen(false);
      }
    }
  };

  const onTableChange = (pagination) => {
    setPagination(pagination);
  };

  //search
  const onSearchChange = (e) => {
    const convertValue = e.target.value.toLowerCase();
    const labelValue = e.target.value;
    setSearch(labelValue);

    const filteredData = listMethod.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          !isEmpty(item[key]) &&
          item[key].toString().toLowerCase().search(convertValue) !== -1
      );
    });

    setListFilteredMethod(filteredData);
  };

  const useInitData = () => {
    getBankList();
    getChartOfAccountList();
    getPaymentMethodStatusList();
    getPaymentMethodList();
    getMethodList();
  };

  const useEvaluateSubmitButton = () => {
    if (
      !!bank &&
      !!chartOfAccount &&
      !!name &&
      // (!!feeCharge || feeCharge === 0) &&
      // (!!feeChargeInterbank || feeChargeInterbank === 0) &&
      !!paymentMethodStatus &&
      !!paymentMethod
    ) {
      setIsSubmitButtonEnabled(true);
    } else {
      setIsSubmitButtonEnabled(false);
    }
  };

  useEffect(useInitData, []);

  useEffect(useEvaluateSubmitButton, [
    bank,
    chartOfAccount,
    name,
    feeCharge,
    feeChargeInterbank,
    paymentMethodStatus,
    paymentMethod,
  ]);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <div className="mb-15">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Manage Metode Pembayaran
              </strong>
            </Col>
          </Row>
        </div>
        <div className="panel">
          <Row>
            <Col span={12}>
              <div className="mb-15">
                <Button
                  style={{
                    color: '#FFF',
                    backgroundColor: '#32c5d2',
                    borderColor: '#32c5d2',
                  }}
                  onClick={() => handleFlow('new', 'openPopup')}>
                  <strong>TAMBAH METODE PEMBAYARAN</strong>
                </Button>
              </div>
            </Col>
            <Col span={12} type="flex" align="end">
              <div className="mb-15">
                <Button onClick={() => {}}>PRINT</Button>
                <Button onClick={() => {}}>COPY</Button>
                <Button onClick={() => {}}>PDF</Button>
                <Button onClick={() => {}}>EXCEL</Button>
                <Button onClick={() => {}}>CSV</Button>
                <Button onClick={getMethodList}>RELOAD</Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={2} type="flex" align="start">
              <></>
            </Col>
            <Col span={5} offset={17} type="flex" align="end">
              <div className="mb-15">
                <Input
                  className="skybase-dashboard__search-input"
                  placeholder="Search"
                  onChange={onSearchChange}
                  value={search}
                />
              </div>
            </Col>
          </Row>
          <Table
            dataSource={listFilteredMethod}
            rowKey="id"
            scroll={{ x: 1100, y: 500 }}
            onChange={onTableChange}
            pagination={pagination}
            columns={paymentMethodColumns(
              handleViewMethodClick,
              handleEditMethodClick,
              handleDeleteMethodClick
            )}
            className="skybase-dashboard__active-order-table"
          />
        </div>
      </Content>

      <Modal
        visible={isEditPopupOpen}
        width={'35%'}
        closable={false}
        footer={
          typeModal === 'view'
            ? [
                <Button
                  type="danger"
                  onClick={() => handleFlow(typeModal, 'closePopup')}>
                  Kembali
                </Button>,
              ]
            : [
                <Button
                  type="danger"
                  onClick={() => handleFlow(typeModal, 'closePopup')}>
                  Kembali
                </Button>,
                <Button
                  type="primary"
                  onClick={() => handleFlow(typeModal, 'openConfirm')}
                  disabled={!isSubmitButtonEnabled}>
                  Simpan
                </Button>,
              ]
        }>
        <Row type="flex" align="middle">
          <Col span={6} className="mb-5">
            <div>Bank</div>
          </Col>
          <Col span={18}>
            <div className="skybase-dashboard__search mb-5">
              {typeModal === 'view' ? (
                <Input readOnly value={bank} />
              ) : (
                <SelectDropdown
                  options={listBankOptions}
                  placeHolder="Bank"
                  onChange={handleBankChange}
                  value={bank}
                />
              )}
            </div>
          </Col>
        </Row>
        <Row type="flex" align="middle">
          <Col span={6} className="mb-5">
            <div>Chart Of Account</div>
          </Col>
          <Col span={18}>
            <div className="skybase-dashboard__search mb-5">
              {typeModal === 'view' ? (
                <Input readOnly value={chartOfAccount} />
              ) : (
                <SelectDropdown
                  options={listChartOfAccountOptions}
                  placeHolder="Chart Of Account"
                  onChange={handleChartOfAccountChange}
                  value={chartOfAccount}
                />
              )}
            </div>
          </Col>
        </Row>
        <Row type="flex" align="middle">
          <Col span={6} className="mb-5">
            <div>Nama</div>
          </Col>
          <Col span={18}>
            <div className="skybase-dashboard__search mb-5">
              <Input
                placeholder="Nama"
                onChange={handleNameChange}
                value={name}
                readOnly={typeModal === 'view'}
              />
            </div>
          </Col>
        </Row>
        <Row type="flex" align="middle">
          <Col span={6} className="mb-5">
            <div>Fee Charge</div>
          </Col>
          <Col span={18}>
            <div className="skybase-dashboard__search mb-5">
              <InputNumber
                placeholder="Fee Charge"
                onChange={handleFeeChargeChange}
                value={feeCharge}
                formatter={currencyFormatter}
                parser={currencyParser}
                style={{ textAlign: 'right' }}
                readOnly={typeModal === 'view'}
              />
            </div>
          </Col>
        </Row>
        {typeModal === 'view' ? (
          <></>
        ) : (
          <Row type="flex" align="middle">
            <Col span={6} className="mb-5">
              <div>Fee Charge Beda Bank</div>
            </Col>
            <Col span={18}>
              <div className="skybase-dashboard__search mb-5">
                <InputNumber
                  placeholder="Fee Charge Beda Bank"
                  onChange={handleFeeChargeInterbankChange}
                  value={feeChargeInterbank}
                  formatter={currencyFormatter}
                  parser={currencyParser}
                  style={{ textAlign: 'right' }}
                  readOnly={typeModal === 'view'}
                />
              </div>
            </Col>
          </Row>
        )}
        <Row type="flex" align="middle">
          <Col span={6} className="mb-5">
            <div>Status Metode Pembayaran</div>
          </Col>
          <Col span={18}>
            <div className="skybase-dashboard__search mb-5">
              {typeModal === 'view' ? (
                <Input readOnly value={paymentMethodStatus} />
              ) : (
                <SelectDropdown
                  options={listPaymentMethodStatusOptions}
                  placeHolder="Status Metode Pembayaran"
                  onChange={handlePaymentMethodStatusChange}
                  value={paymentMethodStatus}
                />
              )}
            </div>
          </Col>
        </Row>
        {typeModal === 'view' ? (
          <></>
        ) : (
          <Row type="flex" align="middle">
            <Col span={6} className="mb-5">
              <div>Jenis Master Pembayaran</div>
            </Col>
            <Col span={18}>
              <div className="skybase-dashboard__search mb-5">
                {typeModal === 'view' ? (
                  <Input readOnly value={paymentMethod} />
                ) : (
                  <SelectDropdown
                    options={listPaymentMethodOptions}
                    placeHolder="Jenis Master Pembayaran"
                    onChange={handlePaymentMethodChange}
                    value={paymentMethod}
                  />
                )}
              </div>
            </Col>
          </Row>
        )}
      </Modal>

      <Modal
        visible={isConfirmPopupOpen}
        width={'35%'}
        closable={false}
        footer={[
          <Button
            type="default"
            onClick={() => handleFlow(typeModal, 'closeConfirm')}>
            Tidak
          </Button>,
          <Button type="danger" onClick={() => handleFlow(typeModal, 'submit')}>
            Ya
          </Button>,
        ]}>
        <Row>
          <div className="mt-10 mb-10">
            <ExclamationCircleTwoTone
              twoToneColor="#f8bb86"
              style={{ fontSize: 50 }}
            />
          </div>
        </Row>
        {typeModal === 'delete' ? (
          <>
            <Row>
              <div className="mb-10">
                <strong>Anda sudah yakin melakukan penghapusan data?</strong>
              </div>
            </Row>
            <Row>
              <div>Data yang sudah dihapus tidak dapat dikembalikan</div>
            </Row>
          </>
        ) : (
          <Row>
            <div>
              <strong>Apakah data sudah sesuai?</strong>
            </div>
          </Row>
        )}
      </Modal>

      <Modal
        visible={isSuccessPopupOpen}
        width={'35%'}
        closable={false}
        footer={[
          <Button
            type="primary"
            onClick={() => handleFlow(typeModal, 'closeSuccess')}>
            OK
          </Button>,
        ]}>
        <Row>
          <div className="mt-10 mb-10">
            <CheckCircleTwoTone
              twoToneColor="#a5dc86"
              style={{ fontSize: 50 }}
            />
          </div>
        </Row>
        <Row>
          <div className="mb-10">
            <strong>Success</strong>
          </div>
        </Row>
        <Row>
          <div>
            Data berhasil {typeModal === 'delete' ? 'dihapus' : 'disimpan'}
          </div>
        </Row>
      </Modal>
    </Layout>
  );
}

export default withRouter(ManageMetodePembayaran);
