import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import { useOuterClickNotifier } from 'utils/hooks';

const { Header } = Layout;

export function HeaderComponent({ toggleCollapsed, collapse }) {

  const [userShow, setUserShow] = useState(false);
  const innerRef = useRef(null);
  const handleUserShow = () => {
    setUserShow(!userShow);
  };

  async function handleLogout() {
    const cms_token = localStorage.getItem('cms_token');

    if (cms_token) {
      localStorage.removeItem('cms_token');
    }
  }

  useOuterClickNotifier(handleUserShow, innerRef);

  return (
    // <Header className={!isEmbed ? 'header' : 'header embed'}>
    <Header className="header embed">
      {/* {!isEmbed ? (
        <Row>
          <Col span={12}>
            <Button onClick={toggleCollapsed}>
              <Icon type={collapse ? 'menu-unfold' : 'menu-fold'} />
            </Button>
          </Col>
          <Col span={12} type="flex" align="end">
            <Button onClick={handleUserShow}>
              <Icon type="user" />
            </Button>
          </Col>
        </Row>
      ) : (
        <></>
      )} */}

      {userShow && (
        <div className="header__user-content" ref={innerRef}>
          <div>
            <Link onClick={handleLogout} to={'/'}>
              Logout
            </Link>
          </div>
        </div>
      )}
    </Header>
  );
}

export default HeaderComponent;
