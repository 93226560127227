import React, { useEffect, useState, useRef } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { Col, Row, Layout, Button } from 'antd';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { PageSpinner } from 'components/page-spinner';
import AppointmentApi from 'api/appointment';
import TranscationApi from 'api/transaction';
import { currencyFormatter } from 'utils';
import Terbilang from 'terbilang';

import '../styles/index.scss';

const { Content } = Layout;

export function KwitansiKwitansiPembayaran({ history }) {
  const [patientDetail, setPatientDetail] = useState();

  const [receiptDetail, setReceiptDetail] = useState();

  const [loadingPage, setLoadingPage] = useState(false);

  const printComponentRef = useRef();

  const { id } = useParams();

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const getPatientDetail = async () => {
    try {
      setLoadingPage(true);

      const appointmentId = id.split('_')[0];

      const response = await AppointmentApi.getPatientDetail(appointmentId);

      let patient = response.data.data;

      setPatientDetail(patient);
    } finally {
      setLoadingPage(false);
    }
  };

  const getReceiptDetail = async () => {
    try {
      setLoadingPage(true);

      const receiptId = id.split('_')[2];

      const response = await TranscationApi.getReceiptDetailById(receiptId);

      const receipt = response.data.data;

      setReceiptDetail(receipt);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleTreatmentList = (treatmentList) => {
    let treatment = '';

    for (let index = 0; index < treatmentList.length; index++) {
      treatment += `${treatmentList[index].description}, `;
    }

    return treatment.trim().slice(0, -1);
  };

  const redirectToPreviousPage = () => {
    history.goBack();
  };

  const redirectToManageCetakKwitansi = () => {
    history.push('/kasir/manage-cetak-kasir-pasien');
  };

  const redirectToPrintPembayaran = () => {
    const appointmentId = id.split('_')[0];

    const receiptNumber = id.split('_')[1];

    history.push(`/kasir/kwitansi/${appointmentId}_${receiptNumber}`);
  };

  const redirectToStrukPembayaran = () => {
    const appointmentId = id.split('_')[0];

    const receiptNumber = id.split('_')[1];

    const receiptId = id.split('_')[2];

    history.push(
      `/kasir/struk-pembayaran/${appointmentId}_${receiptNumber}_${receiptId}`
    );
  };

  const redirectToKwitansiPembayaran = () => {
    window.location.reload();
  };

  const redirectToRincianPembayaran = () => {
    const appointmentId = id.split('_')[0];

    const receiptNumber = id.split('_')[1];

    const receiptId = id.split('_')[2];

    history.push(
      `/kasir/rincian-pembayaran/${appointmentId}_${receiptNumber}_${receiptId}`
    );
  };

  const useInitData = () => {
    if (id) {
      getPatientDetail();
      getReceiptDetail();
    }
  };

  useEffect(useInitData, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <div className="mb-15">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Kwitansi Pembayaran
              </strong>
            </Col>
          </Row>
        </div>
        {!!patientDetail && !!receiptDetail && (
          <>
            <div className="mb-50">
              <Row>
                <Col span={24} type="flex" align="middle">
                  <div className="mb-15">
                    <Button
                      type="danger"
                      style={{ marginRight: '5px' }}
                      onClick={redirectToPreviousPage}>
                      KEMBALI
                    </Button>
                    <Button
                      type="primary"
                      style={{
                        marginRight: '5px',
                        color: '#FFF',
                        backgroundColor: '#4DB3A2',
                        borderColor: '#4DB3A2',
                      }}
                      onClick={redirectToManageCetakKwitansi}>
                      KEMBALI MANAGE KWITANSI
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24} type="flex" align="middle">
                  <Button
                    type="primary"
                    style={{ marginRight: '5px' }}
                    onClick={redirectToPrintPembayaran}>
                    PRINT PEMBAYARAN
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginRight: '5px' }}
                    onClick={redirectToStrukPembayaran}>
                    STRUK PEMBAYARAN
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      marginRight: '5px',
                      color: '#FFF',
                      backgroundColor: '#32c5d2',
                      borderColor: '#32c5d2',
                    }}
                    onClick={redirectToKwitansiPembayaran}>
                    KWITANSI PEMBAYARAN
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      marginRight: '5px',
                      color: '#FFF',
                      backgroundColor: '#c49f47',
                      borderColor: '#c49f47',
                    }}
                    onClick={redirectToRincianPembayaran}>
                    RINCIAN PEMBAYARAN
                  </Button>
                  {/* <Button
                    type="primary"
                    style={{
                      marginRight: '5px',
                      color: '#FFF',
                      backgroundColor: '#4DB3A2',
                      borderColor: '#4DB3A2',
                    }}>
                    KIRIM EMAIL KWITANSI
                  </Button> */}
                  <Button
                    type="danger"
                    style={{ marginRight: '5px' }}
                    onClick={handlePrint}>
                    PRINT HALAMAN
                  </Button>
                </Col>
              </Row>
            </div>
            <div className="receipt-content">
              <div ref={printComponentRef} style={{ padding: '40px' }}>
                <div className="mb-15">
                  <Row>
                    <Col span={24} type="flex" align="middle">
                      <strong style={{ fontSize: '40px' }}>RATA.ID</strong>
                    </Col>
                  </Row>
                </div>
                <div className="mb-15">
                  <Row>
                    <Col span={24} type="flex" align="middle">
                      <strong style={{ fontSize: '10px' }}>Jakarta</strong>
                    </Col>
                  </Row>
                </div>
                <div className="mb-30">
                  <Row>
                    <Col span={24} type="flex" align="middle">
                      <strong>KWITANSI PEMBAYARAN</strong>
                    </Col>
                  </Row>
                </div>
                <div className="mb-30">
                  <Row>
                    <Col span={24} type="flex" align="middle">
                      <strong>
                        <u>NO : {receiptDetail.receiptNumber}</u>
                      </strong>
                    </Col>
                  </Row>
                </div>
                <div className="mb-5">
                  <Row>
                    <Col span={6}>
                      <strong>SUDAH TERIMA DARI</strong>
                    </Col>
                    <Col span={1}>
                      <strong>:</strong>
                    </Col>
                    <Col span={17}>
                      <strong>
                        {`${patientDetail.patientName} - ${patientDetail.patient.id}`}
                      </strong>
                    </Col>
                  </Row>
                </div>
                <div className="mb-5">
                  <Row>
                    <Col span={6}>
                      <strong>UANG SEJUMLAH</strong>
                    </Col>
                    <Col span={1}>
                      <strong>:</strong>
                    </Col>
                    <Col span={17}>
                      <strong>{Terbilang(receiptDetail.grandTotal)}</strong>
                    </Col>
                  </Row>
                </div>
                <div className="mb-15">
                  <Row>
                    <Col span={6}>
                      <div>
                        <strong>UNTUK PEMBAYARAN</strong>
                      </div>
                    </Col>
                    <Col span={1}>
                      <strong>:</strong>
                    </Col>
                    <Col span={17}>
                      <strong>
                        {handleTreatmentList(receiptDetail.slipTreatmentList)}
                      </strong>
                    </Col>
                  </Row>
                </div>
                <div className="mb-15">
                  <Row>
                    <Col span={6}></Col>
                    <Col span={1}></Col>
                    <Col span={17}>
                      <strong
                        style={{ fontSize: '30px' }}>{`Rp ${currencyFormatter(
                        receiptDetail.grandTotal
                      )}`}</strong>
                    </Col>
                  </Row>
                </div>
                <div className="mb-45">
                  <Row>
                    <Col span={4} offset={18} type="flex" align="middle">
                      <strong>{moment().format('DD MMMM YYYY')}</strong>
                    </Col>
                  </Row>
                </div>
                <div className="mb-15">
                  <Row>
                    <Col span={4} offset={18} type="flex" align="middle">
                      <strong>RATA Klinik</strong>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </>
        )}
      </Content>
    </Layout>
  );
}

export default withRouter(KwitansiKwitansiPembayaran);
