import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Layout,
  Table,
  Button,
  Input,
  DatePicker,
  Modal,
  Collapse,
  Tabs,
  Steps,
} from 'antd';
import { DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import UserApi from 'api/user';
import ClinicApi from 'api/clinic';
import InsuranceApi from 'api/insurance';
import { PageSpinner } from 'components/page-spinner';
import SelectDropdown from 'components/select-dropdown';
import MultiselectDropdown from 'components/multiselect-dropdown';
import { setToArray } from 'utils';
import XLSX from 'xlsx';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

import {
  insuranceReportWaitingForDocumentsColumns,
  insuranceReportColumns,
  excessInsuranceColumns,
  insuranceReportDocumentsInProgressColumns,
  insuranceReportApprovedByFinanceColumns,
} from '../helper/';
import '../styles/index.scss';

const { Content } = Layout;
const { Panel } = Collapse;
const { Search, TextArea } = Input;
const { TabPane } = Tabs;
const { Step } = Steps;

export function InsuranceReport({ history }) {
  const listDateRange = [
    {
      label: 'Last 7 Days',
      value: 'SEVEN',
    },
    {
      label: 'Last 30 Days',
      value: 'THIRTY',
    },
    {
      label: 'Custom Range',
      value: 'CUSTOM',
    },
  ];

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [rangeDate, setRangeDate] = useState();

  const [listClinic, setListClinic] = useState([]);
  const [listDoctor, setListDoctor] = useState([]);

  const [listClinicOptions, setListClinicOptions] = useState([]);
  const [listDoctorOptions, setListDoctorOptions] = useState([]);

  // const [listCashierDetail, setListCashierDetail] = useState([]);
  // const [listFilteredCashierDetail, setListFilteredCashierDetail] = useState(
  //   []
  // );

  // const [listInsurance, setListInsurance] = useState([]);
  const [listFilteredInsurance, setListFilteredInsurance] = useState([]);

  const [insuranceDetail, setInsuranceDetail] = useState();
  const [insuranceRevisionNote, setInsuranceRevisionNote] = useState();
  const [listInsuranceLoa, setListInsuranceLoa] = useState([]);
  const [listInsuranceLoc, setListInsuranceLoc] = useState([]);
  const [listInsuranceDocs, setListInsuranceDocs] = useState([]);
  const [isInsuranceDetailModalShown, setIsInsuranceDetailModalShown] =
    useState(false);
  const [isInsuranceApproveModalShown, setIsInsuranceApproveModalShown] =
    useState(false);
  const [isInsuranceReviseModalShown, setIsInsuranceReviseModalShown] =
    useState(false);
  const [isInsuranceDisbursementDateModalShown, setIsInsuranceDisbursementDateModalShown] =
    useState(false);
  const [disbursementDate, setDisbursementDate] = useState();
  // const [isInsuranceDocumentsModalShown, setIsInsuranceDocumentsModalShown] =
  //   useState(false);

  const [tabActiveKey, setTabActiveKey] = useState('1');

  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);

  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    showSizeChanger: true,
    total: 0,
  });

  const { status } = useParams();
  const redirect = useHistory();
  const { confirm } = Modal;

  const generateInsuranceStatus = (lastStatus) => {
    switch (lastStatus) {
      case 'WAITING_FOR_DOCUMENTS':
      case 'waiting-for-documents':
        return 'Waiting for Documents';
      case 'DOCUMENTS_IN_PROGRESS':
      case 'documents-in-progress':
        return 'Documents in Progress';
      case 'APPROVED_BY_INSURANCE':
      case 'approved-by-insurance':
        return 'Approved by Insurance';
      case 'APPROVED_BY_FINANCE':
      case 'approved-by-finance':
        return 'Approved by Finance';
      default:
        break;
    }
  };

  const getInsuranceList = async (limit, page) => {
    try {
      setLoadingTable(true);

      let chosenStatus = '';

      switch (status) {
        case 'waiting-for-documents':
          chosenStatus = 'WAITING_FOR_DOCUMENTS';
          break;
        case 'documents-in-progress':
          chosenStatus = 'DOCUMENTS_IN_PROGRESS';
          break;
        case 'approved-by-finance':
          chosenStatus = 'APPROVED_BY_FINANCE';
          break;
        case 'approved-by-insurance':
          chosenStatus = 'APPROVED_BY_INSURANCE';
          break;
        default:
          break;
      }

      const clinicIds = listClinic
        .map((item) => {
          return item;
        })
        .join(',');

      const doctorIds = listDoctor
        .map((item) => {
          return item;
        })
        .join(',');

      const response = await InsuranceApi.getInsuranceList(
        chosenStatus,
        listClinic.length > 0 ? clinicIds : '',
        listDoctor.length > 0 ? doctorIds : '',
        search,
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
        limit,
        page
      );

      console.log(response);
      let insuranceList = setToArray(response.data);

      const modifiedInsuranceList = insuranceList.map((item, index) => ({
        ...item,
        key: index + 1,
        lastStatusLabel: generateInsuranceStatus(item.lastStatus),
      }));

      // setListInsurance(modifiedInsuranceList);
      setListFilteredInsurance(modifiedInsuranceList);

      const paginationConfig = {
        ...pagination,
        current: response.data.data.page,
        pageSize: response.data.data.limit,
        total: response.data.data.totalData,
      };

      setPagination(paginationConfig);
    } catch (error) {
      Modal.warning({
        title: 'Perhatian',
        content: 'Terjadi kesalahan pada server.',
      });
      console.log(error);
    } finally {
      setLoadingTable(false);
    }
  };

  const getInsuranceDetail = async (receiptId) => {
    try {
      const response = await InsuranceApi.getInsuranceDetail(receiptId);

      let insurance = response.data.data;

      setInsuranceDetail(insurance);

      if (!!insurance.loaUrl) {
        const loaDocs = [];

        loaDocs.push({
          uri: insurance.loaUrl,
          fileType: insurance.loaFilename.split('.').pop(),
        });

        setListInsuranceLoa(loaDocs);
      }

      if (!!insurance.locUrl) {
        const locDocs = [];

        locDocs.push({
          uri: insurance.locUrl,
          fileType: insurance.locFilename.split('.').pop(),
        });

        setListInsuranceLoc(locDocs);
      }

      if (!!insurance.documents && insurance.documents.length > 0) {
        const insuranceDocs = [];

        insurance.documents.forEach((item) => {
          insuranceDocs.push({
            uri: item.url,
            fileType: item.filename.split('.').pop(),
          });
        });

        setListInsuranceDocs(insuranceDocs);
      }
    } catch (error) {
      Modal.warning({
        title: 'Perhatian',
        content: 'Terjadi kesalahan pada server.',
      });
      console.log(error);
    }
  };

  async function getClinicList() {
    try {
      setLoadingPage(true);

      const response = await ClinicApi.listClinic();

      let clinicList = setToArray(response.data);

      const clinicListOpts = clinicList.map((value) => ({
        label: value.clinicName,
        value: value.id,
      }));

      setListClinicOptions(clinicListOpts);
    } finally {
      setLoadingPage(false);
    }
  }

  async function getDoctorList() {
    try {
      setLoadingPage(true);

      const response = await UserApi.getDoctorList();

      let doctorList = Object.values(response.data.result);

      const doctorListOpts = doctorList.map((value) => ({
        label: value.name,
        value: value.id_user,
      }));

      setListDoctorOptions(doctorListOpts);
    } finally {
      setLoadingPage(false);
    }
  }

  async function generateExcelReport() {
    try {
      setLoadingExport(true);

      let chosenStatus = '';

      switch (status) {
        case 'waiting-for-documents':
          chosenStatus = 'WAITING_FOR_DOCUMENTS';
          break;
        case 'documents-in-progress':
          chosenStatus = 'DOCUMENTS_IN_PROGRESS';
          break;
        case 'approved-by-insurance':
          chosenStatus = 'APPROVED_BY_INSURANCE';
          break;
        default:
          break;
      }

      const clinicIds = listClinic
        .map((item) => {
          return item;
        })
        .join(',');

      const doctorIds = listDoctor
        .map((item) => {
          return item;
        })
        .join(',');

      const response = await InsuranceApi.getInsuranceList(
        chosenStatus,
        listClinic.length > 0 ? clinicIds : '',
        listDoctor.length > 0 ? doctorIds : '',
        search,
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD')
        // limit,
        // page
      );

      let insuranceList = setToArray(response.data);

      let arrayExcel;

      if (status === 'waiting-for-documents') {
        arrayExcel = [
          [
            'No',
            'Patient Name',
            'MR Number',
            'Phone Number',
            'Email',
            'Claim Status',
            'Insurance Company',
            'Insurance Card ID',
            'Provider',
            'Service',
            'Last Updated By',
            'Doctor Name',
            'Clinic Name',
            'Cashier Name',
          ],
        ];
      } else {
        arrayExcel = [
          [
            'No',
            'Patient Name',
            'MR Number',
            'Phone Number',
            'Email',
            'Claim Status',
            'Insurance Company',
            'Insurance Card ID',
            'Provider',
            'Service',
            'Letter of Authorization',
            'Letter of Confirmation',
            'Supporting Documents',
            'Last Updated By',
            'Doctor Name',
            'Clinic Name',
            'Cashier Name',
          ],
        ];
      }

      insuranceList.forEach((item, index) => {
        if (status === 'waiting-for-documents') {
          arrayExcel.push([
            index + 1, // No
            item.patientName ? item.patientName : '-', // Patient Name
            item.patientMedicalRecordNumber
              ? item.patientMedicalRecordNumber
              : '-', // MR Number
            item.patientPhone ? item.patientPhone : '-', // Phone Number
            item.patientEmail ? item.patientEmail : '-', // Email
            item.lastStatus ? generateInsuranceStatus(item.lastStatus) : '-', // Claim Status
            item.insuranceCompanyName ? item.insuranceCompanyName : '-', // Insurance Company
            item.patientInsuranceNumber ? item.patientInsuranceNumber : '-', // Insurance Card ID
            item.insuranceProviderName ? item.insuranceProviderName : '-', // Provider
            item.treatments ? item.treatments : '-', // Service
            item.lastUpdatedBy ? item.lastUpdatedBy : '-', // Last Updated By
            item.doctorName ? item.doctorName : '-', // Doctor Name
            item.clinicName ? item.clinicName : '-', // Clinic Name
            item.cashierName ? item.cashierName : '-', // Cashier Name
          ]);
        } else {
          arrayExcel.push([
            index + 1, // No
            item.patientName ? item.patientName : '-', // Patient Name
            item.patientMedicalRecordNumber
              ? item.patientMedicalRecordNumber
              : '-', // MR Number
            item.patientPhone ? item.patientPhone : '-', // Phone Number
            item.patientEmail ? item.patientEmail : '-', // Email
            item.lastStatus ? generateInsuranceStatus(item.lastStatus) : '-', // Claim Status
            item.insuranceCompanyName ? item.insuranceCompanyName : '-', // Insurance Company
            item.patientInsuranceNumber ? item.patientInsuranceNumber : '-', // Insurance Card ID
            item.insuranceProviderName ? item.insuranceProviderName : '-', // Provider
            item.treatments ? item.treatments : '-', // Service
            item.loaFilename ? item.loaFilename : '-', // Letter of Authorization
            item.locFilename ? item.locFilename : '-', // Letter of Confirmation
            item.documentCount ? `${item.documentCount} documents` : '-', // Supporting Documents
            item.lastUpdatedBy ? item.lastUpdatedBy : '-', // Last Updated By
            item.doctorName ? item.doctorName : '-', // Doctor Name
            item.clinicName ? item.clinicName : '-', // Clinic Name
            item.cashierName ? item.cashierName : '-', // Cashier Name
          ]);
        }
      });

      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.aoa_to_sheet(arrayExcel);
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        `Insurance ${generateInsuranceStatus(status)}`
      );

      const fileName = `Insurance ${generateInsuranceStatus(
        status
      )}-${moment().format('YYYY-MM-DD')}.xlsx`;

      /* Trigger Download with `writeFile` */
      XLSX.writeFile(wb, fileName, { compression: true });
    } catch (error) {
      Modal.warning({
        title: 'PERHATIAN',
        content: 'Terjadi error pada server. Silahkan coba lagi!',
      });
    } finally {
      setLoadingExport(false);
    }
  }

  const handleClinicChange = (value) => {
    setListClinic(value);
  };

  const handleDoctorChange = (value) => {
    setListDoctor(value);
  };

  const handleStartDateChange = (date, dateString) => {
    if (!date) setStartDate(moment());
    else setStartDate(date);
  };

  const handleEndDateChange = (date, dateString) => {
    if (!date) setEndDate(moment());
    else setEndDate(date);
  };

  const handleRangeDateChange = (value) => {
    const today = moment();

    if (value === 'SEVEN') {
      const sevenDaysAgo = moment().subtract(7, 'd');

      setStartDate(sevenDaysAgo);
      setEndDate(today);
    } else if (value === 'THIRTY') {
      const thirtyDaysAgo = moment().subtract(30, 'd');

      setStartDate(thirtyDaysAgo);
      setEndDate(today);
    } else {
      setStartDate();
      setEndDate();
    }

    setRangeDate(value);
  };

  const onTableChange = (pagination) => {
    getInsuranceList(pagination.pageSize, pagination.current);
    setPagination(pagination);
  };

  //search
  // const onSearchChange = (e) => {
  //   const convertValue = e.target.value.toLowerCase();
  //   const labelValue = e.target.value;
  //   setSearch(labelValue);
  //
  //   const filteredData = listCashierDetail.filter((item) => {
  //     return Object.keys(item).some(
  //       (key) =>
  //         !isEmpty(item[key]) &&
  //         item[key].toString().toLowerCase().search(convertValue) !== -1
  //     );
  //   });
  //
  //   setListFilteredCashierDetail(filteredData);
  // };

  const handleSearchKeywordChange = (event) => {
    setSearch(event.target.value);
  };

  const handleSearchKeywordKeyPress = () => {
    getInsuranceList(10, 1);
  };

  const handleSearchOnSearch = () => {
    if (!!search) {
      getInsuranceList(10, 1);
    }
  };

  const handleInsuranceModal = (isShown, receiptId, chosenTabActiveKey) => {
    try {
      setLoadingTable(true);

      setIsInsuranceDetailModalShown(isShown);

      if (isShown) {
        getInsuranceDetail(receiptId);
        handleInsuranceTab(chosenTabActiveKey);
      } else {
        setInsuranceDetail();
        setListInsuranceDocs([]);
        setTabActiveKey('1');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTable(false);
    }
  };

  const handleInsuranceTab = (chosenTabActiveKey) => {
    setTabActiveKey(chosenTabActiveKey);
  };

  const handleApproveInsuranceModal = (receiptId, status) => {
    confirm({
      title : 'Are you sure that you want to approve this insurance?',
      icon:<ExclamationCircleOutlined />,
      content: '',
      onOk : async() => {
        const payload = {
          status,
          lastUpdatedBy : localStorage.getItem('name') ? localStorage.getItem('name') : ''
        }
        const response = await InsuranceApi.updateInsuranceStatus(payload, receiptId);
        if (response.data.status === 'SUCCESS') {
          let statusQuery = '';
          if (status === 'APPROVED_BY_FINANCE') {
            statusQuery = 'approved-by-finance';
            window.parent.postMessage(statusQuery, '*');
          }
        } else {
          Modal.warning({
            title: 'Perhatian',
            content: 'Terjadi kesalahan pada server.',
          });
        }
      },
      onCancel() {
      },
    })
  };

  const handleReviseInsuranceModal = async (isShown, receiptId) => {
    try {
      if (receiptId) {
        await getInsuranceDetail(receiptId);
      }

      if (isShown) {
        setIsInsuranceReviseModalShown(isShown);
      } else {
        setInsuranceRevisionNote();
        setIsInsuranceReviseModalShown(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDisbursementDateModal = async (isShown, receiptId) => {
    try {
      // setLoadingTable(true);
      setIsInsuranceDisbursementDateModalShown(isShown);
      if (isShown) {
        await getInsuranceDetail(receiptId);
      }
    } catch (error) {
     console.log(error);
    } finally {
      // setLoadingTable(false);
    }
  };

  const handleDisbursementDateChange = (date, dateString) => {
    setDisbursementDate(date);
  };

  const handleSubmitDisbursementDate = async (receiptId) => {
    try {
      if (!disbursementDate) {
        document.getElementById('disbursementDateWarning').innerHTML = 'Data tidak boleh kosong';
      } else {
        document.getElementById('disbursementDateWarning').innerHTML = '';
        const payload = {
          status : 'APPROVED_BY_INSURANCE',
          lastUpdatedBy : localStorage.getItem('name') ? localStorage.getItem('name') : '',
          disbursementDate : disbursementDate,
        }
        const response = await InsuranceApi.updateInsuranceStatus(payload, receiptId);
        console.log(response);
        if (response.data.status === 'SUCCESS') {
          setIsInsuranceDisbursementDateModalShown(false);
          window.parent.postMessage('approved-by-insurance', '*');
        } else {
          Modal.warning({
            title : 'Perhatian',
            content: 'Terjadi kesalahan pada server.',
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitRevisionNote = async (receiptId) => {
    if (!insuranceRevisionNote) {
      document.getElementById('insuranceRevisionNoteWarning').innerHTML = 'Data tidak boleh kosong';
    } else {
      document.getElementById('insuranceRevisionNoteWarning').innerHTML = '';
      const payload = {
        status : 'WAITING_FOR_DOCUMENTS',
        lastUpdatedBy : localStorage.getItem('name') ? localStorage.getItem('name') : '',
        revisionNote : insuranceRevisionNote
      }
      const response = await InsuranceApi.updateInsuranceStatus(payload, receiptId);
      if (response.data.status === 'SUCCESS') {
        setIsInsuranceReviseModalShown(false);
        window.parent.postMessage('waiting-for-documents', '*');
      } else {
        Modal.warning({
          title: 'Perhatian',
          content: 'Terjadi kesalahan pada server.',
        });
      }
    }
  }

  const resetAllFields = () => {
    setRangeDate();
    setStartDate();
    setEndDate();
    setListClinic([]);
    setListDoctor([]);

    // getInsuranceList(10, 1);
  };

  const useInitData = () => {
    getInsuranceList(pagination.pageSize, pagination.current);
    getClinicList();
    getDoctorList();
  };

  useEffect(useInitData, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <Collapse
          defaultActiveKey={['1']}
          expandIconPosition="right"
          className="mb-15">
          <Panel
            header={
              <strong className="text-md skybase-dashboard__title">
                Filter
              </strong>
            }
            key="1">
            <div>
              <Row gutter={[16, 8]}>
                <Col span={8}>
                  Date
                  <SelectDropdown
                    options={listDateRange}
                    placeHolder="Select Range Date"
                    onChange={handleRangeDateChange}
                    value={rangeDate}
                    allowClear
                  />
                </Col>
                <Col span={8}>
                  Start Date
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <DatePicker
                        showToday
                        allowClear={false}
                        format="DD/MM/YYYY"
                        onChange={handleStartDateChange}
                        value={startDate}
                        placeholder="Start Date"
                        disabled={rangeDate !== 'CUSTOM'}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  End Date
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <DatePicker
                        showToday
                        allowClear={false}
                        format="DD/MM/YYYY"
                        onChange={handleEndDateChange}
                        value={endDate}
                        placeholder="End Date"
                        disabled={rangeDate !== 'CUSTOM'}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col span={8}>
                  Clinics
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <MultiselectDropdown
                        options={listClinicOptions}
                        placeHolder="Select Clinics"
                        showArrow
                        onChange={handleClinicChange}
                        selectValue={listClinic}
                        allowClear
                      />
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  Doctors
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <MultiselectDropdown
                        options={listDoctorOptions}
                        placeHolder="Select Doctors"
                        showArrow
                        onChange={handleDoctorChange}
                        selectValue={listDoctor}
                        allowClear
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <hr></hr>
              <Row className="mt-20 mb-10">
                <Col span={24} type="flex" align="end">
                  <Row gutter={[16, 8]}>
                    <Button onClick={resetAllFields}>Reset</Button>
                    <Button
                      type="primary"
                      style={{ marginLeft: '10px', marginRight: '10px' }}
                      onClick={() => getInsuranceList(10, 1)}>
                      Filter
                    </Button>
                  </Row>
                </Col>
              </Row>
            </div>
          </Panel>
        </Collapse>
        <div className="panel">
          <Row>
            <Col span={24}>
              <div className="mb-15">
                <strong className="text-md skybase-dashboard__title">
                  List of {generateInsuranceStatus(status)}
                </strong>
              </div>
            </Col>
          </Row>
          {loadingTable ? (
            <PageSpinner />
          ) : (
            <>
              <Row>
                <Col span={2} type="flex" align="start">
                  <div className="mb-15">
                    <Button
                      type="default"
                      onClick={generateExcelReport}
                      loading={loadingExport}
                      style={{ color: '#00C5DC', border: '1px solid' }}>
                      <DownloadOutlined />
                      Export File
                    </Button>
                  </div>
                </Col>
                <Col span={5} offset={17} type="flex" align="end">
                  <div className="mb-15">
                    <Search
                      placeholder="Medical Record / Name / Phone"
                      onChange={handleSearchKeywordChange}
                      onPressEnter={handleSearchKeywordKeyPress}
                      onSearch={handleSearchOnSearch}
                      value={search}
                      allowClear
                    />
                  </div>
                </Col>
              </Row>
              <Table
                dataSource={listFilteredInsurance}
                rowKey="key"
                columns={
                  status === 'waiting-for-documents'
                    ? insuranceReportWaitingForDocumentsColumns(
                        handleInsuranceModal,
                        status
                      )
                    : status === 'documents-in-progress'
                    ? insuranceReportDocumentsInProgressColumns(handleInsuranceModal, handleApproveInsuranceModal, handleReviseInsuranceModal)
                    :  status === 'approved-by-finance'
                    ? insuranceReportApprovedByFinanceColumns(handleInsuranceModal, handleDisbursementDateModal)
                    :
                    insuranceReportColumns(handleInsuranceModal, status)
                }
                onChange={onTableChange}
                className="skybase-dashboard__active-order-table"
                pagination={pagination}
                size="small"
                scroll={{ x: 2000, y: 500 }}
              />
            </>
          )}
        </div>
      </Content>

      <Modal
        visible={isInsuranceDetailModalShown}
        title={<>Insurance</>}
        width={'90vw'}
        closable={true}
        onCancel={() => handleInsuranceModal(false)}
        footer={null}>
        <Row className="mb-15">
          <Col span={24}>
            <Tabs
              activeKey={tabActiveKey}
              onChange={(activeKey) => handleInsuranceTab(activeKey)}>
              <TabPane tab="Service" key="1">
                {!!insuranceDetail && (
                  <Table
                    dataSource={insuranceDetail.treatments}
                    rowKey="key"
                    columns={excessInsuranceColumns}
                    pagination={false}
                    size="small"
                  />
                )}
              </TabPane>
              <TabPane tab="History" key="2">
                {!!insuranceDetail && (
                  <Steps progressDot current={0} direction="vertical">
                    {insuranceDetail.history.map((item) => (
                      <Step
                        title={<>{generateInsuranceStatus(item.status)}</>}
                        subTitle={
                          <>
                            <br></br>
                            {moment(item.createdAt).format('DD MMM YYYY HH:mm')}
                          </>
                        }
                        description={<>{`by ${item.createdBy}`}</>}
                      />
                    ))}
                  </Steps>
                )}
              </TabPane>
              {status !== 'waiting-for-documents' && (
                <TabPane tab="LoA" key="3">
                  {!!listInsuranceLoa && listInsuranceLoa.length > 0 && (
                    <DocViewer
                      documents={listInsuranceLoa}
                      pluginRenderers={DocViewerRenderers}
                    />
                  )}
                </TabPane>
              )}
              {status !== 'waiting-for-documents' && (
                <TabPane tab="LoC" key="4">
                  {!!listInsuranceLoc && listInsuranceLoc.length > 0 && (
                    <DocViewer
                      documents={listInsuranceLoc}
                      pluginRenderers={DocViewerRenderers}
                    />
                  )}
                </TabPane>
              )}
              {status !== 'waiting-for-documents' && (
                <TabPane tab="Documents" key="5">
                  {!!listInsuranceDocs && listInsuranceDocs.length > 0 && (
                    <DocViewer
                      documents={listInsuranceDocs}
                      pluginRenderers={DocViewerRenderers}
                    />
                  )}
                </TabPane>
              )}
            </Tabs>
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={isInsuranceReviseModalShown}
        title={<>Insurance</>}
        closable={true}
        onCancel={() => {handleReviseInsuranceModal(false)}}
        footer={[
          <Button
            onClick={() => {handleReviseInsuranceModal(false)}}
          >
            Cancel
          </Button>,
          <Button
            type='primary'
            onClick={() => handleSubmitRevisionNote(insuranceDetail.receiptId)}
          >
            Submit
          </Button>,
        ]}
      >
        <div className='mb-15'>
          <label for='revisedNotes'>Revised Notes *</label>
          <TextArea
            autoSize={{ minRows : 3, maxRows: 5}}
            placeholder='Document Revised Notes'
            id='revisedNotes'
            required
            onChange={(e) => setInsuranceRevisionNote(e.target.value)}
            value={insuranceRevisionNote}
          />
          <small style={{color: 'red'}} id='insuranceRevisionNoteWarning'></small>
        </div>
      </Modal>

      <Modal
        visible={isInsuranceDisbursementDateModalShown}
        title={<>Disbursement Date</>}
        closable={true}
        onCancel={() => {handleDisbursementDateModal(false)}}
        footer={[
          <Button onClick={() => handleDisbursementDateModal(false)}>
            Cancel
          </Button>,
          <Button
            type='primary'
            onClick={() => handleSubmitDisbursementDate(insuranceDetail.receiptId)}
          >
            Submit
          </Button>
        ]}
      >
        <DatePicker
          size='large'
          allowClear={true}
          showToday
          format='DD/MM/YYYY'
          placeholder='Disbursement Date'
          onChange={handleDisbursementDateChange}
          value={disbursementDate}
        />
        <small style={{color: 'red'}} id='disbursementDateWarning'></small>
      </Modal>
    </Layout>
  );
}

export default withRouter(InsuranceReport);
