import React, { useEffect, useState, useRef } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { Col, Row, Layout, Button } from 'antd';
import { useReactToPrint } from 'react-to-print';
import { PageSpinner } from 'components/page-spinner';
import HtmlRender from 'components/html-render';
import AppointmentApi from 'api/appointment';
import TranscationApi from 'api/transaction';
import UserApi from 'api/user';
import { currencyFormatter } from 'utils';
import Terbilang from 'terbilang';

import '../styles/index.scss';

const { Content } = Layout;

export function KwitansiSelection({ history }) {
  const [patientDetail, setPatientDetail] = useState();
  const [receiptDetail, setReceiptDetail] = useState();

  const [receiptDate, setReceiptDate] = useState();


  const [clinicType, setClinicType] = useState();
  const [doctorDetail, setDoctorDetail] = useState();
  const [doctorId, setDoctorId] = useState();
  const [diagnose, setDiagnose] = useState();

  const [receiptType, setReceiptType] = useState();

  const [loadingPage, setLoadingPage] = useState(false);

  const printComponentRef = useRef();

  const { id } = useParams();

  const backgroundImageStyles = clinicType ? {
    padding: '40px',
    backgroundImage: `url(${clinicType.logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize : 'contain',
    position: 'relative',
    overflow: 'hidden',
  } : {
    padding: '40px',
    backgroundImage : `url(${process.env.PUBLIC_URL}/img/logo/logo-red.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize : 'contain',
    position : 'relative',
    overflow: 'hidden',
  };

  const overlayStyles = {
    backgroundColor : 'rgba(255,255,255,0.96)',
    position: 'absolute',
    top: 0,
    right : 0,
    bottom: 0,
    left : 0,
    zIndex : 1,
  };

  const contentStyles = {
    position: 'relative',
    zIndex : 2,
    color: '#000',
  };

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const getPatientDetail = async () => {
    try {
      setLoadingPage(true);

      const appointmentId = id.split('_')[0];

      const response = await AppointmentApi.getPatientDetail(appointmentId);

      let patient = response.data.data;

      if (patient.clinicType && Object.values(patient.clinicType).length !== 0) {
        setClinicType(patient.clinicType);
      } else if (Object.values(patient.clinic.clinicType).length !== 0) {
        setClinicType(patient.clinic.clinicType);
      }

      if (patient.signedBy) {
        const res = await UserApi.getDoctorById(patient.signedBy);
        setDoctorDetail(res.data.result[0]);
      }

      const date = patient.visitDate ?
      new Date(patient.visitDate.split('-')[0], patient.visitDate.split('-')[1]-1, patient.visitDate.split('-')[2]).toLocaleDateString('en-gb', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      }) 
      : '-';

      let diagnosa = '';
      if (patient.customDiagnosis) {
        diagnosa = patient.customDiagnosis;
      } else if (patient.resumeDiagnosisList && patient.resumeDiagnosisList.length > 0) {
        patient.resumeDiagnosisList.forEach((val) => {
          diagnosa += `${val.resumeDiagnosis} \n`
        });
      } else {
        diagnosa = '-';
      }

      setDiagnose((prev) => prev = diagnosa);
      setReceiptDate((prev) => ({...prev, visitDate : date}));
      setPatientDetail(patient);
    } finally {
      setLoadingPage(false);
    }
  };

  const getReceiptDetail = async () => {
    try {
      setLoadingPage(true);

      const receiptId = id.split('_')[2];

      const slipTreatmentId = id.split('_')[3];

      const payload = {
        slipTreatmentIds: slipTreatmentId.split('-'),
      };

      const response =
        await TranscationApi.getReceiptDetailByIdAndSlipTreatmentId(
          payload,
          receiptId
        );

      const receipt = response.data.data;

      const date = receipt.paymentDate ? 
      new Date(receipt.paymentDate.split('-')[0], receipt.paymentDate.split('-')[1]-1, receipt.paymentDate.split('-')[2]).toLocaleDateString('en-gb', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      })
      : '-';
      setReceiptDate((prev) => ({...prev, paymentDate : date}));
      setReceiptDetail(receipt);
    } finally {
      setLoadingPage(false);
    }
  };

  const redirectToPreviousPage = () => {
    history.goBack();
  };

  const redirectToAntrianKasir = () => {
    history.push('/kasir/antrian-pasien-rawat-jalan');
  };

  const redirectToManageCetakKwitansi = () => {
    history.push('/kasir/manage-cetak-kasir-pasien');
  };

  const redirectToStrukPembayaran = () => {
    const appointmentId = id.split('_')[0];

    const receiptNumber = id.split('_')[1];

    const receiptId = id.split('_')[2];

    history.push(
      `/kasir/struk-pembayaran/${appointmentId}_${receiptNumber}_${receiptId}`
    );
  };

  const redirectToKwitansiPembayaran = () => {
    const appointmentId = id.split('_')[0];

    const receiptNumber = id.split('_')[1];

    const receiptId = id.split('_')[2];

    history.push(
      `/kasir/kwitansi-pembayaran/${appointmentId}_${receiptNumber}_${receiptId}`
    );
  };

  const redirectToRincianPembayaran = () => {
    const appointmentId = id.split('_')[0];

    const receiptNumber = id.split('_')[1];

    const receiptId = id.split('_')[2];

    history.push(
      `/kasir/rincian-pembayaran/${appointmentId}_${receiptNumber}_${receiptId}`
    );
  };

  const useInitData = () => {
    if (id) {
      getPatientDetail();
      getReceiptDetail();

      setReceiptType(id.split('_')[4]);
    }
  };

  useEffect(useInitData, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <div className="mb-15">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Kwitansi
              </strong>
            </Col>
          </Row>
        </div>
        {!!patientDetail && !!receiptDetail && (
          <>
            <div className="mb-50">
              <Row>
                <Col span={24}>
                  <Button
                    type="danger"
                    style={{ marginRight: '5px' }}
                    onClick={redirectToPreviousPage}>
                    KEMBALI
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginRight: '5px' }}
                    onClick={redirectToAntrianKasir}>
                    KEMBALI ANTRIAN KASIR
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginRight: '5px' }}
                    onClick={redirectToManageCetakKwitansi}>
                    KEMBALI MANAGE KWITANSI
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginRight: '5px' }}
                    onClick={redirectToStrukPembayaran}>
                    STRUK PEMBAYARAN
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginRight: '5px' }}
                    onClick={redirectToKwitansiPembayaran}>
                    KWITANSI PEMBAYARAN
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginRight: '5px' }}
                    onClick={redirectToRincianPembayaran}>
                    RINCIAN PEMBAYARAN
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginRight: '5px' }}
                    onClick={handlePrint}>
                    PRINT THIS PAGE
                  </Button>
                  {/* <Button type="primary" style={{ marginRight: '5px' }}>
                    KIRIM KWITANSI
                  </Button> */}
                </Col>
              </Row>
            </div>
            <div ref={printComponentRef} style={backgroundImageStyles}>
              <div style={overlayStyles}></div>
              <div style={contentStyles}>
                <div className="mb-45 w-100">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}>
                    <Col style={{width: '90%'}}>
                      <div style={{ fontSize: '25px', width: '100%' }}>
                        <strong>{clinicType.name}</strong>
                      </div>
                    </Col>
                    <Col style={{width: '10%', maxWidth: '250px'}}>
                      <img
                        src={clinicType.logo ? clinicType.logo : `${process.env.PUBLIC_URL}/img/logo/logo-red.png`}
                        alt={`${clinicType.name.toLowerCase()}-logo.img`}
                        style={{width: '100%'}}
                      />
                    </Col>
                  </div>
                </div>
                <div>
                  <div className='d-flex align-items-center'>
                    <p style={{ width: '90px' }}>Telp</p>
                    <p style={{ marginLeft: '20px', paddingLeft: '15px' }}>{ clinicType.phone && clinicType.phone }</p>
                  </div>
                  <div className='d-flex align-items-center'>
                    <p style={{ width: '90px' }}>Email</p>
                    <p style={{ marginLeft: '20px', paddingLeft: '15px' }}>{ clinicType.email && clinicType.email }</p>
                  </div>
                </div>
                <div className="mb-15">
                  <div className='separator'>
                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                      <strong>BUKTI PEMBAYARAN</strong>
                    </Col>
                  </div>
                </div>
                <div className="mb-15">
                  <Row>
                    <Col span={24}>PELANGGAN</Col>
                  </Row>
                </div>
                <div className="mb-15">
                  <Row>
                    <Col span={12}>
                      <div
                        style={{ border: '1px solid #000000', padding: '10px' }}>
                        <div className="mb-5">
                          <div
                            style={{ display: 'inline-block', width: '100px' }}>
                            NAMA
                          </div>
                          <div style={{ display: 'inline-block' }}>
                            {patientDetail.patient.fullname}
                          </div>
                        </div>
                        <div className="mb-5">
                          <div
                            style={{ display: 'inline-block', width: '100px' }}>
                            ALAMAT
                          </div>
                          <div style={{ display: 'inline-block' }}>
                            {patientDetail.patient.identityAddress}
                          </div>
                        </div>
                        <div className="mb-5">
                          <div
                            style={{ display: 'inline-block', width: '100px' }}>
                            TELP
                          </div>
                          <div style={{ display: 'inline-block' }}>
                            {patientDetail.patient.phoneNumber1}
                          </div>
                        </div>
                        <div>
                          <div
                            style={{ display: 'inline-block', width: '100px' }}>
                            FAX
                          </div>
                          <div style={{ display: 'inline-block' }}>-</div>
                        </div>
                      </div>
                    </Col>
                    <Col span={11} offset={1}>
                      <div
                        style={{ border: '1px solid #000000', padding: '10px' }}>
                        <div className="mb-5">
                          <div
                            style={{ display: 'inline-block', width: '175px' }}>
                            PEMESANAN #
                          </div>
                          <div style={{ display: 'inline-block' }}></div>
                        </div>
                        <div className="mb-5">
                          <div
                            style={{ display: 'inline-block', width: '175px' }}>
                            JATUH TEMPO
                          </div>
                          <div style={{ display: 'inline-block' }}>-</div>
                        </div>
                        <div className="mb-5">
                          <div
                            style={{ display: 'inline-block', width: '175px' }}>
                            REFERENSI #
                          </div>
                          <div style={{ display: 'inline-block' }}>
                            {receiptDetail.receiptNumber}
                          </div>
                        </div>
                        <div className='mb-5'>
                          <div style={{display: 'inline-block', width: '175px'}}></div>
                          <div style={{display: 'inline-block'}}></div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="mb-15">
                  <Row>
                    <Col span={24}>
                      <table
                        style={{
                          borderCollapse: 'collapse',
                          width: '100%',
                        }}
                        border="1"
                        className="table-receipt">
                        <tbody>
                          <tr style={{ textAlign: 'center' }}>
                            <td>No</td>
                            <td>Keterangan</td>
                            <td>Qty</td>
                            <td>Harga Satuan</td>
                            <td>Diskon (Rp.)</td>
                            <td>Jumlah (Rp.)</td>
                          </tr>
                          {!!receiptDetail.slipTreatmentList &&
                            receiptDetail.slipTreatmentList.map(
                              (treatment, index) => (
                                <tr>
                                  <td style={{ textAlign: 'center' }}>
                                    {index + 1}
                                  </td>
                                  <td>{treatment.description}</td>
                                  <td style={{ textAlign: 'center' }}>
                                    {treatment.quantity}
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {currencyFormatter(treatment.unitPrice)}
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {currencyFormatter(treatment.discountTotal)}
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    {currencyFormatter(treatment.totalPrice)}
                                  </td>
                                </tr>
                              )
                            )}
                          {!!receiptDetail.receiptDetailList &&
                            receiptType === 'all' && (
                              <>
                                <tr>
                                  <td
                                    style={{
                                      borderBottom: 'hidden',
                                      borderLeft: 'hidden',
                                      textAlign: 'center',
                                    }}
                                    colspan="4"
                                    rowspan={
                                      receiptDetail.receiptDetailList.length + 6
                                    }></td>
                                  <td style={{ textAlign: 'right' }}>
                                    <strong>SUB TOTAL</strong>
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    <strong>
                                      {currencyFormatter(receiptDetail.subTotal)}
                                    </strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: 'right' }}>
                                    <strong>GRAND TOTAL</strong>
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    <strong>
                                      {currencyFormatter(
                                        receiptDetail.grandTotal
                                      )}
                                    </strong>
                                  </td>
                                </tr>
                                {receiptDetail.receiptDetailList.map(
                                  (payment) => (
                                    <tr>
                                      <td style={{ textAlign: 'right' }}>
                                        <strong>{payment.paymentMethod}</strong>
                                      </td>
                                      <td style={{ textAlign: 'center' }}>
                                        <strong>
                                          {currencyFormatter(
                                            payment.totalPayment
                                          )}
                                        </strong>
                                      </td>
                                    </tr>
                                  )
                                )}
                                <tr>
                                  <td style={{ textAlign: 'right' }}>
                                    <strong>JUMLAH PEMBAYARAN</strong>
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    <strong>
                                      {currencyFormatter(
                                        receiptDetail.grandTotal
                                      )}
                                    </strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: 'right' }}>
                                    <strong>KEMBALI</strong>
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    <strong>
                                      {currencyFormatter(
                                        receiptDetail.changeReturn
                                      )}
                                    </strong>
                                  </td>
                                </tr>
                              </>
                            )}
                          {!!receiptDetail.receiptDetailList &&
                            receiptType === 'selection' && (
                              <>
                                <tr>
                                  <td
                                    style={{
                                      borderBottom: 'hidden',
                                      borderLeft: 'hidden',
                                      textAlign: 'center',
                                    }}
                                    colspan="4"
                                    rowspan={
                                      receiptDetail.receiptDetailList.length + 4
                                    }></td>
                                  <td style={{ textAlign: 'right' }}>
                                    <strong>TOTAL</strong>
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    <strong>
                                      {currencyFormatter(
                                        receiptDetail.grandTotal
                                      )}
                                    </strong>
                                  </td>
                                </tr>
                              </>
                            )}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col span={12}>
                    <div className="mb-45">
                      <Row>
                        <Col span={24}>
                          <Row>
                            <strong>
                              <u>TERBILANG</u>
                            </strong>
                          </Row>
                          <Row style={{ textTransform: 'uppercase' }}>{`${Terbilang(
                            receiptDetail.grandTotal
                          )} RUPIAH`}</Row>
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-10">
                      <Row>
                        <Col span={24} style={{minHeight: '100px'}}>
                          <Row>
                            <strong>
                              <u>Diagnosa</u>
                            </strong>
                          </Row>
                          <Row>
                            {diagnose}
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <div className='mb-10' style={{fontSize: '13px'}}>
                      <i>Kuitansi ini merupakan bukti pembayaran yang SAH.</i>
                      <br/>
                      <i>Pengembalian dana tidak dapat dilakukan dengan alasan apapun apabila kuitansi treatment/produk telah diterbitkan.</i>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div style={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', alignItems: 'flex-end', paddingRight: '20px'}}>
                      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '250px', minHeight: '400px' }}>
                        <p style={{marginBottom: '5px', textAlign: 'center'}}>Doctor</p>
                        {
                          doctorDetail ? 
                          <>
                            { doctorDetail.signature && <img src={process.env.REACT_APP_AWS_URL + doctorDetail.signature} alt='doctor-signature' style={{ width: '150px', height: '150px' }}/> }
                            <p style={{marginBottom: '0px', marginTop: '15px', fontWeight: 'normal' }}>{doctorDetail.name}</p>
                            <p style={{marginBottom: '0px', fontWeight: 'normal', fontSize: '12px' }}>SIP: {doctorDetail.sip_number}</p>
                          </> 
                          :
                          <></>
                        }
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        )}
      </Content>
    </Layout>
  );
}

export default withRouter(KwitansiSelection);
