import React, { useEffect, useState } from 'react';
import { Col, Row, Layout, Table, Button, Input, Modal } from 'antd';
import {
  ExclamationCircleTwoTone,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import { isEmpty } from 'lodash';
// import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router-dom';
import MarketingApi from 'api/marketing';
import { PageSpinner } from 'components/page-spinner';
import { setToArray } from 'utils';

import { categoryProductionColumns } from '../helper/';
import '../styles/index.scss';

const { Content } = Layout;

export function ManageKategoriProduksi({ history }) {
  const [name, setName] = useState();

  const [listCategory, setListCategory] = useState([]);
  const [listFilteredCategory, setListFilteredCategory] = useState([]);

  const [idModal, setIdModal] = useState();
  const [typeModal, setTypeModal] = useState();

  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);

  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);

  const [loadingPage, setLoadingPage] = useState(false);

  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    showSizeChanger: true,
  });

  async function getCategoryList() {
    try {
      setLoadingPage(true);

      const response = await MarketingApi.getCategoryProductionList();

      let categoryList = setToArray(response.data);

      setListCategory(categoryList);
      setListFilteredCategory(categoryList);
    } finally {
      setLoadingPage(false);
    }
  }

  async function submitCategory() {
    const payload = {
      name: name,
    };

    const response = await MarketingApi.submitCategoryProduction(payload);

    if (response.data.resultCode === '00') {
      getCategoryList();

      setName();

      setIsConfirmPopupOpen(false);
      setIsSuccessPopupOpen(true);
    }
  }

  async function editCategory() {
    const payload = {
      name: name,
    };

    const response = await MarketingApi.editCategoryProduction(
      payload,
      idModal
    );

    if (response.data.resultCode === '00') {
      getCategoryList();

      setIdModal();
      setName();

      setIsConfirmPopupOpen(false);
      setIsSuccessPopupOpen(true);
    }
  }

  async function deleteCategory() {
    const response = await MarketingApi.deleteCategoryProduction(idModal);

    if (response.data.resultCode === '00') {
      getCategoryList();

      setIdModal();

      setIsConfirmPopupOpen(false);
      setIsSuccessPopupOpen(true);
    }
  }

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEditCategoryClick = (id, value) => {
    handleFlow('edit', 'openPopup', id, value);
  };

  const handleDeleteCategoryClick = (id) => {
    setIdModal(id);

    handleFlow('delete', 'openConfirm', id);
  };

  const handleFlow = (type, flow, id, value) => {
    if (typeModal !== type) setTypeModal(type);

    if (type === 'new') {
      if (flow === 'openPopup') {
        setIsEditPopupOpen(true);
      } else if (flow === 'closePopup') {
        setIdModal();
        setName();

        setIsEditPopupOpen(false);
      } else if (flow === 'openConfirm') {
        setIsEditPopupOpen(false);
        setIsConfirmPopupOpen(true);
      } else if (flow === 'closeConfirm') {
        setIsConfirmPopupOpen(false);
      } else if (flow === 'submit') {
        submitCategory();
      } else if (flow === 'closeSuccess') {
        setIdModal();
        setName();

        setIsSuccessPopupOpen(false);
      }
    } else if (type === 'edit') {
      if (flow === 'openPopup') {
        const { name } = value;

        setIdModal(id);
        setName(name);

        setIsEditPopupOpen(true);
      } else if (flow === 'closePopup') {
        setIdModal();
        setName();

        setIsEditPopupOpen(false);
      } else if (flow === 'openConfirm') {
        setIsEditPopupOpen(false);
        setIsConfirmPopupOpen(true);
      } else if (flow === 'closeConfirm') {
        setIsConfirmPopupOpen(false);
      } else if (flow === 'submit') {
        editCategory();
      } else if (flow === 'closeSuccess') {
        setIdModal();
        setName();

        setIsSuccessPopupOpen(false);
      }
    } else if (type === 'delete') {
      if (flow === 'openConfirm') {
        setIsConfirmPopupOpen(true);
      } else if (flow === 'closeConfirm') {
        setIsConfirmPopupOpen(false);
      } else if (flow === 'submit') {
        deleteCategory();
      } else if (flow === 'closeSuccess') {
        setIsSuccessPopupOpen(false);
      }
    }
  };

  const onTableChange = (pagination) => {
    setPagination(pagination);
  };

  //search
  const onSearchChange = (e) => {
    const convertValue = e.target.value.toLowerCase();
    const labelValue = e.target.value;
    setSearch(labelValue);

    const filteredData = listCategory.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          !isEmpty(item[key]) &&
          item[key].toString().toLowerCase().search(convertValue) !== -1
      );
    });

    setListFilteredCategory(filteredData);
  };

  const useInitData = () => {
    getCategoryList();
  };

  const useEvaluateSubmitButton = () => {
    if (!!name) {
      setIsSubmitButtonEnabled(true);
    } else {
      setIsSubmitButtonEnabled(false);
    }
  };

  useEffect(useInitData, []);

  useEffect(useEvaluateSubmitButton, [name]);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <div className="mb-15">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Manage Kategori Produksi
              </strong>
            </Col>
          </Row>
        </div>
        <div className="panel">
          <Row>
            <Col span={12}>
              <div className="mb-15">
                <Button
                  style={{
                    color: '#FFF',
                    backgroundColor: '#32c5d2',
                    borderColor: '#32c5d2',
                  }}
                  onClick={() => handleFlow('new', 'openPopup')}>
                  <strong>TAMBAH KATEGORI PRODUKSI</strong>
                </Button>
              </div>
            </Col>
            <Col span={12} type="flex" align="end">
              <div className="mb-15">
                <Button onClick={() => {}}>PRINT</Button>
                <Button onClick={() => {}}>COPY</Button>
                <Button onClick={() => {}}>PDF</Button>
                <Button onClick={() => {}}>EXCEL</Button>
                <Button onClick={() => {}}>CSV</Button>
                <Button onClick={getCategoryList}>RELOAD</Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={2} type="flex" align="start">
              <></>
            </Col>
            <Col span={5} offset={17} type="flex" align="end">
              <div className="mb-15">
                <Input
                  className="skybase-dashboard__search-input"
                  placeholder="Search"
                  onChange={onSearchChange}
                  value={search}
                />
              </div>
            </Col>
          </Row>
          <Table
            dataSource={listFilteredCategory}
            rowKey="id"
            scroll={{ y: 500 }}
            onChange={onTableChange}
            pagination={pagination}
            columns={categoryProductionColumns(
              handleEditCategoryClick,
              handleDeleteCategoryClick
            )}
            className="skybase-dashboard__active-order-table"
          />
        </div>
      </Content>

      <Modal
        visible={isEditPopupOpen}
        width={'35%'}
        closable={false}
        footer={[
          <Button
            type="danger"
            onClick={() => handleFlow(typeModal, 'closePopup')}>
            Kembali
          </Button>,
          <Button
            type="primary"
            onClick={() => handleFlow(typeModal, 'openConfirm')}
            disabled={!isSubmitButtonEnabled}>
            Simpan
          </Button>,
        ]}>
        <Row type="flex" align="middle">
          <Col span={6} className="mb-5">
            <div>Nama</div>
          </Col>
          <Col span={18}>
            <div className="skybase-dashboard__search mb-5">
              <Input
                placeholder="Nama"
                onChange={handleNameChange}
                value={name}
              />
            </div>
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={isConfirmPopupOpen}
        width={'35%'}
        closable={false}
        footer={[
          <Button
            type="default"
            onClick={() => handleFlow(typeModal, 'closeConfirm')}>
            Tidak
          </Button>,
          <Button type="danger" onClick={() => handleFlow(typeModal, 'submit')}>
            Ya
          </Button>,
        ]}>
        <Row>
          <div className="mt-10 mb-10">
            <ExclamationCircleTwoTone
              twoToneColor="#f8bb86"
              style={{ fontSize: 50 }}
            />
          </div>
        </Row>
        {typeModal === 'delete' ? (
          <>
            <Row>
              <div className="mb-10">
                <strong>Anda sudah yakin melakukan penghapusan data?</strong>
              </div>
            </Row>
            <Row>
              <div>Data yang sudah dihapus tidak dapat dikembalikan</div>
            </Row>
          </>
        ) : (
          <Row>
            <div>
              <strong>Apakah data sudah sesuai?</strong>
            </div>
          </Row>
        )}
      </Modal>

      <Modal
        visible={isSuccessPopupOpen}
        width={'35%'}
        closable={false}
        footer={[
          <Button
            type="primary"
            onClick={() => handleFlow(typeModal, 'closeSuccess')}>
            OK
          </Button>,
        ]}>
        <Row>
          <div className="mt-10 mb-10">
            <CheckCircleTwoTone
              twoToneColor="#a5dc86"
              style={{ fontSize: 50 }}
            />
          </div>
        </Row>
        <Row>
          <div className="mb-10">
            <strong>Success</strong>
          </div>
        </Row>
        <Row>
          <div>
            Data berhasil {typeModal === 'delete' ? 'dihapus' : 'disimpan'}
          </div>
        </Row>
      </Modal>
    </Layout>
  );
}

export default withRouter(ManageKategoriProduksi);
