import axios from 'axios';

export const createAPI = (
  baseURL = process.env.REACT_APP_RATA_API_URL,
  config = {}
) => {
  const axiosInstance = axios.create({
    baseURL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization:
        localStorage.getItem('cms_token') &&
        `Bearer ${localStorage.getItem('cms_token')}`,
    },
    ...config,
  });

  // setup axios.intercept
  return axiosInstance;
};

const api = createAPI();

export default api;
