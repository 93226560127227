import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Layout,
  Table,
  Button,
  Input,
  Collapse,
  Tabs,
  Divider,
  Card,
  Checkbox,
} from 'antd';
import { withRouter, useParams } from 'react-router-dom';
import moment from 'moment';
import ImageViewer from 'react-simple-image-viewer';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';
import PatientApi from 'api/patient';
import PatientInteractionApi from 'api/patient-interaction';
import MedicalRecordApi from 'api/medical-record';
import AppointmentApi from 'api/appointment';
// import { PageSpinner } from 'components/page-spinner';
import { setToArray } from 'utils';

import {
  afterSalesHistoryColumns,
  treatmentPlanColumns,
  uploadInformedPatientColumns,
  refinementColumns,
  cetakUlangColumns,
  extraAlignerColumns,
  retainerRataColumns,
  reqcColumns,
  noteColumns,
  prescriptionColumns,
  treatmentColumns,
  icd10CodeColumns,
} from './helper';
import {
  allTreatmentColumns,
  allTreatmentSummaryColumns,
} from '../kasir/helper';

import './styles/index.scss';
import ReactQuill from "react-quill";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Panel } = Collapse;

export function RekamMedis({ history }) {
  let sanitize = DOMPurify.sanitize;

  const cutUpperList = [
    '18',
    '17',
    '16',
    '15',
    '14',
    '13',
    '12',
    '11',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
  ];
  const cutLowerList = [
    '48',
    '47',
    '46',
    '45',
    '44',
    '43',
    '42',
    '41',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
  ];
  const objectTreatmentPayment = [
    {
      key: 1,
      column8: 'Total Tagihan Lunas',
      column9: 0,
    },
    {
      key: 2,
      column8: 'Total Tagihan Tak Lunas',
      column9: 0,
    },
  ];

  const [patient, setPatient] = useState();
  const [aligner, setAligner] = useState();

  const [listFotoBeforeAfter, setListFotoBeforeAfter] = useState([]);
  const [listTreatmentPlan, setListTreatmentPlan] = useState([]);
  const [listFotoMedis, setListFotoMedis] = useState([]);
  const [listAfterSales, setListAfterSales] = useState([]);
  const [listIc, setListIc] = useState([]);
  const [listFirstSetVisit, setListFirstSetVisit] = useState([]);
  const [listRefinement, setListRefinement] = useState([]);
  const [listCetakUlang, setListCetakUlang] = useState([]);
  const [listExtraAligner, setListExtraAligner] = useState([]);
  const [listRetainerRata, setListRetainerRata] = useState([]);
  const [listReQc, setListReQc] = useState([]);
  const [listNote, setListNote] = useState([]);
  const [listHistory, setListHistory] = useState([]);
  const [listPaginatedHistory, setListPaginatedHistory] = useState([]);
  const [listTreatment, setListTreatment] = useState([]);
  const [listTreatmentPayment, setListTreatmentPayment] = useState(
    objectTreatmentPayment
  );

  const [currentPageHistory, setCurrentPageHistory] = useState(1);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [listImages, setListImages] = useState([]);

  // const [loadingPage, setLoadingPage] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    showSizeChanger: true,
  });

  const { patientId } = useParams();

  async function getPatientDetail() {
    const response = await PatientApi.getPatientDetail(patientId);

    let patientDetail = response.data.data;

    setPatient(patientDetail);
  }

  async function getFotoBeforeAfterList() {
    const response = await MedicalRecordApi.getFotoBeforeAfterList(patientId);
    const images = [];

    let fotoBeforeAfterList = setToArray(response.data);

    const modifiedFotoBeforeAfterList = fotoBeforeAfterList.map(
      (item, index) => ({
        ...item,
        key: index + 1,
      })
    );

    setListFotoBeforeAfter(modifiedFotoBeforeAfterList);

    modifiedFotoBeforeAfterList.forEach((pic) => {
      images.push(pic.url);
    });

    setListImages(images);
  }

  async function getTreatmentPlanList() {
    const response = await MedicalRecordApi.getTreatmentPlanList(patientId);

    let treatmentPlanList = setToArray(response.data);

    const modifiedTreatmentPlanList = treatmentPlanList.map((item, index) => ({
      ...item,
      key: index + 1,
    }));

    setListTreatmentPlan(modifiedTreatmentPlanList);
  }

  async function getFotoMedisList() {
    const response = await PatientApi.getFotoMedisList(patientId);
    const images = [];

    let fotoMedisList = setToArray(response.data);

    const modifiedFotoMedisList = fotoMedisList.map((item, index) => ({
      ...item,
      key: index + 1,
    }));

    setListFotoMedis(modifiedFotoMedisList);

    modifiedFotoMedisList.forEach((item) => {
      item.patientPhotos.forEach((pic) => {
        images.push(pic.url);
      });
    });

    setListImages(images);
  }

  async function getInformedConcernList() {
    const response = await PatientInteractionApi.getInformedConcern(patientId);

    let informedConcernList = setToArray(response.data);

    const modifiedInformedConcernList = informedConcernList.map(
      (item, index) => ({
        ...item,
        key: index + 1,
      })
    );

    setListIc(modifiedInformedConcernList);
  }

  async function getAfterSalesList() {
    const response = await PatientInteractionApi.getAfterSalesList(patientId);

    let afterSalesList = setToArray(response.data);

    const modifiedAfterSalesList = afterSalesList.map((item, index) => ({
      ...item,
      key: index + 1,
    }));

    setListAfterSales(modifiedAfterSalesList);
  }

  async function getAligner() {
    const response = await MedicalRecordApi.getAligner(patientId);

    let alignerDetail = response.data.data;

    setAligner(alignerDetail);
  }

  async function getFirstSetVisitList() {
    const response = await MedicalRecordApi.getFirstSetVisitList(patientId);

    let firstSetVisitList = setToArray(response.data);

    const modifiedFirstSetVisitList = firstSetVisitList.map((item, index) => ({
      ...item,
      key: index + 1,
    }));

    setListFirstSetVisit(modifiedFirstSetVisitList);
  }

  async function getRefinementList() {
    const response = await MedicalRecordApi.getRefinementList(patientId);

    let refinementList = setToArray(response.data);

    const modifiedRefinementList = refinementList.map((item, index) => ({
      ...item,
      key: index + 1,
    }));

    setListRefinement(modifiedRefinementList);
  }

  async function getCetakUlangList() {
    const response = await MedicalRecordApi.getCetakUlangList(patientId);

    let cetakUlangList = setToArray(response.data);

    const modifiedCetakUlangList = cetakUlangList.map((item, index) => ({
      ...item,
      key: index + 1,
    }));

    setListCetakUlang(modifiedCetakUlangList);
  }

  async function getExtraAlignerList() {
    const response = await MedicalRecordApi.getExtraAlignerList(patientId);

    let extraAlignerList = setToArray(response.data);

    const modifiedAlignerList = extraAlignerList.map((item, index) => ({
      ...item,
      key: index + 1,
    }));

    setListExtraAligner(modifiedAlignerList);
  }

  async function getRetainerRataList() {
    const response = await MedicalRecordApi.getRetainerRataList(patientId);

    let retainerRataList = setToArray(response.data);

    const modifiedRetainerRataList = retainerRataList.map((item, index) => ({
      ...item,
      key: index + 1,
    }));

    setListRetainerRata(modifiedRetainerRataList);
  }

  async function getReQcList() {
    const response = await MedicalRecordApi.getReQcList(patientId);

    let reqcList = setToArray(response.data);

    const modifiedReQcList = reqcList.map((item, index) => ({
      ...item,
      key: index + 1,
    }));

    setListReQc(modifiedReQcList);
  }

  async function getNoteList() {
    const response = await MedicalRecordApi.getNoteList(patientId);

    let noteList = setToArray(response.data);

    const modifiedNoteList = noteList.map((item, index) => ({
      ...item,
      key: index + 1,
    }));

    setListNote(modifiedNoteList);
  }

  async function getHistoryList() {
    const response = await AppointmentApi.getAllTreatmentHistory(patientId);

    let historyList = setToArray(response.data);

    const modifiedHistoryList = historyList.map((item, index) => ({
      ...item,
      key: index + 1,
    }));

    setListHistory(modifiedHistoryList);
    handleHistoryPagination(modifiedHistoryList);
  }

  async function getAllTreatmentList() {
    const response = await AppointmentApi.getAllTreatmentList(patientId);

    let treatment = setToArray(response.data);

    const modifiedTreatment = treatment.map((item, index) => ({
      ...item,
      key : index + 1,
    }));

    setListTreatment(modifiedTreatment);

    const newTreatmentPayment = [...listTreatmentPayment];

    newTreatmentPayment[0].column9 = response.data.data.paidOff;

    newTreatmentPayment[1].column9 = response.data.data.notPaid;

    setListTreatmentPayment(newTreatmentPayment);
  }

  const handleHistoryPagination = (list, type) => {
    let page = currentPageHistory;

    if (type === 'next') page = currentPageHistory + 1;
    else if (type === 'previous') page = currentPageHistory - 1;

    const minPageSize = 3 * (page - 1);
    const maxPageSize = 3 * page;

    const paginatedList = [];

    for (let index = 0; index < list.length; index++) {
      if (index >= minPageSize && index < maxPageSize)
        paginatedList.push(list[index]);
    }

    setListPaginatedHistory(paginatedList);
    setCurrentPageHistory(page);
  };

  const onTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleTabChange = async (key) => {
    switch (key) {
      case '1':
        getPatientDetail();
        getHistoryList();
        break;
      case '2':
        getAligner();
        getFirstSetVisitList();
        getRefinementList();
        getCetakUlangList();
        getExtraAlignerList();
        getRetainerRataList();
        getReQcList();
        getNoteList();
        break;
      case '3':
        getFotoMedisList();
        break;
      case '4':
        getInformedConcernList();
        getAfterSalesList();
        break;
      case '5':
        getTreatmentPlanList();
        break;
      case '6':
        getFotoBeforeAfterList();
        break;
      case '7':
        await getAllTreatmentList();
        break;
      default:
        break;
    }

    setPagination({ ...pagination, current: 1, pageSize: 10 });
  };

  const handleDownloadFileClick = (url) => {
    window.open(url);
  };

  const handleImageViewerOpen = (index, isFotoMedis = false, url = '') => {
    let finalIndex = index;

    if (isFotoMedis) {
      finalIndex = listImages.indexOf(url);
    }

    setCurrentImage(finalIndex);
    setIsViewerOpen(true);
  };

  const handleImageViewerClose = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const handleDocumentImageClick = (listPhoto) => {
    const modifiedListPhoto = listPhoto.map((photo) => photo.url);

    setListImages(modifiedListPhoto);

    handleImageViewerOpen(0);
  };

  const handleHistoryDetail = (type, historyDetail) => {
    const listDetail = [];

    for (let index = 0; index < historyDetail.length; index++) {
      listDetail.push(historyDetail[index][type]);
    }

    return listDetail;
  };

  const useInitData = () => {
    getPatientDetail();
    getHistoryList();
  };

  useEffect(useInitData, []);

  // if (loadingPage) {
  //   return <PageSpinner />;
  // }

  return (
    <Layout>
      <Content>
        {/* <div className="mb-15">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Rekam Medis
              </strong>
            </Col>
          </Row>
        </div> */}
        <Collapse
          defaultActiveKey={['1']}
          expandIconPosition="right"
          className="mb-15">
          <Panel
            header={
              <strong className="text-md skybase-dashboard__title">
                DATA PASIEN
              </strong>
            }
            key="1">
            {!!patient && (
              <>
                <Row gutter={16}>
                  <Col span={12}>
                    <div className="mb-10">
                      <strong>Nama</strong>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="mb-10">
                      <strong>Jenis Kelamin</strong>
                    </div>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <div className="mb-10">
                      <Input
                        disabled
                        value={patient.fullname}
                        style={{
                          color: 'rgba(0, 0, 0, 0.65)',
                          cursor: 'auto',
                        }}
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="mb-10">
                      <Input
                        disabled
                        value={patient.gender}
                        style={{
                          color: 'rgba(0, 0, 0, 0.65)',
                          cursor: 'auto',
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <div className="mb-10">
                      <strong>Medical Record Number</strong>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="mb-10">
                      <strong>Umur</strong>
                    </div>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <div className="mb-10">
                      <Input
                        disabled
                        value={patient.id}
                        style={{
                          color: 'rgba(0, 0, 0, 0.65)',
                          cursor: 'auto',
                        }}
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="mb-10">
                      <Input
                        disabled
                        value={patient.patientType}
                        style={{
                          color: 'rgba(0, 0, 0, 0.65)',
                          cursor: 'auto',
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <div className="mb-10">
                      <strong>Nomor RM Periksa</strong>
                    </div>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <div className="mb-10">
                      <Input
                        disabled
                        value={
                          patient.medicalRecordNumber
                            ? patient.medicalRecordNumber
                            : '-'
                        }
                        style={{
                          color: 'rgba(0, 0, 0, 0.65)',
                          cursor: 'auto',
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Panel>
        </Collapse>

        <div className="panel">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                REKAM MEDIS
              </strong>
              <Divider></Divider>
              <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                <TabPane tab="Rekam Medis" key="1">
                  {listPaginatedHistory &&
                    listPaginatedHistory.map((history) => (
                      <Card className="mb-15">
                        <Row className="mb-15">
                          <Col span={24}>
                            <strong style={{ color: '#44b6ae' }}>
                              {history.doctorName}
                            </strong>{' '}
                            {moment(history.visitDate).format('DD MMMM YYYY')}{' '}
                            {moment(history.visitStart, 'hh:mm:ss.S').format(
                              'HH:mm:ss'
                            )}
                          </Col>
                        </Row>
                        <Row className="mb-15">
                          <Col span={11}>
                            {history.treatmentAnamnesisList.length > 0 ? (
                              <>
                                <Row className="mb-5">
                                  <Col span={6}>
                                    <div>
                                      <strong>KLINIK :</strong>
                                    </div>
                                  </Col>
                                  <Col span={18}>
                                    <div>
                                      {history.clinic
                                        ? history.clinic.clinicName
                                        : '-'}
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="mb-5">
                                  <Col span={6}>
                                    <div>
                                      <strong>Chief Complaint :</strong>
                                    </div>
                                  </Col>
                                  <Col span={18}>
                                    {handleHistoryDetail(
                                      'chiefComplain',
                                      history.treatmentAnamnesisList
                                    ).map((item) => (
                                      <>
                                        {item && (
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: sanitize(item),
                                            }}
                                          />
                                        )}
                                        {/* <div>
                                          {item &&
                                            `- ${DOMPurify.sanitize(item)}`}
                                        </div> */}
                                        <br></br>
                                      </>
                                    ))}
                                  </Col>
                                </Row>
                                <Row className="mb-5">
                                  <Col span={6}>
                                    <div>
                                      <strong>
                                        {`Anamnesis & Diagnosis`} :
                                      </strong>
                                    </div>
                                  </Col>
                                  <Col span={18}>
                                    {handleHistoryDetail(
                                      'anamnesisDiagnosis',
                                      history.treatmentAnamnesisList
                                    ).map((item) => (
                                      <>
                                        {item && (
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: sanitize(item),
                                            }}
                                          />
                                        )}
                                        {/* <div>{item && `- ${item}`}</div> */}
                                        <br></br>
                                      </>
                                    ))}
                                  </Col>
                                </Row>
                                <Row className="mb-5">
                                  <Col span={6}>
                                    <div>
                                      <strong>Treatment Planning :</strong>
                                    </div>
                                  </Col>
                                  <Col span={18}>
                                    {handleHistoryDetail(
                                      'treatmentPlanning',
                                      history.treatmentAnamnesisList
                                    ).map((item) => (
                                      <>
                                        {item && (
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: sanitize(item),
                                            }}
                                          />
                                        )}
                                        {/* <div>{item && `- ${item}`}</div> */}
                                        <br></br>
                                      </>
                                    ))}
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                          {history.patientPhotoList.length > 0 ? (
                            <>
                              <Col span={11} offset={2}>
                                <Button
                                  type="danger"
                                  onClick={() =>
                                    handleDocumentImageClick(
                                      history.patientPhotoList
                                    )
                                  }
                                  style={{
                                    width: '100%',
                                    color: '#FFF',
                                    backgroundColor: '#c49f47',
                                    borderColor: ' #c49f47',
                                  }}>
                                  DOKUMEN IMAGE
                                </Button>
                              </Col>
                            </>
                          ) : (
                            <></>
                          )}
                        </Row>
                        <Row className="mb-15">
                          <Col span={11}>
                            <Table
                              dataSource={[]}
                              rowKey="key"
                              scroll={{ x: 800, y: 300 }}
                              onChange={onTableChange}
                              pagination={false}
                              columns={prescriptionColumns}
                              className="skybase-dashboard__active-order-table"
                              size="small"
                            />
                          </Col>
                          <Col span={11} offset={2}>
                            <Table
                              dataSource={(() => {
                                if (
                                  history.receiptList &&
                                  history.receiptList.length > 0
                                ) {
                                  const treatments = [];
                                  history.receiptList.forEach((item) =>
                                    item.slipTreatmentList.forEach(
                                      (treatment) => treatments.push({...treatment, receiptNumber: item.receiptNumber})
                                    )
                                  );
                                  return treatments;
                                }
                              })()}
                              rowKey="key"
                              scroll={{ x: 800, y: 300 }}
                              onChange={onTableChange}
                              pagination={false}
                              columns={treatmentColumns}
                              className="skybase-dashboard__active-order-table"
                              size="small"
                            />
                          </Col>
                        </Row>
                        <Row className="mb-15">
                          <Col span={11}>
                            <Table
                              dataSource={history.patientIcdList}
                              rowKey="key"
                              scroll={{ x: 800, y: 300 }}
                              onChange={onTableChange}
                              pagination={false}
                              columns={icd10CodeColumns}
                              className="skybase-dashboard__active-order-table"
                              size="small"
                            />
                          </Col>
                        </Row>
                      </Card>
                    ))}
                  <Row>
                    <Col span={24} type="flex" align="end">
                      <Button
                        disabled={currentPageHistory === 1}
                        onClick={() => {
                          handleHistoryPagination(listHistory, 'previous');
                        }}>
                        PREVIOUS
                      </Button>
                      <Button
                        disabled={
                          Math.ceil(listHistory.length / 3) ===
                          currentPageHistory
                        }
                        onClick={() => {
                          handleHistoryPagination(listHistory, 'next');
                        }}>
                        NEXT
                      </Button>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Lab Order" key="2">
                  <Row>
                    <Col span={24}>
                      <Collapse
                        defaultActiveKey={['1']}
                        expandIconPosition="right"
                        className="mb-15">
                        <Panel header={<strong></strong>} key="1">
                          {!!patient && !!aligner && (
                            <>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>PATIENT NAME</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: {patient.fullname}</div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>GENDER</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: {patient.gender}</div>
                                </Col>
                              </Row>
                              <Row className="mb-30">
                                <Col span={6}>
                                  <div>
                                    <strong>AGE</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: {patient.patientType}</div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>DOCTOR NAME</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: {aligner.doctorName}</div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>DATE</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>
                                    :{' '}
                                    {moment(aligner.date).format('DD-MM-YYYY')}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>TYPE OF IMPRESSION</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: {aligner.typeOfImpression}</div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>ORDER TYPE</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: {aligner.orderType}</div>
                                </Col>
                              </Row>
                              <Row className="mb-5">
                                <Col span={6}>
                                  <div>
                                    <strong>DUE DATE</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>
                                    :{' '}
                                    {moment(aligner.dueDate).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Panel>
                      </Collapse>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Collapse
                        defaultActiveKey={['1']}
                        expandIconPosition="right"
                        className="mb-15">
                        <Panel header={<strong>ALIGNER RATA</strong>} key="1">
                          {!!aligner && (
                            <>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>ARCH TO TREAT</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: {aligner.archToTreat}</div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>TEST FIT</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: {aligner.testFit ? 'Yes' : 'No'}</div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>
                                      ANGLE'S CLASSIFICATION OF MALOCCLUSIONS
                                    </strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>
                                    :{' '}
                                    {aligner.angleClassificationOfMalocclusions}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mb-30">
                                <Col span={6}>
                                  <div>
                                    <strong>CLINICAL CONDITION</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: {aligner.clinicalCondition}</div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>IPR</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: - UPPER : {aligner.iprUpper}</div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong></strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: - LOWER : {aligner.iprLower}</div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>TOOTH MOVEMENT RESTRICTION</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>
                                    : {aligner.toothMovementRestriction}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>EXTRACTION</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: {aligner.extraction}</div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>MISSING TEETH</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: {aligner.missingTeeth}</div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>ATTACHMENT</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: {aligner.attachmentNote}</div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>USIA DENTALIS</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: {aligner.age}</div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>ALIGNER TRIM TYPE</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: - UPPER : {aligner.trimTypeUpper}</div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong></strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: - LOWER : {aligner.trimTypeLower}</div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>RONTGEN</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>
                                    : {aligner.rontgen ? aligner.rontgen : null}{' '}
                                    {aligner.rontgenNote
                                      ? '- ' + aligner.rontgenNote
                                      : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>PRODUCT TEENS / RATA JUNIOR</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: {aligner.withMyobrace != null ? (aligner.withMyobrace == true ? 'Kombinasi' : 'Hanya Aligner Saja') : aligner.product !== null ? aligner.product.includes('RATA JR') ? aligner.product : '-' : '-'}</div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>MYOBRACE </strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: {aligner.myobraceType.toString()}</div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>SPECIAL BLOCKING REQUEST GIGI </strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: {aligner.blockingSpecialRequest}</div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>PRIMARY TEETH (IF ANY) </strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div>: {aligner.primaryTeeth}</div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>DESIGN AND INSTRUCTION</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <ReactQuill
                                    value={aligner.designAndInstruction}
                                    readOnly
                                    theme="snow"
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>SPECIAL NOTES</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <ReactQuill
                                    value={aligner.specialNotes}
                                    readOnly
                                    theme="snow"
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={6}>
                                  <div>
                                    <strong>CUTTING INSTRUCTION</strong>
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <div></div>
                                </Col>
                              </Row>
                              <Row className="mb-10">
                                <Col span={3}>
                                  <div>
                                    <strong>Upper</strong>
                                  </div>
                                </Col>
                                <Col span={21}>
                                  <Row>
                                    {cutUpperList.map((itemCut) => (
                                      <Col
                                        span={1}
                                        offset={itemCut === '21' ? 1 : 0}>
                                        {itemCut}
                                        <br></br>
                                        <Checkbox
                                          disabled
                                          checked={
                                            !!aligner.cutInstructionUpper &&
                                            aligner.cutInstructionUpper.includes(
                                              itemCut
                                            )
                                          }></Checkbox>
                                      </Col>
                                    ))}
                                  </Row>
                                </Col>
                              </Row>
                              <Row className="mb-5">
                                <Col span={3}>
                                  <div>
                                    <strong>Lower</strong>
                                  </div>
                                </Col>
                                <Col span={21}>
                                  <Row>
                                    {cutLowerList.map((itemCut) => (
                                      <Col
                                        span={1}
                                        offset={itemCut === '31' ? 1 : 0}>
                                        {itemCut}
                                        <br></br>
                                        <Checkbox
                                          disabled
                                          checked={
                                            !!aligner.cutInstructionLower &&
                                            aligner.cutInstructionLower.includes(
                                              itemCut
                                            )
                                          }></Checkbox>
                                      </Col>
                                    ))}
                                  </Row>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Panel>
                      </Collapse>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Collapse
                        defaultActiveKey={['1']}
                        expandIconPosition="right"
                        className="mb-15">
                        <Panel header={<strong>VISIT</strong>} key="1">
                          <Row gutter={[16, 40]} justify="start" align="top">
                            {!!listFirstSetVisit &&
                              listFirstSetVisit.map((item, index) => (
                                <Col span={12} order={index + 1}>
                                  <table
                                    style={{
                                      borderCollapse: 'collapse',
                                      width: '100%',
                                    }}
                                    border="1"
                                    rules="none"
                                    className="table-first-set-visit">
                                    <tbody>
                                      <tr>
                                        <th colspan="3">Visit Ke</th>
                                        <th colspan="1">{item.visit}</th>
                                      </tr>
                                    </tbody>
                                    <tbody>
                                      <tr>
                                        <td colspan="3">Tanggal:</td>
                                        <td colspan="1">
                                          {moment(item.date).format(
                                            'DD-MM-YYYY'
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="3">Dokter:</td>
                                        <td colspan="1">{item.doctorName}</td>
                                      </tr>
                                      <tr>
                                        <td colspan="3">Hasil Pemakaian:</td>
                                        <td colspan="1">
                                          {item.aligner === 'junior-plus-step-1' ? 'Kontrol Akhir RATA JR PLUS STEP 1': `Aligner No. ${item.aligner}`}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="3">Condition Status:</td>
                                        <td colspan="1">
                                          {item.conditionStatus}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="3">Attachment Passive:</td>
                                        <td colspan="1">
                                          {item.installPassiveAttachment
                                            ? 'Ya'
                                            : 'Tidak'}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="3">
                                          Alasan Aligner Bermasalah - RA
                                        </td>
                                        {/* <td>- RA</td> */}
                                        <td colspan="1">
                                          {item.raNote ? item.raNote : ''}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="3">
                                          Alasan Aligner Bermasalah - RB
                                        </td>
                                        {/* <td>- RB</td> */}
                                        <td colspan="1">
                                          {item.rbNote ? item.rbNote : ''}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="3" width="50%">
                                          Instruksi
                                        </td>
                                        <td colspan="1">{item.instruction}</td>
                                      </tr>
                                      <tr>
                                        <td colspan="3">Keterangan Tambahan</td>
                                        <td colspan="1">
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: sanitize(
                                                item.additionalNote
                                              ),
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </Col>
                              ))}
                          </Row>
                        </Panel>
                      </Collapse>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Collapse
                        defaultActiveKey={['1']}
                        expandIconPosition="right"
                        className="mb-15">
                        <Panel header={<strong>REFINEMENT</strong>} key="1">
                          <Table
                            dataSource={listRefinement}
                            rowKey="key"
                            scroll={{ x: 800, y: 300 }}
                            onChange={onTableChange}
                            pagination={false}
                            columns={refinementColumns}
                            className="skybase-dashboard__active-order-table"
                            size="small"
                          />
                        </Panel>
                      </Collapse>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Collapse
                        defaultActiveKey={['1']}
                        expandIconPosition="right"
                        className="mb-15">
                        <Panel header={<strong>CETAK ULANG</strong>} key="1">
                          <Table
                            dataSource={listCetakUlang}
                            rowKey="key"
                            scroll={{ x: 800, y: 300 }}
                            onChange={onTableChange}
                            pagination={false}
                            columns={cetakUlangColumns}
                            className="skybase-dashboard__active-order-table"
                            size="small"
                          />
                        </Panel>
                      </Collapse>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Collapse
                        defaultActiveKey={['1']}
                        expandIconPosition="right"
                        className="mb-15">
                        <Panel header={<strong>EXTRA ALIGNER</strong>} key="1">
                          <Table
                            dataSource={listExtraAligner}
                            rowKey="key"
                            scroll={{ x: 800, y: 300 }}
                            onChange={onTableChange}
                            pagination={false}
                            columns={extraAlignerColumns}
                            className="skybase-dashboard__active-order-table"
                            size="small"
                          />
                        </Panel>
                      </Collapse>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Collapse
                        defaultActiveKey={['1']}
                        expandIconPosition="right"
                        className="mb-15">
                        <Panel header={<strong>RETAINER RATA</strong>} key="1">
                          <Table
                            dataSource={listRetainerRata}
                            rowKey="key"
                            scroll={{ x: 800, y: 300 }}
                            onChange={onTableChange}
                            pagination={false}
                            columns={retainerRataColumns}
                            className="skybase-dashboard__active-order-table"
                            size="small"
                          />
                        </Panel>
                      </Collapse>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Collapse
                        defaultActiveKey={['1']}
                        expandIconPosition="right"
                        className="mb-15">
                        <Panel header={<strong>RE-QC</strong>} key="1">
                          <Table
                            dataSource={listReQc}
                            rowKey="key"
                            scroll={{ x: 800, y: 300 }}
                            onChange={onTableChange}
                            pagination={false}
                            columns={reqcColumns}
                            className="skybase-dashboard__active-order-table"
                            size="small"
                          />
                        </Panel>
                      </Collapse>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Collapse
                        defaultActiveKey={['1']}
                        expandIconPosition="right"
                        className="mb-15">
                        <Panel header={<strong>NOTE</strong>} key="1">
                          <Table
                            dataSource={listNote}
                            rowKey="key"
                            scroll={{ x: 800, y: 300 }}
                            onChange={onTableChange}
                            pagination={false}
                            columns={noteColumns}
                            className="skybase-dashboard__active-order-table"
                            size="small"
                          />
                        </Panel>
                      </Collapse>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Foto Medis" key="3">
                  <Row>
                    <Col span={24}>
                      <Collapse
                        defaultActiveKey={['1']}
                        expandIconPosition="right"
                        className="mb-15">
                        <Panel header={<strong>FOTO MEDIS</strong>} key="1">
                          {!!listFotoMedis &&
                            listFotoMedis.map((item, indexGroup) => (
                              <Collapse
                                defaultActiveKey={['1']}
                                expandIconPosition="right"
                                className="mb-15">
                                <Panel
                                  header={
                                    <strong>
                                      Visit Ke-
                                      {listFotoMedis.length - indexGroup} :{' '}
                                      {moment(item.groupDate).format(
                                        'DD MMMM YYYY'
                                      )}
                                    </strong>
                                  }
                                  key="1">
                                  <Row
                                    justify="center"
                                    align="top"
                                    gutter={[8, 8]}>
                                    {!!item.patientPhotos &&
                                      item.patientPhotos.map(
                                        (item, indexPic) => (
                                          <Col span={6} order={indexPic + 1}>
                                            <div>
                                              <img
                                                alt="foto"
                                                src={item.url}
                                                style={{ cursor: 'pointer' }}
                                                height={'150px'}
                                                width={'150px'}
                                                onClick={() => {
                                                  handleImageViewerOpen(
                                                    indexPic,
                                                    true,
                                                    item.url
                                                  );
                                                }}></img>
                                              <br></br>
                                              {item.filename}
                                            </div>
                                          </Col>
                                        )
                                      )}
                                  </Row>
                                </Panel>
                              </Collapse>
                            ))}
                        </Panel>
                      </Collapse>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Interaksi Pasien" key="4">
                  <Row className="mb-15">
                    <Col span={24}>
                      <Card title="INFORMED CONCERN PASIEN">
                        <Table
                          dataSource={listIc}
                          rowKey="key"
                          scroll={{ x: 800, y: 300 }}
                          onChange={onTableChange}
                          pagination={false}
                          columns={uploadInformedPatientColumns(
                            handleDownloadFileClick
                          )}
                          className="skybase-dashboard__active-order-table"
                          size="small"
                        />
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-15">
                    <Col span={24}>
                      <Card title="HISTORY AFTER SALES">
                        <Table
                          dataSource={listAfterSales}
                          rowKey="key"
                          scroll={{ x: 1100, y: 500 }}
                          onChange={onTableChange}
                          pagination={false}
                          columns={afterSalesHistoryColumns}
                          className="skybase-dashboard__active-order-table"
                          size="small"
                        />
                      </Card>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col span={24}>
                      <Collapse
                        defaultActiveKey={['1']}
                        expandIconPosition="right"
                        className="mb-15">
                        <Panel header={<strong>FOTO CS</strong>} key="1">
                          <div className="mb-15" style={{ fontSize: 15 }}>
                            <strong>FOTO</strong>
                          </div>
                        </Panel>
                      </Collapse>
                    </Col>
                  </Row> */}
                </TabPane>
                <TabPane tab="File" key="5">
                  <Row className="mb-15">
                    <Col span={24}>
                      <Card title="TREATMENT PLAN">
                        <Table
                          dataSource={listTreatmentPlan}
                          rowKey="key"
                          scroll={{ x: 800, y: 300 }}
                          onChange={onTableChange}
                          pagination={false}
                          columns={treatmentPlanColumns(
                            handleDownloadFileClick
                          )}
                          className="skybase-dashboard__active-order-table"
                          size="small"
                        />
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Foto Before After" key="6">
                  <Row>
                    <Col span={24}>
                      <Collapse
                        defaultActiveKey={['1']}
                        expandIconPosition="right"
                        className="mb-15">
                        <Panel
                          header={<strong>FOTO BEFORE AFTER</strong>}
                          key="1">
                          <div className="mb-15" style={{ fontSize: 15 }}>
                            <strong>FOTO</strong>
                          </div>
                          <Row justify="space-between" align="bottom">
                            {!!listFotoBeforeAfter &&
                              listFotoBeforeAfter.map((item, index) => (
                                <Col span={8}>
                                  <img
                                    alt="foto"
                                    src={item.url}
                                    height={'auto'}
                                    width={'75%'}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      handleImageViewerOpen(index)
                                    }></img>
                                  <br></br>
                                  {index + 1}
                                </Col>
                              ))}
                          </Row>
                        </Panel>
                      </Collapse>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Detail Tagihan Pasien" key='7'>
                  <Table
                    dataSource={listTreatment}
                    rowKey="key"
                    scroll={{ x: 1100, y: 300}}
                    onChange={onTableChange}
                    pagination={pagination}
                    columns={allTreatmentColumns}
                    className='skybase-dashboard__active-order-table'
                    footer={() => (
                      <Table
                        dataSource={listTreatmentPayment}
                        rowKey="key"
                        columns={allTreatmentSummaryColumns}
                        className="skybase-dashboard__active-order-table"
                        showHeader={false}
                        pagination={false}
                        size="small"
                      />
                    )}
                    size='small'
                  />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </Content>

      {isViewerOpen && (
        <ImageViewer
          src={listImages}
          currentIndex={currentImage}
          onClose={handleImageViewerClose}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
        />
      )}
    </Layout>
  );
}

export default withRouter(RekamMedis);
