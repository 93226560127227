import React, { useEffect, useState, useRef } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { Col, Row, Layout, Button } from 'antd';
import { useReactToPrint } from 'react-to-print';
import { PageSpinner } from 'components/page-spinner';
import AppointmentApi from 'api/appointment';
import TranscationApi from 'api/transaction';
import { currencyFormatter } from 'utils';

import '../styles/index.scss';

const { Content } = Layout;

export function KwitansiRincianPembayaran({ history }) {
  const [patientDetail, setPatientDetail] = useState();

  const [receiptDetail, setReceiptDetail] = useState();

  const [loadingPage, setLoadingPage] = useState(false);

  const printComponentRef = useRef();

  const { id } = useParams();

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const getPatientDetail = async () => {
    try {
      setLoadingPage(true);

      const appointmentId = id.split('_')[0];

      const response = await AppointmentApi.getPatientDetail(appointmentId);

      let patient = response.data.data;

      setPatientDetail(patient);
    } finally {
      setLoadingPage(false);
    }
  };

  const getReceiptDetail = async () => {
    try {
      setLoadingPage(true);

      const receiptId = id.split('_')[2];

      const response = await TranscationApi.getReceiptDetailById(receiptId);

      const receipt = response.data.data;

      setReceiptDetail(receipt);
    } finally {
      setLoadingPage(false);
    }
  };

  const redirectToPreviousPage = () => {
    history.goBack();
  };

  const redirectToManageCetakKwitansi = () => {
    history.push('/kasir/manage-cetak-kasir-pasien');
  };

  const redirectToPrintPembayaran = () => {
    const appointmentId = id.split('_')[0];

    const receiptNumber = id.split('_')[1];

    history.push(`/kasir/kwitansi/${appointmentId}_${receiptNumber}`);
  };

  const redirectToStrukPembayaran = () => {
    const appointmentId = id.split('_')[0];

    const receiptNumber = id.split('_')[1];

    const receiptId = id.split('_')[2];

    history.push(
      `/kasir/struk-pembayaran/${appointmentId}_${receiptNumber}_${receiptId}`
    );
  };

  const redirectToKwitansiPembayaran = () => {
    const appointmentId = id.split('_')[0];

    const receiptNumber = id.split('_')[1];

    const receiptId = id.split('_')[2];

    history.push(
      `/kasir/kwitansi-pembayaran/${appointmentId}_${receiptNumber}_${receiptId}`
    );
  };

  const redirectToRincianPembayaran = () => {
    window.location.reload();
  };

  const useInitData = () => {
    if (id) {
      getPatientDetail();
      getReceiptDetail();
    }
  };

  useEffect(useInitData, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <div className="mb-15">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Rincian Pembayaran
              </strong>
            </Col>
          </Row>
        </div>
        {!!patientDetail && !!receiptDetail && (
          <>
            <div className="mb-50">
              <Row>
                <Col span={24} type="flex" align="middle">
                  <div className="mb-15">
                    <Button
                      type="danger"
                      style={{ marginRight: '5px' }}
                      onClick={redirectToPreviousPage}>
                      KEMBALI
                    </Button>
                    <Button
                      type="primary"
                      style={{
                        marginRight: '5px',
                        color: '#FFF',
                        backgroundColor: '#4DB3A2',
                        borderColor: '#4DB3A2',
                      }}
                      onClick={redirectToManageCetakKwitansi}>
                      KEMBALI MANAGE KWITANSI
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24} type="flex" align="middle">
                  <Button
                    type="primary"
                    style={{ marginRight: '5px' }}
                    onClick={redirectToPrintPembayaran}>
                    PRINT PEMBAYARAN
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginRight: '5px' }}
                    onClick={redirectToStrukPembayaran}>
                    STRUK PEMBAYARAN
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      marginRight: '5px',
                      color: '#FFF',
                      backgroundColor: '#32c5d2',
                      borderColor: '#32c5d2',
                    }}
                    onClick={redirectToKwitansiPembayaran}>
                    KWITANSI PEMBAYARAN
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      marginRight: '5px',
                      color: '#FFF',
                      backgroundColor: '#c49f47',
                      borderColor: '#c49f47',
                    }}
                    onClick={redirectToRincianPembayaran}>
                    RINCIAN PEMBAYARAN
                  </Button>
                  {/* <Button
                    type="primary"
                    style={{
                      marginRight: '5px',
                      color: '#FFF',
                      backgroundColor: '#4DB3A2',
                      borderColor: '#4DB3A2',
                    }}>
                    KIRIM EMAIL KWITANSI
                  </Button> */}
                  <Button
                    type="danger"
                    style={{ marginRight: '5px' }}
                    onClick={handlePrint}>
                    PRINT HALAMAN
                  </Button>
                </Col>
              </Row>
            </div>
            <div className="receipt-content">
              <div ref={printComponentRef} style={{ padding: '40px' }}>
                <div className="mb-15">
                  <Row>
                    <Col span={24} type="flex" align="middle">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/logo/logo-red.png`}
                        alt="Rata Logo"
                      />
                    </Col>
                  </Row>
                </div>
                <hr></hr>
                <div className="mb-15">
                  <Row>
                    <Col span={24} type="flex" align="middle">
                      <strong style={{ fontSize: '30px' }}>
                        PERINCIAN BIAYA PERAWATAN
                      </strong>
                    </Col>
                  </Row>
                </div>
                <div className="mb-5">
                  <Row>
                    <Col span={8}>
                      <strong>Pasien:</strong>
                    </Col>
                    <Col span={8}>
                      <strong>Keterangan:</strong>
                    </Col>
                    <Col span={8}>
                      <strong>Informasi:</strong>
                    </Col>
                  </Row>
                </div>
                <div className="mb-5">
                  <Row>
                    <Col span={8}>
                      <strong>No RM #: {patientDetail.patient.id}</strong>
                    </Col>
                    <Col span={8}>
                      <strong></strong>
                    </Col>
                    <Col span={8}>
                      <strong>
                        No Kwitansi #: {receiptDetail.receiptNumber}
                      </strong>
                    </Col>
                  </Row>
                </div>
                <div className="mb-15">
                  <Row>
                    <Col span={8}>
                      <strong>Nama #: {patientDetail.patientName}</strong>
                    </Col>
                    <Col span={8}>
                      <strong></strong>
                    </Col>
                    <Col span={8}>
                      <strong>
                        No Registrasi #: {receiptDetail.registrationNumber}
                      </strong>
                    </Col>
                  </Row>
                </div>
                <div className="mb-30">
                  <Row>
                    <Col span={24}>
                      <strong>
                        <table
                          style={{
                            borderCollapse: 'collapse',
                            width: '100%',
                          }}
                          border="1"
                          className="table-receipt">
                          <thead>
                            <tr>
                              <td>#</td>
                              <td>DESKRIPSI LAYANAN</td>
                              <td>QTY</td>
                              <td>HARGA</td>
                              <td>SUBTOTAL</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ textAlign: 'center' }} colspan="5">
                                PELAYANAN
                              </td>
                            </tr>
                            {!!receiptDetail.slipTreatmentList &&
                              receiptDetail.slipTreatmentList.map(
                                (treatment, index) => (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{treatment.description}</td>
                                    <td>{treatment.quantity}</td>
                                    <td>
                                      {currencyFormatter(treatment.unitPrice)}
                                    </td>
                                    <td>
                                      {currencyFormatter(treatment.totalPrice)}
                                    </td>
                                  </tr>
                                )
                              )}
                            <tr>
                              <td style={{ textAlign: 'right' }} colspan="4">
                                Total Biaya Pelayanan
                              </td>
                              <td>
                                {currencyFormatter(receiptDetail.grandTotal)}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'right' }} colspan="4">
                                Total Biaya BMHP
                              </td>
                              <td>0</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'center' }} colspan="5">
                                FARMASI
                              </td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'right' }} colspan="4">
                                Total Biaya Farmasi
                              </td>
                              <td>0</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'right' }} colspan="4">
                                SubTotal
                              </td>
                              <td>0</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'right' }} colspan="4">
                                Diskon
                              </td>
                              <td>0</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'right' }} colspan="4">
                                Grand Total
                              </td>
                              <td>0</td>
                            </tr>
                          </tbody>
                        </table>
                      </strong>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </>
        )}
      </Content>
    </Layout>
  );
}

export default withRouter(KwitansiRincianPembayaran);
