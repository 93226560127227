import React from 'react';
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import moment from 'moment';
import { currencyFormatter } from 'utils';
import { Tag } from 'antd';

export const visitationPatientColumns = [
  {
    title: 'Registation Number',
    key: 'registrationNumber',
    sorter: (a, b) => a.registrationNumber.localeCompare(b.registrationNumber),
    render: (row) =>
      row['registrationNumber'] ? row['registrationNumber'] : '-',
  },
  {
    title: 'Booking Date & Time',
    key: 'visitDate',
    sorter: (a, b) =>
      `${a.visitDate} ${a.visitStart}`.localeCompare(
        `${b.visitDate} ${b.visitStart}`
      ),
    render: (row) =>
      `${moment(row['visitDate']).format('DD-MM-YYYY')} ${moment(
        row['visitStart'],
        'hh:mm:ss'
      ).format('HH:mm:ss')}`,
  },
  {
    title: 'Medical Record Number',
    key: 'medicalRecordNumber',
    sorter: (a, b) =>
      a.medicalRecordNumber.localeCompare(b.medicalRecordNumber),
    render: (row) =>
      row['medicalRecordNumber'] ? row['medicalRecordNumber'] : '-',
  },
  {
    title: 'Patient Name',
    key: 'patientName',
    sorter: (a, b) => a.patientName.localeCompare(b.patientName),
    render: (row) => (row['patientName'] ? row['patientName'] : '-'),
  },
  {
    title: 'Age',
    key: 'patientDateOfBirth',
    sorter: (a, b) =>
      a['patientDateOfBirth'].localeCompare(b['patientDateOfBirth']),
    render: (row) =>
      row['patientDateOfBirth']
        ? getAge(row['patientDateOfBirth']) + ' Years Old'
        : '-',
  },
  {
    title: 'Gender',
    key: 'patientGender',
    sorter: (a, b) => a['patientGender'].localeCompare(b['patientGender']),
    render: (row) =>
      row['patientGender']
        ? row['patientGender'] === 'PEREMPUAN'
          ? 'female'
          : 'male'
        : '-',
  },
  {
    title: 'Phone',
    key: 'patientPhoneNumber',
    sorter: (a, b) =>
      a['patientPhoneNumber'].localeCompare(b['patientPhoneNumber']),
    render: (row) =>
      row['patientPhoneNumber'] ? row['patientPhoneNumber'] : '-',
  },
  {
    title: 'Email',
    key: 'patientEmail',
    sorter: (a, b) => a['patientEmail'].localeCompare(b['patientEmail']),
    render: (row) => (row['patientEmail'] ? row['patientEmail'] : '-'),
  },
  {
    title: 'Address',
    key: 'patientAddress',
    sorter: (a, b) => a['patientAddress'].localeCompare(b['patientAddress']),
    render: (row) => (row['patientAddress'] ? row['patientAddress'] : '-'),
  },
  {
    title: 'Clinic',
    key: 'clinicName',
    sorter: (a, b) => a['clinicName'].localeCompare(b['clinicName']),
    render: (row) => (row['clinicName'] ? row['clinicName'] : '-'),
  },
  {
    title: 'Doctor Name',
    key: 'doctorName',
    sorter: (a, b) => a.doctorName.localeCompare(b.doctorName),
    render: (row) => (row['doctorName'] ? row['doctorName'] : '-'),
  },
  {
    title: 'Diagnosis',
    key: 'resumeDiagnosis',
    sorter: (a, b) => a.resumeDiagnosis.localeCompare(b.resumeDiagnosis),
    render: (row) => (row['resumeDiagnosis'] ? row['resumeDiagnosis'] : '-'),
  },
  {
    title: 'Missing Tooth',
    key: 'hasMissingTooth',
    sorter: (a, b) => a.hasMissingTooth.localeCompare(b.hasMissingTooth),
    render: (row) =>
      row['hasMissingTooth'] !== null
        ? row['hasMissingTooth']
          ? 'YES'
          : 'NO'
        : '-',
  },
  /*{
    title: 'Implant',
    key: 'agreeToImplant',
    sorter: (a, b) => a.agreeToImplant.localeCompare(b.agreeToImplant),
    render: (row) =>
        row['agreeToImplant'] !== null ? row['agreeToImplant'] : '-',
  },
  {
    title: 'Reason Maybe to Implant',
    key: 'reasonMaybeImplant',
    sorter: (a, b) => a.reasonMaybeImplant.localeCompare(b.reasonMaybeImplant),
    render: (row) =>
        row['reasonMaybeImplant'] !== null ? row['reasonMaybeImplant'] : '-',
  },*/
  {
    title: 'Impacted Tooth',
    key: 'hasImpactedTooth',
    sorter: (a, b) => a.hasImpactedTooth.localeCompare(b.hasImpactedTooth),
    render: (row) =>
      row['hasImpactedTooth'] !== null
        ? row['hasImpactedTooth']
          ? 'YES'
          : 'NO'
        : '-',
  },
  {
    title: 'Odontectomy',
    key: 'agreeToOdontectomy',
    sorter: (a, b) => a.agreeToOdontectomy.localeCompare(b.agreeToOdontectomy),
    render: (row) =>
      row['agreeToOdontectomy'] !== null ? row['agreeToOdontectomy'] : '-',
  },
  {
    title: 'Reason Maybe to Odontectomy',
    key: 'reasonMaybeOdontectomy',
    sorter: (a, b) =>
      a.reasonMaybeOdontectomy.localeCompare(b.reasonMaybeOdontectomy),
    render: (row) =>
      row['reasonMaybeOdontectomy'] !== null
        ? row['reasonMaybeOdontectomy']
        : '-',
  },
  {
    title: 'ICD 10',
    key: 'patientIcd10',
    sorter: (a, b) => a.patientIcd10.localeCompare(b.patientIcd10),
    render: (row) =>
      row['patientIcd10'].length > 0 ? row['patientIcd10'].join(' | ') : '-',
  },
  {
    title: 'ICD 10 / Teeth Number',
    key: 'patientIcd10Tooth',
    sorter: (a, b) => a.patientIcd10Tooth.localeCompare(b.patientIcd10Tooth),
    render: (row) =>
      row['patientIcd10Tooth'].length > 0
        ? row['patientIcd10Tooth'].join(' | ')
        : '-',
  },
  {
    title: 'Patient Status',
    key: 'patientStatus',
    sorter: (a, b) => a.patientStatus.localeCompare(b.patientStatus),
    render: (row) =>
      row['patientStatus']
        ? row['patientStatus'] === 'Pasien Lama'
          ? 'Old'
          : 'New'
        : '-',
  },
  {
    title: 'Booking Status',
    key: 'appointmentStatus',
    sorter: (a, b) => a.appointmentStatus.localeCompare(b.appointmentStatus),
    render: (row) =>
      row['appointmentStatus'] ? row['appointmentStatus'] : '-',
  },
];

export const visitationPatientSummaryColumns = [
  {
    title: '',
    key: 'column1',
    render: (row) => (row['column1'] ? row['column1'] : '0'),
    width: '90%',
    align: 'right',
  },
  {
    title: '',
    key: 'column2',
    render: (row) => (row['column2'] ? row['column2'] : '0'),
    width: '10%',
  },
];

export const nextVisitationPatientColumns = [
  {
    title: 'Kunjungan',
    key: 'visitDate',
    sorter: (a, b) => a.visitDate.localeCompare(b.visitDate),
    render: (row) => `${moment(row['visitDate']).format('DD-MM-YYYY')}`,
  },
  {
    title: 'Tanggal Kunjungan Berikutnya',
    key: 'nextVisitDate',
    // sorter: (a, b) => a.visitDate.localeCompare(b.visitDate),
    render: (row) =>
      `${moment(`${row['nextVisitDate']}T${row['nextVisitTime']}`)
        .add(7, 'hours')
        .format('DD-MM-YYYY')}`,
  },
  {
    title: 'Jam Kunjungan Berikutnya',
    key: 'nextVisitTime',
    // sorter: (a, b) => a.nextVisitTime.localeCompare(b.nextVisitTime),
    render: (row) =>
      `${moment(`${row['nextVisitDate']}T${row['nextVisitTime']}`)
        .add(7, 'hours')
        .format('HH:mm')}`,
  },
  {
    title: 'Durasi Kunjungan Berikutnya (Menit)',
    key: 'nextVisitDuration',
    // sorter: (a, b) => a.nextVisitDuration.localeCompare(b.nextVisitDuration),
    render: (row) =>
      row['nextVisitDuration'] && row['nextVisitDuration']
        ? row['nextVisitDuration']
        : '-',
  },
  {
    title: 'Tujuan Kunjungan Berikutnya',
    key: 'tujuanNextVisit',
    render: (row) =>
      row.tujuanNextVisit ? row.tujuanNextVisit.map(
          (tujuan) => {
            if (tujuan === 'CABUT_GIGI')
              return 'Exo / Cabut Gigi';
            else if (tujuan === 'TEETH_RECONTOURING')
              return 'Teeth Recontouring / Reshaping';
            else
              return tujuan.split('_').map(word => word[0] + word.slice(1).toLowerCase()).join(' ');
          }
      ).join(', ') : ''
  },
  {
    title: 'No RM',
    key: 'medicalRecordNumber',
    sorter: (a, b) =>
      a.medicalRecordNumber.localeCompare(b.medicalRecordNumber),
    render: (row) =>
      row['medicalRecordNumber'] ? row['medicalRecordNumber'] : '-',
  },
  {
    title: 'Nama Pasien',
    key: 'fullname',
    sorter: (a, b) => a.fullname.localeCompare(b.fullname),
    render: (row) =>
      row['fullname'] && row['fullname'] ? row['fullname'] : '-',
  },
  {
    title: 'Alamat',
    key: 'address',
    sorter: (a, b) => a['address'].patientAddress.localeCompare(b['address']),
    render: (row) => (row['address'] && row['address'] ? row['address'] : '-'),
  },
  {
    title: 'No. Telp',
    key: 'phoneNumber',
    sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
    render: (row) => (row['phoneNumber'] ? row['phoneNumber'] : '-'),
  },
  {
    title: 'Klinik',
    key: 'clinicName',
    sorter: (a, b) => a.clinicName.localeCompare(b.clinicName),
    render: (row) => (row['clinicName'] ? row['clinicName'] : '-'),
  },
  {
    title: 'Dokter',
    key: 'doctorName',
    sorter: (a, b) => a.doctorName.localeCompare(b.doctorName),
    render: (row) => (row['doctorName'] ? row['doctorName'] : '-'),
  },
  {
    title: 'Keterangan',
    key: 'remarks',
    sorter: (a, b) => a.remarks.localeCompare(b.remarks),
    render: (row) => (row['remarks'] ? row['remarks'] : '-'),
  },
];

export const visitationPatientCheckColumns = [
  {
    title: 'NO REGISTRASI',
    key: 'registrationNumber',
    sorter: (a, b) => a.registrationNumber.localeCompare(b.registrationNumber),
    render: (row) =>
      row['registrationNumber'] ? row['registrationNumber'] : '-',
  },
  {
    title: 'TANGGAL & JAM DATANG',
    key: 'visitDate',
    sorter: (a, b) =>
      `${a.visitDate} ${a.visitStart}`.localeCompare(
        `${b.visitDate} ${b.visitStart}`
      ),
    render: (row) =>
      `${moment(row['visitDate']).format('DD-MM-YYYY')} ${moment(
        row['visitStart'],
        'hh:mm:ss'
      ).format('HH:mm:ss')}`,
  },
  {
    title: 'TANGGAL & JAM PULANG',
    key: 'visitDate',
    sorter: (a, b) =>
      `${a.visitDate} ${a.visitStart}`.localeCompare(
        `${b.visitDate} ${b.visitStart}`
      ),
    render: (row) =>
      `${moment(row['visitDate']).format('DD-MM-YYYY')} ${moment(
        row['visitEnd'],
        'hh:mm:ss'
      ).format('HH:mm:ss')}`,
  },
  {
    title: 'NO REKAM MEDIS - NAMA',
    key: 'medicalRecordNumber',
    sorter: (a, b) =>
      `${a.medicalRecordNumber} ${a.patientName}`.localeCompare(
        `${b.medicalRecordNumber} ${b.patientName}`
      ),
    render: (row) =>
      `${row['medicalRecordNumber']} - ${
        row['patientName'] ? row['patientName'] : ''
      }`,
  },
  {
    title: 'KLINIK, DOKTER',
    key: 'clinic.clinicName',
    sorter: (a, b) =>
      `${a['clinic'] ? a['clinic'].clinicName : '-'} ${
        a.doctorName
      }`.localeCompare(
        `${b['clinic'] ? b['clinic'].clinicName : '-'} ${b.doctorName}`
      ),
    render: (row) =>
      `${row['clinicName'] ? row['clinicName'] : '-'}, ${
        row['doctorName'] ? row['doctorName'] : '-'
      }`,
  },
  {
    title: 'DIAGNOSA',
    key: 'diagnose',
    sorter: (a, b) => a.diagnose.localeCompare(b.diagnose),
    render: (row) => (row['diagnose'] ? row['diagnose'] : '-'),
  },
  {
    title: 'ASUHAN KEPERAWATAN',
    key: 'nurseInstruction',
    sorter: (a, b) => a.nurseInstruction.localeCompare(b.nurseInstruction),
    render: (row) =>
      row['nurseInstruction'] ? (
        <CheckCircleTwoTone twoToneColor="green" style={{ fontSize: 30 }} />
      ) : (
        <CloseCircleTwoTone twoToneColor="red" style={{ fontSize: 30 }} />
      ),
  },
  {
    title: 'CPPT DOKTER',
    key: 'doctorCppt',
    sorter: (a, b) => a.doctorCppt.localeCompare(b.doctorCppt),
    render: (row) =>
      row['doctorCppt'] ? (
        <CheckCircleTwoTone twoToneColor="green" style={{ fontSize: 30 }} />
      ) : (
        <CloseCircleTwoTone twoToneColor="red" style={{ fontSize: 30 }} />
      ),
  },
  {
    title: 'CPPT PERAWAT',
    key: 'nurseCppt',
    sorter: (a, b) => a.nurseCppt.localeCompare(b.nurseCppt),
    render: (row) =>
      row['nurseCppt'] ? (
        <CheckCircleTwoTone twoToneColor="green" style={{ fontSize: 30 }} />
      ) : (
        <CloseCircleTwoTone twoToneColor="red" style={{ fontSize: 30 }} />
      ),
  },
  {
    title: 'STATUS PASIEN',
    key: 'statusPatient',
    sorter: (a, b) => a.statusPatient.localeCompare(b.statusPatient),
    render: (row) => (row['statusPatient'] ? row['statusPatient'] : '-'),
  },
];

export const doctorTreatmentReportColumns = (pageNumber) => [
  {
    title: 'No',
    render: (record) => record.key + ((pageNumber-1) * 10),
    width: 50,
  },
  {
    title: 'Treatment Date & Time',
    key: 'timestamp',
    sorter: (a,b) => a.timestamp.localeCompare(b.timestamp),
    render: (row) => `${moment(row['timestamp']).format('DD MMM YYYY HH:mm:ss')}`,
    width: 125,
  },
  {
    title: 'Doctor Name',
    key: 'doctorName',
    sorter: (a,b) => a.doctorName.localeCompare(b.doctorName),
    render: (row) => row['doctorName'],
    width: 200,
  },
  {
    title: 'Service Category',
    key: 'serviceCategory',
    sorter: (a,b) => a.serviceCategory.localeCompare(b.serviceCategory),
    render: (row) => row['serviceCategoryName'],
    width: 180,
  },
  {
    title: 'Service',
    key: 'service',
    sorter: (a,b) => a.service.localeCompare(b.service),
    render: (row) => row['serviceName'],
    width: 180,
  },
  {
    title: 'Price',
    key: 'treatmentPrice',
    sorter: (a,b) => a.treatmentPrice - b.treatmentPrice,
    render: (row) => row['price'] ? `Rp ${currencyFormatter(row['price'])}` : currencyFormatter(0),
    width: 180,
  },
  {
    title: 'Qty',
    key : 'quantity',
    sorter : (a,b) => a.quantity - b.quantity,
    render : (row) => row['qty'],
    width: 80,
  },
  {
    title: 'Total Price',
    key: 'totalPrice',
    sorter: (a,b) => a.totalPrice - b.totalPrice,
    render : (row) => row['totalPrice'] ? `Rp ${currencyFormatter(row['totalPrice'])}` : currencyFormatter(0),
    width: 180,
  },
  {
    title: 'Payment Status',
    key: 'paymentStatus',
    render: (row) => <Tag color={row['status'] === 'PAID' ? 'green' : 'red'}>{row['status'] === 'CONFIRMED' ? 'UNPAID' : row['status']}</Tag>,
    width: 100,
  },
  {
    title: 'Void Reason',
    key: 'voidReason',
    sorter: (a,b) => a.voidReason.localeCompare(b.voidReason),
    render: (row) => row['voidReason'] ? row['voidReason'].length > 30 ? `${row['voidReason'].slice(0,30)}...` : row['voidReason'] : '',
    width: 200,
  },
  {
    title: 'Clinic',
    key: 'clinicName',
    sorter: (a,b) => a.clinicName.localeCompare(b.clinicName),
    render : (row) => row['clinicName'],
    width: 200,
  },
];

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
