import React from 'react';
import { Button, Tag } from 'antd';
import moment from 'moment';

export const patientPaymentColumns = (redirectToPatientPaymentDetail) => [
  {
    title: 'Aksi',
    key: 'index',
    render: (row) => (
      <Button
        type="primary"
        onClick={() => redirectToPatientPaymentDetail(`${row['id']}`)}>
        INFO PASIEN
      </Button>
    ),
  },
  {
    title: 'Nomer MR',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
    render: (row) => (row['id'] ? row['id'] : '-'),
  },
  {
    title: 'Nama',
    key: 'fullname',
    sorter: (a, b) => a.fullname.localeCompare(b.fullname),
    render: (row) => (row['fullname'] ? row['fullname'] : '-'),
  },
  {
    title: 'Tanggal Lahir',
    key: 'dateOfBirth',
    sorter: (a, b) => a.dateOfBirth.localeCompare(b.dateOfBirth),
    render: (row) => (row['dateOfBirth'] ? row['dateOfBirth'] : '-'),
  },
  {
    title: 'Nama Orang Tua',
    key: 'parentName',
    sorter: (a, b) => a.parentName.localeCompare(b.parentName),
    render: (row) => (row['parentName'] ? row['parentName'] : '-'),
  },
  {
    title: 'Alamat',
    key: 'patientAddress',
    sorter: (a, b) => a.patientAddress.localeCompare(b.patientAddress),
    render: (row) => (row['patientAddress'] ? row['patientAddress'] : '-'),
  },
];

export const afterSalesHistoryColumns = (editRow, closeRow) => [
  {
    title: 'Action',
    key: 'index',
    render: (row) =>
      row['status'].toLowerCase() === 'pending' ? (
        <>
          <Button
            type="primary"
            onClick={() =>
              editRow(row['id'], {
                category: row['category'],
                complain: row['complain'],
                complainDetail: row['complainDetail'],
                solution: row['solution'],
                group: row['group'],
              })
            }
            style={{ width: '100%' }}>
            EDIT
          </Button>
          <Button
            type="danger"
            onClick={() => closeRow(row['id'])}
            style={{ width: '100%' }}>
            CLOSE
          </Button>
        </>
      ) : (
        <></>
      ),
  },
  {
    title: 'Group',
    key: 'group',
    render: (row) => (row['group'] ? row['group'] : '-'),
  },
  {
    title: 'Kategori',
    key: 'category',
    render: (row) => (row['category'] ? row['category'] : '-'),
  },
  {
    title: 'Keluhan',
    key: 'complain',
    render: (row) => (row['complain'] ? row['complain'] : '-'),
  },
  {
    title: 'Detail Keluhan',
    key: 'complainDetail',
    render: (row) => (row['complainDetail'] ? row['complainDetail'] : '-'),
  },
  {
    title: 'Keluhan Aligner',
    children: [
      {
        title: 'RA',
        key: 'alignerIssueRa',
        render: (row) => (row['alignerIssueRa'] ? row['alignerIssueRa'] : '-'),
      },
      {
        title: 'RB',
        key: 'alignerIssueRb',
        render: (row) => (row['alignerIssueRb'] ? row['alignerIssueRb'] : '-'),
      },
    ],
  },
  {
    title: 'Solusi',
    key: 'solution',
    render: (row) => (row['solution'] ? row['solution'] : '-'),
  },
  {
    title: 'Staff Name',
    key: 'staffName',
    render: (row) => (row['staffName'] ? row['staffName'] : '-'),
  },
  {
    title: 'Tanggal Input',
    key: 'createdAt',
    render: (row) =>
      row['createdAt']
        ? moment(row['createdAt']).format('DD-MM-YYYY HH:mm:ss')
        : '-',
  },
  {
    title: 'Status',
    key: 'status',
    render: (row) =>
      row['status'] ? (
        <Tag
          style={
            row['status'].toLowerCase() === 'pending'
              ? {
                  color: '#fa8c16',
                  background: '#fff7e6',
                  borderColor: '#ffd591',
                }
              : {
                  color: '#fff',
                  backgroundColor: '#e7505a',
                  borderColor: '#e7505a',
                }
          }>
          {row['status']}
        </Tag>
      ) : (
        '-'
      ),
  },
];

export const uploadInformedPatientColumns = (downloadFile) => [
  {
    title: 'No',
    render: (record) => record.key,
    width: 50,
  },
  {
    title: 'Timestamp',
    key: 'createdAt',
    render: (row) =>
      row['createdAt'] ? moment(row['createdAt']).format('DD-MM-YYYY') : '-',
  },
  {
    title: 'File Name',
    key: 'filename',
    sorter: (a, b) => a.filename.localeCompare(b.filename),
    render: (row) => (row['filename'] ? row['filename'] : '-'),
  },
  {
    title: 'Staff Name',
    key: 'staffName',
    sorter: (a, b) => a.staffName.localeCompare(b.staffName),
    render: (row) => (row['staffName'] ? row['staffName'] : '-'),
  },
  {
    title: 'Action',
    key: 'index',
    render: (row) => (
      <Button type="primary" onClick={() => downloadFile(`${row['url']}`)}>
        DOWNLOAD FILE
      </Button>
    ),
  },
];

export const interactionPatientReportColumns = [
  {
    title: 'Tanggal Input',
    key: 'date',
    render: (row) =>
      row['date'] ? moment(row['date']).format('DD-MM-YYYY') : '-',
  },
  {
    title: 'Nama Pasien',
    key: 'fullname',
    render: (row) => (row['fullname'] ? row['fullname'] : '-'),
  },
  {
    title: 'Group',
    key: 'group',
    render: (row) => (row['group'] ? row['group'] : '-'),
  },
  {
    title: 'Kategori',
    key: 'category',
    render: (row) => (row['category'] ? row['category'] : '-'),
  },
  {
    title: 'Keluhan',
    key: 'complain',
    render: (row) => (row['complain'] ? row['complain'] : '-'),
  },
  {
    title: 'Detail Keluhan',
    key: 'complainDetail',
    render: (row) => (row['complainDetail'] ? row['complainDetail'] : '-'),
  },
  {
    title: 'Keluhan Aligner',
    children: [
      {
        title: 'RA',
        key: 'alignerIssueRa',
        render: (row) => (row['alignerIssueRa'] ? row['alignerIssueRa'] : '-'),
      },
      {
        title: 'RB',
        key: 'alignerIssueRb',
        render: (row) => (row['alignerIssueRb'] ? row['alignerIssueRb'] : '-'),
      },
    ],
  },
  {
    title: 'Solusi',
    key: 'solution',
    render: (row) => (row['solution'] ? row['solution'] : '-'),
  },
  {
    title: 'Status',
    key: 'status',
    render: (row) =>
      row['status'] ? (
        <Tag
          style={
            row['status'].toLowerCase() === 'pending'
              ? {
                  color: '#fa8c16',
                  background: '#fff7e6',
                  borderColor: '#ffd591',
                }
              : {
                  color: '#fff',
                  backgroundColor: '#e7505a',
                  borderColor: '#e7505a',
                }
          }>
          {row['status'] && row['status'].toUpperCase()}
        </Tag>
      ) : (
        '-'
      ),
  },
  {
    title: 'Staff Name',
    key: 'staffName',
    render: (row) => (row['staffName'] ? row['staffName'] : '-'),
  },
  {
    title: 'Tanggal (Pending Case)',
    key: 'pendingTimestamp',
    render: (row) =>
      row['pendingTimestamp']
        ? moment(row['pendingTimestamp']).format('DD-MM-YYYY HH:mm:ss')
        : '-',
  },
  {
    title: 'Tanggal (Closed Case)',
    key: 'closedTimestamp',
    render: (row) =>
      row['closedTimestamp']
        ? moment(row['closedTimestamp']).format('DD-MM-YYYY HH:mm:ss')
        : '-',
  },
];
