import React, { Fragment, useState } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import 'rc-color-picker/assets/index.css';

import { LoginPageForm as LoginPage } from 'pages/login';
import { PembayaranPasien } from 'pages/kasir/pembayaran-pasien';
import { PembayaranPasienDetail } from 'pages/kasir/pembayaran-pasien/detail';
import { AntrianPasienRawatJalan } from 'pages/kasir/antrian-pasien';
import { PointOfSales } from 'pages/kasir/point-of-sales';
import { ManageCetakKasirPasien } from 'pages/kasir/manage-cetak-kasir-pasien';
import { ManageBatalKasirPasien } from 'pages/kasir/manage-batal-kasir-pasien';
import { ManagePrintKwitansi } from 'pages/kasir/manage-print-kwitansi';
import { DetailBatalKasir } from 'pages/kasir/detail-batal-kasir';
import { Kwitansi } from 'pages/kasir/kwitansi';
import { KwitansiSelection } from 'pages/kasir/kwitansi-selection';
import { KwitansiStrukPembayaran } from 'pages/kasir/kwitansi-struk-pembayaran';
import { KwitansiKwitansiPembayaran } from 'pages/kasir/kwitansi-kwitansi-pembayaran';
import { KwitansiRincianPembayaran } from 'pages/kasir/kwitansi-rincian-pembayaran';
import { LaporanDiskon } from 'pages/kasir/laporan-diskon';
import { DetailLaporanKasir } from 'pages/kasir/detail-laporan-kasir';
import { InteraksiPasien } from 'pages/interaksi-pasien';
import { InteraksiPasienDetail } from 'pages/interaksi-pasien/detail';
import { LaporanInteraksiPasien } from 'pages/laporan-interaksi-pasien';
import { RekamMedis } from 'pages/rekam-medis';
import { LaporanKunjunganPasien } from 'pages/laporan-medical-record/laporan-kunjungan-pasien';
import { LaporanKunjunganBerikutnya } from 'pages/laporan-medical-record/laporan-kunjungan-berikutnya';
import { LaporanPengunjung } from 'pages/laporan-medical-record/laporan-pengunjung';
import { CekLaporanKunjunganPasien } from 'pages/laporan-medical-record/cek-laporan-kunjungan-pasien';
import { DoctorTreatmentReport } from 'pages/laporan-medical-record/doctor-treatment-report';
import { ManageMetodePembayaran } from 'pages/master-keuangan/metode-pembayaran';
import { ManageKategoriAfterSales } from 'pages/master-marketing/kategori-after-sales';
import { ManageKeluhanAfterSales } from 'pages/master-marketing/keluhan-after-sales';
import { ManageKategoriProduksi } from 'pages/master-marketing/kategori-produksi';
import { ManageReasonLabOrder } from 'pages/master-marketing/reason-lab-order';
import { InsuranceReport } from 'pages/insurance/report';
import { InvoiceInsurance } from 'pages/kasir/invoice-insurance';

import Header from 'components/header';
import AuthenticatedGuardRoute from 'components/guard/authenticated';
import NotAuthenticatedGuardRoute from 'components/guard/notAuthenticated';

//context
import AuthenticationContext from 'contexts/authentication';

import 'antd/dist/antd.css';
import './App.scss';

function ContentManagement() {

  const [collapse, setCollapse] = useState(false);

  const toggleCollapsed = () => {
    setCollapse(!collapse);
  };

  return (
    <Fragment>
      <Switch>
        <NotAuthenticatedGuardRoute exact path="/" component={LoginPage} />;
        <Layout className="pd-cms">
          {/* {!isEmbed ? <Sidebar collapse={collapse} /> : <></>} */}
          {/* <Sidebar collapse={collapse} /> */}
          <Layout>
            <Header toggleCollapsed={toggleCollapsed} collapse={collapse} />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/pembayaran-pasien"
              component={PembayaranPasien}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/pembayaran-pasien/:patientId"
              component={PembayaranPasienDetail}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/antrian-pasien-rawat-jalan"
              component={AntrianPasienRawatJalan}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/point-of-sales"
              component={PointOfSales}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/point-of-sales/:appointmentId"
              component={PointOfSales}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/manage-cetak-kasir-pasien"
              component={ManageCetakKasirPasien}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/manage-batal-kasir-pasien"
              component={ManageBatalKasirPasien}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/manage-print-kwitansi/"
              component={ManagePrintKwitansi}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/manage-print-kwitansi/:id"
              component={ManagePrintKwitansi}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/detail-batal-kasir/"
              component={DetailBatalKasir}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/detail-batal-kasir/:id"
              component={DetailBatalKasir}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/kwitansi"
              component={Kwitansi}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/kwitansi/:id"
              component={Kwitansi}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/kwitansi-selection"
              component={KwitansiSelection}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/kwitansi-selection/:id"
              component={KwitansiSelection}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/struk-pembayaran"
              component={KwitansiStrukPembayaran}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/struk-pembayaran/:id"
              component={KwitansiStrukPembayaran}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/kwitansi-pembayaran"
              component={KwitansiKwitansiPembayaran}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/kwitansi-pembayaran/:id"
              component={KwitansiKwitansiPembayaran}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/rincian-pembayaran"
              component={KwitansiRincianPembayaran}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/rincian-pembayaran/:id"
              component={KwitansiRincianPembayaran}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/laporan-diskon"
              component={LaporanDiskon}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/detail-laporan-kasir"
              component={DetailLaporanKasir}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/interaksi-pasien"
              component={InteraksiPasien}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/interaksi-pasien/:patientId"
              component={InteraksiPasienDetail}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/laporan-interaksi-pasien"
              component={LaporanInteraksiPasien}
            />
            <AuthenticatedGuardRoute
              exact
              path="/rekam-medis/:patientId"
              component={RekamMedis}
            />
            <AuthenticatedGuardRoute
              exact
              path="/laporan-medical-record/laporan-kunjungan-pasien"
              component={LaporanKunjunganPasien}
            />
            <AuthenticatedGuardRoute
              exact
              path="/laporan-medical-record/laporan-kunjungan-berikutnya"
              component={LaporanKunjunganBerikutnya}
            />
            <AuthenticatedGuardRoute
              exact
              path="/laporan-medical-record/laporan-pengunjung"
              component={LaporanPengunjung}
            />
            <AuthenticatedGuardRoute
              exact
              path="/laporan-medical-record/cek-laporan-kunjungan-pasien"
              component={CekLaporanKunjunganPasien}
            />
            <AuthenticatedGuardRoute
              exact
              path="/laporan-medical-record/doctor-treatment-report"
              component={DoctorTreatmentReport}
            />
            <AuthenticatedGuardRoute
              exact
              path="/master-keuangan/manage-metode-pembayaran"
              component={ManageMetodePembayaran}
            />
            <AuthenticatedGuardRoute
              exact
              path="/master-marketing/manage-kategori-after-sales"
              component={ManageKategoriAfterSales}
            />
            <AuthenticatedGuardRoute
              exact
              path="/master-marketing/manage-keluhan-after-sales"
              component={ManageKeluhanAfterSales}
            />
            <AuthenticatedGuardRoute
              exact
              path="/master-marketing/manage-kategori-produksi"
              component={ManageKategoriProduksi}
            />
            <AuthenticatedGuardRoute
              exact
              path="/master-marketing/manage-reason-lab-order"
              component={ManageReasonLabOrder}
            />
            <AuthenticatedGuardRoute
              exact
              path="/insurance/report"
              component={InsuranceReport}
            />
            <AuthenticatedGuardRoute
              exact
              path="/insurance/report/:status"
              component={InsuranceReport}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/invoice-insurance"
              component={InvoiceInsurance}
            />
            <AuthenticatedGuardRoute
              exact
              path="/kasir/invoice-insurance/:id"
              component={InvoiceInsurance}
            />
          </Layout>
        </Layout>
      </Switch>
    </Fragment>
  );
}

function App() {
  return (
    <Router>
      <AuthenticationContext>
        <ContentManagement />
      </AuthenticationContext>
    </Router>
  );
}

export default App;
