import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Layout,
  Table,
  Button,
  Input,
  Tabs,
  Modal,
  Collapse,
  Upload,
} from 'antd';
import {
  ExclamationCircleTwoTone,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import { withRouter, useParams } from 'react-router-dom';
import PatientInteractionApi from 'api/patient-interaction';
import MarketingApi from 'api/marketing';
import { PageSpinner } from 'components/page-spinner';
import SelectDropdown from 'components/select-dropdown';
import { setToArray } from 'utils';

import {
  afterSalesHistoryColumns,
  uploadInformedPatientColumns,
} from '../helper';

import '../styles/index.scss';

const { Content } = Layout;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Panel } = Collapse;

export function InteraksiPasienDetail({ history }) {
  const [category, setCategory] = useState();
  const [complain, setComplain] = useState();
  const [complainDetail, setComplainDetail] = useState();
  const [alignerIssueRa, setAlignerIssueRa] = useState();
  const [alignerIssueRb, setAlignerIssueRb] = useState();
  const [solution, setSolution] = useState();
  const [group, setGroup] = useState();
  const [status, setStatus] = useState();

  const [idModal, setIdModal] = useState();
  const [categoryModal, setCategoryModal] = useState();
  const [complainModal, setComplainModal] = useState();
  const [complainDetailModal, setComplainDetailModal] = useState();
  const [solutionModal, setSolutionModal] = useState();
  const [groupModal, setGroupModal] = useState();

  const [listAfterSales, setListAfterSales] = useState([]);
  const [listIc, setListIc] = useState([]);
  const [listFileIc, setListFileIc] = useState([]);

  const [listCategoryOptions, setListCategoryOptions] = useState([]);
  const [listComplainOptions, setListComplainOptions] = useState([]);
  const [listFilteredComplainOptions, setListFilteredComplainOptions] =
    useState([]);
  const [listAlignerIssueOptions, setListAlignerIssueOptions] = useState([]);
  const [listGroupOptions, setListGroupOptions] = useState([]);
  const [listStatusOptions, setListStatusOptions] = useState([]);

  const [isSubmitAfterSalesButtonEnabled, setIsSubmitAfterSalesButtonEnabled] =
    useState(false);

  const [isEditAfterSalesPopupOpen, setIsEditAfterSalesPopupOpen] =
    useState(false);

  const [isConfirmAfterSalesPopupOpen, setIsConfirmAfterSalesPopupOpen] =
    useState(false);

  const [isSuccessAfterSalesPopupOpen, setIsSuccessAfterSalesPopupOpen] =
    useState(false);

  const [changeStatus, setChangeStatus] = useState();

  const [loadingPage, setLoadingPage] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    showSizeChanger: true,
  });

  const { patientId } = useParams();

  // set options for dropdown (temporary for category and complain)
  const getCategoryOptions = async () => {
    const response = await MarketingApi.getCategoryList();

    let categoryList = setToArray(response.data);

    const categoryListOpts = categoryList.map((value) => ({
      label: value.name,
      value: value.name,
    }));

    setListCategoryOptions(categoryListOpts);
  };

  const getComplainOptions = async () => {
    const response = await MarketingApi.getComplainList();

    let complainList = setToArray(response.data);

    const complainListOpts = complainList.map((value) => ({
      label: value.name,
      value: value.name,
    }));

    setListComplainOptions(response.data.data.list);
    setListFilteredComplainOptions(complainListOpts);
  };

  const getAlignerIssueOptions = () => {
    const alignerIssueList = [];

    for (let index = 0; index < 30; index++) {
      alignerIssueList.push(index + 1);
    }

    const alignerIssueListOpts = alignerIssueList.map((value) => ({
      label: value,
      value: value,
    }));

    setListAlignerIssueOptions(alignerIssueListOpts);
  };

  const getGroupOptions = () => {
    const groupList = ['Inbound', 'Escalation', 'Outbound'];

    const groupListOpts = groupList.map((value) => ({
      label: value,
      value: value,
    }));

    setListGroupOptions(groupListOpts);
  };

  const getStatusOptions = () => {
    const statusList = ['Pending', 'Closed'];

    const statusListOpts = statusList.map((value) => ({
      label: value,
      value: value,
    }));

    setListStatusOptions(statusListOpts);
  };

  // api call after sales
  async function getAfterSalesList() {
    const response = await PatientInteractionApi.getAfterSalesList(patientId);

    let afterSalesList = setToArray(response.data);

    const modifiedAfterSalesList = afterSalesList.map((item, index) => ({
      ...item,
      key: index + 1,
    }));

    setListAfterSales(modifiedAfterSalesList);
  }

  async function submitAfterSales() {
    const staffId = localStorage.getItem('id');
    const staffName = localStorage.getItem('name');

    const payload = {
      category: category,
      complain: complain,
      complainDetail: complainDetail,
      alignerIssueRa: alignerIssueRa,
      alignerIssueRb: alignerIssueRb,
      solution: solution,
      group: group,
      status: status,
      staffName: staffName ? staffName : '',
      staffId: staffId ? staffId : 0,
    };

    const response = await PatientInteractionApi.submitAfterSales(
      payload,
      patientId
    );

    if (response.data.resultCode === '00') {
      getAfterSalesList();

      setCategory();
      setComplain();
      setComplainDetail();
      setAlignerIssueRa();
      setAlignerIssueRb();
      setSolution();
      setGroup();
      setStatus();

      setIsConfirmAfterSalesPopupOpen(false);
      setIsSuccessAfterSalesPopupOpen(true);
    }
  }

  async function editAfterSales() {
    const staffId = localStorage.getItem('id');
    const staffName = localStorage.getItem('name');

    const payload = {
      category: categoryModal,
      complain: complainModal,
      complainDetail: complainDetailModal,
      solution: solutionModal,
      group: groupModal,
      staffName: staffName ? staffName : '',
      staffId: staffId ? staffId : 0,
    };

    const response = await PatientInteractionApi.editAfterSales(
      payload,
      patientId,
      idModal
    );

    if (response.data.resultCode === '00') {
      getAfterSalesList();

      setIdModal();
      setCategoryModal();
      setComplainModal();
      setComplainDetailModal();
      setSolutionModal();
      setGroupModal();

      setIsConfirmAfterSalesPopupOpen(false);
      setIsSuccessAfterSalesPopupOpen(true);
    }
  }

  async function closeAfterSales() {
    const payload = {
      status: 'CLOSED',
    };

    const response = await PatientInteractionApi.editAfterSales(
      payload,
      patientId,
      idModal
    );

    if (response.data.resultCode === '00') {
      getAfterSalesList();

      setIdModal();

      setIsConfirmAfterSalesPopupOpen(false);
      setIsSuccessAfterSalesPopupOpen(true);
    }
  }

  // api call informed concern
  async function getInformedConcernList() {
    const response = await PatientInteractionApi.getInformedConcern(patientId);

    let informedConcernList = setToArray(response.data);

    const modifiedInformedConcernList = informedConcernList.map(
      (item, index) => ({
        ...item,
        key: index + 1,
      })
    );

    setListIc(modifiedInformedConcernList);
  }

  async function submitInformedConcern(listFile) {
    try {
      setLoadingPage(true);

      const payload = new FormData();

      payload.append('file', listFileIc[0]);
      payload.append('clinicCode', '');
      payload.append(
        'staffName',
        localStorage.getItem('name') ? localStorage.getItem('name') : ''
      );
      payload.append(
        'staffId',
        localStorage.getItem('id') ? localStorage.getItem('id') : ''
      );
      payload.append('clinic', '');

      const response = await PatientInteractionApi.submitInformedConcern(
        payload,
        patientId
      );

      if (response.data.resultCode === '00') {
        getInformedConcernList();

        setListFileIc([]);

        setIsSuccessAfterSalesPopupOpen(true);
      } else {
        Modal.warning({
          title: 'Perhatian',
          content: 'Terjadi kesalahan pada server.',
        });
      }
    } finally {
      setLoadingPage(false);
    }
  }

  const handleComplainFilter = (category) => {
    const filteredComplainOpts = listComplainOptions.filter(
      (item) => item.afterSalesCategory.name === category
    );

    const modifiedComplainListOpts = filteredComplainOpts.map((value) => ({
      label: value.name,
      value: value.name,
    }));

    setListFilteredComplainOptions(modifiedComplainListOpts);
  };

  // handler input
  const handleCategoryChange = (value) => {
    setCategory(value);
    handleComplainFilter(value);
  };

  const handleComplainChange = (value) => {
    setComplain(value);
  };

  const handleComplainDetailChange = (event) => {
    setComplainDetail(event.target.value);
  };

  const handleAlignerIssueRaChange = (value) => {
    setAlignerIssueRa(value);
  };

  const handleAlignerIssueRbChange = (value) => {
    setAlignerIssueRb(value);
  };

  const handleSolutionChange = (event) => {
    setSolution(event.target.value);
  };

  const handleGroupChange = (value) => {
    setGroup(value);
  };

  const handleStatusChange = (value) => {
    setStatus(value);
  };

  // handler input modal
  const handleCategoryModalChange = (value) => {
    setCategoryModal(value);
    handleComplainFilter(value);
  };

  const handleComplainModalChange = (value) => {
    setComplainModal(value);
  };

  const handleComplainDetailModalChange = (event) => {
    setComplainDetailModal(event.target.value);
  };

  const handleSolutionModalChange = (event) => {
    setSolutionModal(event.target.value);
  };

  const handleGroupModalChange = (value) => {
    setGroupModal(value);
  };

  // handle after sales table action
  const handleEditAfterSalesClick = (id, value) => {
    handleFlowAfterSales('edit', 'openPopup', id, value);
  };

  const handleCloseAfterSalesClick = (id) => {
    setIdModal(id);

    handleFlowAfterSales('close', 'openConfirm', id);
  };

  // handle flow after sales
  const handleFlowAfterSales = (type, flow, id, value) => {
    if (changeStatus !== type) setChangeStatus(type);

    if (type === 'new') {
      if (flow === 'openConfirm') {
        setIsConfirmAfterSalesPopupOpen(true);
      } else if (flow === 'closeConfirm') {
        setIsConfirmAfterSalesPopupOpen(false);
      } else if (flow === 'submit') {
        submitAfterSales();
      } else if (flow === 'closeSuccess') {
        setIsSuccessAfterSalesPopupOpen(false);
      }
    } else if (type === 'edit') {
      if (flow === 'openPopup') {
        const { category, complain, complainDetail, solution, group } = value;

        handleComplainFilter(category);

        setIdModal(id);
        setCategoryModal(category);
        setComplainModal(complain);
        setComplainDetailModal(complainDetail);
        setSolutionModal(solution);
        setGroupModal(group);

        setIsEditAfterSalesPopupOpen(true);
      } else if (flow === 'closePopup') {
        setIdModal();
        setCategoryModal();
        setComplainModal();
        setComplainDetailModal();
        setSolutionModal();
        setGroupModal();

        setIsEditAfterSalesPopupOpen(false);
      } else if (flow === 'openConfirm') {
        setIsEditAfterSalesPopupOpen(false);
        setIsConfirmAfterSalesPopupOpen(true);
      } else if (flow === 'closeConfirm') {
        setIsConfirmAfterSalesPopupOpen(false);
      } else if (flow === 'submit') {
        editAfterSales();
      } else if (flow === 'closeSuccess') {
        setIsSuccessAfterSalesPopupOpen(false);
      }
    } else if (type === 'close') {
      if (flow === 'openConfirm') {
        setIsConfirmAfterSalesPopupOpen(true);
      } else if (flow === 'closeConfirm') {
        setIsConfirmAfterSalesPopupOpen(false);
      } else if (flow === 'submit') {
        closeAfterSales();
      } else if (flow === 'closeSuccess') {
        setIsSuccessAfterSalesPopupOpen(false);
      }
    } else {
      if (flow === 'closeSuccess') {
        setIsSuccessAfterSalesPopupOpen(false);
      }
    }
  };

  // handle informed concern table action
  const handleDownloadFileClick = (url) => {
    window.open(url);
  };

  // handle upload informed concern
  const handleIcBeforeUpload = (file) => {
    const isExtensionCorrect =
      file.type === 'application/pdf' ||
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg';

    if (isExtensionCorrect) {
      setListFileIc([file]);
    } else {
      Modal.warning({
        title: 'Perhatian',
        content:
          'Extension file tidak sesuai. Silahkan upload file dengan extension pdf, jpeg, jpg.',
      });
    }

    return false;
  };

  const handleIcRemove = () => {
    setListFileIc([]);
  };

  const handleIcUpload = () => {
    const formData = new FormData();

    listFileIc.forEach((file) => {
      formData.append('file', file);
    });

    submitInformedConcern(formData);
  };

  const onTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleOuterTabChange = async (key) => {
    switch (key) {
      case '1':
        break;
      case '2':
        history.push(`/rekam-medis/${patientId}`);
        break;
      default:
        break;
    }
  };

  const handleTabChange = async (key) => {
    switch (key) {
      case '1':
        await getAfterSalesList();
        break;
      case '2':
        await getInformedConcernList();
        break;
      default:
        break;
    }
  };

  const useInitData = () => {
    getAfterSalesList();

    getCategoryOptions();
    getComplainOptions();
    getAlignerIssueOptions();
    getGroupOptions();
    getStatusOptions();
  };

  const useEvaluateSubmitAfterSalesButton = () => {
    if (!!category && !!complain && !!group && !!status) {
      setIsSubmitAfterSalesButtonEnabled(true);
    } else {
      setIsSubmitAfterSalesButtonEnabled(false);
    }
  };

  useEffect(useInitData, []);

  useEffect(useEvaluateSubmitAfterSalesButton, [
    category,
    complain,
    group,
    status,
  ]);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <Row>
          <Col span={24}>
            <Tabs
              defaultActiveKey="1"
              type="card"
              onChange={handleOuterTabChange}>
              <TabPane tab="Interaksi Pasien" key="1">
                <div className="panel">
                  <Row>
                    <Col span={24}>
                      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                        <TabPane tab="After Sales" key="1">
                          <Row>
                            <Col span={10}>
                              <Row>
                                <Col span={6}>
                                  <div>Kategori:</div>
                                </Col>
                                <Col span={18}>
                                  <div className="skybase-dashboard__search mb-5">
                                    <SelectDropdown
                                      options={listCategoryOptions}
                                      placeHolder="Kategori"
                                      onChange={handleCategoryChange}
                                      value={category}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={6}>
                                  <div>Keluhan:</div>
                                </Col>
                                <Col span={18}>
                                  <div className="skybase-dashboard__search mb-5">
                                    <SelectDropdown
                                      options={listFilteredComplainOptions}
                                      placeHolder="Keluhan"
                                      onChange={handleComplainChange}
                                      value={complain}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={6}>
                                  <div>Detail Keluhan:</div>
                                </Col>
                                <Col span={18}>
                                  <div className="skybase-dashboard__search mb-5">
                                    <TextArea
                                      rows={4}
                                      placeholder="Detail Keluhan"
                                      onChange={handleComplainDetailChange}
                                      value={complainDetail}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={6}>
                                  <div>Aligner Bermasalah:</div>
                                </Col>
                                <Col span={2}>
                                  <div>RA</div>
                                </Col>
                                <Col span={16}>
                                  <div className="skybase-dashboard__search mb-5">
                                    <SelectDropdown
                                      options={listAlignerIssueOptions}
                                      placeHolder="RA"
                                      onChange={handleAlignerIssueRaChange}
                                      value={alignerIssueRa}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={6}>
                                  <div></div>
                                </Col>
                                <Col span={2}>
                                  <div>RB</div>
                                </Col>
                                <Col span={16}>
                                  <div className="skybase-dashboard__search mb-5">
                                    <SelectDropdown
                                      options={listAlignerIssueOptions}
                                      placeHolder="RB"
                                      onChange={handleAlignerIssueRbChange}
                                      value={alignerIssueRb}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={6}>
                                  <div>Solusi:</div>
                                </Col>
                                <Col span={18}>
                                  <div className="skybase-dashboard__search mb-5">
                                    <TextArea
                                      rows={4}
                                      placeholder="Solusi"
                                      onChange={handleSolutionChange}
                                      value={solution}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={6}>
                                  <div>Group:</div>
                                </Col>
                                <Col span={18}>
                                  <div className="skybase-dashboard__search mb-5">
                                    <SelectDropdown
                                      options={listGroupOptions}
                                      placeHolder="Group"
                                      onChange={handleGroupChange}
                                      value={group}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={6}>
                                  <div>Status:</div>
                                </Col>
                                <Col span={18}>
                                  <div className="skybase-dashboard__search mb-5">
                                    <SelectDropdown
                                      options={listStatusOptions}
                                      placeHolder="Status"
                                      onChange={handleStatusChange}
                                      value={status}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={6}>
                                  <div></div>
                                </Col>
                                <Col span={18}>
                                  <Button
                                    type="primary"
                                    style={{ width: '100%' }}
                                    onClick={() =>
                                      handleFlowAfterSales('new', 'openConfirm')
                                    }
                                    disabled={!isSubmitAfterSalesButtonEnabled}>
                                    <strong>SIMPAN</strong>
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={13} offset={1}>
                              <Table
                                dataSource={listAfterSales}
                                rowKey="key"
                                scroll={{ x: 1100, y: 500 }}
                                onChange={onTableChange}
                                pagination={pagination}
                                columns={afterSalesHistoryColumns(
                                  handleEditAfterSalesClick,
                                  handleCloseAfterSalesClick
                                )}
                                className="skybase-dashboard__active-order-table"
                                size="small"
                              />
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tab="Upload Informed Concern Pasien" key="2">
                          <Row>
                            <Col span={9}>
                              <Collapse
                                defaultActiveKey={['1']}
                                expandIconPosition="right">
                                <Panel
                                  header={
                                    <p
                                      style={{
                                        color: '#2ab4c0',
                                        marginBottom: 0,
                                      }}>
                                      <strong>Informed Concern Pasien</strong>
                                    </p>
                                  }
                                  key="1">
                                  <>
                                    <Upload
                                      beforeUpload={handleIcBeforeUpload}
                                      onRemove={handleIcRemove}
                                      fileList={listFileIc}>
                                      <Button>Pilih File</Button>
                                    </Upload>
                                    <div
                                      style={{
                                        width: 'fit-content',
                                        marginTop: '10px',
                                        padding: '15px',
                                        backgroundColor: '#f9e491',
                                        borderColor: '#f9e491',
                                        borderRadius: '2px',
                                        color: '#c29d0b',
                                      }}>
                                      File format : *pdf, *jpeg, *jpg
                                    </div>
                                    <Button
                                      type="danger"
                                      onClick={handleIcUpload}
                                      disabled={listFileIc.length === 0}
                                      //   loading={uploading}
                                      style={{
                                        marginTop: '10px',
                                        width: '100%',
                                      }}>
                                      <strong>UPLOAD IC PASIEN</strong>
                                    </Button>
                                  </>
                                </Panel>
                              </Collapse>
                            </Col>
                            <Col span={14} offset={1}>
                              <Table
                                dataSource={listIc}
                                rowKey="key"
                                scroll={{ x: 800, y: 300 }}
                                onChange={onTableChange}
                                pagination={pagination}
                                columns={uploadInformedPatientColumns(
                                  handleDownloadFileClick
                                )}
                                className="skybase-dashboard__active-order-table"
                                size="small"
                              />
                            </Col>
                          </Row>
                        </TabPane>
                      </Tabs>
                    </Col>
                  </Row>
                </div>
              </TabPane>
              <TabPane tab="Rekam Medis" key="2"></TabPane>
            </Tabs>
          </Col>
        </Row>
      </Content>

      <Modal
        visible={isEditAfterSalesPopupOpen}
        title="Edit After Sales"
        width={'35%'}
        closable={false}
        footer={[
          <Button
            type="danger"
            onClick={() => handleFlowAfterSales(changeStatus, 'closePopup')}>
            Kembali
          </Button>,
          <Button
            type="primary"
            onClick={() => handleFlowAfterSales(changeStatus, 'openConfirm')}>
            Simpan
          </Button>,
        ]}>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={6}>
                <div>Kategori:</div>
              </Col>
              <Col span={18}>
                <div className="skybase-dashboard__search mb-5">
                  <SelectDropdown
                    options={listCategoryOptions}
                    placeHolder="Kategori"
                    onChange={handleCategoryModalChange}
                    value={categoryModal}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <div>Keluhan:</div>
              </Col>
              <Col span={18}>
                <div className="skybase-dashboard__search mb-5">
                  <SelectDropdown
                    options={listFilteredComplainOptions}
                    placeHolder="Keluhan"
                    onChange={handleComplainModalChange}
                    value={complainModal}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <div>Detail Keluhan:</div>
              </Col>
              <Col span={18}>
                <div className="skybase-dashboard__search mb-5">
                  <TextArea
                    rows={4}
                    onChange={handleComplainDetailModalChange}
                    value={complainDetailModal}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <div>Solusi:</div>
              </Col>
              <Col span={18}>
                <div className="skybase-dashboard__search mb-5">
                  <TextArea
                    rows={4}
                    onChange={handleSolutionModalChange}
                    value={solutionModal}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <div>Group:</div>
              </Col>
              <Col span={18}>
                <div className="skybase-dashboard__search mb-5">
                  <SelectDropdown
                    options={listGroupOptions}
                    placeHolder="Group"
                    onChange={handleGroupModalChange}
                    value={groupModal}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={isConfirmAfterSalesPopupOpen}
        width={'35%'}
        closable={false}
        footer={[
          <Button
            type="default"
            // onClick={() => handleCloseAfterSalesPopupOpen(false)}>
            onClick={() => handleFlowAfterSales(changeStatus, 'closeConfirm')}>
            Tidak
          </Button>,
          <Button
            type="danger"
            // onClick={closeAfterSales}
            onClick={() => handleFlowAfterSales(changeStatus, 'submit')}>
            Ya
          </Button>,
        ]}>
        <Row>
          <div className="mt-10 mb-10">
            <ExclamationCircleTwoTone
              twoToneColor="#f8bb86"
              style={{ fontSize: 50 }}
            />
          </div>
        </Row>
        {changeStatus === 'close' ? (
          <>
            <Row>
              <div className="mb-10">
                <strong>
                  Apakah anda ingin mengubah status menjadi closed?
                </strong>
              </div>
            </Row>
            <Row>
              <div>Data yang sudah di closed tidak dapat diubah kembali</div>
            </Row>
          </>
        ) : (
          <Row>
            <div>
              <strong>Apakah data sudah sesuai?</strong>
            </div>
          </Row>
        )}
      </Modal>

      <Modal
        visible={isSuccessAfterSalesPopupOpen}
        width={'35%'}
        closable={false}
        footer={[
          <Button
            type="primary"
            // onClick={() => handleSuccessAfterSalesPopupOpen(false)}
            onClick={() => handleFlowAfterSales(changeStatus, 'closeSuccess')}>
            OK
          </Button>,
        ]}>
        <Row>
          <div className="mt-10 mb-10">
            <CheckCircleTwoTone
              twoToneColor="#a5dc86"
              style={{ fontSize: 50 }}
            />
          </div>
        </Row>
        <Row>
          <div className="mb-10">
            <strong>Success</strong>
          </div>
        </Row>
        <Row>
          <div>Data berhasil disimpan</div>
        </Row>
      </Modal>
    </Layout>
  );
}

export default withRouter(InteraksiPasienDetail);
