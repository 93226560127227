import React, { useEffect, useState } from 'react';
import {Col, Row, Layout, Table, Button, DatePicker, Collapse, Dropdown, Menu, Modal} from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';
// import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router-dom';
import ReportApi from 'api/report';
import ClinicApi from 'api/clinic';
import { PageSpinner } from 'components/page-spinner';
import SelectDropdown from 'components/select-dropdown';
import { setToArray } from 'utils';
import {
  visitationPatientColumns,
  visitationPatientSummaryColumns,
} from '../helper/';
import '../styles/index.scss';
import { DownloadOutlined, DownOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import XLSX from 'xlsx';

const { Content } = Layout;
const { Panel } = Collapse;
const listDateRange = [
  {
    label: 'Last 7 Days',
    value: 'SEVEN',
  },
  {
    label: 'Last 30 Days',
    value: 'THIRTY',
  },
  {
    label: 'Custom Range',
    value: 'CUSTOM',
  },
];

export function LaporanKunjunganPasien({ history }) {
  const objectVisitationSummary = [
    {
      key: 1,
      column1: 'Total Pasien Baru',
      column2: 0,
    },
    {
      key: 2,
      column1: 'Total Pasien Lama',
      column2: 0,
    },
  ];

  const [clinic, setClinic] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [rangeDate, setRangeDate] = useState();

  const [filteredQueue, setFilteredQueue] = useState([]);
  const [summaryQueue, setSummaryQueue] = useState([]);

  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [listClinicOptions, setListClinicOptions] = useState([]);

  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    showSizeChanger: true,
    total: 0,
  });

  const handleRangeDateChange = (value) => {
    const today = moment();

    if (value === 'SEVEN') {
      const sevenDaysAgo = moment().subtract(7, 'd');

      setStartDate(sevenDaysAgo);
      setEndDate(today);
    } else if (value === 'THIRTY') {
      const thirtyDaysAgo = moment().subtract(30, 'd');

      setStartDate(thirtyDaysAgo);
      setEndDate(today);
    } else {
      setStartDate();
      setEndDate();
    }

    setRangeDate(value);
  };

  async function getQueueList(limit, page, search = '') {
    try {
      setLoadingTable(true);

      const response = await ReportApi.getPatientVisitList(
        clinic,
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
        limit,
        page,
        search
      );

      let queueList = setToArray(response.data);

      const modifiedQueueList = queueList.map((item, index) => ({
        ...item,
        key: index + 1,
      }));

      setFilteredQueue(modifiedQueueList);

      const newVisitationSummary = [...objectVisitationSummary];

      newVisitationSummary[0].column2 = response.data.data.newPatientTotal;
      newVisitationSummary[1].column2 = response.data.data.oldPatientTotal;

      setSummaryQueue(objectVisitationSummary);

      const paginationConfig = {
        ...pagination,
        current: response.data.data.page,
        pageSize: response.data.data.limit,
        total: response.data.data.totalData,
      };

      setPagination(paginationConfig);
    } finally {
      setLoadingTable(false);
    }
  }

  async function generateExcelReport() {
    try {
      const response = await ReportApi.getPatientVisitList(
        clinic,
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
        '',
        '',
        search
      );

      let cashierDetailList = setToArray(response.data);

      let arrayExcel = [
        [
          'No',
          'Registration Number',
          'Booking Date & Time',
          'Medical Record Number',
          'Patient Name',
          'Age',
          'Gender',
          'Phone',
          'Email',
          'Address',
          'Clinic',
          'Doctor Name',
          'Diagnosis',
          'Missing Tooth',
          //'Implant',
          //'Reason Maybe to Implant',
          'Impacted Tooth',
          'Odontectomy',
          'Reason Maybe to Odontectomy',
          'ICD 10',
          'ICD 10 / Tooth Number',
          'Patient Status',
          'Booking Status',
        ],
      ];

      cashierDetailList.forEach((item, index) => {
        arrayExcel.push([
          index + 1,
          item.registrationNumber,
          `${moment(item.visitDate).format('DD-MM-YYYY')} ${moment(
            item.visitStart,
            'hh:mm:ss'
          ).format('HH:mm:ss')}`,
          item.medicalRecordNumber,
          item.patientName,
          item.patientDateOfBirth,
          item.patientGender,
          item.patientPhoneNumber,
          item.patientEmail,
          item.patientAddress,
          item.clinicName,
          item.doctorName,
          item.resumeDiagnosis,
          item.hasMissingTooth !== null
            ? item.hasMissingTooth
              ? 'YES'
              : 'NO'
            : '-',
          //item.agreeToImplant !== null ? item.agreeToImplant : '-',
          //item.reasonMaybeImplant !== null ? item.reasonMaybeImplant : '-',
          item.hasImpactedTooth !== null
            ? item.hasImpactedTooth
              ? 'YES'
              : 'NO'
            : '-',
          item.agreeToOdontectomy !== null ? item.agreeToOdontectomy : '-',
          item.reasonMaybeOdontectomy !== null
            ? item.reasonMaybeOdontectomy
            : '-',
          item.patientIcd10.length > 0 ? item.patientIcd10.join(' | ') : '-',
          item.patientIcd10Tooth.length > 0
            ? item.patientIcd10Tooth.join(' | ')
            : '-',
          item.patientStatus,
          item.appointmentStatus,
        ]);
      });

      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.aoa_to_sheet(arrayExcel);
      XLSX.utils.book_append_sheet(wb, ws, 'Data Laporan Kunjungan Pasien');

      const fileName = `Data_Laporan_Kunjungan_Pasien_-${moment().format(
        'YYYY-MM-DD'
      )}.xlsx`;

      /* Trigger Download with `writeFile` */
      XLSX.writeFile(wb, fileName, { compression: true });
    } catch (error) {
      Modal.warning({
        title: 'PERHATIAN',
        content: 'Terjadi error pada server. Silahkan coba lagi!',
      });
    }
  }

  async function getClinicList() {
    try {
      setLoadingPage(true);

      const response = await ClinicApi.listClinic();

      let clinicList = setToArray(response.data);

      const clinicListOpts = clinicList.map((value) => ({
        label: value.clinicName,
        value: value.id,
      }));

      clinicListOpts.unshift({ label: 'Semua Klinik', value: '' });

      setListClinicOptions(clinicListOpts);
    } finally {
      setLoadingPage(false);
    }
  }

  const handleClinicChange = (value) => {
    setClinic(value);
  };

  const resetAllFields = () => {
    setRangeDate();
    setStartDate();
    setEndDate();
    setClinic();
  };

  const handleStartDateChange = (date, dateString) => {
    if (!date) setStartDate(moment());
    else setStartDate(date);
  };

  const handleEndDateChange = (date, dateString) => {
    if (!date) setEndDate(moment());
    else setEndDate(date);
  };

  const onTableChange = (pagination) => {
    getQueueList(pagination.pageSize, pagination.current);
    setPagination(pagination);
  };

  //search
  const onSearchChange = (e) => {
    const labelValue = e.target.value;
    setSearch(labelValue);
  };
  const onSearchSubmit = (value) => {
    const convertValue = value.toLowerCase();
    getQueueList(10, 1, convertValue);
  };

  const useInitData = () => {
    getQueueList(pagination.pageSize, pagination.current);
    getClinicList();

    const today = moment();
    setStartDate(today);
    setEndDate(today);
  };

  useEffect(useInitData, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <div className="mb-15">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Laporan Kunjungan Pasien
              </strong>
            </Col>
          </Row>
        </div>
        <Collapse
          defaultActiveKey={['1']}
          expandIconPosition="right"
          className="mb-15">
          <Panel
            header={
              <strong className="text-md skybase-dashboard__title">
                Filter
              </strong>
            }
            key="1">
            <div>
              <Row gutter={[16, 8]}>
                <Col span={8}>
                  Date
                  <SelectDropdown
                    options={listDateRange}
                    placeHolder="Select Range Date"
                    onChange={handleRangeDateChange}
                    value={rangeDate}
                    allowClear
                  />
                </Col>
                <Col span={8}>
                  Start Date
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <DatePicker
                        showToday
                        allowClear={false}
                        format="DD/MM/YYYY"
                        onChange={handleStartDateChange}
                        value={startDate}
                        placeholder="Start Date"
                        disabled={rangeDate !== 'CUSTOM'}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  End Date
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <DatePicker
                        showToday
                        allowClear={false}
                        format="DD/MM/YYYY"
                        onChange={handleEndDateChange}
                        value={endDate}
                        placeholder="End Date"
                        disabled={rangeDate !== 'CUSTOM'}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col span={8}>
                  Clinics
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <SelectDropdown
                        placeHolder="Select Clinic"
                        options={listClinicOptions}
                        onChange={handleClinicChange}
                        value={clinic}
                        dropdownMatchSelectWidth={false}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <hr></hr>
              <Row className="mt-20 mb-10">
                <Col span={24} type="flex" align="end">
                  <Row gutter={[16, 8]}>
                    <Button onClick={resetAllFields}>Reset</Button>
                    <Button
                      type="primary"
                      style={{ marginLeft: '10px', marginRight: '10px' }}
                      onClick={() => getQueueList(10, 1)}>
                      Filter
                    </Button>
                  </Row>
                </Col>
              </Row>
            </div>
          </Panel>
        </Collapse>
        <div className="panel">
          <Row>
            <Col span={24}>
              <div className="mb-15">
                <strong className="text-md skybase-dashboard__title">
                  Kunjungan Pasien
                </strong>
              </div>
            </Col>
          </Row>
          {loadingTable ? (
            <PageSpinner />
          ) : (
            <>
              <Row>
                <Col span={2} type="flex" align="start">
                  <div className="mb-15">
                    <Dropdown
                      trigger={['click']}
                      overlay={() => (
                        <>
                          <Menu>
                            <Menu.Item>
                              <Button type="link" onClick={generateExcelReport}>
                                Excel Laporan Kunjungan Pasien
                              </Button>
                            </Menu.Item>
                          </Menu>
                        </>
                      )}
                      placement="bottomLeft"
                      arrow>
                      <Button>
                        <DownloadOutlined />
                        Export File <DownOutlined />
                      </Button>
                    </Dropdown>
                  </div>
                </Col>
                <Col span={5} offset={17} type="flex" align="end">
                  <div className="mb-15">
                    <Search
                      placeholder="Search"
                      onSearch={onSearchSubmit}
                      onChange={onSearchChange}
                      value={search}
                    />
                  </div>
                </Col>
              </Row>
              <Table
                dataSource={filteredQueue}
                rowKey="key"
                columns={visitationPatientColumns}
                onChange={onTableChange}
                className="skybase-dashboard__active-order-table"
                footer={() => (
                  <Table
                    dataSource={summaryQueue}
                    rowKey="key"
                    columns={visitationPatientSummaryColumns}
                    className="skybase-dashboard__active-order-table"
                    showHeader={false}
                    pagination={false}
                    size="small"
                  />
                )}
                pagination={pagination}
                size="small"
                scroll={{ x: 5000, y: 500 }}
              />
            </>
          )}
        </div>
      </Content>
    </Layout>
  );
}

export default withRouter(LaporanKunjunganPasien);
