import React from 'react';
import { Button, Tag } from 'antd';
import moment from 'moment';
import DOMPurify from 'dompurify';

export const afterSalesHistoryColumns = [
  {
    title: 'Deadline',
    key: 'deadline',
    render: (row) =>
      row['deadline']
        ? moment(row['deadline']).format('DD-MM-YYYY HH:mm:ss')
        : '-',
  },
  {
    title: 'Group',
    key: 'group',
    render: (row) => (row['group'] ? row['group'] : '-'),
  },
  {
    title: 'Kategori',
    key: 'category',
    render: (row) => (row['category'] ? row['category'] : '-'),
  },
  {
    title: 'Keluhan',
    key: 'complain',
    render: (row) => (row['complain'] ? row['complain'] : '-'),
  },
  {
    title: 'Detail Keluhan',
    key: 'complainDetail',
    render: (row) => (row['complainDetail'] ? row['complainDetail'] : '-'),
  },
  {
    title: 'Solusi',
    key: 'solution',
    render: (row) => (row['solution'] ? row['solution'] : '-'),
  },
  {
    title: 'Staff Name',
    key: 'staffName',
    render: (row) => (row['staffName'] ? row['staffName'] : '-'),
  },
  // {
  //   title: 'Tanggal Input',
  //   key: 'createdAt',
  //   render: (row) =>
  //     row['createdAt']
  //       ? moment(row['createdAt']).format('DD-MM-YYYY hh:mm:ss')
  //       : '-',
  // },
  {
    title: 'Tanggal Input',
    key: 'date',
    render: (row) =>
      row['date'] ? moment(row['date']).format('DD-MM-YYYY') : '-',
  },
  {
    title: 'Status',
    key: 'status',
    render: (row) =>
      row['status'] ? (
        <Tag
          style={
            row['status'].toLowerCase() === 'pending'
              ? {
                  color: '#fa8c16',
                  background: '#fff7e6',
                  borderColor: '#ffd591',
                }
              : {
                  color: '#fff',
                  backgroundColor: '#e7505a',
                  borderColor: '#e7505a',
                }
          }>
          {row['status'].toUpperCase()}
        </Tag>
      ) : (
        '-'
      ),
  },
];

export const treatmentPlanColumns = (downloadFile) => [
  {
    title: '',
    width: 12,
  },
  {
    title: 'Timestamp',
    key: 'createdAt',
    render: (row) =>
      row['createdAt'] ? moment(row['createdAt']).format('DD-MM-YYYY') : '-',
  },
  {
    title: 'File Name',
    key: 'filename',
    sorter: (a, b) => a.filename.localeCompare(b.filename),
    render: (row) => (row['filename'] ? row['filename'] : '-'),
  },
  {
    title: 'Staff Name',
    key: 'staffName',
    sorter: (a, b) => a.staffName.localeCompare(b.staffName),
    render: (row) => (row['staffName'] ? row['staffName'] : '-'),
  },
  {
    title: 'Action',
    key: 'index',
    render: (row) => (
      <Button type="primary" onClick={() => downloadFile(`${row['url']}`)}>
        DOWNLOAD FILE
      </Button>
    ),
  },
];

export const uploadInformedPatientColumns = (downloadFile) => [
  {
    title: 'No',
    render: (record) => record.key,
    width: 50,
  },
  {
    title: 'Timestamp',
    key: 'createdAt',
    render: (row) =>
      row['createdAt'] ? moment(row['createdAt']).format('DD-MM-YYYY') : '-',
  },
  {
    title: 'File Name',
    key: 'filename',
    sorter: (a, b) => a.filename.localeCompare(b.filename),
    render: (row) => (row['filename'] ? row['filename'] : '-'),
  },
  {
    title: 'Staff Name',
    key: 'staffName',
    sorter: (a, b) => a.staffName.localeCompare(b.staffName),
    render: (row) => (row['staffName'] ? row['staffName'] : '-'),
  },
  {
    title: 'Action',
    key: 'index',
    render: (row) => (
      <Button type="primary" onClick={() => downloadFile(`${row['url']}`)}>
        DOWNLOAD FILE
      </Button>
    ),
  },
];

export const refinementColumns = [
  {
    title: '',
    width: 11,
  },
  {
    title: 'DATE',
    key: 'date',
    render: (row) => (row['date'] ? row['date'] : '-'),
  },
  {
    title: 'ARCH',
    key: 'arch',
    render: (row) => (row['arch'] ? row['arch'] : '-'),
  },
  {
    title: 'REFINEMENT',
    key: 'refinement',
    render: (row) => (row['refinement'] ? row['refinement'] : '-'),
  },
  {
    title: 'INSTRUCTION',
    key: 'instruction',
    render: (row) =>
      row['instruction'] ? (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(row['instruction']),
          }}
        />
      ) : (
        '-'
      ),
  },
  {
    title: 'DUE DATE',
    key: 'dueDate',
    render: (row) =>
      row['dueDate'] ? moment(row['dueDate']).format('DD-MM-YYYY') : '-',
  },
  {
    title: 'DOCTOR',
    key: 'doctorName',
    render: (row) => (row['doctorName'] ? row['doctorName'] : '-'),
  },
  {
    title: 'PENGIRIMAN',
    key: 'delivery',
    render: (row) => (row['delivery'] ? row['delivery'] : '-'),
  },
];

export const cetakUlangColumns = [
  {
    title: '',
    width: 11,
  },
  {
    title: 'DATE',
    key: 'date',
    render: (row) => (row['date'] ? row['date'] : '-'),
  },
  {
    title: 'ARCH',
    key: 'arch',
    render: (row) => (row['arch'] ? row['arch'] : '-'),
  },
  {
    title: 'TEST FIT',
    key: 'testFit',
    render: (row) => (row['testFit'] ? 'Yes' : 'No'),
  },
  {
    title: 'REASON',
    key: 'reason',
    render: (row) => (row['reason'] ? row['reason'] : '-'),
  },
  {
    title: 'INSTRUCTION',
    key: 'instruction',
    render: (row) =>
      row['instruction'] ? (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(row['instruction']),
          }}
        />
      ) : (
        '-'
      ),
  },
  {
    title: 'DUE DATE',
    key: 'dueDate',
    render: (row) =>
      row['dueDate'] ? moment(row['dueDate']).format('DD-MM-YYYY') : '-',
  },
  {
    title: 'DOCTOR',
    key: 'doctorName',
    render: (row) => (row['doctorName'] ? row['doctorName'] : '-'),
  },
  {
    title: 'PENGIRIMAN',
    key: 'delivery',
    render: (row) => (row['delivery'] ? row['delivery'] : '-'),
  },
];

export const extraAlignerColumns = [
  {
    title: '',
    width: 11,
  },
  {
    title: 'DATE',
    key: 'date',
    render: (row) => (row['date'] ? row['date'] : '-'),
  },
  {
    title: 'ARCH',
    key: 'arch',
    render: (row) => (row['arch'] ? row['arch'] : '-'),
  },
  {
    title: 'INSTRUCTION',
    key: 'instruction',
    render: (row) =>
      row['instruction'] ? (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(row['instruction']),
          }}
        />
      ) : (
        '-'
      ),
  },
  {
    title: 'ALASAN',
    key: 'reason',
    render: (row) =>
      row['reason'] ? (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(row['reason']),
          }}
        />
      ) : (
        '-'
      ),
  },
  {
    title: 'DUE DATE',
    key: 'dueDate',
    render: (row) =>
      row['dueDate'] ? moment(row['dueDate']).format('DD-MM-YYYY') : '-',
  },
  {
    title: 'DOCTOR',
    key: 'doctorName',
    render: (row) => (row['doctorName'] ? row['doctorName'] : '-'),
  },
  {
    title: 'PENGIRIMAN',
    key: 'delivery',
    render: (row) => (row['delivery'] ? row['delivery'] : '-'),
  },
];

export const retainerRataColumns = [
  {
    title: '',
    width: 11,
  },
  {
    title: 'DATE',
    key: 'date',
    render: (row) => (row['date'] ? row['date'] : '-'),
  },
  {
    title: 'ARCH',
    key: 'arch',
    render: (row) => (row['arch'] ? row['arch'] : '-'),
  },
  {
    title: 'RETAINER TYPE',
    key: 'type',
    render: (row) => (row['type'] ? row['type'] : '-'),
  },
  {
    title: 'SPECIAL NOTES',
    key: 'specialNote',
    render: (row) =>
      row['specialNote'] ? (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(row['specialNote']),
          }}
        />
      ) : (
        '-'
      ),
  },
  {
    title: 'DOCTOR',
    key: 'doctorName',
    render: (row) => (row['doctorName'] ? row['doctorName'] : '-'),
  },
];

export const reqcColumns = [
  {
    title: '',
    width: 11,
  },
  {
    title: 'DATE',
    key: 'date',
    render: (row) => (row['date'] ? row['date'] : '-'),
  },
  {
    title: 'ARCH',
    key: 'arch',
    render: (row) => (row['arch'] ? row['arch'] : '-'),
  },
  {
    title: 'TEST FIT',
    key: 'testFit',
    render: (row) => (row['testFit'] ? 'Yes' : 'No'),
  },
  {
    title: 'RE-QC REASON',
    key: 'reason',
    render: (row) => (row['reason'] ? row['reason'] : '-'),
  },
  {
    title: 'SOLUTION',
    key: 'solution',
    render: (row) => (row['solution'] ? row['solution'] : '-'),
  },
  {
    title: 'DESCRIPTION',
    key: 'description',
    render: (row) =>
      row['solution'] === 'Redesign' ? (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(row['instruction']),
          }}
        />
      ) : row['solution'] === 'Cutting Aligner' ? (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(row['cuttingAlignerTo']),
          }}
        />
      ) : row['solution'] === 'Trimming Aligner' ? (
        row['trimType']
      ) : (
        '-'
      ),
  },
  {
    title: 'DUE DATE',
    key: 'dueDate',
    render: (row) => (row['dueDate'] ? row['dueDate'] : '-'),
  },
  {
    title: 'DOCTOR',
    key: 'doctorName',
    render: (row) => (row['doctorName'] ? row['doctorName'] : '-'),
  },
  {
    title: 'PENGIRIMAN',
    key: 'delivery',
    render: (row) => (row['delivery'] ? row['delivery'] : '-'),
  },
];

export const noteColumns = [
  {
    title: '',
    width: 11,
  },
  {
    title: 'INPUT DATE',
    key: 'date',
    render: (row) => (row['date'] ? row['date'] : '-'),
  },
  {
    title: 'STAFF NAME',
    key: 'staffName',
    render: (row) => (row['staffName'] ? row['staffName'] : '-'),
  },
  {
    title: 'DESCRIPTION',
    key: 'notes',
    render: (row) =>
      row['notes'] ? (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(row['notes']),
          }}
        />
      ) : (
        '-'
      ),
  },
];

export const prescriptionColumns = [
  {
    title: '',
    width: 11,
  },
  {
    title: '#',
    render: (record) => record.key,
    width: 50,
  },
  {
    title: 'Resep',
    key: 'description',
    render: (row) => (row['description'] ? row['description'] : '-'),
  },
];

export const treatmentColumns = [
  {
    title: '',
    width: 11,
  },
  {
    title: '#',
    render: (value, item, index) => index + 1,
    width: 50,
  },
  {
    title: 'No Kwitansi',
    key: 'receiptNumber',
    render: (row) => (row['receiptNumber'] ? row['receiptNumber'] : '-'),
  },
  {
    title: 'Tindakan',
    key: 'description',
    render: (row) => (row['description'] ? row['description'] : '-'),
  },
];

export const icd10CodeColumns = [
  {
    title: '',
    width: 15,
  },
  {
    title: '#',
    render: (value, item, index) => index + 1,
    width: 50,
  },
  {
    title: 'Kode - ICD 10',
    key: 'icdName',
    render: (row) => (row['icdName'] ? row['icdName'] : '-'),
  },
];
