import axios from 'axios';

export const createAPI = (
  baseURL = `${process.env.REACT_APP_API_URL}/api`,
  config = {}
) => {
  const axiosInstance = axios.create({
    baseURL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...config,
  });

  // setup axios.intercept
  return axiosInstance;
};

const api = createAPI();

export default api;
