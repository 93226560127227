import api from './index';

export default {
  getCategoryList() {
    return api.get(`/marketing/after-sales/category`);
  },

  submitCategory(payload) {
    return api.post(`/marketing/after-sales/category`, payload);
  },

  editCategory(payload, id) {
    return api.put(`/marketing/after-sales/category/${id}`, payload);
  },

  deleteCategory(id) {
    return api.delete(`/marketing/after-sales/category/${id}`);
  },

  getComplainList() {
    return api.get(`/marketing/after-sales/complain`);
  },

  submitComplain(payload) {
    return api.post(`/marketing/after-sales/complain`, payload);
  },

  editComplain(payload, id) {
    return api.put(`/marketing/after-sales/complain/${id}`, payload);
  },

  deleteComplain(id) {
    return api.delete(`/marketing/after-sales/complain/${id}`);
  },

  getCategoryProductionList() {
    return api.get(`/marketing/production/category`);
  },

  submitCategoryProduction(payload) {
    return api.post(`/marketing/production/category`, payload);
  },

  editCategoryProduction(payload, id) {
    return api.put(`/marketing/production/category/${id}`, payload);
  },

  deleteCategoryProduction(id) {
    return api.delete(`/marketing/production/category/${id}`);
  },

  getReasonLabOrderList() {
    return api.get(`/marketing/reason?type=lab_order`);
  },

  submitReasonLabOrder(payload) {
    return api.post(`/marketing/reason`, payload);
  },

  editReasonLabOrder(payload, id) {
    return api.put(`/marketing/reason/${id}`, payload);
  },

  deleteReasonLabOrder(id) {
    return api.delete(`/marketing/reason/${id}`);
  },

  getReasonLabOrderType() {
    return api.get(`/marketing/reason/type/lab_order`);
  },
};
