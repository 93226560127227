import apiBe from './index-be';

export default {
  getCsList() {
    return apiBe.get(`api/user/role/3`);
  },

  getCashierList() {
    return apiBe.get(`api/user/role/${process.env.REACT_APP_CASHIER_ROLE_ID}`);
  },

  getDoctorList() {
    return apiBe.get(`api/user/doctor`);
  },

  getDoctorById(id) {
    return apiBe.get(`api/user/${id}`);
  },
  
  getDoctorHasSipNumber() {
    return apiBe.get(`api/user/doctor?has_sip_number=true`);
  }
};
