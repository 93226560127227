import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Layout,
  Table,
  Button,
  Input,
  DatePicker,
  Modal,
  Collapse,
  Dropdown,
  Menu,
} from 'antd';
import { DownloadOutlined, DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import ReportApi from 'api/report';
// import TreatmentApi from 'api/treatment';
import UserApi from 'api/user';
import ClinicApi from 'api/clinic';
import { PageSpinner } from 'components/page-spinner';
import SelectDropdown from 'components/select-dropdown';
import MultiselectDropdown from 'components/multiselect-dropdown';
import { setToArray, currencyFormatter } from 'utils';
import XLSX from 'xlsx';

import { cashierReportDetailColumns, discountSummaryColumns } from '../helper/';
import '../styles/index.scss';

const { Content } = Layout;
const { Panel } = Collapse;
const { Search } = Input;

export function DetailLaporanKasir({ history }) {
  const objectSummaryCashierDetail = [
    {
      key: 1,
      column1: 'Sub Total',
      column2: 0,
    },
    {
      key: 2,
      column1: 'Diskon',
      column2: 0,
    },
    {
      key: 3,
      column1: 'PPN',
      column2: 0,
    },
    {
      key: 4,
      column1: 'Total Penjualan (Sub Total + Diskon + PPN)',
      column2: 0,
    },
    {
      key: 5,
      column1: 'Total Void',
      column2: 0,
    },
    {
      key: 6,
      column1: 'Total Void Discount',
      column2: 0,
    },
    {
      key: 7,
      column1: 'Total Void PPN',
      column2: 0,
    },
    {
      key: 8,
      column1:
        'Total Penerimaan (Total penjualan + Total void + Total void discount + Total void PPN)',
      column2: 0,
    },
  ];

  const listDateRange = [
    {
      label: 'Last 7 Days',
      value: 'SEVEN',
    },
    {
      label: 'Last 30 Days',
      value: 'THIRTY',
    },
    {
      label: 'Custom Range',
      value: 'CUSTOM',
    },
  ];

  const custEntryPoint = [
    {
      label: 'RATA',
      value: 'RATA',
    },
    {
      label: 'SMILE DENTAL',
      value: 'SMILE_DENTAL',
    },
  ]

  // const [category, setCategory] = useState();
  const [cashier, setCashier] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [rangeDate, setRangeDate] = useState();
  const [entryPoint, setEntryPoint] = useState();

  const [listClinic, setListClinic] = useState([]);
  const [listClinicGroup, setListClinicGroup] = useState([]);
  const [listClinicOptions, setListClinicOptions] = useState([]);
  const [listClinicGroupOptions, setListClinicGroupOptions] = useState([]);
  // const [listCategoryOptions, setListCategoryOptions] = useState([]);
  const [listCashierOptions, setListCashierOptions] = useState([]);

  const [listCashierDetail, setListCashierDetail] = useState([]);
  const [listFilteredCashierDetail, setListFilteredCashierDetail] = useState(
    []
  );
  const [listSummaryCashierDetail, setListSummaryCashierDetail] = useState([]);

  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);

  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    showSizeChanger: true,
    total: 0,
  });

  async function getCashierDetailList(limit, page) {
    try {
      setLoadingTable(true);

      const clinicIds = listClinic
        .map((item) => {
          return item;
        })
        .join(',');

      const clinicGroupIds = listClinicGroup
        .map((item) => {
          return item;
        })
        .join(',');

      const response = await ReportApi.getCashierDetailListV2(
        '',
        cashier,
        listClinic.length > 0 ? clinicIds : '',
        listClinicGroup.length > 0 ? clinicGroupIds : '',
        '',
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
        entryPoint,
        limit,
        page
      );

      let cashierDetailList = setToArray(response.data);

      const modifiedCashierDetailList = cashierDetailList.map(
        (item, index) => ({
          ...item,
          key: index + 1,
        })
      );

      setListCashierDetail(modifiedCashierDetailList);
      setListFilteredCashierDetail(modifiedCashierDetailList);

      const newCashierDetailSummary = [...objectSummaryCashierDetail];

      newCashierDetailSummary[0].column2 = currencyFormatter(
        response.data.data.subTotal
      );
      newCashierDetailSummary[1].column2 = currencyFormatter(
        response.data.data.totalDiscount
      );
      newCashierDetailSummary[2].column2 = currencyFormatter(
        response.data.data.totalPpn
      );
      newCashierDetailSummary[3].column2 = currencyFormatter(
        response.data.data.totalSales
      );
      newCashierDetailSummary[4].column2 = currencyFormatter(
        response.data.data.totalVoid
      );
      newCashierDetailSummary[5].column2 = currencyFormatter(
        response.data.data.totalVoidDiscount
      );
      newCashierDetailSummary[6].column2 = currencyFormatter(
        response.data.data.totalVoidPpn
      );
      newCashierDetailSummary[7].column2 = currencyFormatter(
        response.data.data.totalAcceptance
      );

      setListSummaryCashierDetail(objectSummaryCashierDetail);

      const paginationConfig = {
        ...pagination,
        current: response.data.data.page,
        pageSize: response.data.data.limit,
        total: response.data.data.totalData,
      };

      setPagination(paginationConfig);
    } catch (error) {
      Modal.warning({
        title: 'PERHATIAN',
        content: 'Terjadi error pada server. Silahkan coba lagi!',
      });
    } finally {
      setLoadingTable(false);
    }
  }

  // async function getCategoryList() {
  //   try {
  //     setLoadingPage(true);
  //
  //     const response = await TreatmentApi.listTreatmentCategoryOptions();
  //
  //     let categoryList = setToArray(response.data);
  //
  //     const categoryListOpts = categoryList.map((value) => ({
  //       label: value.name,
  //       value: value.id,
  //     }));
  //
  //     // setListCategoryOptions(categoryListOpts);
  //   } finally {
  //     setLoadingPage(false);
  //   }
  // }

  async function getCashierList() {
    try {
      setLoadingPage(true);

      const response = await UserApi.getCashierList();

      let cashierList = Object.values(response.data.result);

      const cashierListOpts = cashierList.map((value) => ({
        label: value.name,
        value: value.id_user,
      }));

      setListCashierOptions(cashierListOpts);
    } finally {
      setLoadingPage(false);
    }
  }

  async function getClinicList() {
    try {
      setLoadingPage(true);

      const response = await ClinicApi.listClinic();

      let clinicList = setToArray(response.data);

      const clinicListOpts = clinicList.map((value) => ({
        label: value.clinicName,
        value: value.id,
      }));

      setListClinicOptions(clinicListOpts);
    } finally {
      setLoadingPage(false);
    }
  }

  async function getClinicGroupList() {
    try {
      setLoadingPage(true);

      const response = await ClinicApi.listClinicGroup();

      let clinicGroupList = setToArray(response.data);

      const clinicGroupListOpts = clinicGroupList.map((value) => ({
        label: value.groupName,
        value: value.id,
      }));

      setListClinicGroupOptions(clinicGroupListOpts);
    } finally {
      setLoadingPage(false);
    }
  }

  async function generateExcelReport() {
    try {
      const clinicIds = listClinic
        .map((item) => {
          return item;
        })
        .join(',');

      const clinicGroupIds = listClinicGroup
        .map((item) => {
          return item;
        })
        .join(',');

      const response = await ReportApi.getCashierDetailListV2(
        '',
        cashier,
        listClinic.length > 0 ? clinicIds : '',
        listClinicGroup.length > 0 ? clinicGroupIds : '',
        1,
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
        entryPoint,
        // pagination.pageSize,
        // pagination.current
      );

      let cashierDetailList = setToArray(response.data);

      let arrayExcel = [
        [
          'No',
          'Tanggal',
          'Jam',
          'No Kwitansi',
          'No RM - NAMA PASIEN',
          'Kode',
          'Keterangan',
          'Kategori',
          'Jenis Kunjungan',
          'Metode Pembayaran',
          'Trace Number',
          'Nomor Approval',
          'Harga Satuan',
          'Jumlah',
          'Diskon per Tindakan',
          'Invoice Discount Rate',
          'Sub Total',
          'Instalasi',
          'Nama Dokter',
          'Nama Asisten Dokter',
          'Nama Kasir',
          'Kode Klinik',
          'Customer Entry Point'
        ],
      ];

      cashierDetailList.forEach((item, index) => {
        arrayExcel.push([
          index + 1, //No
          moment(item.paymentDate).format('DD-MM-YYYY'), // Tanggal
          moment(item.paymentTimestamp).format('HH:mm'), // Jam
          item.receiptNumber ? item.receiptNumber : '-', // No Kwitansi
          `${item.medicalRecordNumber} - ${item.patientName}`, // No RM - NAMA PASIEN
          item.treatmentCode ? item.treatmentCode : '-', // Kode
          item.treatmentName ? item.treatmentName : '-', // Keterangan
          item.treatmentCategoryName ? item.treatmentCategoryName : '-', // Kategori
          item.jenisKunjungan ? item.jenisKunjungan : '-', // Jenis Kunjungan
          item.paymentMethod ? item.paymentMethod : '-', // Metode Pembayaran
          item.traceNumber ? item.traceNumber : '-', // Trace Number
          item.approvalNumber ? item.approvalNumber : '-', // Nomor Approval
          item.price ? item.price : 0, // Harga Satuan
          item.quantity ? item.quantity : 0, // Jumlah
          item.paymentDiscount ? 0 : item.discountTotal, // Diskon PerTindakan
          item.paymentDiscount ? item.discountTotal : 0, // Invoice Discount Rate
          item.totalPrice
            ? item.receiptNumber[0] === 'R'
              ? item.totalPrice * -1
              : item.totalPrice
            : 0, // Sub Total
          item.clinicName ? item.clinicName : 0, // Instalasi
          item.doctorName ? item.doctorName : '-', // Nama Dokter
          item.asstDoctorName ? item.asstDoctorName : '-', // Nama Asisten Dokter
          item.cashierName ? item.cashierName : '-', // Nama Kasir
          item.clinicCode ? item.clinicCode : '-', // Kode Klinik
          item.entryPoint === 'SMILE_DENTAL' ? 'SMILE DENTAL' : 'RATA' // Customer Entry Point
        ]);
      });

      arrayExcel.push(
        [
          '', //No
          '', // Tanggal
          '', // Jam
          '', // No Kwitansi
          '', // No RM - NAMA PASIEN
          '', // Kode
          '', // Keterangan
          '', // Kategori
          '', // Metode Pembayaran
          '', // Trace Number
          '', // Nomor Approval
          '', // Harga Satuan
          '', // Jumlah
          '', // Diskon PerTindakan
          'Sub Total', // Invoice Discount Rate
          response.data.data.subTotal, // Sub Total
          '', // Instalasi
          '', // Nama Dokter
          '', // Nama Asisten Dokter
          '', // Nama Kasir
          '', // Kode Klinik
          '', // Customer Entry Point
        ],
        [
          '', //No
          '', // Tanggal
          '', // Jam
          '', // No Kwitansi
          '', // No RM - NAMA PASIEN
          '', // Kode
          '', // Keterangan
          '', // Kategori
          '', // Metode Pembayaran
          '', // Trace Number
          '', // Nomor Approval
          '', // Harga Satuan
          '', // Jumlah
          '', // Diskon PerTindakan
          'Diskon', // Invoice Discount Rate
          response.data.data.totalDiscount, // Sub Total
          '', // Instalasi
          '', // Nama Dokter
          '', // Nama Asisten Dokter
          '', // Nama Kasir
          '', // Kode Klinik
          '', // Customer Entry Point
        ],
        [
          '', //No
          '', // Tanggal
          '', // Jam
          '', // No Kwitansi
          '', // No RM - NAMA PASIEN
          '', // Kode
          '', // Keterangan
          '', // Kategori
          '', // Metode Pembayaran
          '', // Trace Number
          '', // Nomor Approval
          '', // Harga Satuan
          '', // Jumlah
          '', // Diskon PerTindakan
          'PPN', // Invoice Discount Rate
          response.data.data.totalPpn, // Sub Total
          '', // Instalasi
          '', // Nama Dokter
          '', // Nama Asisten Dokter
          '', // Nama Kasir
          '', // Kode Klinik
          '', // Customer Entry Point
        ],
        [
          '', //No
          '', // Tanggal
          '', // Jam
          '', // No Kwitansi
          '', // No RM - NAMA PASIEN
          '', // Kode
          '', // Keterangan
          '', // Kategori
          '', // Metode Pembayaran
          '', // Trace Number
          '', // Nomor Approval
          '', // Harga Satuan
          '', // Jumlah
          '', // Diskon PerTindakan
          'Total Penjualan (Sub Total + Diskon + PPN)', // Invoice Discount Rate
          response.data.data.totalSales, // Sub Total
          '', // Instalasi
          '', // Nama Dokter
          '', // Nama Asisten Dokter
          '', // Nama Kasir
          '', // Kode Klinik
          '', // Customer Entry Point
        ],
        [
          '', //No
          '', // Tanggal
          '', // Jam
          '', // No Kwitansi
          '', // No RM - NAMA PASIEN
          '', // Kode
          '', // Keterangan
          '', // Kategori
          '', // Metode Pembayaran
          '', // Trace Number
          '', // Nomor Approval
          '', // Harga Satuan
          '', // Jumlah
          '', // Diskon PerTindakan
          'Total Void	', // Invoice Discount Rate
          response.data.data.totalVoid, // Sub Total
          '', // Instalasi
          '', // Nama Dokter
          '', // Nama Asisten Dokter
          '', // Nama Kasir
          '', // Kode Klinik
          '', // Customer Entry Point
        ],
        [
          '', //No
          '', // Tanggal
          '', // Jam
          '', // No Kwitansi
          '', // No RM - NAMA PASIEN
          '', // Kode
          '', // Keterangan
          '', // Kategori
          '', // Metode Pembayaran
          '', // Trace Number
          '', // Nomor Approval
          '', // Harga Satuan
          '', // Jumlah
          '', // Diskon PerTindakan
          'Total Void	Discount', // Invoice Discount Rate
          response.data.data.totalVoidDiscount, // Sub Total
          '', // Instalasi
          '', // Nama Dokter
          '', // Nama Asisten Dokter
          '', // Nama Kasir
          '', // Kode Klinik
          '', // Customer Entry Point
        ],
        [
          '', //No
          '', // Tanggal
          '', // Jam
          '', // No Kwitansi
          '', // No RM - NAMA PASIEN
          '', // Kode
          '', // Keterangan
          '', // Kategori
          '', // Metode Pembayaran
          '', // Trace Number
          '', // Nomor Approval
          '', // Harga Satuan
          '', // Jumlah
          '', // Diskon PerTindakan
          'Total Void	PPN', // Invoice Discount Rate
          response.data.data.totalVoidPpn, // Sub Total
          '', // Instalasi
          '', // Nama Dokter
          '', // Nama Asisten Dokter
          '', // Nama Kasir
          '', // Kode Klinik
          '', // Customer Entry Point
        ],
        [
          '', //No
          '', // Tanggal
          '', // Jam
          '', // No Kwitansi
          '', // No RM - NAMA PASIEN
          '', // Kode
          '', // Keterangan
          '', // Kategori
          '', // Metode Pembayaran
          '', // Trace Number
          '', // Nomor Approval
          '', // Harga Satuan
          '', // Jumlah
          '', // Diskon PerTindakan
          'Total Penerimaan (Total penjualan + Total void + Total void discount + Total void PPN)', // Invoice Discount Rate
          response.data.data.totalAcceptance, // Sub Total
          '', // Instalasi
          '', // Nama Dokter
          '', // Nama Asisten Dokter
          '', // Nama Kasir
          '', // Kode Klinik
          '', // Customer Entry Point
        ]
      );

      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.aoa_to_sheet(arrayExcel);
      XLSX.utils.book_append_sheet(wb, ws, 'Data Laporan Kasir Pasien');

      const fileName = `Data_Laporan_Kasir_Pasien_Dan_Dokter-${moment().format(
        'YYYY-MM-DD'
      )}.xlsx`;

      /* Trigger Download with `writeFile` */
      XLSX.writeFile(wb, fileName, { compression: true });
    } catch (error) {
      Modal.warning({
        title: 'PERHATIAN',
        content: 'Terjadi error pada server. Silahkan coba lagi!',
      });
    }
  }

  async function generateExcelJurnalIdReport(reportType) {
    try {
      const clinicIds = listClinic
        .map((item) => {
          return item;
        })
        .join(',');

      const clinicGroupIds = listClinicGroup
        .map((item) => {
          return item;
        })
        .join(',');

      const response = await ReportApi.getCashierDetailListV2(
        '',
        cashier,
        listClinic.length > 0 ? clinicIds : '',
        listClinicGroup.length > 0 ? clinicGroupIds : '',
        reportType,
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD')
        // pagination.pageSize,
        // pagination.current
      );

      let cashierDetailList = setToArray(response.data);

      console.log(cashierDetailList);

      let arrayExcel = [
        [
          '*Customer',
          'Email',
          'BillingAddress',
          'ShippingAddress',
          '*InvoiceDate',
          '*DueDate',
          'ShippingDate',
          'ShipVia',
          'TrackingNo',
          'CustomerRefNo',
          '*InvoiceNumber',
          'Message',
          'Memo',
          '*ProductName',
          'Description',
          '*Quantity',
          'Unit',
          '*UnitPrice',
          'ProductDiscountRate(%)',
          'InvoiceDiscountRate(%)',
          'TaxName',
          'TaxRate(%)',
          'ShippingFee',
          'WitholdingAccountCode',
          'WitholdingAmount(value or %)',
          '#paid?(yes/no)',
          '#PaymentMethod',
          '#PaidToAccountCode',
          'Tags (use ; to separate tags)',
          'WarehouseName',
        ],
      ];

      cashierDetailList.forEach((item) => {
        arrayExcel.push([
          item.patientName, //Customer
          item.email, // Email
          '', // BillingAddress
          '', // ShippingAddress
          moment(item.dueDate).format('DD-MM-YYYY'), // InvoiceDate
          moment(item.dueDate).format('DD-MM-YYYY'), // DueDate
          '', // ShippingDate
          '', // ShipVia
          '', // TrackingNo
          item.beId, //CustomerRefNo
          item.receiptNumber, //InvoiceNumber
          '', // Message
          '', // Memo
          item.treatmentName, // ProductName
          '', // Description
          item.quantity, // Quantity
          '', // Unit
          item.price, // UnitPrice
          item.productDiscountRate, // ProductDiscountRate
          0, // InvoiceDiscountRate
          item.taxName, // TaxName
          item.taxRate, // TaxRate
          '', // ShippingFee
          '', // WithholdingAccountCode
          '', // WitholdingAmount(value or %)
          'NO', // #paid?(yes/no)
          '', // #PaymentMethod
          '', // #PaidToAccountCode
          item.tags, // Tags (use ; to separate tags)
          '', // WarehouseName
        ]);
      });

      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.aoa_to_sheet(arrayExcel);
      XLSX.utils.book_append_sheet(wb, ws, 'Jurnal Sales Invoice');

      const fileName = `upload_jurnal_${moment().format('YYYY-MM-DD')}.xlsx`;

      /* Trigger Download with `writeFile` */
      XLSX.writeFile(wb, fileName, { compression: true });
    } catch (error) {
      Modal.warning({
        title: 'PERHATIAN',
        content: 'Terjadi error pada server. Silahkan coba lagi!',
      });
    }
  }

  const handleClinicChange = (value) => {
    setListClinic(value);
  };

  const handleClinicGroupChange = (value) => {
    setListClinicGroup(value);
  };

  // const handleCategoryChange = (value) => {
  //   setCategory(value);
  // };

  const handleCashierChange = (value) => {
    setCashier(value);
  };

  const handleStartDateChange = (date, dateString) => {
    if (!date) setStartDate(moment());
    else setStartDate(date);
  };

  const handleEndDateChange = (date, dateString) => {
    if (!date) setEndDate(moment());
    else setEndDate(date);
  };

  const handleRangeDateChange = (value) => {
    const today = moment();

    if (value === 'SEVEN') {
      const sevenDaysAgo = moment().subtract(7, 'd');

      setStartDate(sevenDaysAgo);
      setEndDate(today);
    } else if (value === 'THIRTY') {
      const thirtyDaysAgo = moment().subtract(30, 'd');

      setStartDate(thirtyDaysAgo);
      setEndDate(today);
    } else {
      setStartDate();
      setEndDate();
    }

    setRangeDate(value);
  };

  const onTableChange = (pagination) => {
    getCashierDetailList(pagination.pageSize, pagination.current);
    setPagination(pagination);
  };

  //search
  const onSearchChange = (e) => {
    const convertValue = e.target.value.toLowerCase();
    const labelValue = e.target.value;
    setSearch(labelValue);

    const filteredData = listCashierDetail.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          !isEmpty(item[key]) &&
          item[key].toString().toLowerCase().search(convertValue) !== -1
      );
    });

    setListFilteredCashierDetail(filteredData);
  };

  const resetAllFields = () => {
    setRangeDate();
    setStartDate();
    setEndDate();
    setListClinicGroup([]);
    setListClinic([]);
    setCashier();
    setEntryPoint();
  };

  const useInitData = () => {
    getCashierDetailList(pagination.pageSize, pagination.current);
    // getCategoryList();
    getCashierList();
    getClinicList();
    getClinicGroupList();
  };

  useEffect(useInitData, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <div className="mb-15">
          <Row gutter={24}>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Detail Laporan Kasir
              </strong>
            </Col>
          </Row>
        </div>
        <Collapse
          defaultActiveKey={['1']}
          expandIconPosition="right"
          className="mb-15">
          <Panel
            header={
              <strong className="text-md skybase-dashboard__title">
                Filter
              </strong>
            }
            key="1">
            <div>
              <Row gutter={[16, 8]}>
                <Col span={8}>
                  Date
                  <SelectDropdown
                    options={listDateRange}
                    placeHolder="Select Range Date"
                    onChange={handleRangeDateChange}
                    value={rangeDate}
                    allowClear
                  />
                </Col>
                <Col span={8}>
                  Start Date
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <DatePicker
                        showToday
                        allowClear={false}
                        format="DD/MM/YYYY"
                        onChange={handleStartDateChange}
                        value={startDate}
                        placeholder="Start Date"
                        disabled={rangeDate !== 'CUSTOM'}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  End Date
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <DatePicker
                        showToday
                        allowClear={false}
                        format="DD/MM/YYYY"
                        onChange={handleEndDateChange}
                        value={endDate}
                        placeholder="End Date"
                        disabled={rangeDate !== 'CUSTOM'}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col span={8}>
                  Clinic Groups
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <MultiselectDropdown
                        disabled={listClinic.length > 0}
                        options={listClinicGroupOptions}
                        placeHolder="Select Clinic Groups"
                        showArrow
                        onChange={handleClinicGroupChange}
                        selectValue={listClinicGroup}
                        allowClear
                      />
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  Clinics
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <MultiselectDropdown
                        disabled={listClinicGroup.length > 0}
                        options={listClinicOptions}
                        placeHolder="Select Clinics"
                        showArrow
                        onChange={handleClinicChange}
                        selectValue={listClinic}
                        allowClear
                      />
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  Cashiers
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <SelectDropdown
                        options={listCashierOptions}
                        placeHolder="Select Cashiers"
                        onChange={handleCashierChange}
                        value={cashier}
                        allowClear
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16,8]}>
                <Col span={8}>
                  Customer Entry Point
                  <div className='mb-15'>
                    <div className='skybase-dashboard__search'>
                      <SelectDropdown
                        options={custEntryPoint}
                        placeHolder='Select Customer Entry Point'
                        onChange={(value) => setEntryPoint(value)}
                        allowClear
                        value={entryPoint}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <hr></hr>
              <Row className="mt-20 mb-10">
                <Col span={24} type="flex" align="end">
                  <Row gutter={[16, 8]}>
                    <Button onClick={resetAllFields}>Reset</Button>
                    <Button
                      type="primary"
                      style={{ marginLeft: '10px', marginRight: '10px' }}
                      onClick={() => getCashierDetailList(10, 1)}>
                      Filter
                    </Button>
                  </Row>
                </Col>
              </Row>
            </div>
          </Panel>
        </Collapse>
        <div className="panel">
          <Row>
            <Col span={24}>
              <div className="mb-15">
                <strong className="text-md skybase-dashboard__title">
                  Detail Laporan Kasir
                </strong>
              </div>
            </Col>
          </Row>
          {loadingTable ? (
            <PageSpinner />
          ) : (
            <>
              <Row>
                <Col span={19} type="flex" align="start">
                  <div className="mb-15">
                    {/* <Dropdown
                      trigger={['click']}
                      overlay={() => (
                        <>
                          <Menu>
                            <Menu.Item onClick={generateExcelReport}>
                              <Button type="link"
                              // onClick={generateExcelReport}
                              >
                                Excel Detail Laporan Kasir
                              </Button>
                            </Menu.Item>
                            <Menu.Item>
                              <Button
                                type="link"
                                onClick={() => generateExcelJurnalIdReport(2)}>
                                Excel Sales Invoice - Jurnal.ID
                              </Button>
                            </Menu.Item>
                            <Menu.Item>
                              <Button
                                type="link"
                                onClick={() => generateExcelJurnalIdReport(3)}>
                                Excel Insurance Sales Invoice - Jurnal.ID
                              </Button>
                            </Menu.Item>
                          </Menu>
                        </>
                      )}
                      placement="bottomLeft"
                      arrow>
                      <Button>
                        <DownloadOutlined />
                        Export File <DownOutlined />
                      </Button>
                    </Dropdown> */}
                    <Button
                    onClick={generateExcelReport}
                    >
                      Excel Detail Laporan Kasir
                    </Button>
                    <Button
                      onClick={() => generateExcelJurnalIdReport(2)} style={{marginLeft: 5, marginRight: 5}}>
                      Excel Sales Invoice - Jurnal.ID
                    </Button>
                    <Button
                      onClick={() => generateExcelJurnalIdReport(3)}>
                      Excel Insurance Sales Invoice - Jurnal.ID
                    </Button>
                  </div>
                </Col>
                <Col span={5} type="flex" align="end">
                  <div className="mb-15">
                    <Search
                      placeholder="Search"
                      onChange={onSearchChange}
                      value={search}
                    />
                  </div>
                </Col>
              </Row>
              <Table
                dataSource={listFilteredCashierDetail}
                rowKey="key"
                columns={cashierReportDetailColumns}
                onChange={onTableChange}
                className="skybase-dashboard__active-order-table"
                footer={() => (
                  <Table
                    dataSource={listSummaryCashierDetail}
                    rowKey="key"
                    columns={discountSummaryColumns}
                    className="skybase-dashboard__active-order-table"
                    showHeader={false}
                    pagination={false}
                    size="small"
                  />
                )}
                pagination={pagination}
                size="small"
                scroll={{ x: 2000, y: 500 }}
              />
            </>
          )}
        </div>
      </Content>
    </Layout>
  );
}

export default withRouter(DetailLaporanKasir);
