import api from './index';

export default {
  getMethodList() {
    return api.get(`/finance/payment-method/type`);
  },

  submitMethod(payload) {
    return api.post(`/finance/payment-method/type`, payload);
  },

  editMethod(payload, id) {
    return api.put(`/finance/payment-method/type/${id}`, payload);
  },

  deleteMethod(id) {
    return api.delete(`/finance/payment-method/type/${id}`);
  },

  getPaymentMethodType() {
    return api.get(`/finance/payment-method`);
  },
};
