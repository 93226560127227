import api from './index';
import apiUpload from './index-upload';

export default {
  getListInsuranceCompany() {
    return api.get(`/insurance/companies`);
  },

  getInsuranceDetail(receiptId) {
    return api.get(`/insurance/receipts/${receiptId}/claim`);
  },

  submitInsuranceClaim(payload, receiptId) {
    return api.post(
      `/insurance/receipts/${receiptId}/claim-treatments`,
      payload
    );
  },

  submitInsuranceDocuments(payload, receiptId, lastUpdatedBy) {
    return apiUpload.post(
      `/insurance/receipts/${receiptId}/claim-documents?lastUpdatedBy=${lastUpdatedBy}`,
      payload
    );
  },

  deleteInsuranceLoaLocDocuments(payload, receiptId) {
    return api.put(
      `/insurance/receipts/${receiptId}/claim-treatments`,
      payload
    );
  },

  deleteInsuranceSupportDocuments(receiptId, lastUpdatedBy, documentId) {
    return api.delete(
      `/insurance/receipts/${receiptId}/claim-documents?lastUpdatedBy=${lastUpdatedBy}&documentId=${documentId}`
    );
  },

  updateInsuranceStatus(payload, receiptId) {
    return api.post(`/insurance/receipts/${receiptId}/claim-status`, payload);
  },

  getInsuranceList(
    status = '',
    clinicIds = '',
    doctorIds = '',
    keyword = '',
    from = '',
    to = '',
    limit = '',
    page = ''
  ) {
    return api.get(
      `/insurance/claims?${status ? `status=${status}&` : ''}${
        clinicIds ? `clinicId=${clinicIds}&` : ''
      }${doctorIds ? `doctorId=${doctorIds}&` : ''}${
        keyword ? `keyword=${keyword}&` : ''
      }${from ? `from=${from}` : ''}${to ? `&to=${to}` : ''}${
        limit ? `&limit=${limit}` : ''
      }${page ? `&page=${page}` : ''}`
    );
  },
};
